/* eslint-disable react-hooks/exhaustive-deps */
import { CloseIcon } from '@chakra-ui/icons';
import { VStack, Flex, Text } from '@chakra-ui/layout';
import { Button, Skeleton, Tag, Link as CLink, HStack } from '@chakra-ui/react';
import {
  AutoComplete,
  AutoCompleteCreatable,
  AutoCompleteGroup,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from '@choc-ui/chakra-autocomplete';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { getProblems } from '../../api';
import { useSuggestedProblems } from '../../hooks';

import { BoxWithTitle } from '@/components/common/BoxWithTitle';
import { FieldWrapper, TagCheckbox, TextArea } from '@/components/Form';
import { useAuth, useScroll } from '@/context/AppProvider';
import { useInfinitePagination } from '@/hooks/useInfinitePagination';
import { formatArray } from '@/utils/formatArray';

export const CarProblemsComponent = ({ selectedProblems, setSelectedProblems, next }) => {
  const { scrollRef } = useScroll();
  const [search, setSearch] = useState<string>('');

  const { data: problems, fetchNextPage } = useInfinitePagination({
    tag: 'getSearch',
    request: getProblems,
    search,
  });

  const { data: suggestedProblems, isLoading: isLoadingSuggestedProblems } = useSuggestedProblems(
    {},
  );

  const [onSelect, setOnSelect] = useState(true);
  const { user } = useAuth();

  const handleTagBoxChange = (value: any) => {
    if (selectedProblems.find((item) => item.label === value.label)) {
      setSelectedProblems(selectedProblems.filter((item) => item.label !== value.label));
    } else {
      setSelectedProblems((prev) => [...prev, value]);
    }

    setOnSelect(false);
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollToTop();
    }
  }, []);

  useEffect(() => {
    if (selectedProblems.length === 0) {
      setOnSelect(true);
    }
  }, [selectedProblems]);

  const debouncedFunction = (e: any, set: Dispatch<SetStateAction<any>>) => {
    setTimeout(() => set(e.target.value), 300);
  };

  return (
    <VStack
      spacing={3}
      alignItems="flex-start"
      justifyContent="flex-start"
      h="fit-content"
      p={[5, 0]}
    >
      <BoxWithTitle
        title="Qual o problema do seu carro?"
        titleSize="xl"
        wrapperProps={{
          w: 'full',
        }}
      >
        <HStack
          display="flex"
          flexWrap="wrap"
          align="flex-start"
          justify="start"
          w="full"
          spacing={5}
        >
          {selectedProblems.map((item) => (
            <Tag ml="0px !important" mr="15px !important" mb="10px" key={item.value}>
              {item.label}
              <CloseIcon
                cursor="pointer"
                onClick={() =>
                  setSelectedProblems(
                    selectedProblems.filter((problem) => item.label !== problem.label),
                  )
                }
                color="gray.500"
                fontSize={10}
                ml={3}
              />
            </Tag>
          ))}
        </HStack>
        {onSelect ? (
          <FieldWrapper
            name="problem_id"
            boxProps={{
              w: 'full',
              mt: -5,
            }}
            as={() => (
              <>
                <AutoComplete
                  onChange={(e) => {
                    const aux = problems?.pages
                      ?.flatMap((page) => page?.results)
                      ?.find((item) => item.name === e);

                    if (aux) {
                      if (selectedProblems.find((item) => item.label === aux.name)) {
                        setSelectedProblems(
                          selectedProblems?.filter((item) => item.label !== aux.name),
                        );
                      } else {
                        setSelectedProblems((prev) => [
                          ...prev,
                          { value: aux.id, label: aux.name },
                        ]);
                      }
                    } else {
                      if (selectedProblems.find((item) => item.label === e)) {
                        setSelectedProblems(selectedProblems?.filter((item) => item.label !== e));
                      } else {
                        setSelectedProblems((prev) => [...prev, { value: e, label: e }]);
                      }
                    }

                    setOnSelect(false);
                  }}
                  openOnFocus
                  creatable
                >
                  <AutoCompleteInput
                    mt={5}
                    rounded="md"
                    placeholder="Selecionar problema"
                    variant="outline"
                    onChange={(e) => debouncedFunction(e, setSearch)}
                  />
                  <AutoCompleteList
                    onScroll={(e) => {
                      const { clientHeight, scrollTop, scrollHeight } = e.target as HTMLElement;
                      const bottom = Math.ceil(clientHeight + scrollTop) >= scrollHeight;
                      if (bottom) fetchNextPage();
                    }}
                  >
                    <AutoCompleteGroup>
                      {problems?.pages?.flatMap((page) => page?.results) &&
                        problems?.pages?.flatMap((page) => page?.results)?.length > 0 &&
                        problems?.pages
                          ?.flatMap((page) => page?.results)
                          ?.map((problem) => problem?.name)
                          .map((name, index) => (
                            <AutoCompleteItem
                              key={`option-${index}`}
                              value={name}
                              textTransform="capitalize"
                            >
                              {String(name)}
                            </AutoCompleteItem>
                          ))}
                    </AutoCompleteGroup>

                    <AutoCompleteCreatable>
                      {({ value }) => <span>Adicionar {value}</span>}
                    </AutoCompleteCreatable>
                  </AutoCompleteList>
                </AutoComplete>
              </>
            )}
          />
        ) : (
          <>
            <Button mt={5} mb={5} size="sm" variant="@primary" onClick={() => setOnSelect(true)}>
              Adicionar outro problema
            </Button>
          </>
        )}
      </BoxWithTitle>

      {onSelect && (
        <BoxWithTitle
          title="Problemas mais comuns"
          titleSize="xl"
          subtitle="Selecione caso seu problema seja algum desses"
        >
          {isLoadingSuggestedProblems || !suggestedProblems ? (
            <Skeleton height={8} borderRadius={20} mb={8} />
          ) : (
            <FieldWrapper
              name="problem_id_tags"
              as={(fieldProps) => (
                <TagCheckbox
                  options={formatArray(suggestedProblems)}
                  additionalOnClick={handleTagBoxChange}
                  additionalSelectedItems={selectedProblems}
                  {...fieldProps}
                />
              )}
            />
          )}
        </BoxWithTitle>
      )}

      <BoxWithTitle
        title="Tem alguma observação?"
        titleSize="xl"
        wrapperProps={{
          w: 'full',
          marginTop: 15,
        }}
      >
        <FieldWrapper
          name="comment"
          boxProps={{
            w: 'full',
          }}
          required
          as={(fieldProps) => <TextArea {...fieldProps} />}
        />
      </BoxWithTitle>

      <Flex
        w="full"
        mb={5}
        h="fit-content"
        alignItems="center"
        flexDirection={{ base: 'column', lg: 'row' }}
      >
        <Button
          onClick={next}
          size="sm"
          variant="@primary"
          disabled={selectedProblems.length === 0}
          mb={{ base: 5, lg: 0 }}
        >
          Continuar para agendamento
        </Button>

        {!user && (
          <Text ml={{ base: 0, lg: 8 }}>
            Não perca as informações pesquisadas!{' '}
            <CLink textDecoration="underline">
              <Link to="/entrar">Crie sua conta</Link>
            </CLink>
          </Text>
        )}
      </Flex>
    </VStack>
  );
};
