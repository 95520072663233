/* eslint-disable react-hooks/exhaustive-deps */
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Flex,
  Button,
  VStack,
  Stack,
  HStack,
  Text,
  Divider,
  Box,
  Tag,
  useMediaQuery,
  Skeleton,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useCallback, useEffect, useRef, useState } from 'react';
import { BsChat } from 'react-icons/bs';
import { MdKeyboardArrowLeft } from 'react-icons/md';

dayjs.extend(customParseFormat);

import { updateBudget } from '../../api';
import { useBudgetParts, useChatNotifications } from '../../hooks';
import { budgetLabelMap } from '../../utils';
import { StoreChat } from '../StoreChat';

import { BudgetStatusProgress } from '@/components/common/BudgetStatusProgress';
import { useFCM } from '@/context/AppProvider';
import { db } from '@/firebase';
import { readNotification } from '@/modules/consumer';
import { createChatNotification } from '@/modules/store';

export const TrackShipping = ({ selectedSchedule, setSelectedSchedule, isUnread }) => {
  const [isSmallScreen] = useMediaQuery('(max-width: 860px)');
  const [openChat, setOpenChat] = useState(false);
  const { refetchUserNotifications, storeSentBudgets, storeOrders, storeBudgetHistory } = useFCM();

  const [socketList] = useState(
    JSON.stringify([...storeSentBudgets, ...storeOrders, ...storeBudgetHistory]),
  );

  const { data: budgetParts, isLoading: isLoadingBudgetParts } = useBudgetParts({
    id: selectedSchedule.id,
    config: {
      enabled: !!selectedSchedule,
    },
  });

  const { data: chatNotifications, refetch: refetchChatNotifications } = useChatNotifications({
    id: selectedSchedule.id,
    config: { enabled: !!selectedSchedule },
  });

  const markNotificationAsRead = useCallback(async () => {
    if (isUnread?.length > 0) {
      const readingNotifications = isUnread?.map(
        async (notification) => await readNotification(notification),
      );

      await Promise.all(readingNotifications).then(() => refetchUserNotifications());
    }
  }, [isUnread, refetchUserNotifications]);

  useEffect(() => {
    markNotificationAsRead();
  }, []);

  useEffect(() => {
    const aux = [...storeSentBudgets, ...storeOrders, ...storeBudgetHistory];

    if (socketList !== JSON.stringify(aux)) {
      setSelectedSchedule && setSelectedSchedule(null);
    }
  }, [storeSentBudgets, storeOrders, storeBudgetHistory]);

  const createNotification = async () => {
    await createChatNotification({
      user: selectedSchedule.budget_request.customer.id,
      budget: selectedSchedule.id,
    });
    refetchChatNotifications();
  };

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
  });

  useEffect(() => {
    db?.collection(
      `user-${selectedSchedule.budget_request.customer.id}-store-${selectedSchedule.store.id}-budget-${selectedSchedule.id}-budgetRequest-${selectedSchedule.budget_request.id}-chat`,
    )
      .orderBy('createdAt')
      .onSnapshot((querySnapShot: any) => {
        const data = querySnapShot.docs.map((doc: any) => ({
          ...doc.data(),
          id: doc.id,
        }));

        if (!isFirstRun.current) {
          if (data?.at(-1)?.sender === selectedSchedule.store.name) {
            createNotification();
            refetchChatNotifications();
          } else {
            refetchChatNotifications();
          }
        }
      });
  }, [db, selectedSchedule.budget_request.customer.id, selectedSchedule.store.id, isFirstRun]);

  return (
    <Flex w="full" flexDirection="column">
      <HStack w="100%" alignItems="center" justifyContent="space-between">
        <Button
          variant="ghost"
          leftIcon={<MdKeyboardArrowLeft />}
          alignSelf="flex-start"
          pl="0"
          mb="4"
          onClick={() => {
            if (openChat) {
              setOpenChat(!openChat);
            } else {
              setSelectedSchedule && setSelectedSchedule(null);
            }
          }}
          mt={10}
        >
          voltar
        </Button>

        {['PAID', 'SENT'].includes(selectedSchedule.status) && (
          <Button
            variant={
              chatNotifications?.find((notification) => notification.is_read === false)
                ? '@primary'
                : '@secondary'
            }
            leftIcon={<BsChat />}
            onClick={() => setOpenChat((prev) => !prev)}
            size={isSmallScreen ? 'sm' : 'md'}
          >
            {chatNotifications?.find((notification) => notification.is_read === false)
              ? 'Nova mensagem!'
              : 'Mensagens'}
          </Button>
        )}
      </HStack>

      {['PAID', 'SENT', 'FINISHED'].includes(selectedSchedule.status) && openChat ? (
        <Stack px="2">
          <StoreChat
            selectedSchedule={selectedSchedule}
            refetchChatNotifications={refetchChatNotifications}
          />
        </Stack>
      ) : (
        <VStack>
          <Stack
            flexDirection={{ base: 'column', sm: 'row' }}
            alignItems={{ base: 'flex-start', sm: 'center' }}
            justify="space-between"
            w="full"
            rounded="md"
            bg="white"
            p="4"
            spacing={0}
          >
            <HStack
              w={{ base: 'full', sm: 'fit-content' }}
              mb={{ base: 3, sm: 0 }}
              justify="space-between"
            >
              <HStack mr={3}>
                <Text color="gray.400" whiteSpace="nowrap">
                  {`${dayjs(selectedSchedule.created_at).format('DD/MM/YYYY')}, ${dayjs(
                    selectedSchedule.created_at,
                  ).format('HH:mm')}`}
                </Text>
              </HStack>

              <Text
                marginStart="0px !important"
                marginInlineStart="0px !important"
                fontWeight="bold"
              >{`${selectedSchedule.budget_request.car.model.manufacturer.name} ${selectedSchedule.budget_request.car.model.name} ${selectedSchedule.budget_request.car.year}`}</Text>
            </HStack>

            <HStack justify={{ base: 'flex-start', sm: 'flex-end' }} w="fit-content">
              {selectedSchedule.status === 'REJECTED' ? <CloseIcon /> : <CheckIcon />}
              <Text ml={6} whiteSpace="nowrap" pos="relative">
                {budgetLabelMap[selectedSchedule.status]}
              </Text>
            </HStack>
          </Stack>

          <VStack
            w="full"
            p={4}
            rounded="md"
            bg="white"
            alignItems="flex-start"
            flex="1"
            spacing={5}
          >
            <HStack w="full" justify={{ base: 'space-between', lg: 'flex-start' }}>
              <Text color="gray.400">Nome do cliente</Text>
              <Text fontWeight="bold">{selectedSchedule.budget_request.customer.name}</Text>
            </HStack>
            {selectedSchedule.status === 'FINISHED' && (
              <Box border="2px solid #F8BB32" rounded="full" p={2}>
                <Text fontWeight="bold">{`Entregue dia ${dayjs(selectedSchedule.updated_at).format(
                  'DD/MM/YYYY',
                )}`}</Text>
              </Box>
            )}
          </VStack>

          <VStack w="full" p={4} rounded="md" bg="white" flex="1" align="flex-start" spacing={0}>
            <HStack
              w="full"
              flexDir={{ base: 'column', md: 'row' }}
              align="flex-start"
              justify="space-between"
            >
              <Text fontSize="md" fontWeight="bold">
                Endereço
              </Text>

              <Text marginStart="0px !important" marginInlineStart="0px !important">
                {selectedSchedule.budget_request.shipping}
              </Text>
            </HStack>
          </VStack>

          <VStack
            w="full"
            p={4}
            rounded="md"
            bg="white"
            alignItems="flex-start"
            flex="1"
            spacing="5"
          >
            <Text fontSize="md" fontWeight="bold">
              Peças
            </Text>
            <VStack w="full">
              {isLoadingBudgetParts || !budgetParts ? (
                <Skeleton w="full" height={8} borderRadius={20} />
              ) : (
                budgetParts?.map((item) => (
                  <>
                    <VStack w="full" key={item.id} align="space-between" flexDirection="row" mb={5}>
                      <VStack align="flex-start" w="full">
                        <HStack w="full" align="space-between">
                          <VStack w="full" align="flex-start">
                            <Text fontWeight="bold">{`${item.quantity}x ${item.budget_request_part.part.name}`}</Text>
                            <Text color="gray.400">{item.manufacturer.name}</Text>
                          </VStack>

                          <VStack w="full" align="start">
                            <Text w="full" align="end" fontWeight="bold">
                              {Number(Number(item.price) * item.quantity).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </Text>
                          </VStack>
                        </HStack>

                        <HStack
                          display="flex"
                          flexWrap="wrap"
                          align="center"
                          justify="start"
                          w="full"
                        >
                          {item?.budget_request_part?.attributes?.map((attribute) => (
                            <Box
                              marginInlineStart="0px !important"
                              marginInlineEnd="3px !important"
                              key={attribute.id}
                              mt="5px !important"
                              mb="5px !important"
                            >
                              <Tag>
                                <Text marginTop="0px !important">{attribute.attribute.name}</Text>
                              </Tag>
                            </Box>
                          ))}
                        </HStack>
                      </VStack>
                    </VStack>

                    <Divider />
                  </>
                ))
              )}
            </VStack>
          </VStack>

          <VStack
            w="full"
            p={4}
            mb={5}
            rounded="md"
            bg="white"
            flex="1"
            align="flex-end"
            spacing="5"
          >
            <HStack w="full" justify="space-between">
              <VStack align="flex-start">
                <Text color="gray.500" fontSize="sm" fontWeight="bold">
                  Subtotal
                </Text>
                <Text color="gray.500" fontSize="sm" fontWeight="bold">
                  Frete
                </Text>
                <Text fontSize="md" fontWeight="bold">
                  Total
                </Text>
              </VStack>
              <VStack align="flex-end">
                {isLoadingBudgetParts || !budgetParts ? (
                  <Skeleton w="xs" height={8} borderRadius={20} />
                ) : (
                  <Text color="gray.500" fontSize="sm" fontWeight="bold">
                    {Number(
                      budgetParts?.reduce(
                        (acc, part) => acc + Number(part.price) * part.quantity,
                        0,
                      ),
                    ).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </Text>
                )}
                {isLoadingBudgetParts || !budgetParts ? (
                  <Skeleton w="xs" height={8} borderRadius={20} />
                ) : (
                  <Text color="gray.500" fontSize="sm" fontWeight="bold">
                    {Number(selectedSchedule.shipping_price).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </Text>
                )}

                {isLoadingBudgetParts || !budgetParts ? (
                  <Skeleton w="xs" height={8} borderRadius={20} />
                ) : (
                  <Text fontSize="md" fontWeight="bold">
                    {Number(
                      Number(
                        budgetParts?.reduce(
                          (acc, part) => acc + Number(part.price) * part.quantity,
                          0,
                        ),
                      ) + Number(selectedSchedule.shipping_price),
                    ).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </Text>
                )}
              </VStack>
            </HStack>
          </VStack>

          {selectedSchedule.status !== 'REJECTED' && (
            <HStack
              pt={5}
              sx={{
                '&::-webkit-scrollbar': {
                  width: 0,
                  backgroundColor: `transparent`,
                },
                '&::-webkit-scrollbar-thumb': {
                  width: 0,
                  backgroundColor: `transparent`,
                },
              }}
              overflow="overlay auto"
              w="full"
              align="flex-start"
            >
              <BudgetStatusProgress status={selectedSchedule.status} />
            </HStack>
          )}

          {selectedSchedule.status === 'PAID' && (
            <VStack
              w="full"
              p={4}
              flex="1"
              align={{ base: 'flex-start', md: 'center' }}
              spacing="5"
            >
              <Button
                onClick={async () => {
                  await updateBudget(selectedSchedule.id, {
                    status: 'SENT',
                  });

                  setSelectedSchedule(null);
                }}
                w="100%"
                maxW={300}
                size="sm"
                variant="@primary"
              >
                Enviar pedido
              </Button>
            </VStack>
          )}

          {selectedSchedule.status === 'SENT' && (
            <VStack
              w="full"
              p={4}
              flex="1"
              align={{ base: 'flex-start', md: 'center' }}
              spacing="5"
            >
              <Button
                onClick={async () => {
                  await updateBudget(selectedSchedule.id, {
                    status: 'FINISHED',
                  });

                  setSelectedSchedule(null);
                }}
                w="100%"
                maxW={300}
                size="sm"
                variant="@primary"
              >
                Marcar entrega como concluída
              </Button>
            </VStack>
          )}
        </VStack>
      )}
    </Flex>
  );
};
