/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, Text, VStack, HStack } from '@chakra-ui/layout';
import { Tag, TagLabel, TagRightIcon, Button, Input, Link as CLink } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import mapboxgl from 'mapbox-gl';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { FaRegEdit } from 'react-icons/fa';
import MapGL from 'react-map-gl';
import Geocoder from 'react-map-gl-geocoder';
import { Link } from 'react-router-dom';

import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import './geocoder.css';

import { getParts } from '../../api';
import { AddressDetail } from '../ChoosePartComponent/AddressDetail';

import { CreatePart } from './CreatePart';
import { EditPart } from './EditPart';

import { BoxWithTitle } from '@/components/common/BoxWithTitle';
import { useScroll } from '@/context/AppProvider';
import { useInfinitePagination } from '@/hooks/useInfinitePagination';

(mapboxgl as any).workerClass =
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

export const ChoosePartComponent = ({
  selectedParts,
  selectedAddress,
  setSelectedAddress,
  setSelectedParts,
  previous,
  submit,
  isLoading,
}) => {
  const { scrollRef } = useScroll();
  const [search, setSearch] = useState<string>('');
  const { data: parts, fetchNextPage } = useInfinitePagination({
    tag: 'getParts',
    request: getParts,
    search,
  });

  const [selectedPart, setSelectedPart] = useState<any>({});
  const [onEdition, setOnEdition] = useState<any | null>(null);
  const [attributes, setAttributes] = useState<any>();
  const [newPart, setNewPart] = useState<boolean>(true);

  const [parsedUser] = useState(() => {
    const user = localStorage.getItem('@user');
    return user && JSON.parse(user);
  });

  const [onEditionAddress, setOnEditionAddress] = useState(!selectedAddress);
  const [viewport, setViewport] = useState({});
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue('parts', selectedParts);
  }, [selectedParts]);

  useEffect(() => {
    scrollRef?.current?.scrollToTop();
  }, []);

  const mapRef = useRef<any>();

  const handleLabels = (part: any) => {
    switch (part?.multiple) {
      case 1:
        return part?.quantity > 1 ? `${part?.quantity} peças` : `1 peça`;
      case 2:
        return part?.quantity > 1 ? `${part?.quantity} pares` : `1 par`;
      default:
        return part?.quantity > 1 ? `${part?.quantity} jogos` : `1 jogo`;
    }
  };

  const handleViewportChange = useCallback((newViewport) => setViewport(newViewport), []);

  const getResult = useCallback((item) => {
    setSelectedAddress({
      ...selectedAddress,
      latitude: item.result.geometry.coordinates[1],
      longitude: item.result.geometry.coordinates[0],
      full_address: item.result.place_name,
      result: item,
    });
  }, []);

  return (
    <>
      <VStack p={5} spacing={3} alignItems="flex-start" justifyContent="flex-start" h="fit-content">
        <BoxWithTitle
          title="Endereço de entrega"
          titleSize="xl"
          wrapperProps={{
            rounded: 'md',
            mb: 10,
            maxW: ['100%', 400, 600],
          }}
        >
          <VStack align="flex-start" spacing={5}>
            <Flex mb={5} w="100%" h="fit-content" justify="flex-start" align="center">
              <MapGL
                ref={mapRef}
                {...viewport}
                width={400}
                height={400}
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                style={{
                  display: !onEditionAddress ? 'none' : 'block',
                }}
                onViewportChange={handleViewportChange}
                mapStyle="mapbox://styles/mapbox/light-v10"
              >
                <Geocoder
                  mapRef={mapRef}
                  mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                  onViewportChange={handleViewportChange}
                  placeholder="Buscar"
                  position="top-left"
                  onResult={getResult}
                />
              </MapGL>

              {!onEditionAddress && (
                <AddressDetail
                  fullAddress={selectedAddress.full_address}
                  complement={selectedAddress.complement}
                />
              )}

              {!onEditionAddress && (
                <Text
                  onClick={() => {
                    setSelectedAddress(null);
                    setOnEditionAddress(true);
                  }}
                  cursor="pointer"
                  fontWeight="bold"
                  color="gray.400"
                  background="transparent"
                >
                  Trocar
                </Text>
              )}
            </Flex>
            <Text display={!onEditionAddress ? 'none' : 'block'}>Complemento</Text>
            <Input
              display={!onEditionAddress ? 'none' : 'block'}
              defaultValue={selectedAddress?.complement || ''}
              value={selectedAddress?.complement || ''}
              onChange={(e) => {
                setSelectedAddress({
                  ...selectedAddress,
                  complement: e.target.value,
                });
              }}
              rounded="md"
            />
            <Button
              display={!onEditionAddress ? 'none' : 'block'}
              size="sm"
              variant="@primary"
              onClick={() => {
                setOnEditionAddress(false);
              }}
              isDisabled={!selectedAddress?.latitude && !selectedAddress?.longitude}
            >
              Adicionar endereço
            </Button>
          </VStack>
        </BoxWithTitle>

        <BoxWithTitle
          title="Qual a peça que você procura?"
          titleSize="xl"
          wrapperProps={{
            w: 'full',
          }}
        >
          <>
            <HStack
              display="flex"
              flexWrap="wrap"
              align="center"
              justify="start"
              w="full"
              spacing={0}
            >
              {selectedParts?.length && (
                <HStack
                  display="flex"
                  flexWrap="wrap"
                  align="center"
                  justify="start"
                  w="fit-content"
                  spacing={0}
                  mb={5}
                >
                  {selectedParts?.map((partItem) => {
                    return (
                      <Flex key={partItem.name} w="fit-content">
                        <Tag
                          display="flex"
                          alignItems="center"
                          bg="white"
                          border="none"
                          rounded="md"
                          px={4}
                          cursor="pointer"
                          w="fit-content"
                          ml={0}
                          marginInlineStart="0px !important"
                          marginStart="0px !important"
                          mb={3}
                        >
                          <TagLabel
                            ml={0}
                            marginInlineStart="0px !important"
                            marginStart="0px !important"
                            mr={5}
                          >
                            <Text fontSize="sm" fontWeight="bold" mb={2}>
                              {partItem.name}
                            </Text>
                            <Text color="gray.400" fontSize="sm" fontWeight="bold">
                              {handleLabels(partItem)}
                            </Text>
                          </TagLabel>
                          <TagRightIcon
                            onClick={() => {
                              setOnEdition(true);
                              setSelectedPart(partItem);
                              setSelectedParts(
                                selectedParts?.filter((part) => !_.isEqual(part, partItem)),
                              );
                            }}
                            fontSize={23}
                            as={FaRegEdit}
                            color="gray.400"
                          />
                          <TagRightIcon
                            fontSize={25}
                            onClick={() =>
                              setSelectedParts(
                                selectedParts.filter((part) => _.isEqual(part, partItem) === false),
                              )
                            }
                            as={AiOutlineDelete}
                            color="gray.400"
                          />
                        </Tag>
                      </Flex>
                    );
                  })}
                </HStack>
              )}

              {!newPart && !onEdition && (
                <Button size="sm" variant="@primary" onClick={() => setNewPart(true)}>
                  Adicionar peça
                </Button>
              )}
            </HStack>

            {onEdition ? (
              <EditPart
                allParts={parts}
                selectedPart={selectedPart}
                setSelectedPart={setSelectedPart}
                attributes={attributes}
                setAttributes={setAttributes}
                setSelectedParts={setSelectedParts}
                setOnEdition={setOnEdition}
                price={false}
                setSearch={setSearch}
                fetchNextPage={fetchNextPage}
              />
            ) : (
              newPart && (
                <CreatePart
                  allParts={parts}
                  selectedPart={selectedPart}
                  setSelectedPart={setSelectedPart}
                  attributes={attributes}
                  setAttributes={setAttributes}
                  setSelectedParts={setSelectedParts}
                  setNewPart={setNewPart}
                  price={false}
                  setSearch={setSearch}
                  fetchNextPage={fetchNextPage}
                />
              )
            )}
          </>
        </BoxWithTitle>

        {!parsedUser && (
          <Text pt={5} pb={0}>
            Não perca as informações pesquisadas!{' '}
            <CLink textDecoration="underline">
              <Link to="/entrar">Crie sua conta</Link>
            </CLink>
          </Text>
        )}

        <VStack pt={3} w="full" align="flex-start" flexDir="row">
          <Button
            mr={{ base: 0, md: 5 }}
            mb={{ base: 5, md: 0 }}
            variant="@primary"
            bg="gray.300"
            onClick={previous}
            size="sm"
          >
            Voltar
          </Button>

          {parsedUser ? (
            <Button
              mt="0px !important"
              isDisabled={!selectedParts?.length || !selectedAddress}
              variant="@primary"
              size="sm"
              type="submit"
              onClick={submit}
              isLoading={isLoading}
            >
              Buscar peças
            </Button>
          ) : (
            <Button
              mt="0px !important"
              isDisabled={!selectedParts?.length}
              variant="@primary"
              size="sm"
              type="submit"
              onClick={submit}
              isLoading={isLoading}
            >
              Buscar peças
            </Button>
          )}
        </VStack>
      </VStack>
    </>
  );
};
