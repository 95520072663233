import { createContext, useContext, useState } from 'react';

import { ConsumerScheduleContextType } from '../types';

const ConsumerScheduleContext = createContext({} as ConsumerScheduleContextType);

export const ConsumerScheduleConsumer = ({ children }) => (
  <ConsumerScheduleContext.Consumer>{children}</ConsumerScheduleContext.Consumer>
);

export const ConsumerScheduleProvider = ({ children }) => {
  const [selectedSchedule, setSelectedSchedule] = useState<any | null>(null);

  return (
    <ConsumerScheduleContext.Provider value={{ selectedSchedule, setSelectedSchedule }}>
      {children}
    </ConsumerScheduleContext.Provider>
  );
};

export const useConsumerSchedule = () => useContext(ConsumerScheduleContext);
