import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const getTimePassed = (created_at) => {
  const timePassed = dayjs().diff(created_at, 'second');

  if (timePassed < 60) {
    return `${timePassed} segundos`;
  } else if (timePassed < 3600) {
    return `${Math.floor(timePassed / 60)} minutos`;
  } else if (timePassed < 86400) {
    return `${Math.floor(timePassed / 3600)} horas`;
  } else if (timePassed < 604800) {
    return `${Math.floor(timePassed / 86400)} dias`;
  } else if (timePassed < 2419200) {
    return `${Math.floor(timePassed / 604800)} semanas`;
  } else if (timePassed < 29030400) {
    return `${Math.floor(timePassed / 2419200)} meses`;
  } else {
    return `${Math.floor(timePassed / 29030400)} años`;
  }
};
