import dayjs from 'dayjs';

export const getProgress = ({
  start_date,
  end_date,
}: {
  start_date: string;
  end_date: string;
}): number => {
  if (!start_date || !end_date) return -1;

  const start = dayjs(start_date).toDate().getTime();
  const end = dayjs(end_date).toDate().getTime();
  const now = Date.now();

  if (start > now) return 0;
  if (end < now) return 100;

  const progress = ((start - now) * 100) / (start - end);

  return progress;
};
