export default {
  baseStyle: {
    fontFamily: 'body',
    fontWeight: 'medium',
  },
  variants: {
    outline: () => ({
      borderColor: 'gray.300',
      border: '1px solid',
      bg: 'white',
      color: 'black',
      shadow: 'sm',
      rounded: 'lg',
    }),
  },
  defaultProps: {
    variant: 'outline',
  },
  sizes: {
    xs: {
      fontSize: 'xs',
    },
    sm: {
      fontSize: 'xs',
    },
    md: {
      fontSize: 'sm',
    },
    lg: {
      fontSize: 'md',
    },
  },
};
