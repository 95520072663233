import { useQuery } from 'react-query';

import { getCars } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseCarsOptions = {
  config?: QueryConfig<typeof getCars>;
};

export const useCars = ({ config }: UseCarsOptions) => {
  return useQuery(['getCars'], getCars, config);
};
