import { CarDiagnosisStatusI } from '@/modules/repair-shop';
import { BudgetStatusType } from '@/modules/store';

export const diagnosisLabelMap: { [key in CarDiagnosisStatusI]: string } = {
  started: 'Aguardando diagnóstico',
  diagnosed: 'Diagnóstico realizado',
  diagnostic_accepted_by_user: 'Aguardando início do serviço',
  diagnostic_canceled_by_user: 'Serviço cancelado',
  diagnostic_canceled_by_repairshop: 'Serviço cancelado',
  in_progress: 'Serviço em andamento',
  finished: 'Serviço finalizado',
};

export const budgetLabelMap: { [key in BudgetStatusType]: string } = {
  INIT: 'Aguardando orçamento',
  BUDGETED: 'Orçamento realizado',
  REJECTED: 'Orçamento rejeitado',
  PAID: 'Pedido solicitado',
  SENT: 'Pedido em transporte',
  CANCELLED: 'Pedido cancelado',
  FINISHED: 'Pedido finalizado',
};
