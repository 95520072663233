/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { CarDiagnosis } from '../components/CarDiagnosis';
import { NewSolicitations } from '../components/NewSolicitations';
import { RepairShopSidebar } from '../components/RepairShopSidebar';
import { SchedulesList } from '../components/SchedulesList';
import { RepairShopScheduleConsumer, RepairShopScheduleProvider } from '../contexts';
import { RepairShopScheduleContextI } from '../types';

import { Page } from '@/components/Page';
import { TabList, Tabs, Tab, TabPanels, TabPanel } from '@/components/Tabs';
import { useFCM } from '@/context/AppProvider';

export const MySchedules = () => {
  const [activeTab, setActiveTab] = useState<string>('schedules');
  const [scheduleNotifications, setScheduleNotifications] = useState<any[]>([]);
  const [scheduleRequestNotifications, setScheduleRequestNotifications] = useState<any[]>([]);

  const { userNotifications, refetchUserNotifications } = useFCM();

  useEffect(() => {
    setScheduleNotifications(
      userNotifications?.filter((notification) =>
        [
          'REPAIRSHOP_SCHEDULE',
          'DIAGNOSIS_IN_PROGRESS',
          'DIAGNOSIS_REALIZED',
          'DIAGNOSIS_APPROVED',
          'DIAGNOSIS_FINISHED',
        ].includes(notification.type),
      ),
    );
    setScheduleRequestNotifications(
      userNotifications?.filter((notification) => notification.type === 'REPAIRSHOP_NEW_REQUEST'),
    );
  }, [userNotifications]);

  useEffect(() => refetchUserNotifications(), [activeTab]);

  return (
    <Page title="Seus agendamentos" pos="relative" minH="100vh">
      <RepairShopScheduleProvider>
        <RepairShopScheduleConsumer>
          {({ selectedSchedule, setSelectedSchedule }: RepairShopScheduleContextI) => (
            <RepairShopSidebar>
              {selectedSchedule ? (
                <CarDiagnosis
                  selectedSchedule={selectedSchedule}
                  setSelectedSchedule={setSelectedSchedule}
                  isUnread={scheduleNotifications?.find(
                    (notification) => notification.object_pk === selectedSchedule.id,
                  )}
                />
              ) : (
                <Tabs>
                  <TabList bg="gray.100" w="full" d="flex">
                    <Tab onClick={() => setActiveTab('schedules')} fontSize="sm">
                      Agendamentos
                      {scheduleNotifications?.length > 0 && (
                        <Text
                          color="black"
                          fontSize="xs"
                          w={5}
                          h={5}
                          display="flex"
                          alignItems="center"
                          textAlign="center"
                          justifyContent="center"
                          rounded="full"
                          bg="brand.500"
                          ml={3}
                        >
                          {scheduleNotifications.length}
                        </Text>
                      )}
                    </Tab>
                    <Tab onClick={() => setActiveTab('schedule-requests')} fontSize="sm">
                      Novas solicitações
                      {scheduleRequestNotifications?.length > 0 && (
                        <Text
                          color="black"
                          fontSize="xs"
                          w={5}
                          h={5}
                          display="flex"
                          alignItems="center"
                          textAlign="center"
                          justifyContent="center"
                          rounded="full"
                          bg="brand.500"
                          ml={3}
                        >
                          {scheduleRequestNotifications.length}
                        </Text>
                      )}
                    </Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <Box w="full">
                        <SchedulesList activeTab={activeTab} />
                      </Box>
                    </TabPanel>
                    <TabPanel>
                      <Box w="full">
                        <NewSolicitations activeTab={activeTab} />
                      </Box>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              )}
            </RepairShopSidebar>
          )}
        </RepairShopScheduleConsumer>
      </RepairShopScheduleProvider>
    </Page>
  );
};
