import { HStack, Stack, VStack, Text, Avatar } from '@chakra-ui/react';
import { AiFillStar } from 'react-icons/ai';

import { useBudget } from '../../hooks';

export const EvaluationCard = ({ evaluation }) => {
  const { data: budget } = useBudget({
    id: evaluation.budget,
    config: { enabled: !!evaluation },
  });

  return (
    <Stack
      rounded="md"
      bg="white"
      shadow="lg"
      p="4"
      flexDirection="column"
      spacing={2}
      mr={{ base: 0, md: 4 }}
      mb={4}
      justifyContent="space-between"
    >
      <VStack alignItems="flex-start" w="full" spacing="1">
        <HStack w="full" align="flex-start" justify="space-between">
          <HStack align="flex-start">
            <Avatar rounded="full" w={14} h={14} src={evaluation.user.photo} mr={3} />
            <VStack w="full" align="flex-start">
              <Text fontWeight="bold" fontSize="sm">
                {evaluation.user.name}
              </Text>
              <HStack>
                {budget?.budget_parts?.map((part, index) => (
                  <Text
                    key={part.id}
                    color="gray.400"
                    mt="0px !important"
                    fontWeight="bold"
                    fontSize="xs"
                  >
                    {`${part.budget_request_part.part.name}${
                      budget.budget_parts.length - 1 === index ? '' : ', '
                    }`}
                  </Text>
                ))}
              </HStack>

              <HStack display={{ base: 'flex', sm: 'none' }} py={2}>
                {new Array(Math.trunc(evaluation.stars)).fill(0).map((star) => (
                  <AiFillStar key={star} size="20px" color="#EFA808" />
                ))}
              </HStack>
            </VStack>
          </HStack>
          <HStack display={{ base: 'none', sm: 'flex' }} py={2}>
            {new Array(Math.trunc(evaluation.stars)).fill(0).map((star) => (
              <AiFillStar key={star} size="20px" color="#EFA808" />
            ))}
          </HStack>
        </HStack>
        <VStack w="full" align="flex-start" px={20} pr={{ base: 0, sm: 20 }}>
          {evaluation.comment && <Text mb={5}>{evaluation.comment}</Text>}
        </VStack>

        <VStack w="full" align="flex-start" px={5} pl={2}>
          {evaluation.store_reply && budget && (
            <VStack w="full" align="flex-start">
              <HStack w="full" align="flex-start" justify="space-between">
                <HStack align="flex-start">
                  <Avatar rounded="full" w={10} h={10} src={budget.store.profile_pic} mr={5} />
                  <VStack align="flex-start">
                    <Text fontWeight="bold" fontSize="sm">
                      {budget.store.name}
                    </Text>
                    <Text>{evaluation.store_reply}</Text>
                  </VStack>
                </HStack>
              </HStack>
            </VStack>
          )}
        </VStack>
      </VStack>
    </Stack>
  );
};
