/* eslint-disable react/display-name */
import { Box } from '@chakra-ui/layout';
import Scrollbar from 'react-scrollbars-custom';

import { useScroll } from '@/context/AppProvider';

export const Scroll = ({ children }: any) => {
  const { scrollRef } = useScroll();

  return (
    <Box pos="fixed" boxSize="full">
      <Scrollbar
        ref={scrollRef}
        contentProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;

            return <span {...restProps} style={{ padding: 0 }} ref={elementRef} />;
          },
        }}
        // mobileNative
      >
        {children}
      </Scrollbar>
    </Box>
  );
};
