import { useQuery } from 'react-query';

import { getBudgetParts } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseBudgetPartsOptions = {
  id: number;
  config?: QueryConfig<typeof getBudgetParts>;
};

export const useBudgetParts = ({ id, config }: UseBudgetPartsOptions) => {
  return useQuery(['getBudgetParts', id], getBudgetParts, config);
};
