import {
  Address,
  CreateAddress,
  RepairShop,
  RepairShopSchedule,
  RepairShopSolicitation,
} from './types';

import axios from '@/lib/axios';

export const getSchedule = (queryObject): Promise<RepairShopSchedule[]> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`/schedule/${id}/`);
};

export const getRepairShop = async (queryObject): Promise<RepairShop> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`/repair-shop/${id}`);
};

export const getRepairShopRating = async (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`repairshop-rating/${id}/`);
};

export const getRepairShopPhotos = async (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`repair-shop-photo/${id}/photos/`);
};

export const getRepairShopServices = async (pageParam, queryKey): Promise<any> => {
  const [_, search, id] = queryKey;

  return axios
    .authorized({})
    .get(`repair-shop-service/${id}/by-repairshop/?page=${pageParam}&name=${search || ''}`);
};

export const getRepairShopServicesWithoutPagination = async (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`repair-shop-service/${id}/by-repairshop/`);
};

export const getRepairShopSolicitations = (): Promise<RepairShopSolicitation[]> => {
  return axios.authorized({}).get('schedule-request-repairshop/all-repairshop-schedules/');
};

export const getRepairShopSchedules = (queryObject): Promise<RepairShopSchedule[]> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`/schedule/${id}/repairshop-schedules/`);
};

export const answerRepairShopSchedule = (id, isApproved) => {
  return axios.authorized({}).patch(`/schedule-request-repairshop/${id}/`, {
    is_approved: isApproved,
  });
};

export const deleteRepairShopScheduleRequest = (id) => {
  return axios.authorized({}).delete(`/schedule-request-repairshop/${id}/`);
};

export const createRepairShopAddress = (address: CreateAddress): Promise<Address> => {
  return axios.authorized({}).post(`/address/`, address);
};

export const updateRepairShop = (repairShop: FormData, id: number) => {
  return axios.authorized({}).put(`/repair-shop/${id}/`, repairShop);
};

export const updateRepairShopUserEmail = (id: number, email: string) => {
  return axios.authorized({}).patch(`/user/${id}/`, { email });
};

export const createRepairShopDiagnsosis = (diagnosis): Promise<any> => {
  return axios.authorized({}).post(`/repair-shop-diagnosis/`, diagnosis);
};

export const updateRepairShopDiagnsosis = (id, diagnosis): Promise<any> => {
  return axios.authorized({}).patch(`/repair-shop-diagnosis/${id}/`, diagnosis);
};

export const updateSchedule = (id: number, data: any): Promise<any> => {
  return axios.authorized({}).patch(`/schedule/${id}/`, data);
};

export const getDiagnosis = (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`/repair-shop-diagnosis/${id}/by-schedule/`);
};

export const getDiagnosisPhotos = (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`/repair-shop-diagnosis-photo/${id}/by-diagnosis/`);
};

export const getRepairShopEvaluations = (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`/schedule-evaluation/${id}/by-repair-shop/`);
};

export const sendEvaluationReply = (id, data): Promise<any> => {
  return axios.authorized({}).patch(`/schedule-evaluation/${id}/`, { repair_shop_reply: data });
};

export const sendBudgetEvaluationReply = (id, data): Promise<any> => {
  return axios.authorized({}).patch(`/budget-evaluation/${id}/`, { store_reply: data });
};

export const createRepairShopService = (service): Promise<any> => {
  return axios.authorized({}).post(`/schedule-service/`, service);
};

export const createSchedulePart = (data): Promise<any> => {
  return axios.authorized({}).post(`/schedule-part/`, data);
};

export const getRepairShopParts = (pageParam, queryKey): Promise<any> => {
  const [_, search] = queryKey;

  return axios.authorized({}).get(`/repair-shop-part/?page=${pageParam}&name=${search || ''}`);
};

export const createSchedulePartAttributes = (data): Promise<any> => {
  return axios.authorized({}).post(`/schedule-part-attribute/`, data);
};

export const getRepairShopManufacturers = (pageParam, queryKey): Promise<any> => {
  const [_, search] = queryKey;

  return axios.authorized({}).get(`/part-manufacturer/?page=${pageParam}&name=${search || ''}`);
};

export const updateUser = (id, data): Promise<any> => {
  return axios.authorized({}).patch(`/user/${id}/`, data);
};

export const createChatNotification = (data): Promise<any> => {
  return axios.authorized({}).post(`/chat-schedule-notification/ `, data);
};

export const getChatNotifications = (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios
    .authorized({})
    .get(`/chat-schedule-notification/${id}/notification-by-user-and-schedule/`);
};

export const readAllNotifications = (): Promise<any> => {
  return axios.authorized({}).post('/chat-schedule-notification/read-all-notifications/');
};
