type ObjectFormularyType = {
  [key: string]: any;
};

export const getFormularyDiff = (initial: ObjectFormularyType, final: ObjectFormularyType) => {
  return Object.keys(final).reduce((prev, curr) => {
    if (initial[curr] !== final[curr]) {
      return { ...prev, [curr]: final[curr] };
    }
    return prev;
  }, {});
};
