import pipe from 'ramda/es/pipe';
import create, { StateCreator } from 'zustand';

import { ScheduleRequest } from '../types';

import { immer } from '@/lib/zustand-middlewares';

interface ScheduleRequestI {
  state: {
    formData:
      | (Omit<ScheduleRequest, 'problem_id_tags' | 'problem_id'> & { problem_id: string[] })
      | unknown;
  };
  actions: {
    setData: (
      data: Omit<ScheduleRequest, 'problem_id_tags' | 'problem_id'> & { problem_id: string[] },
    ) => void;
    reset: () => void;
  };
}

const createStore = pipe(immer, create);

const initialState = {
  formData: window.localStorage.getItem('@schedule-request')
    ? (JSON.parse(window.localStorage.getItem('@schedule-request') as string) as Omit<
        ScheduleRequest,
        'problem_id_tags'
      >)
    : ({} as Omit<ScheduleRequest, 'problem_id_tags'>),
};

const store: StateCreator<ScheduleRequestI> = (set) => ({
  state: {
    ...initialState,
  },
  actions: {
    setData: (data) =>
      set(({ state }) => {
        state.formData = data;
      }),
    reset: () => set({ state: { ...initialState } }),
  },
});

export const useScheduleRequestStore = createStore(store);
