import { Box } from '@chakra-ui/layout';
import ptBR from 'date-fns/locale/pt-BR';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import ReactDatePicker, { registerLocale } from 'react-datepicker';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

registerLocale('pt-BR', ptBR);

export const DatePicker = ({
  selectedDate,
  onChange,
  isClearable = false,
  showPopperArrow = false,
  ...props
}) => {
  const isAfterEight = dayjs().isAfter(dayjs().set('hour', 7).set('minute', 59));
  const isAfterSix = dayjs().isAfter(dayjs().set('hour', 17).set('minute', 59));
  const isToday = dayjs().isSame(selectedDate, 'day');
  const hasSelectedDate = !!selectedDate;

  const availableDates = isAfterEight ? dayjs().toDate() : dayjs().hour(7).minute(59).toDate();

  return (
    // if you don't want to use chakra's colors or you just wwant to use the original ones,
    // set className to "light-theme-original" ↓↓↓↓
    <Box className="light-theme-original">
      <ReactDatePicker
        locale="pt-BR"
        minTime={
          hasSelectedDate
            ? isToday
              ? availableDates
              : dayjs().hour(7).minute(59).toDate()
            : availableDates
        }
        maxTime={dayjs().hour(18).minute(0).toDate()}
        minDate={isAfterSix ? dayjs().add(1, 'day').toDate() : dayjs().toDate()}
        dateFormat="dd/MM/yyyy"
        timeCaption="Hora"
        selected={selectedDate}
        onChange={onChange}
        isClearable={isClearable}
        showPopperArrow={showPopperArrow}
        className="react-datapicker__input-text" //input is white by default and there is no already defined class for it so I created a new one
        {...props}
      />
    </Box>
  );
};
