import { HStack, Stack, VStack, Text, Avatar } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { AiFillStar } from 'react-icons/ai';

import { useDiagnosis } from '@/modules/repair-shop';

export const EvaluationCard = ({ evaluation }) => {
  const [services, setServices] = useState<string[]>([]);

  const { data: diagnosis } = useDiagnosis({
    id: evaluation.schedule,
    config: { enabled: !!evaluation },
  });

  useEffect(() => {
    if (diagnosis) {
      setServices(diagnosis[0].services_list.map(({ service }) => service.name));
    }
  }, [diagnosis]);

  return (
    <Stack
      rounded="md"
      bg="white"
      shadow="lg"
      p="4"
      flexDirection="column"
      spacing={2}
      mr={{ base: 0, md: 4 }}
      mb={4}
      justifyContent="space-between"
    >
      <VStack alignItems="flex-start" w="full" spacing="1">
        <HStack w="full" align="flex-start" justify="space-between">
          <HStack align="flex-start">
            <Avatar rounded="full" w={14} h={14} src={evaluation.user.photo} mr={3} />
            <VStack w="full" align="flex-start">
              <Text fontWeight="bold" fontSize="sm">
                Você
              </Text>
              <HStack>
                {services.map((service, index) => (
                  <Text
                    key={service}
                    color="gray.400"
                    mt="0px !important"
                    fontWeight="bold"
                    fontSize="xs"
                  >
                    {`${service}${services.length - 1 === index ? '' : ', '}`}
                  </Text>
                ))}
              </HStack>

              <HStack display={{ base: 'flex', sm: 'none' }} py={2}>
                {new Array(Math.trunc(evaluation.stars)).fill(0).map((star) => (
                  <AiFillStar key={star} size="20px" color="#EFA808" />
                ))}
              </HStack>
            </VStack>
          </HStack>
          <HStack display={{ base: 'none', sm: 'flex' }} py={2}>
            {new Array(Math.trunc(evaluation.stars)).fill(0).map((star) => (
              <AiFillStar key={star} size="20px" color="#EFA808" />
            ))}
          </HStack>
        </HStack>
        <VStack w="full" align="flex-start" px={20} pr={{ base: 0, sm: 20 }}>
          {evaluation.comment ? (
            <Text mb={5}>{evaluation.comment}</Text>
          ) : (
            <Text color="gray.500" mb={5}>
              O usuário avaliou mas não deixou comentários.
            </Text>
          )}
        </VStack>

        <VStack w="full" align="flex-start" px={5} pl={2}>
          {evaluation?.repair_shop_reply && (
            <VStack w="full" align="flex-start">
              <HStack w="full" align="flex-start" justify="space-between">
                <HStack align="flex-start">
                  <Avatar
                    rounded="full"
                    w={10}
                    h={10}
                    src={diagnosis?.length && diagnosis[0]?.repair_shop?.profile_pic}
                    mr={5}
                  />
                  <VStack align="flex-start">
                    <Text fontWeight="bold" fontSize="sm">
                      {diagnosis?.length && diagnosis[0]?.repair_shop.name}
                    </Text>
                    <Text>{evaluation.repair_shop_reply}</Text>
                  </VStack>
                </HStack>
              </HStack>
            </VStack>
          )}
        </VStack>
      </VStack>
    </Stack>
  );
};
