/* eslint-disable react-hooks/exhaustive-deps */
import { StarIcon } from '@chakra-ui/icons';
import {
  Flex,
  Button,
  VStack,
  Stack,
  HStack,
  Text,
  Tag,
  Divider,
  Spinner,
  Skeleton,
  useToast,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useCallback, useEffect, useState } from 'react';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { Swiper, SwiperSlide } from 'swiper/react';

import { readNotification, updateBudgetRequest } from '../../api';
import { BudgetRequestCheckout } from '../BudgetRequestCheckout';

import { useFCM } from '@/context/AppProvider';

dayjs.extend(customParseFormat);

export const BudgetRequest = ({
  selectedSchedule,
  setSelectedSchedule,
  isUnread /* , isLoaded */,
}) => {
  const {
    setBudgetRequest: setBudget,
    budgetRequestResponse,
    isLoadingBudgetRequestResponse,
    refetchUserNotifications,
    consumerBudgetRequests,
    refetchConsumerBudgetRequests,
  } = useFCM();

  const toast = useToast();

  const [socketList] = useState(consumerBudgetRequests);

  const [budgetRequest, setBudgetRequest] = useState<any>(null);

  const markNotificationAsRead = useCallback(async () => {
    if (isUnread?.length > 0) {
      const readingNotifications = isUnread?.map(
        async (notification) => await readNotification(notification),
      );

      await Promise.all(readingNotifications).then(() => refetchUserNotifications());
    }
  }, [isUnread, refetchUserNotifications]);

  useEffect(() => {
    setBudget(selectedSchedule);
    markNotificationAsRead();
  }, []);

  useEffect(() => {
    if (socketList !== consumerBudgetRequests) {
      setSelectedSchedule && setSelectedSchedule(null);
    }
  }, [consumerBudgetRequests]);

  return (
    <Flex w="full" flexDirection="column">
      <Button
        variant="ghost"
        leftIcon={<MdKeyboardArrowLeft />}
        alignSelf="flex-start"
        pl="0"
        mb="4"
        onClick={() => {
          if (setSelectedSchedule) setSelectedSchedule(null);
        }}
      >
        voltar
      </Button>

      <VStack spacing={10}>
        {!budgetRequest && (
          <VStack w="full" alignItems="flex-start" flex="1">
            <Text fontSize="lg" fontWeight="bold" mb={3}>
              Orçamentos recebidos
            </Text>

            <Swiper
              style={{ width: '100%' }}
              allowSlideNext={budgetRequestResponse?.length > 1}
              spaceBetween={15}
            >
              {isLoadingBudgetRequestResponse ? (
                <Skeleton w={300} h={350} rounded="2xl" />
              ) : (
                budgetRequestResponse &&
                [...budgetRequestResponse, {}]?.map((item, index) =>
                  index === budgetRequestResponse?.length ? (
                    <SwiperSlide
                      style={{
                        width: 'fit-content !important',
                        maxWidth: '300px',
                        margin: 0,
                        marginRight: '10px !important',
                      }}
                    >
                      <VStack
                        cursor="default"
                        p={4}
                        w={300}
                        h={350}
                        align="center"
                        justify="center"
                      >
                        <Spinner
                          thickness="4px"
                          speed="0.65s"
                          emptyColor="gray.200"
                          color="#F8BB32"
                          size="xl"
                          mb={3}
                        />
                        {budgetRequestResponse?.length ? (
                          <>
                            <Text>Buscando mais</Text>
                            <Text>orçamentos...</Text>
                          </>
                        ) : (
                          <Text>Buscando orçamentos</Text>
                        )}
                      </VStack>
                    </SwiperSlide>
                  ) : (
                    <SwiperSlide
                      key={item.id}
                      style={{
                        width: 'fit-content !important',
                        maxWidth: '300px',
                        margin: 0,
                        marginRight: '10px !important',
                      }}
                    >
                      <VStack
                        p={4}
                        w={300}
                        h={350}
                        align="center"
                        justify="space-between"
                        bg="white"
                        rounded="2xl"
                      >
                        <VStack w="100%" align="flex-start" mb={5}>
                          <Text fontWeight="bold">{item.store.name}</Text>
                          <HStack>
                            {item.store_evaluation ? (
                              Array.from({ length: item.store_evaluation }).map((_, index) => (
                                <StarIcon key={index} color="brand.500" />
                              ))
                            ) : (
                              <Text>Esta loja ainda não possui avaliações.</Text>
                            )}
                          </HStack>
                          <Divider />

                          <Text border="1.5px solid #f9bb33" px={3} py={2} rounded="2xl">
                            {`Entrega em ${item.shipping_time} horas`}
                          </Text>

                          <Divider />

                          <HStack mt="15px !important" w="full" justify="flex-end">
                            <Text color="gray.500" fontSize="sm">
                              Subtotal
                            </Text>

                            <Text fontSize="sm" fontWeight="bold">
                              {Number(
                                item?.budget_parts?.reduce(
                                  (acc, curr) => acc + Number(curr.price) * curr.quantity,
                                  0,
                                ),
                              ).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </Text>
                          </HStack>
                          <HStack w="full" justify="flex-end">
                            <Text color="gray.500" fontSize="sm">
                              Frete
                            </Text>

                            <Text fontSize="sm" fontWeight="bold">
                              {Number(item.shipping_price).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </Text>
                          </HStack>

                          <Divider />

                          <HStack w="full" justify="end">
                            <HStack align="end" flexDir={{ base: 'column', md: 'row' }}>
                              <Text color="gray.500" fontSize="md" fontWeight="bold">
                                Total
                              </Text>
                              <Text fontSize="lg" fontWeight="bold">
                                {Number(
                                  Number(
                                    item?.budget_parts?.reduce(
                                      (acc, curr) => acc + Number(curr.price) * curr.quantity,
                                      0,
                                    ),
                                  ) + Number(item.shipping_price),
                                ).toLocaleString('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL',
                                })}
                              </Text>
                            </HStack>
                          </HStack>
                        </VStack>
                        <Button
                          onClick={() => {
                            setBudgetRequest(item);
                          }}
                          size="md"
                          variant="@primary"
                        >
                          Escolher orçamento
                        </Button>
                      </VStack>
                    </SwiperSlide>
                  ),
                )
              )}
            </Swiper>
          </VStack>
        )}

        <VStack w="full" alignItems="flex-start" flex="1">
          <Text w="full" textAlign="left" fontSize="lg" fontWeight="bold" mb={3}>
            Informações do pedido
          </Text>

          <Stack
            flexDirection={{ base: 'column', md: 'row' }}
            alignItems={{ base: 'flex-start', md: 'center' }}
            justify="space-between"
            spacing={{ base: 2, md: 0 }}
            w="full"
            rounded="md"
            bg="white"
            p="4"
          >
            <HStack>
              <HStack mr={3}>
                <Text fontWeight="bold" whiteSpace="nowrap">
                  {`${selectedSchedule.car.model.manufacturer.name} ${selectedSchedule.car.model.name} ${selectedSchedule?.car.year}`}
                </Text>
                <Text color="gray.400" whiteSpace="nowrap">
                  {`${dayjs(selectedSchedule.created_at).format('DD/MM/YYYY')}, ${dayjs(
                    selectedSchedule.created_at,
                  ).format('HH:mm')}`}
                </Text>
              </HStack>
            </HStack>

            <HStack justifyContent="space-between" w={{ base: 'full', md: 'fit-content' }}>
              <Text
                ml={6}
                whiteSpace="nowrap"
                pos="relative"
                _before={{
                  content: '""',
                  width: 4,
                  height: 4,
                  zIndex: 1000,
                  pos: 'absolute',
                  top: '50%',
                  left: -6,
                  transform: 'translateY(-50%)',
                  border: '3px solid',
                  borderColor: 'brand.500',
                  rounded: 'full',
                }}
              >
                {budgetRequestResponse?.length ? 'Escolhendo orçamento' : 'Aguardando orçamento'}
              </Text>
            </HStack>
          </Stack>

          <VStack w="full" p={4} rounded="md" bg="white" alignItems="flex-start" flex="1">
            <Text>Endereço</Text>
            <Text fontWeight="bold">{selectedSchedule.shipping}</Text>
          </VStack>

          <VStack
            w="full"
            p={4}
            rounded="md"
            bg="white"
            alignItems="flex-start"
            flex="1"
            spacing="5"
          >
            <Text fontSize="sm">Peças</Text>
            <VStack w="full">
              <HStack w="full">
                <VStack w="full">
                  {budgetRequest
                    ? budgetRequest?.budget_parts?.map((item) => {
                        return (
                          <VStack
                            w="full"
                            key={item.id}
                            align="space-between"
                            flexDirection="row"
                            mb={5}
                          >
                            <VStack align="flex-start" w="full">
                              <HStack w="full" align="space-between">
                                <VStack w="full" align="flex-start">
                                  <Text fontWeight="bold">{`${item.quantity}x ${item.budget_request_part.part.name}`}</Text>
                                  <Text color="gray.400">{item.manufacturer.name}</Text>
                                </VStack>

                                <VStack w="full" align="start">
                                  <Text w="full" align="end" fontWeight="bold">
                                    {Number(Number(item.price) * item.quantity).toLocaleString(
                                      'pt-BR',
                                      {
                                        style: 'currency',
                                        currency: 'BRL',
                                      },
                                    )}
                                  </Text>
                                </VStack>
                              </HStack>
                              <HStack
                                display="flex"
                                flexWrap="wrap"
                                align="flex-start"
                                justify="start"
                                w="full"
                                spacing={0}
                              >
                                {item?.budget_request_part?.attributes?.map((attribute) => (
                                  <Tag
                                    ml="0px !important"
                                    mr="15px !important"
                                    mb="10px"
                                    key={attribute.id}
                                  >
                                    <Text>{attribute.attribute.name}</Text>
                                  </Tag>
                                ))}
                              </HStack>
                            </VStack>
                          </VStack>
                        );
                      })
                    : selectedSchedule?.part?.map((item) => (
                        <VStack
                          w="full"
                          key={item.id}
                          align="space-between"
                          flexDirection="row"
                          mb={5}
                        >
                          <VStack align="flex-start" w="full">
                            <HStack w="full" align="space-between">
                              <VStack w="full" align="flex-start">
                                <Text fontWeight="bold">{`${item.quantity}x ${item.part.name}`}</Text>
                                <Text color="gray.400">{item.manufacturer.name}</Text>
                              </VStack>
                            </HStack>
                            <HStack
                              display="flex"
                              flexWrap="wrap"
                              align="flex-start"
                              justify="start"
                              w="full"
                              spacing={0}
                            >
                              {item?.attributes?.map((attribute) => (
                                <Tag
                                  ml="0px !important"
                                  mr="15px !important"
                                  mb="10px"
                                  key={attribute.id}
                                >
                                  <Text>{attribute.attribute.name}</Text>
                                </Tag>
                              ))}
                            </HStack>
                          </VStack>
                        </VStack>
                      ))}
                </VStack>
              </HStack>
            </VStack>
          </VStack>

          {!budgetRequest && (
            <Button
              onClick={async () => {
                try {
                  document.body.style.cursor = 'not-allowed';

                  await updateBudgetRequest(selectedSchedule.id, { cancelled_by_user: true });

                  await refetchConsumerBudgetRequests();

                  toast({
                    title: 'Solicitação cancelada com sucesso.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                  });

                  document.body.style.cursor = 'default';
                } catch (error) {
                  document.body.style.cursor = 'default';

                  toast({
                    title: 'Erro ao cancelar solicitação. Tente novamente.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                  });
                }
              }}
              size="sm"
              variant="solid"
              bg="white"
            >
              Cancelar orçamento
            </Button>
          )}

          {budgetRequest && (
            <BudgetRequestCheckout
              budgetRequest={budgetRequest}
              selectedBudget={budgetRequest}
              setSelectedBudget={setBudgetRequest}
              setSelectedSchedule={setSelectedSchedule}
            />
          )}
        </VStack>
      </VStack>
    </Flex>
  );
};
