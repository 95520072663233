/* eslint-disable react-hooks/exhaustive-deps */

import { GridItem, HStack, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { useEffect } from 'react';

import { useStoreBudgetEvaluations } from '../../hooks';

import { EvaluationCard } from './EvaluationCard';

import { useAuth } from '@/context/AppProvider';

export const EvaluationsList = ({ activeTab }) => {
  const { store } = useAuth();

  const { data: storeEvaluations, refetch } = useStoreBudgetEvaluations({
    id: store?.id,
    config: { enabled: !!store },
  });

  useEffect(() => {
    activeTab === 'store-evaluations' && refetch();
  }, [activeTab]);

  return (
    <VStack spacing={6}>
      <HStack alignSelf="flex-start">
        <Text fontSize="xl">Suas avaliações</Text>
      </HStack>

      <SimpleGrid
        maxH={{ base: '80vh', md: '800px' }}
        w="full"
        overflowY="auto"
        columns={{ base: 1, lg: 1, xl: 2 }}
      >
        {storeEvaluations?.map((evaluation) => (
          <GridItem key={evaluation.id}>
            <EvaluationCard evaluation={evaluation} refetch={refetch} />
          </GridItem>
        ))}
      </SimpleGrid>
    </VStack>
  );
};
