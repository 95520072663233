/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@chakra-ui/react';

import { CarDiagnosis, ConsumerSidebar, SchedulesList } from '../components';
import { ConsumerScheduleConsumer, ConsumerScheduleProvider } from '../contexts';
import { ConsumerScheduleContextType } from '../types';

import { Page } from '@/components/Page';
import { useFCM } from '@/context/AppProvider';
import { ChatProvider } from '@/context/AppProvider/ChatProvider';

export const MySchedules = () => {
  const { consumerSchedules, refetchConsumerSchedules } = useFCM();

  return (
    <ChatProvider>
      <Page title="Seus agendamentos" pos="relative" minH="100vh">
        <ConsumerScheduleProvider>
          <ConsumerScheduleConsumer>
            {({ selectedSchedule, setSelectedSchedule }: ConsumerScheduleContextType) => {
              return (
                <ConsumerSidebar>
                  {selectedSchedule ? (
                    <CarDiagnosis
                      selectedSchedule={selectedSchedule}
                      setSelectedSchedule={setSelectedSchedule}
                    />
                  ) : (
                    consumerSchedules && (
                      <Box w="full">
                        <SchedulesList
                          refetch={refetchConsumerSchedules}
                          consumerSchedules={consumerSchedules}
                        />
                      </Box>
                    )
                  )}
                </ConsumerSidebar>
              );
            }}
          </ConsumerScheduleConsumer>
        </ConsumerScheduleProvider>
      </Page>
    </ChatProvider>
  );
};
