import { useQuery } from 'react-query';

import { getScheduleRequestResponse } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseScheduleRequestResponse = {
  id: string;
  config?: QueryConfig<typeof getScheduleRequestResponse>;
};

export const useScheduleRequestResponse = ({ id, config }: UseScheduleRequestResponse) => {
  return useQuery(['scheduleRequestResponse', id], getScheduleRequestResponse, config);
};
