import { CheckIcon } from '@chakra-ui/icons';
import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

import { ReactComponent as ClockIcon } from './assets/Clock.svg';
import { ReactComponent as TruckIcon } from './assets/Truck.svg';

type BudgetStatusProgressProps = {
  status: string;
};

export const BudgetStatusProgress = ({ status }: BudgetStatusProgressProps) => {
  const steps: { value: string; label: string; icon: FunctionComponent }[] = [
    {
      value: 'INIT',
      label: 'Aguardando envio',
      icon: ClockIcon,
    },
    {
      value: 'SENT',
      label: 'Pedido enviado',
      icon: TruckIcon,
    },
    {
      value: 'FINISHED',
      label: 'Pedido finalizado',
      icon: CheckIcon,
    },
  ];

  const getStep = () => {
    if (['INIT', 'BUDGETED', 'PAID'].includes(status)) return 0;
    if (status.includes('SENT')) return 1;
    if (status.includes('FINISHED')) return 2;

    return 0;
  };

  const getStatus = (index): 'past' | 'present' | 'future' => {
    if (index < getStep()) return 'past';
    if (index === getStep()) return 'present';
    if (index > getStep()) return 'future';

    return 'future';
  };

  return (
    <Flex alignItems="center" pb="32px" mr={5}>
      {steps.map((indexStatus, index) => {
        const currentStatus = getStatus(index);

        const getJustifyContent = (): 'flex-start' | 'center' | 'flex-end' => {
          if (index === 0) return 'flex-start';
          if (index === steps.length - 1) return 'flex-end';
          return 'center';
        };

        return (
          <>
            <Flex
              key={indexStatus.value}
              w="36px"
              h="36px"
              justifyContent="center"
              alignItems="center"
              opacity={currentStatus !== 'present' ? 0.6 : 1}
              position="relative"
            >
              <Flex
                alignItems="center"
                justifyContent="center"
                rounded="full"
                border="5px solid #F8BB32"
                w="36px"
                h="36px"
                bg={currentStatus === 'future' ? 'transparent' : '#F8BB32'}
              >
                {currentStatus !== 'future' && <Icon as={indexStatus.icon} />}
              </Flex>
              <Flex
                justifyContent={getJustifyContent()}
                w="36px"
                left={0}
                bottom={0}
                transform="translateY(100%)"
                position="absolute"
                opacity={currentStatus === 'present' ? 1 : 0}
              >
                <Text
                  lineHeight="20px"
                  mt="12px"
                  whiteSpace="nowrap"
                  color="#21272A"
                  fontWeight="semibold"
                >
                  {indexStatus.label}
                </Text>
              </Flex>
            </Flex>
            {index < steps.length - 1 && (
              <Box w="70px" h="4px" bg={currentStatus === 'past' ? '#F8BB32' : '#e9e9e9'} />
            )}
          </>
        );
      })}
    </Flex>
  );
};
