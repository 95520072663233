/* eslint-disable no-case-declarations */
import {
  Box,
  Button,
  Flex,
  HStack,
  Stack,
  Text,
  VStack,
  useToast,
  Tag,
  Image,
  Skeleton,
  chakra,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useState } from 'react';
import { ImageViewer } from 'react-image-viewer-dv';
import { ImageListType } from 'react-images-uploading';

import { CreatePart } from '../';
import {
  createRepairShopDiagnsosis,
  createRepairShopService,
  createSchedulePart,
  createSchedulePartAttributes,
  updateRepairShopDiagnsosis,
  updateSchedule,
} from '../../api';
import { RepairShopScheduleContextI } from '../../types';
import { diagnosisLabelMap } from '../../utils';
import { ServiceComponent } from '../ServiceComponent';

import { CarDiagnosisFormI } from './types';

dayjs.extend(customParseFormat);

import { BoxWithTitle } from '@/components/common/BoxWithTitle';
import { FieldWrapper, Form, UploadImage } from '@/components/Form';
import axios from '@/lib/axios';
import { useScheduleRequestProblems } from '@/modules/consumer';
import parseLongString from '@/utils/parseLongString';

export const CarDiagnosisStartedForm = ({
  selectedSchedule,
  setSelectedSchedule,
  selectedProblems,
  diagnosisId,
  scheduleRequestPhotos,
}: Partial<RepairShopScheduleContextI>) => {
  const [images, setImages] = useState<ImageListType>([]);
  const toast = useToast();
  const [newService, setNewService] = useState<boolean>(false);
  const [selectedServices, setSelectedServices] = useState<any[]>([]);
  const [customServices, setCustomServices] = useState<any[]>([]);
  const [customParts, setCustomParts] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const [selectedParts, setSelectedParts] = useState<any[]>([]);

  const [onEditionPart, setOnEditionPart] = useState<any | null>(null);
  const [newPart, setNewPart] = useState<boolean>(false);

  const { data: scheduleRequestProblems, isLoading: isLoadingScheduleRequestProblems } =
    useScheduleRequestProblems({
      id: selectedSchedule.schedule_request.id,
      config: { enabled: !!selectedSchedule },
    });

  const formatServicesIds = (services: any) => {
    return services.map(({ value }) => value);
  };

  const orderByCreatedAt = (array: any[]) => {
    return array.sort((a, b) => {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    });
  };

  return (
    <Form<CarDiagnosisFormI>
      initialValues={{
        photo: [],
        problem_id: [],
        service_id: [],
        parts: [],
        time: '',
        total_value: '',
      }}
      onSubmit={async () => {
        setLoading(true);
        try {
          const services: any[] = [];

          const aux = selectedServices.map(async (service) => {
            await createRepairShopService({
              repairshop_service: service.service_id,
              minutes: service.time,
              price: Number(service.price).toFixed(2),
            }).then((response: any) => services.push(response.id));
          });

          await Promise.all(aux);

          const payload = {
            repair_shop: selectedSchedule.repair_shop.id,
            schedule: selectedSchedule.id,
            problems: formatServicesIds(selectedProblems),
            schedule_services: services,
            price: Number(
              Number(
                selectedParts?.reduce(
                  (acc, curr) => acc + Number(curr.price) * Number(curr.quantity),
                  0,
                ),
              ) +
                Number(
                  customParts?.reduce(
                    (acc, curr) => acc + Number(curr.price) * Number(curr.quantity),
                    0,
                  ),
                ) +
                Number(selectedServices?.reduce((acc, curr) => acc + curr.price, 0)) +
                Number(customServices?.reduce((acc, curr) => acc + curr.price, 0)),
            ).toFixed(2),
            time:
              Number(selectedServices?.reduce((acc, curr) => acc + curr.time, 0)) +
              Number(customServices?.reduce((acc, curr) => acc + curr.time, 0)),
            custom_repair_shop_services: JSON.stringify(customServices),
            custom_repair_shop_parts: JSON.stringify(customParts),
          };

          selectedParts.forEach(async (selectedPart) => {
            const partAttriutes = selectedPart.selected_attributes.map(async (attribute) => {
              return await createSchedulePartAttributes({
                repair_shop_part: selectedPart.id,
                attribute: Number(attribute),
                quantity: Number(selectedPart.quantity),
              });
            });

            await Promise.all(partAttriutes).then(async (response: any[]) => {
              await createSchedulePart({
                schedule: selectedSchedule.id,
                schedule_parts_attributes: response?.map((attribute) => attribute.id),
                price: Number(selectedPart.price).toFixed(2),
                manufacturer: selectedPart.manufacturer_id,
                repair_shop_part: selectedPart.id,
                quantity: Number(selectedPart.quantity),
              });
            });
          });

          switch (selectedSchedule.status) {
            case 'diagnostic_canceled_by_user':
              await updateRepairShopDiagnsosis(diagnosisId, payload);

              images?.map(async (photo: any) => {
                const aux = new FormData();
                aux.append('photo', photo.file);
                aux.append('diagnosis', diagnosisId);

                await axios.authorized({}).post('/repair-shop-diagnosis-photo/', aux, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                });
              });

              await updateSchedule(selectedSchedule.id, { status: 'diagnosed' });
              break;

            default:
              const diagnosis = await createRepairShopDiagnsosis(payload);

              images?.map(async (photo: any) => {
                const aux = new FormData();
                aux.append('photo', photo.file);
                aux.append('diagnosis', diagnosis.id);

                await axios.authorized({}).post('/repair-shop-diagnosis-photo/', aux, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                });
              });

              await updateSchedule(selectedSchedule.id, { status: 'diagnosed' });
              break;
          }

          setLoading(false);

          toast({
            title: 'Diagnóstico realizado com sucesso.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });

          setSelectedSchedule && setSelectedSchedule(null);
        } catch (error) {
          setLoading(false);

          toast({
            title: 'Erro ao criar diagnóstico. Tente novamente.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      }}
    >
      {({ submitForm }) => (
        <VStack>
          <Stack
            flexDirection={{ base: 'column', md: 'row' }}
            alignItems={{ base: 'flex-start', md: 'center' }}
            spacing={{ base: 2, md: 0 }}
            w="full"
            rounded="md"
            bg="white"
            p="4"
          >
            <HStack>
              <Text fontWeight="bold">{selectedSchedule?.schedule_request.car.plate}</Text>
              <Text color="gray.400">{`${selectedSchedule.schedule_request.preferred_date.slice(
                0,
                5,
              )}, ${selectedSchedule.schedule_request.preferred_date.slice(10)}`}</Text>
            </HStack>
            <HStack flex={1}>
              <Text d={{ base: 'initial', md: 'none' }}>Veículo</Text>
              <Text fontWeight="bold">
                {selectedSchedule.schedule_request?.car
                  ? parseLongString(
                      `${selectedSchedule.schedule_request?.car?.car?.model?.manufacturer?.name} ${selectedSchedule?.schedule_request?.car?.car?.model?.name} ${selectedSchedule?.schedule_request?.car?.car?.year}`,
                      32,
                    )
                  : 'Veículo apagado'}
              </Text>
            </HStack>
            <HStack justifyContent="space-between" w={{ base: 'full', md: 'fit-content' }}>
              <Text
                ml={6}
                whiteSpace="nowrap"
                pos="relative"
                _before={{
                  content: '""',
                  width: 4,
                  height: 4,
                  zIndex: 1000,
                  pos: 'absolute',
                  top: '50%',
                  left: -6,
                  transform: 'translateY(-50%)',
                  border: '3px solid',
                  borderColor: 'brand.500',
                  rounded: 'full',
                }}
              >
                {selectedSchedule && diagnosisLabelMap[selectedSchedule?.status]}
              </Text>
            </HStack>
          </Stack>

          <BoxWithTitle
            title="Problemas"
            titleSize="lg"
            wrapperProps={{
              w: 'full',
              p: 4,
              rounded: 'md',
              mr: { base: 0, md: 2 },
              mb: { base: 2, md: 0 },
              bg: 'white',
            }}
          >
            {isLoadingScheduleRequestProblems ? (
              <Skeleton height={8} borderRadius={20} mb={8} />
            ) : (
              <>
                <HStack
                  display="flex"
                  flexWrap="wrap"
                  align="flex-start"
                  justify="start"
                  w="full"
                  spacing={0}
                >
                  {scheduleRequestProblems &&
                    scheduleRequestProblems?.map((problem) => (
                      <Tag mr="10px !important" mb={3} key={problem.id} size="sm">
                        {problem.problem.name}
                      </Tag>
                    ))}

                  {selectedSchedule?.schedule_request?.customer_problem?.length &&
                    selectedSchedule?.schedule_request?.customer_problem
                      ?.split(',')
                      ?.map((problem) => (
                        <Tag mr="10px !important" mb={3} key={problem} size="sm">
                          {problem}
                        </Tag>
                      ))}
                </HStack>
                {scheduleRequestPhotos?.length > 0 && (
                  <HStack w="full">
                    {scheduleRequestPhotos?.map((photo) => (
                      <ImageViewer key={photo.id}>
                        <Image
                          src={photo.photo}
                          bgPos="center"
                          bgSize="cover"
                          rounded="md"
                          shadow="md"
                          flex={1}
                          h="200px"
                          w="full"
                        />
                      </ImageViewer>
                    ))}
                  </HStack>
                )}
              </>
            )}
          </BoxWithTitle>

          {selectedSchedule.schedule_request.comment && (
            <Flex flexDirection={{ base: 'column', md: 'row' }} w="full">
              <BoxWithTitle
                title="Observação do cliente"
                titleSize="lg"
                wrapperProps={{
                  w: 'full',
                  bg: 'white',
                  p: 4,
                  rounded: 'md',
                }}
              >
                <Text>{selectedSchedule.schedule_request.comment}</Text>
              </BoxWithTitle>
            </Flex>
          )}

          <Box w="full" rounded="md" bg="white" p="4">
            <FieldWrapper
              name="photo"
              boxProps={{
                w: 'full',
                mb: 0,
              }}
              required
              as={(fieldProps) => (
                <UploadImage
                  title="Envie fotos dos problemas do carro"
                  onChange={(images) => setImages(images)}
                  {...fieldProps}
                />
              )}
            />
          </Box>

          <Flex flexDirection={{ base: 'column', md: 'row' }} w="full">
            <BoxWithTitle
              title="Serviços"
              titleSize="lg"
              wrapperProps={{
                w: 'full',
                bg: 'white',
                p: 4,
                rounded: 'md',
              }}
              pt={selectedServices?.length > 0 || customServices?.length > 0 ? 5 : '0px !important'}
            >
              {(selectedServices?.length > 0 || customServices?.length > 0) && (
                <VStack
                  mt="0px !important"
                  mb={selectedServices.length > 0 ? 6 : 0}
                  align="flex-start"
                  spacing={5}
                >
                  <HStack
                    display="flex"
                    flexWrap="wrap"
                    align="center"
                    justify="start"
                    w="full"
                    spacing={0}
                    mb={1}
                  >
                    {orderByCreatedAt(Array.from([...selectedServices, ...customServices]))?.map(
                      (service, index) => (
                        <ServiceComponent
                          key={index}
                          setNewService={setNewService}
                          selectedSchedule={selectedSchedule}
                          selectedServices={selectedServices}
                          setSelectedServices={setSelectedServices}
                          canEdit={true}
                          service={service}
                          isCustomService={service?.isCustomService}
                          customServices={customServices}
                          setCustomServices={setCustomServices}
                        />
                      ),
                    )}
                  </HStack>
                </VStack>
              )}

              {newService && (
                <Stack w="full">
                  <ServiceComponent
                    setNewService={setNewService}
                    selectedSchedule={selectedSchedule}
                    selectedServices={selectedServices}
                    setSelectedServices={setSelectedServices}
                    canEdit={false}
                    service={null}
                    isCustomService={false}
                    customServices={customServices}
                    setCustomServices={setCustomServices}
                  />
                </Stack>
              )}

              {!newService && (
                <Button
                  onClick={() => setNewService(true)}
                  size="sm"
                  variant="outline"
                  borderColor="brand.500"
                >
                  Adicionar serviço
                </Button>
              )}

              {selectedServices?.length > 0 && (
                <HStack flexDir={{ base: 'column', md: 'row' }} w="full" mt={10}>
                  <Flex w="full" align="center">
                    <Text
                      display="flex"
                      alignItems="center"
                      w={{ base: 'full', md: 'fit-content' }}
                      justifyContent="space-between"
                      mr={{ base: 0, md: 5 }}
                    >
                      Valor total dos serviços
                      <chakra.span ml={3} fontWeight="bold" fontSize="lg">
                        {Number(
                          Number(
                            selectedServices?.reduce((acc, curr) => acc + Number(curr.price), 0),
                          ) +
                            Number(
                              customServices?.reduce((acc, curr) => acc + Number(curr.price), 0),
                            ),
                        ).toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </chakra.span>
                    </Text>
                  </Flex>
                  <Flex marginInlineStart="0px !important" w="full" align="center">
                    <Text
                      w={{ base: 'full', md: 'fit-content' }}
                      justifyContent="space-between"
                      display="flex"
                      alignItems="center"
                      mr={{ base: 0, md: 5 }}
                    >
                      Tempo total do serviço
                      <chakra.span ml={3} fontWeight="bold" fontSize="lg">
                        {`${
                          Number(selectedServices?.reduce((acc, curr) => acc + curr.time, 0)) +
                          Number(customServices?.reduce((acc, curr) => acc + curr.time, 0))
                        } minutos`}
                      </chakra.span>
                    </Text>
                  </Flex>
                </HStack>
              )}
            </BoxWithTitle>
          </Flex>

          <BoxWithTitle
            title="Peças"
            titleSize="lg"
            wrapperProps={{
              w: 'full',
              bg: 'white',
              p: 4,
              rounded: 'md',
            }}
          >
            {(selectedParts?.length > 0 || customParts?.length) > 0 ? (
              <VStack
                mb={selectedParts?.length > 0 || customParts?.length > 0 ? 6 : 0}
                align="flex-start"
                spacing={5}
              >
                <HStack
                  display="flex"
                  flexWrap="wrap"
                  align="center"
                  justify="start"
                  w="full"
                  spacing={0}
                  mb={1}
                >
                  {orderByCreatedAt(Array.from([...selectedParts, ...customParts]))?.map(
                    (partItem) => {
                      return (
                        <CreatePart
                          key={partItem.id}
                          selectedParts={selectedParts}
                          setSelectedParts={setSelectedParts}
                          setNewPart={setNewPart}
                          price={true}
                          part={partItem}
                          isEditable={true}
                          setOnEditionPart={setOnEditionPart}
                          isCustomPart={partItem?.isCustomPart}
                          customParts={customParts}
                          setCustomParts={setCustomParts}
                        />
                      );
                    },
                  )}
                </HStack>
              </VStack>
            ) : (
              <></>
            )}

            {newPart && !onEditionPart && (
              <Stack w="full">
                <CreatePart
                  selectedParts={selectedParts}
                  setSelectedParts={setSelectedParts}
                  setNewPart={setNewPart}
                  price={true}
                  part={null}
                  isEditable={false}
                  setOnEditionPart={null}
                  isCustomPart={false}
                  customParts={customParts}
                  setCustomParts={setCustomParts}
                />
              </Stack>
            )}

            {!newPart && !onEditionPart && (
              <Button
                mt="0px !important"
                size="sm"
                variant="outline"
                borderColor="brand.500"
                onClick={() => setNewPart(true)}
              >
                Adicionar peça
              </Button>
            )}

            {selectedParts?.length > 0 && (
              <HStack h="fit-content" w="full" mt={10} alignItems="center">
                <Text
                  display="flex"
                  alignItems="center"
                  w={{ base: 'full', md: 'fit-content' }}
                  justifyContent="space-between"
                  mr={{ base: 0, md: 5 }}
                >
                  Valor total das peças
                  <chakra.span ml={3} fontWeight="bold" fontSize="lg">
                    {Number(
                      Number(
                        selectedParts?.reduce(
                          (acc, curr) => acc + Number(curr.price) * Number(curr.quantity),
                          0,
                        ),
                      ) +
                        Number(
                          customParts?.reduce(
                            (acc, curr) => acc + Number(curr.price) * Number(curr.quantity),
                            0,
                          ),
                        ),
                    ).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </chakra.span>
                </Text>
              </HStack>
            )}
          </BoxWithTitle>

          <VStack bg="white" p={4} rounded="md" spacing={0} align="flex-start" w="full">
            <HStack w="full" justify="space-between">
              <Text color="gray.400">Serviços</Text>
              <Text color="gray.400" fontWeight="bold">
                {Number(
                  Number(selectedServices?.reduce((acc, curr) => acc + curr.price, 0)) +
                    Number(customServices?.reduce((acc, curr) => acc + curr.price, 0)),
                ).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Text>
            </HStack>
            <HStack w="full" align="flex-start" justify="space-between">
              <Text mt={2} mb={5} color="gray.400">
                Peças
              </Text>
              <Text pt={2} mb={5} color="gray.400" fontWeight="bold">
                {Number(
                  Number(
                    selectedParts?.reduce(
                      (acc, curr) => acc + Number(curr.price) * Number(curr.quantity),
                      0,
                    ),
                  ) +
                    Number(
                      customParts?.reduce(
                        (acc, curr) => acc + Number(curr.price) * Number(curr.quantity),
                        0,
                      ),
                    ),
                ).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Text>
            </HStack>
            <HStack mt={3} w="full" justify="space-between">
              <Text fontWeight="bold" fontSize="lg">
                Total
              </Text>
              <Text fontWeight="bold" fontSize="lg">
                {(
                  Number(
                    selectedParts?.reduce(
                      (acc, curr) => acc + Number(curr.price) * Number(curr.quantity),
                      0,
                    ),
                  ) +
                  Number(
                    customParts?.reduce(
                      (acc, curr) => acc + Number(curr.price) * Number(curr.quantity),
                      0,
                    ),
                  ) +
                  Number(selectedServices?.reduce((acc, curr) => acc + curr.price, 0)) +
                  Number(customServices?.reduce((acc, curr) => acc + curr.price, 0))
                ).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Text>
            </HStack>
          </VStack>

          <HStack w="full" justify="space-between" align="flex-start">
            <HStack
              justify="flex-start"
              align="flex-start"
              flexDir={{ base: 'column', lg: 'row' }}
              pt="5"
              alignSelf="flex-start"
              w="full"
            >
              <Button
                mb={{ base: 5, lg: 0 }}
                variant="@primary"
                onClick={submitForm}
                isLoading={loading}
              >
                Realizar diagnóstico
              </Button>
              <Button
                onClick={async () => {
                  await updateSchedule(selectedSchedule.id, {
                    status: 'diagnostic_canceled_by_user',
                  });
                  setSelectedSchedule && setSelectedSchedule(null);
                }}
                variant="@colorless"
              >
                Cancelar serviço
              </Button>
            </HStack>

            <HStack pt={5}>
              <Button
                size="sm"
                fontSize="sm"
                fontWeight="normal"
                textAlign="center"
                variant="solid"
                onClick={async () => {
                  if (setSelectedSchedule && selectedSchedule)
                    await updateSchedule(selectedSchedule.id, {
                      status: 'diagnostic_canceled_by_repairshop',
                    });
                  setSelectedSchedule && setSelectedSchedule(null);
                }}
              >
                Cancelar agendamento
              </Button>
            </HStack>
          </HStack>
        </VStack>
      )}
    </Form>
  );
};
