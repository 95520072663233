import { Flex } from '@chakra-ui/layout';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import dayjs from 'dayjs';
import * as React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import SwiperCore, { A11y, Navigation, Pagination } from 'swiper';

import { AuthProvider } from './AuthProvider';
import { ScheduleProvider } from './ScheduleProvider';
import { ScrollProvider } from './ScrollContext';
import { FCMProvider } from './SocketProvider';

import { Spinner } from '@/components/common/Spinner/Spinner';
import { APP_NAME } from '@/config';
import { ErrorBoundary } from '@/errors';
import { AuthProvider as DefaultAuthProvider } from '@/lib/authentication';
import { queryClient } from '@/lib/react-query';
import { theme, Fonts } from '@/styles';

import 'swiper/swiper.min.css';
import 'dayjs/locale/pt-br';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';

dayjs.locale('pt-br');
SwiperCore.use([Navigation, Pagination, A11y]);

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <React.Suspense
      fallback={
        <Flex w="100vw" h="100vh" alignItems="center" justifyContent="center">
          <Spinner />
        </Flex>
      }
    >
      <HelmetProvider>
        <Helmet>
          <title>{APP_NAME}</title>
        </Helmet>
        <ChakraProvider
          theme={{
            ...theme,
          }}
        >
          <ColorModeScript initialColorMode={theme.config.initialColorMode} />
          <Fonts />
          <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools position="bottom-left" />
              <FCMProvider>
                <ScheduleProvider>
                  <DefaultAuthProvider>
                    <AuthProvider>
                      <ScrollProvider>
                        <Router>{children}</Router>
                      </ScrollProvider>
                    </AuthProvider>
                  </DefaultAuthProvider>
                </ScheduleProvider>
              </FCMProvider>
            </QueryClientProvider>
          </ErrorBoundary>
        </ChakraProvider>
      </HelmetProvider>
    </React.Suspense>
  );
};
