import { useQuery } from 'react-query';

import { getScheduleRequests } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseScheduleRequestsOptions = {
  config?: QueryConfig<typeof getScheduleRequests>;
};

export const useScheduleRequests = ({ config }: UseScheduleRequestsOptions) => {
  return useQuery(['getRepairShopSchedules'], getScheduleRequests, config);
};
