import { Flex, Button, VStack, Stack, HStack, Text, Tag } from '@chakra-ui/react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { MdKeyboardArrowLeft } from 'react-icons/md';

import { budgetLabelMap } from '../../utils';

import { BudgetStatusProgress } from '@/components/common/BudgetStatusProgress';

dayjs.extend(customParseFormat);

export const Budget = ({ selectedSchedule, setSelectedSchedule }) => {
  return (
    <Flex w="full" flexDirection="column">
      <Button
        variant="ghost"
        leftIcon={<MdKeyboardArrowLeft />}
        alignSelf="flex-start"
        pl="0"
        mb="4"
        onClick={() => {
          if (setSelectedSchedule) setSelectedSchedule(null);
        }}
      >
        voltar
      </Button>

      <VStack>
        <Stack
          flexDirection={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'flex-start', md: 'center' }}
          justify="space-between"
          spacing={{ base: 2, md: 0 }}
          w="full"
          rounded="md"
          bg="white"
          p="4"
        >
          <HStack>
            <HStack mr={3}>
              <Text fontWeight="bold" whiteSpace="nowrap">
                {`${selectedSchedule.budget_request.car.model.manufacturer.name} ${selectedSchedule.budget_request.car.model.name}`}
              </Text>
              <Text color="gray.400" whiteSpace="nowrap">
                {`${dayjs(selectedSchedule.created_at).format('DD/MM/YYYY')}, ${dayjs(
                  selectedSchedule.created_at,
                ).format('HH:mm')}`}
              </Text>
            </HStack>
          </HStack>

          <HStack justifyContent="space-between" w={{ base: 'full', md: 'fit-content' }}>
            <Text
              ml={6}
              whiteSpace="nowrap"
              pos="relative"
              _before={{
                content: '""',
                width: 4,
                height: 4,
                zIndex: 1000,
                pos: 'absolute',
                top: '50%',
                left: -6,
                transform: 'translateY(-50%)',
                border: '3px solid',
                borderColor: 'brand.500',
                rounded: 'full',
              }}
            >
              {budgetLabelMap[selectedSchedule.status]}
            </Text>
          </HStack>
        </Stack>

        <VStack w="full" p={4} rounded="md" bg="white" alignItems="flex-start" flex="1">
          <Text>Endereço</Text>
          <Text fontWeight="bold">{selectedSchedule.budget_request.shipping}</Text>
        </VStack>

        <VStack w="full" p={4} rounded="md" bg="white" alignItems="flex-start" flex="1" spacing="5">
          <Text fontSize="sm" fontWeight="bold">
            Peças
          </Text>
          <VStack h="fit-content" w="full">
            <HStack w="full">
              <VStack w="full" flexDirection="row">
                {selectedSchedule?.budget_parts?.map((item) => (
                  <VStack w="full" key={item.id} align="space-between" flexDirection="row" mb={5}>
                    <VStack align="flex-start" w="full">
                      <HStack w="full" align="space-between">
                        <VStack w="full" align="flex-start">
                          <Text fontWeight="bold">{`${item.quantity}x ${item.budget_request_part.part.name}`}</Text>
                          <Text color="gray.400">{item.manufacturer.name}</Text>
                        </VStack>

                        <VStack w="full" align="start">
                          <Text w="full" align="end" fontWeight="bold">
                            {Number(Number(item.price) * item.quantity).toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </Text>
                        </VStack>
                      </HStack>
                      <HStack
                        display="flex"
                        flexWrap="wrap"
                        align="flex-start"
                        justify="start"
                        w="full"
                        spacing={0}
                      >
                        {item?.budget_request_part?.attributes?.map((attribute) => (
                          <Tag
                            ml="0px !important"
                            mr="15px !important"
                            mb="10px"
                            key={attribute.id}
                          >
                            <Text>{attribute.attribute.name}</Text>
                          </Tag>
                        ))}
                      </HStack>
                    </VStack>
                  </VStack>
                ))}
              </VStack>
            </HStack>
          </VStack>
        </VStack>

        <VStack w="full" p={4} rounded="md" bg="white" flex="1" align="flex-end" spacing={2}>
          <HStack w="full" align="flex-start" justify="space-between">
            <Text color="gray.500" fontSize="sm" fontWeight="bold">
              Frete
            </Text>

            <Text color="gray.500" fontSize="sm" fontWeight="bold">
              {Number(selectedSchedule.shipping_price).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Text>
          </HStack>
          <HStack w="full" justify="space-between">
            <Text color="gray.500" fontSize="sm" fontWeight="bold">
              Subtotal
            </Text>
            <Text color="gray.500" fontSize="sm" fontWeight="bold">
              {Number(
                selectedSchedule?.budget_parts?.reduce(
                  (acc, part) => acc + Number(part.price) * part.quantity,
                  0,
                ),
              ).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Text>
          </HStack>

          <HStack w="full" justify="space-between">
            <HStack w="full" justify="space-between">
              <Text fontSize="md" fontWeight="bold">
                Total
              </Text>
              <Text fontSize="md" fontWeight="bold">
                {Number(
                  Number(
                    selectedSchedule?.budget_request.part?.reduce(
                      (acc, part) => acc + Number(part.part.value) * part.quantity,
                      0,
                    ),
                  ) + Number(selectedSchedule.shipping_price),
                ).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Text>
            </HStack>
          </HStack>
        </VStack>

        <VStack
          pt={5}
          sx={{
            '&::-webkit-scrollbar': {
              width: 0,
              backgroundColor: `transparent`,
            },
            '&::-webkit-scrollbar-thumb': {
              width: 0,
              backgroundColor: `transparent`,
            },
          }}
          overflow="overlay auto"
          w="full"
          align="flex-start"
        >
          <BudgetStatusProgress status={selectedSchedule.status} />
        </VStack>
      </VStack>
    </Flex>
  );
};
