import { useToast } from '@chakra-ui/react';
import { useState } from 'react';

import { ResetPasswordForm } from '../components';
import { AuthLayout } from '../components/AuthLayout';

export const ResetPassword = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  return (
    <AuthLayout
      title="Redefinir Senha"
      subtitle="MyCar, a plataforma que simplifica sua relação com seu carro."
    >
      <ResetPasswordForm
        loading={loading}
        setLoading={setLoading}
        onSuccess={() => {
          toast({
            title: 'Email enviado com sucesso. Verifique sua caixa de entrada.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          setLoading(false);
        }}
        onError={() => {
          toast({
            title: 'Houve um erro. Tente novamente.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          setLoading(false);
        }}
      />
    </AuthLayout>
  );
};
