import { Box, HStack, Text, Image } from '@chakra-ui/react';
import { useState } from 'react';

import Star from '../../../assets/Star.svg';
import { ConsumerSidebar } from '../components';
import { BudgetEvaluationsList } from '../components/ConsumerBudgetEvaluations';
import { ScheduleEvaluationsList } from '../components/ConsumerScheduleEvaluations';
import { ConsumerScheduleConsumer, ConsumerScheduleProvider } from '../contexts';

import { Page } from '@/components/Page';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@/components/Tabs';

export const MyEvaluations = () => {
  const [, setActiveTab] = useState('schedules');
  return (
    <Page title="Suas vendas" pos="relative" minH="100vh">
      <ConsumerScheduleProvider>
        <ConsumerScheduleConsumer>
          {() => (
            <ConsumerSidebar>
              <>
                <HStack alignSelf="flex-start" mb={5}>
                  <Image w={4} src={Star} />
                  <Text fontSize="xl">Minhas avaliações</Text>
                </HStack>
                <Tabs>
                  <TabList bg="gray.100" w="full" d="flex">
                    <Tab onClick={() => setActiveTab('schedules')} fontSize="sm">
                      Agendamentos
                    </Tab>
                    <Tab onClick={() => setActiveTab('budgets')} fontSize="sm">
                      Pedidos
                    </Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <Box w="full">
                        <ScheduleEvaluationsList />
                      </Box>
                    </TabPanel>
                    <TabPanel>
                      <Box w="full">
                        <BudgetEvaluationsList />
                      </Box>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </>
            </ConsumerSidebar>
          )}
        </ConsumerScheduleConsumer>
      </ConsumerScheduleProvider>
    </Page>
  );
};
