import { useQuery } from 'react-query';

import { getRepairShopEvaluations } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseRepairShopEvaluations = {
  id?: number;
  config?: QueryConfig<typeof getRepairShopEvaluations>;
};

export const useRepairShopEvaluations = ({ id, config }: UseRepairShopEvaluations) => {
  return useQuery(['getRepairShopEvaluations', id], getRepairShopEvaluations, config);
};
