/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, Text, HStack } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/react';

import Address from '@/assets/address.svg';

export type AddressDetailProps = {
  fullAddress: string;
  complement: string;
};

export const AddressDetail = ({ fullAddress, complement }: AddressDetailProps) => {
  return (
    <HStack rounded="xl" p={3} bg="white" mr={5} maxW="80%" w="fit-content">
      <HStack mr={10} w="100%">
        <Image src={Address} h={50} w={50} rounded="lg" mr={3} />
        <Flex direction="column" overflow="hidden">
          <Text>{fullAddress} </Text>

          <Text fontSize="sm" color="gray.400" noOfLines={2}>
            {complement || ''}
          </Text>
        </Flex>
      </HStack>
    </HStack>
  );
};
