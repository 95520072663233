import { useQuery } from 'react-query';

import { getStoreRating } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseStoreRatingOptions = {
  id: number;
  config?: QueryConfig<typeof getStoreRating>;
};

export const useStoreRating = ({ id, config }: UseStoreRatingOptions) => {
  return useQuery(['getStoreRating', id], getStoreRating, config);
};
