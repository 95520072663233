import { useQuery } from 'react-query';

import { getSuggestedProblems } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseSuggestedProblemsOptions = {
  config?: QueryConfig<typeof getSuggestedProblems>;
};

export const useSuggestedProblems = ({ config }: UseSuggestedProblemsOptions) => {
  return useQuery(['getSuggestedProblems'], getSuggestedProblems, config);
};
