import { useQuery } from 'react-query';

import { getConsumerBudgets } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseConsumerBudgetsOptions = {
  status?: string;
  config?: QueryConfig<typeof getConsumerBudgets>;
};

export const useConsumerBudgets = ({ status, config }: UseConsumerBudgetsOptions) => {
  return useQuery(['getConsumerBudgets', status], getConsumerBudgets, config);
};
