/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import { Button } from '@chakra-ui/button';
import { Text, Divider, VStack, Flex } from '@chakra-ui/layout';
import { Skeleton } from '@chakra-ui/react';
import { Tag, TagLabel, TagLeftIcon } from '@chakra-ui/tag';
import { getAuth } from 'firebase/auth';
import { useFormikContext } from 'formik';
import { motion } from 'framer-motion';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { FaCarAlt } from 'react-icons/fa';

import { CreatedCar } from '../../../schedule-request/types';
import { deleteUserCar } from '../../api';
import { useCars } from '../../hooks';

import { CreateCarForm } from './CreateCarForm';

import { BoxWithTitle } from '@/components/common/BoxWithTitle';
import { FieldWrapper } from '@/components/Form';
import { useScroll } from '@/context/AppProvider';
import { firebaseApp } from '@/firebase';
import { queue } from '@/utils';

export const SelectCarForm = ({ next, selectedCar, setSelectedCar }) => {
  const { scrollRef } = useScroll();
  const [carLS, setCarLS] = useState<{ cars: CreatedCar[] }>(queue.getCars());
  const [addCarFormOpen, setAddCarFormOpen] = useState(false);
  const auth = getAuth(firebaseApp);

  const { setFieldValue } = useFormikContext();

  const { data: cars, refetch, isLoading } = useCars({ config: { enabled: !!auth.currentUser } });

  useEffect(() => setFieldValue('car', selectedCar), [selectedCar]);

  useEffect(() => {
    scrollRef?.current?.scrollToTop();
  }, []);

  const [user] = useState(() => {
    const user = localStorage.getItem('@user');
    const parsedUser = user && JSON.parse(user);

    return parsedUser;
  });

  return (
    <VStack
      spacing={5}
      alignItems="flex-start"
      justifyContent="flex-start"
      w="full"
      minH="fit-content"
      mb={5}
      p={5}
    >
      <BoxWithTitle
        title="Qual será o carro?"
        titleSize="xl"
        wrapperProps={{
          w: 'full',
        }}
      >
        <FieldWrapper
          name="car"
          boxProps={{ w: 'full' }}
          as={() => (
            <Flex
              flex={1}
              justifyContent="flex-start"
              flexDirection="row"
              pb={2}
              flexWrap="wrap"
              pt={2}
            >
              {user && isLoading ? (
                <Skeleton rounded="md" h={7} w="200px"></Skeleton>
              ) : cars?.length || carLS?.cars.length ? (
                <>
                  {cars?.map((car, index) => {
                    return (
                      <Flex w="fit-content" key={index} mr={4} mb={4}>
                        <Tag
                          maxW="xs"
                          bg="white"
                          rounded="md"
                          px={4}
                          pr={_.isEqual(car, selectedCar) ? 1 : 4}
                          border={_.isEqual(car, selectedCar) ? '1px solid' : 'none'}
                          borderColor={_.isEqual(car, selectedCar) ? 'brand.500' : 'none'}
                          shadow="lg"
                          onClick={() => {
                            if (car === selectedCar) setSelectedCar(undefined);
                            else setSelectedCar(car);
                          }}
                          cursor="pointer"
                          _hover={{
                            transform: 'translateY(-5px)',
                          }}
                        >
                          <TagLeftIcon boxSize="12px" as={FaCarAlt} mr={3} />
                          <TagLabel>
                            <Text fontSize="xs" fontWeight="bold" mb={2}>
                              {car.car.model.manufacturer.name} {car.car.model.name} {car.car.year}
                            </Text>
                            <Text fontSize="xs" fontWeight="bold">
                              {car.plate.substr(0, 3)} {car.plate.substr(3, 7)}
                            </Text>
                          </TagLabel>

                          {_.isEqual(car, selectedCar) && (
                            <>
                              <Divider ml={5} mr={5} orientation="vertical" />

                              <TagLeftIcon
                                _hover={{
                                  color: 'brand.500',
                                }}
                                onClick={async () => {
                                  await deleteUserCar(car.id);
                                  setSelectedCar(undefined);
                                  refetch();
                                }}
                                boxSize={18}
                                as={AiOutlineDelete}
                                ml={-2}
                              />
                            </>
                          )}
                        </Tag>
                      </Flex>
                    );
                  })}

                  {carLS?.cars.map((car, index) => (
                    <Tag
                      maxW="xs"
                      bg="white"
                      key={index}
                      rounded="md"
                      px={4}
                      border={car?.plate === selectedCar?.plate ? '1px solid' : 'none'}
                      borderColor={car?.plate === selectedCar?.plate ? 'brand.500' : 'none'}
                      shadow="lg"
                      onClick={() => {
                        if (car?.plate === selectedCar?.plate) setSelectedCar(undefined);
                        else setSelectedCar(car);
                      }}
                      cursor="pointer"
                      _hover={{
                        transform: 'translateY(-5px)',
                      }}
                      mr={4}
                      mb={4}
                      on
                    >
                      <TagLeftIcon boxSize="12px" as={FaCarAlt} mr={3} />

                      <TagLabel>
                        <Text
                          fontSize="xs"
                          fontWeight="bold"
                          mb={2}
                          wordBreak="break-all"
                          whiteSpace="normal"
                          noOfLines={2}
                        >
                          {car.manufacturer.name} {car.model.name} {car.year}
                        </Text>
                        <Text fontSize="xs" fontWeight="bold">
                          {car.plate.substr(0, 3)} {car.plate.substr(3, 7)}
                        </Text>
                      </TagLabel>
                      {car?.plate === selectedCar?.plate && (
                        <>
                          <Divider ml={5} mr={5} orientation="vertical" />

                          <TagLeftIcon
                            _hover={{
                              color: 'brand.500',
                            }}
                            onClick={() => {
                              queue.removeCar(car);
                              setCarLS(queue.getCars());
                            }}
                            boxSize={18}
                            as={AiOutlineDelete}
                            ml={-2}
                          />
                        </>
                      )}
                    </Tag>
                  ))}
                </>
              ) : (
                <Tag bg="white" rounded="md" px={2} border="1px solid lightgray" shadow="lg">
                  <TagLabel>
                    <Text fontSize="xs" fontWeight="bold">
                      Nenhum carro criado ainda
                    </Text>
                  </TagLabel>
                </Tag>
              )}
            </Flex>
          )}
        />
      </BoxWithTitle>

      <BoxWithTitle
        title="Deseja adicionar outro carro?"
        titleSize="md"
        wrapperProps={{
          w: 'full',
        }}
      >
        <motion.div
          transition={{ duration: 0.4 }}
          initial="init"
          animate="end"
          variants={{
            end: { opacity: 1, y: 0 },
            init: { opacity: 0, y: -40 },
          }}
          style={{
            width: '100%',
          }}
        >
          {!addCarFormOpen ? (
            <Button
              onClick={() => setAddCarFormOpen((prev) => !prev)}
              variant="@secondary"
              mt={0}
              alignSelf="flex-start"
              size="sm"
              w={150}
              mb={5}
            >
              Adicionar carro
            </Button>
          ) : (
            <CreateCarForm
              setAddCarFormOpen={setAddCarFormOpen}
              setCarLS={setCarLS}
              refetch={refetch}
              setSelectedCar={setSelectedCar}
            />
          )}
        </motion.div>
      </BoxWithTitle>

      <Button mt={10} isDisabled={!selectedCar} variant="@primary" onClick={next}>
        Avançar para peças
      </Button>
    </VStack>
  );
};
