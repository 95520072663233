/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Flex, HStack, Tag, Text, VStack } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';

import {
  useScheduleRequestProblems,
  useScheduleRequestCar,
  useScheduleRequestPhotos,
} from '../../../schedule-request/hooks';
import { answerRepairShopSchedule, deleteRepairShopScheduleRequest } from '../../api';
import { RepairShopSolicitation } from '../../api/types';

import { useFCM } from '@/context/AppProvider';
import { readNotification } from '@/modules/consumer';

export interface SolicitationCardProps {
  solicitation: RepairShopSolicitation;
}

export const NewSolicitationCard = ({ solicitation }) => {
  const { refetchScheduleRequests, userNotifications, refetchUserNotifications } = useFCM();

  const { data: scheduleRequestPhotos } = useScheduleRequestPhotos({
    id: solicitation?.schedule_request.id,
    config: { enabled: !!solicitation },
  });

  const { data: scheduleRequestProblems } = useScheduleRequestProblems({
    id: solicitation?.schedule_request.id,
    config: { enabled: !!solicitation },
  });

  const { data: scheduleRequestCar } = useScheduleRequestCar({
    id: solicitation?.schedule_request?.car?.id,
    config: { enabled: !!solicitation },
  });

  const countdown = (start) => {
    const countDownDate = new Date(start).getTime() + 3 * 60000;
    const now = new Date().getTime();
    const distance = now - countDownDate;

    if (distance >= 0) return '0:00';

    const minutes = -1 * Math.floor(distance / 60000);
    const seconds = -1 * Math.floor((distance % 60000) / 1000);

    const m = minutes <= 10 && minutes > 1 ? `0${minutes - 1}` : minutes - 1;
    const s = seconds <= 10 ? `0${seconds - 1}` : seconds - 1;

    return `${m}:${s}`;
  };

  const countdownCallback = useCallback(
    () => countdown(solicitation.created_at),
    [solicitation.created_at],
  );

  const [isUnread, setIsUnread] = useState(
    userNotifications?.find((notification) => notification.object_pk === solicitation.id),
  );

  useEffect(
    () =>
      setIsUnread(
        userNotifications?.find((notification) => notification.object_pk === solicitation.id),
      ),
    [userNotifications, solicitation],
  );

  const [expirationTime, setExpirationTime] = useState(countdownCallback());

  const handleExpiredScheduleResquest = useCallback(async () => {
    const timer = setTimeout(() => {
      const time = countdown(solicitation.created_at);
      setExpirationTime(time);
    }, 1000);

    if (expirationTime === '0:00') {
      clearTimeout(timer);
      await deleteRepairShopScheduleRequest(solicitation.id);
      await refetchScheduleRequests();

      if (isUnread) {
        await readNotification(isUnread);
        refetchUserNotifications();
      }
    }
    return () => clearTimeout(timer);
  }, [expirationTime, refetchScheduleRequests, solicitation.created_at, solicitation.id]);

  useEffect(() => {
    handleExpiredScheduleResquest();
  }, [handleExpiredScheduleResquest]);

  return (
    <>
      {solicitation && scheduleRequestCar && scheduleRequestPhotos && scheduleRequestProblems && (
        <>
          <Flex flex={0.5} flexDirection="column" shadow="md" rounded="3xl" mb="2" mr="2">
            <VStack
              bgGradient="linear(to-t, brown.500, brown.300)"
              p="5"
              roundedTopLeft="3xl"
              roundedTopRight="3xl"
              color="white"
              spacing="5"
            >
              <Text fontSize="4xl" color="brand.500" fontFamily="gill">
                {expirationTime}
              </Text>
              <Text>Novo agendamento</Text>

              <VStack rounded="md" alignItems="flex-start" flex="1" spacing="5" w="full">
                <HStack p={0} spacing={0} w="full" justifyContent="flex-start" flexWrap="wrap">
                  {Array.isArray(scheduleRequestProblems) &&
                    scheduleRequestProblems?.map((problem) => (
                      <Tag
                        key={problem.id}
                        size="sm"
                        whiteSpace="nowrap"
                        color="white"
                        borderColor="transparent"
                        bg="brown.100"
                        mr="10px !important"
                        mb={3}
                      >
                        {problem.problem.name}
                      </Tag>
                    ))}

                  {solicitation?.schedule_request?.customer_problem?.length &&
                    solicitation?.schedule_request?.customer_problem.split(',').map((problem) => (
                      <Tag
                        key={problem.id}
                        size="sm"
                        whiteSpace="nowrap"
                        color="white"
                        borderColor="transparent"
                        bg="brown.100"
                        mr="10px !important"
                        mb="12px !important"
                      >
                        {problem}
                      </Tag>
                    ))}
                </HStack>
                <HStack w="full">
                  {Array.isArray(scheduleRequestPhotos) &&
                    scheduleRequestPhotos?.map((photo) => (
                      <Box
                        key={photo.id}
                        bgImg={photo.photo}
                        bgPos="center"
                        bgSize="cover"
                        rounded="md"
                        shadow="md"
                        flex={1}
                        h="100px"
                      />
                    ))}
                </HStack>
              </VStack>

              <HStack w="full" justifyContent="space-between" alignItems="flex-start" spacing="5">
                <VStack
                  w="full"
                  alignItems="flex-start"
                  borderBottom="2px solid"
                  borderColor="white"
                >
                  <Text fontWeight="bold">Modelo do carro</Text>
                  <Text>{`${scheduleRequestCar.car.model.name} ${scheduleRequestCar.car.year}`}</Text>
                </VStack>
                <VStack
                  w="full"
                  alignItems="flex-start"
                  borderBottom="2px solid"
                  borderColor="white"
                >
                  <Text fontWeight="bold">Fabricante</Text>
                  <Text>{scheduleRequestCar.car.model.manufacturer.name}</Text>
                </VStack>
              </HStack>

              <HStack w="full" justifyContent="space-between" alignItems="flex-start" spacing="5">
                <VStack
                  w="full"
                  alignItems="flex-start"
                  borderBottom="2px solid"
                  borderColor="white"
                >
                  <Text fontWeight="bold">Ano do carro</Text>
                  <Text>{scheduleRequestCar.car.year}</Text>
                </VStack>
                <VStack
                  w="full"
                  alignItems="flex-start"
                  borderBottom="2px solid"
                  borderColor="white"
                >
                  <Text fontWeight="bold">Placa do carro</Text>
                  <Text>{scheduleRequestCar.plate.toUpperCase()}</Text>
                </VStack>
              </HStack>

              <HStack w="full" justifyContent="space-between" alignItems="flex-start" spacing="5">
                <VStack
                  w="full"
                  alignItems="flex-start"
                  borderBottom="2px solid"
                  borderColor="white"
                >
                  <Text fontWeight="bold">Data</Text>
                  <Text>{solicitation.schedule_request.preferred_date.slice(0, 10)}</Text>
                </VStack>
                <VStack
                  w="full"
                  alignItems="flex-start"
                  borderBottom="2px solid"
                  borderColor="white"
                >
                  <Text fontWeight="bold">Horário</Text>
                  <Text>{solicitation.schedule_request.preferred_date.slice(10)}</Text>
                </VStack>
              </HStack>

              <HStack w="full" justifyContent="space-between" alignItems="flex-start" spacing="5">
                <VStack
                  w="full"
                  alignItems="flex-start"
                  borderBottom="2px solid"
                  borderColor="white"
                >
                  <Text fontWeight="bold">Nome do cliente</Text>
                  <Text>{solicitation.schedule_request.customer_name}</Text>
                </VStack>
              </HStack>

              <HStack w="full" justifyContent="space-between" alignItems="flex-start" spacing="5">
                <VStack
                  w="full"
                  alignItems="flex-start"
                  borderBottom="2px solid"
                  borderColor="white"
                >
                  <Text fontWeight="bold">Observação do cliente</Text>
                  <Text>{solicitation.schedule_request.comment}</Text>
                </VStack>
              </HStack>
            </VStack>

            <HStack
              p="5"
              bg="white"
              roundedBottomStart="3xl"
              roundedBottomEnd="3xl"
              justifyContent="center"
              w="full"
            >
              <Button
                variant="@primary"
                onClick={async () => {
                  await answerRepairShopSchedule(solicitation.id, true);
                  await refetchScheduleRequests();

                  if (isUnread) {
                    await readNotification(isUnread);
                    refetchUserNotifications();
                  }
                }}
              >
                Aceitar
              </Button>
              <Button
                variant="@colorless"
                onClick={async () => {
                  await deleteRepairShopScheduleRequest(solicitation.id);
                  await refetchScheduleRequests();

                  if (isUnread) {
                    await readNotification(isUnread);
                    refetchUserNotifications();
                  }
                }}
              >
                Recusar
              </Button>
            </HStack>
          </Flex>
        </>
      )}
    </>
  );
};
