/* eslint-disable react-hooks/exhaustive-deps */
import { VStack, HStack, Text, Tag, Divider, Image } from '@chakra-ui/react';
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from '@choc-ui/chakra-autocomplete';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

import LogoIcon from '../../../../assets/logo-collapsed.svg';
import { getPartManufacturers } from '../../api';
import { useBudgetPartAttribute } from '../../hooks';

import { useInfinitePagination } from '@/hooks/useInfinitePagination';
dayjs.extend(customParseFormat);

export const PartComponent = ({
  part,
  budgetParts,
  setBudgetParts,
  selectedSchedule,
  partsValue,
  setPartsValue,
  index,
}) => {
  const [selectedPart, setSelectedPart] = useState<any>(part);
  const [search, setSearch] = useState<string>();

  const { data: partManufacturers, fetchNextPage } = useInfinitePagination({
    multiple: part.part.multiple,
    name: part.part.name,
    request: getPartManufacturers,
    tag: 'getRepairShopServices',
    search,
    config: { enabled: !!part },
  });

  const { data: partAttributes } = useBudgetPartAttribute({
    id: selectedSchedule.budget_request.id,
    config: { enabled: !!selectedSchedule },
  });

  // const [selectedValue, setSelectedValue] = useState<any>(partsValue[index] / part.quantity);
  const [selectedValue, setSelectedValue] = useState<any>(0);
  const [selectedManufacturer, setSelectedManufacturer] = useState<any>('');

  const changeValue = (index: number, value: number) => {
    const aux = [...partsValue];
    aux[index] = value * part.quantity;
    setPartsValue([...aux]);
  };

  const currencyFormatter = (value) => {
    if (!Number(value)) return '';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  };

  useEffect(() => {
    setBudgetParts([
      ...budgetParts?.filter((budgetPart) => budgetPart.id !== selectedPart.id),
      selectedPart,
    ]);
  }, [selectedPart]);

  useEffect(() => {
    setSelectedPart({
      ...selectedPart,
      selectedValue,
      selectedManufacturer,
    });
  }, [selectedManufacturer, selectedValue]);

  useEffect(() => {
    setSelectedPart({
      ...selectedPart,
      selectedValue,
      selectedManufacturer,
    });

    setBudgetParts([
      ...budgetParts?.filter((budgetPart) => budgetPart.id !== selectedPart.id),
      { ...selectedPart },
    ]);
  }, []);

  const debouncedFunction = (e: any, set: Dispatch<SetStateAction<any>>) => {
    setTimeout(() => set(e.target.value), 300);
  };

  return (
    <>
      <HStack w="full" pt={4}>
        <VStack w="full">
          <HStack w="full" flexDirection={{ base: 'column', md: 'row' }} align="flex-start" mb={5}>
            <VStack align="flex-start" w="full" mb={3} spacing={0}>
              <Text fontWeight="bold" mb={4}>{`${part.quantity}x ${part.part.name}`}</Text>

              <Text color="gray.400">Marca de preferência</Text>
              <Text>{part.manufacturer.name}</Text>
            </VStack>

            <AutoComplete
              onChange={(e) => {
                const manufacturer = partManufacturers?.pages
                  ?.flatMap((page) => page)
                  ?.find((item) => item.name === e);
                setSelectedManufacturer(manufacturer.id);
              }}
              openOnFocus
              emptyState={
                <Text w="full" textAlign="center">
                  Fabricante não encontrado
                </Text>
              }
            >
              <AutoCompleteInput
                w="full"
                rounded="md"
                placeholder="Selecionar fabricante"
                variant="outline"
                onChange={(e) => debouncedFunction(e, setSearch)}
              />
              <AutoCompleteList
                onScroll={(e) => {
                  const { clientHeight, scrollTop, scrollHeight } = e.target as HTMLElement;
                  const bottom = Math.ceil(clientHeight + scrollTop) >= scrollHeight;
                  if (bottom) fetchNextPage();
                }}
              >
                {partManufacturers?.pages
                  ?.flatMap((page) => page)
                  ?.map((storePart, index) => {
                    return (
                      <AutoCompleteItem
                        key={`option-${index}`}
                        value={storePart.name}
                        textTransform="capitalize"
                      >
                        <HStack w="full" justify="space-between">
                          <Text>{String(storePart.name)}</Text>
                          {!storePart.suggested && (
                            <HStack px={3} rounded="full" bg="brand.300">
                              <Text fontSize="xs">Indicação MyCar</Text>
                              <Image src={LogoIcon} w={3} />
                            </HStack>
                          )}
                        </HStack>
                      </AutoCompleteItem>
                    );
                  })}
              </AutoCompleteList>
            </AutoComplete>
          </HStack>
          <HStack w="full" align="flex-start" flexDirection={{ base: 'column', md: 'row' }}>
            <VStack align="flex-start" w="full" mb={3}>
              <Text fontWeight="bold">Atributos</Text>
              <HStack
                display="flex"
                flexWrap="wrap"
                align="flex-start"
                justify="start"
                w="full"
                spacing={0}
              >
                {partAttributes?.length > 0 ? (
                  partAttributes?.map((partAttribute) => (
                    <Tag ml="0px !important" mr="15px !important" mb={5} key={partAttribute.id}>
                      {partAttribute.attribute.name}
                    </Tag>
                  ))
                ) : (
                  <Text fontSize="smaller">Sem atributos</Text>
                )}
              </HStack>
            </VStack>
            <VStack
              marginInlineStart="0px !important"
              marginStart="0px !important"
              justify="flex-start"
              align="flex-start"
              w="full"
            >
              <Text fontWeight="bold">Valor unitário do produto</Text>

              <HStack
                marginInlineStart="0px !important"
                marginStart="0px !important"
                w="full"
                rounded="md"
              >
                <NumberFormat
                  className="custom-input"
                  allowNegative={false}
                  onValueChange={(value) => {
                    const formattedValue = Number(
                      value.formattedValue.replace('R$ ', '').replace('.', '').replace(',', '.'),
                    );
                    setSelectedValue(formattedValue);
                    changeValue(index, formattedValue);
                  }}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale
                  format={currencyFormatter}
                  prefix="R$ "
                  defaultValue={selectedValue * 100}
                />
              </HStack>
            </VStack>
          </HStack>
        </VStack>
      </HStack>

      <Divider />
    </>
  );
};
