import { useQuery } from 'react-query';

import { getBudgetRequestResponse } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseBudgetRequestResponse = {
  id: string;
  config?: QueryConfig<typeof getBudgetRequestResponse>;
};

export const useBudgetRequestResponse = ({ id, config }: UseBudgetRequestResponse) => {
  return useQuery(['getBudgetRequestResponse', id], getBudgetRequestResponse, config);
};
