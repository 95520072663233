import { CarDiagnosisStatusI } from '../types';

export const diagnosisLabelMap: { [key in CarDiagnosisStatusI]: string } = {
  started: 'Aguardando diagnóstico',
  diagnosed: 'Diagnóstico realizado',
  diagnostic_accepted_by_user: 'Diagnóstico Aprovado',
  diagnostic_canceled_by_user: 'Diagnóstico realizado (Reprovado)',
  diagnostic_canceled_by_repairshop: 'Serviço cancelado',
  in_progress: 'Serviço em andamento',
  finished: 'Serviço finalizado',
};

export const diagnosisActionButtonMap: {
  [key in Omit<CarDiagnosisStatusI, 'finished'> as string]: {
    action: {
      label: string;
      nextStatus?: CarDiagnosisStatusI;
    };
    cancelAction: {
      label: string;
    };
  };
} = {
  started: {
    action: {
      label: 'Enviar diagnóstico',
      nextStatus: 'diagnosed',
    },
    cancelAction: {
      label: 'Recusar serviço',
    },
  },
  diagnosed: {
    action: {
      label: 'Iniciar serviço',
      nextStatus: 'in_progress',
    },
    cancelAction: {
      label: 'Recusar serviço',
    },
  },
  diagnostic_accepted_by_user: {
    action: {
      label: 'Iniciar serviço',
      nextStatus: 'in_progress',
    },
    cancelAction: {
      label: 'Recusar serviço',
    },
  },
  diagnostic_canceled_by_user: {
    action: {
      label: 'Enviar diagnóstico',
      nextStatus: 'diagnosed',
    },
    cancelAction: {
      label: 'Recusar serviço',
    },
  },
  in_progress: {
    action: {
      label: 'Terminar serviço',
      nextStatus: 'finished',
    },
    cancelAction: {
      label: 'Recusar serviço',
    },
  },
};
