import { useState } from 'react';

import { BudgetRequest } from '../components/BudgetRequest';
import { EvaluationsList } from '../components/SellerEvaluations';
import { SellerSidebar } from '../components/SellerSidebar';
import { SellerRequest, SellerProvider } from '../contexts';
import { SellerScheduleContextType } from '../types';

import { Page } from '@/components/Page';

export const MyEvaluations = () => {
  const [activeTab] = useState<string>('schedules');

  return (
    <Page title="Suas vendas" pos="relative" minH="100vh">
      <SellerProvider>
        <SellerRequest>
          {({ selectedSchedule, setSelectedSchedule }: SellerScheduleContextType) => (
            <SellerSidebar>
              {selectedSchedule ? (
                <BudgetRequest
                  selectedSchedule={selectedSchedule}
                  setSelectedSchedule={setSelectedSchedule}
                  isUnread={false}
                />
              ) : (
                <EvaluationsList activeTab={activeTab} />
              )}
            </SellerSidebar>
          )}
        </SellerRequest>
      </SellerProvider>
    </Page>
  );
};
