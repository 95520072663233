import { useQuery } from 'react-query';

import { getUserNotifications } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseUserNotificationsOptions = {
  config?: QueryConfig<typeof getUserNotifications>;
};

export const useUserNotifications = ({ config }: UseUserNotificationsOptions) => {
  return useQuery(['getUserNotifications'], getUserNotifications, config);
};
