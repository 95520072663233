import { BudgetStatusType } from '../types';

export const budgetLabelMap: { [key in BudgetStatusType]: string } = {
  INIT: 'Aguardando orçamento',
  BUDGETED: 'Orçamento realizado',
  REJECTED: 'Orçamento rejeitado',
  PAID: 'Pedido solicitado',
  SENT: 'Pedido em transporte',
  CANCELLED: 'Pedido cancelado',
  FINISHED: 'Pedido finalizado',
};

export const budgetActionButtonMap: {
  [key in Omit<BudgetStatusType, 'FINISHED'> as string]: {
    action: {
      label: string;
      nextStatus?: BudgetStatusType;
    };
    cancelAction?: {
      label: string;
    };
  };
} = {
  INIT: {
    action: {
      label: 'Enviar orçamento',
      nextStatus: 'BUDGETED',
    },
    cancelAction: {
      label: 'Recusar pedido',
    },
  },
  BUDGETED: {
    action: {
      label: 'Enviar pedido',
      nextStatus: 'PAID',
    },
  },
  PAID: {
    action: {
      label: 'Enviar pedido',
      nextStatus: 'SENT',
    },
  },
  SENT: {
    action: {
      label: 'Marcar entrega como concluída',
      nextStatus: 'FINISHED',
    },
  },
};
