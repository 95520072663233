import { useQuery } from 'react-query';

import { getBudgetEvaluation } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseBudgetEvaluationOptions = {
  id?: number;
  config?: QueryConfig<typeof getBudgetEvaluation>;
};

export const useBudgetEvaluation = ({ id, config }: UseBudgetEvaluationOptions) => {
  return useQuery(['getBudgetEvaluation', id], getBudgetEvaluation, config);
};
