import { createContext, useContext, useRef } from 'react';

interface ScrollProviderProps {
  scrollRef: any;
}

const ScrollContext = createContext<ScrollProviderProps>({} as ScrollProviderProps);

export const ScrollProvider = ({ children }) => {
  const scrollRef = useRef<any>();

  return (
    <ScrollContext.Provider
      value={{
        scrollRef,
      }}
    >
      {children}
    </ScrollContext.Provider>
  );
};

export const useScroll = (): ScrollProviderProps => {
  const context = useContext(ScrollContext);

  return context;
};
