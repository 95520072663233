import { HStack, IconButton, Stack, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';

import { useRepairShopSchedule } from '../../contexts';
import { diagnosisLabelMap } from '../../utils';

import { useFCM } from '@/context/AppProvider';
import { readNotification } from '@/modules/consumer';
import parseLongString from '@/utils/parseLongString';

export const ScheduleCard = ({ schedule }) => {
  const { userNotifications, refetchUserNotifications } = useFCM();
  const { setSelectedSchedule } = useRepairShopSchedule();

  const [date] = useState(() => {
    const { preferred_date } = schedule.schedule_request;
    const date = preferred_date.substr(0, 10);
    const time = preferred_date.substr(10, 6);

    return `${date}, ${time}`;
  });

  const [isUnread, setIsUnread] = useState(
    userNotifications?.find((notification) => notification.object_pk === schedule.id),
  );

  useEffect(
    () =>
      setIsUnread(
        userNotifications?.find((notification) => notification.object_pk === schedule.id),
      ),
    [userNotifications, schedule],
  );

  return (
    <Stack
      rounded="md"
      bg={isUnread ? 'white' : '#E7EDF6'}
      p="4"
      w="full"
      cursor="pointer"
      flexDirection={{ base: 'column', md: 'row' }}
      spacing={{ base: 2, md: 0 }}
      justifyContent="space-between"
      onClick={async () => {
        setSelectedSchedule(schedule);

        if (isUnread) {
          await readNotification(isUnread);
          refetchUserNotifications();
        }
      }}
    >
      <Stack
        flexDirection={{ base: 'column', md: 'row' }}
        alignItems={{ base: 'flex-start', md: 'center' }}
        spacing={{ base: 2, md: 0 }}
        w="full"
      >
        <HStack mr={3}>
          <Text fontWeight="bold" whiteSpace="nowrap">
            {schedule.schedule_request.car_plate}
          </Text>
          <Text color="gray.400" whiteSpace="nowrap">
            {date}
          </Text>
        </HStack>
        <HStack>
          <Text d={{ base: 'initial', md: 'none' }}>Veículo</Text>
          <Text fontWeight="bold">
            {schedule.schedule_request?.car
              ? parseLongString(
                  `${schedule.schedule_request?.car?.car?.model?.manufacturer?.name} ${schedule?.schedule_request?.car?.car?.model?.name} ${schedule?.schedule_request?.car?.car?.year}`,
                  32,
                )
              : 'Veículo apagado'}
          </Text>
        </HStack>
      </Stack>

      <HStack justifyContent="space-between" w={{ base: 'full', md: 'fit-content' }}>
        <Text
          ml={6}
          whiteSpace="nowrap"
          pos="relative"
          _before={{
            content: '""',
            width: 4,
            height: 4,
            zIndex: 1000,
            pos: 'absolute',
            top: '50%',
            left: -6,
            transform: 'translateY(-50%)',
            border: '3px solid',
            borderColor: 'brand.500',
            rounded: 'full',
          }}
        >
          {diagnosisLabelMap[schedule.status]}
        </Text>

        <IconButton
          aria-label="Ver detalhes"
          icon={<MdKeyboardArrowRight size={28} />}
          bg={isUnread ? 'white' : '#E7EDF6'}
        />
      </HStack>
    </Stack>
  );
};
