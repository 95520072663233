/* eslint-disable react-hooks/exhaustive-deps */
import { VStack } from '@chakra-ui/react';
import { useEffect } from 'react';

import { BudgetHistoryCard } from './BudgetHistoryCard';

import { useFCM } from '@/context/AppProvider';

export const BudgetHistory = ({ activeTab }) => {
  const { storeBudgetHistory, refetchStoreBudgetHistory } = useFCM();

  useEffect(() => {
    activeTab === 3 && refetchStoreBudgetHistory();
  }, [activeTab]);

  return (
    <VStack align="flex-start" maxH={{ base: '80vh', md: '400px' }} overflowY="auto">
      {storeBudgetHistory?.map((storeSchedule) => (
        <BudgetHistoryCard key={storeSchedule.id} schedule={storeSchedule} />
      ))}
    </VStack>
  );
};
