import { mode } from '@chakra-ui/theme-tools';

const customVariant = ({
  bg,
  bgHover = bg,
  bgActive = bgHover,
  color,
  colorHover = color,
  boxShadowFocus = 'outline',
  ...restProps
}: any) => {
  return {
    bg,
    color: color,
    _focus: {
      boxShadow: boxShadowFocus,
    },
    _hover: {
      bg: bgHover,
      color: colorHover,
      _disabled: {
        bg,
      },
    },
    _active: { bg: bgActive },
    ...restProps,
  };
};

export default {
  variants: {
    // Custom variants
    '@primary': () =>
      customVariant({
        bg: 'brand.500',
        bgHover: 'brand.600',
        bgActive: 'brand.700',
        color: 'black',
        boxShadowFocus: 'outline-brand',
        px: 12,
      }),
    '@primaryLight': () =>
      customVariant({
        bg: 'brand.300',
        bgHover: 'brand.400',
        bgActive: 'brand.500',
        color: 'black',
        boxShadowFocus: 'outline-brand',
        px: 12,
      }),
    '@secondary': (props) =>
      customVariant({
        bg: mode('brand.50', 'brand.800')(props),
        bgHover: mode('brand.100', 'brand.700')(props),
        bgActive: mode('brand.200', 'brand.600')(props),
        color: mode('brand.600', 'brand.50')(props),
        colorHover: mode('brand.700', 'brand.100')(props),
        boxShadowFocus: 'outline-brand',
        px: 12,
      }),
    '@danger': (props) =>
      customVariant({
        bg: mode('error.50', 'error.900')(props),
        bgHover: mode('error.100', 'error.800')(props),
        bgActive: mode('error.200', 'error.700')(props),
        color: mode('error.600', 'error.50')(props),
        colorHover: mode('error.700', 'error.100')(props),
        boxShadowFocus: 'outline-error',
        px: 12,
      }),
    '@warning': (props) =>
      customVariant({
        bg: mode('warning.50', 'warning.900')(props),
        bgHover: mode('warning.100', 'warning.800')(props),
        bgActive: mode('warning.200', 'warning.700')(props),
        color: mode('warning.600', 'warning.50')(props),
        colorHover: mode('warning.700', 'warning.100')(props),
        boxShadowFocus: 'outline-warning',
        px: 12,
      }),
    '@colorless': () =>
      customVariant({
        bg: 'gray.300',
        bgHover: 'gray.400',
        bgActive: 'gray.400',
        color: 'black',
        px: 12,
      }),

    // Default variants
    outline: () => ({
      bg: 'transparent',
      _hover: {
        bg: 'transparent',
      },
      border: '2px',
      borderColor: 'white',
      px: 12,
    }),
    solid: () => ({
      bg: 'white',
      color: 'black',
      _hover: {
        bg: 'whiteAlpha.800',
      },
      px: 12,
    }),
    ghost: () => ({
      bg: 'transparent',
      _hover: {
        bg: 'transparent',
      },
      px: 12,
    }),
    social: () => ({
      bg: 'transparent',
      rounded: 'md',
      _hover: {
        bg: 'gray.100',
      },
      fontSize: 'sm',
      border: '2px',
      borderColor: 'gray.200',
      w: 'fit-content',
      px: 12,
      d: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
  },
  baseStyle: {
    borderRadius: 'full',
    fontWeight: 'semibold',
  },
};
