import { createContext, ReactNode, useContext } from 'react';

type ChatProviderType = {
  db: Record<string, any>;
  firebaseApp: Record<string, any>;
};

const ChatContext = createContext({} as ChatProviderType);

import { firebaseApp, db } from '@/firebase';

export const ChatProvider = ({ children }: { children: ReactNode }) => {
  return <ChatContext.Provider value={{ firebaseApp, db }}>{children}</ChatContext.Provider>;
};

export const useChat = () => useContext(ChatContext);
