import { Box, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { BudgetHistory } from '../components/BudgetHistory';
import { BudgetRequest } from '../components/BudgetRequest';
import { BudgetsSent } from '../components/BudgetsSent';
import { NewBudgets } from '../components/NewBudgets';
import { Orders } from '../components/Orders';
import { SellerSidebar } from '../components/SellerSidebar';
import { TrackShipping } from '../components/TrackShipping';
import { SellerRequest, SellerProvider } from '../contexts';
import { SellerScheduleContextType } from '../types';

import { Page } from '@/components/Page';
import { TabList, Tabs, Tab, TabPanels, TabPanel } from '@/components/Tabs';
import { useFCM } from '@/context/AppProvider';

export const MyBudgets = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [newBudgetsNotifications, setNewBudgetsNotifications] = useState<any[]>([]);
  const [budgetsSentNotifications, setBudgetsSentNotifications] = useState<any[]>([]);
  const [budgetHistoryNotifications, setBudgetHistoryNotifications] = useState<any[]>([]);
  const [ordersNotifications, setOrdersNotifications] = useState<any[]>([]);

  const { userNotifications } = useFCM();

  useEffect(() => {
    setNewBudgetsNotifications(
      userNotifications?.filter((notification) => notification.type === 'SELLER_NEW_BUDGET'),
    );
    setBudgetsSentNotifications(
      userNotifications?.filter((notification) =>
        ['DIAGNOSIS_BUDGETED'].includes(notification.type),
      ),
    );
    setBudgetHistoryNotifications(
      userNotifications?.filter((notification) => notification.type === 'SELLER_HISTORY'),
    );
    setOrdersNotifications(
      userNotifications?.filter((notification) =>
        ['SELLER_BUDGET_SENT', 'SELLER_ORDER_RECEIVED', 'SELLER_BUDGET_PAID'].includes(
          notification.type,
        ),
      ),
    );
  }, [userNotifications]);

  return (
    <Page title="Orçamentos" pos="relative" minH="100vh">
      <SellerProvider>
        <SellerRequest>
          {({ selectedSchedule, setSelectedSchedule }: SellerScheduleContextType) => (
            <SellerSidebar>
              {selectedSchedule ? (
                activeTab === 0 ? (
                  <BudgetRequest
                    selectedSchedule={selectedSchedule}
                    setSelectedSchedule={setSelectedSchedule}
                    isUnread={newBudgetsNotifications?.filter(
                      (notification) => notification.object_pk === selectedSchedule.id,
                    )}
                  />
                ) : (
                  <TrackShipping
                    selectedSchedule={selectedSchedule}
                    setSelectedSchedule={setSelectedSchedule}
                    isUnread={userNotifications?.filter(
                      (notification) => notification.object_pk === selectedSchedule.id,
                    )}
                  />
                )
              ) : (
                <Tabs index={activeTab}>
                  <TabList bg="gray.100" w="full" d="flex">
                    <Tab
                      onClick={() => {
                        setActiveTab(0);
                      }}
                      fontSize="sm"
                      mr={3}
                    >
                      Novos orçamentos
                      {newBudgetsNotifications?.length > 0 && (
                        <Text
                          color="black"
                          fontSize="xs"
                          w={5}
                          h={5}
                          display="flex"
                          alignItems="center"
                          textAlign="center"
                          justifyContent="center"
                          rounded="full"
                          bg="brand.500"
                          ml={3}
                        >
                          {newBudgetsNotifications.length}
                        </Text>
                      )}
                    </Tab>
                    <Tab
                      onClick={() => {
                        setActiveTab(1);
                      }}
                      fontSize="sm"
                      mr={3}
                    >
                      Orçamentos enviados
                      {budgetsSentNotifications?.length > 0 && (
                        <Text
                          ml={2}
                          color="black"
                          fontSize="xs"
                          w={5}
                          h={5}
                          display="flex"
                          alignItems="center"
                          textAlign="center"
                          justifyContent="center"
                          rounded="full"
                          bg="brand.500"
                        >
                          {budgetsSentNotifications.length}
                        </Text>
                      )}
                    </Tab>
                    <Tab
                      onClick={() => {
                        setActiveTab(2);
                      }}
                      fontSize="sm"
                    >
                      Pedidos
                      {ordersNotifications?.length > 0 && (
                        <Text
                          ml={2}
                          color="black"
                          fontSize="xs"
                          w={5}
                          h={5}
                          display="flex"
                          alignItems="center"
                          textAlign="center"
                          justifyContent="center"
                          rounded="full"
                          bg="brand.500"
                        >
                          {ordersNotifications.length}
                        </Text>
                      )}
                    </Tab>
                    <Tab
                      onClick={() => {
                        setActiveTab(3);
                      }}
                      fontSize="sm"
                    >
                      Histórico
                      {budgetHistoryNotifications?.length > 0 && (
                        <Text
                          ml={2}
                          color="black"
                          fontSize="xs"
                          w={5}
                          h={5}
                          display="flex"
                          alignItems="center"
                          textAlign="center"
                          justifyContent="center"
                          rounded="full"
                          bg="brand.500"
                        >
                          {budgetHistoryNotifications.length}
                        </Text>
                      )}
                    </Tab>
                  </TabList>
                  <TabPanels act>
                    <TabPanel>
                      <Box w="full">
                        <NewBudgets activeTab={activeTab} />
                      </Box>
                    </TabPanel>
                    <TabPanel>
                      <Box w="full">
                        <BudgetsSent activeTab={activeTab} />
                      </Box>
                    </TabPanel>
                    <TabPanel>
                      <Box w="full">
                        <Orders activeTab={activeTab} />
                      </Box>
                    </TabPanel>
                    <TabPanel>
                      <Box w="full">
                        <BudgetHistory activeTab={activeTab} />
                      </Box>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              )}
            </SellerSidebar>
          )}
        </SellerRequest>
      </SellerProvider>
    </Page>
  );
};
