import { v } from '@/lib/validator';

export default v.object().shape(
  {
    customer_name: v.string().required('Seu nome é obrigatório'),
    preferred_date: v.string().required('Horário preferido é obrigatório'),
    comment: v.string().nullable(),
    // car_plate: v.string().required('Placa do carro é obrigatória'),
    // car_manufacturer: v.string().required('Fabricante do carro é obrigatório'),
    // car_model: v.string().required('Modelo do carro é obrigatório'),
    // car_year: v.string().required('Ano do carro é obrigatório'),
    problem_id: v.array().when('problem_id_tags', {
      is: (problem_id_tags) => !problem_id_tags || problem_id_tags.length === 0,
      then: v.array().required('Por favor selecione um problema pra continuar'),
      otherwise: v.array(),
    }),
    problem_id_tags: v.array().when('problem_id', {
      is: (problem_id) => !problem_id || problem_id.length === 0,
      then: v.array().required(),
      otherwise: v.array(),
    }),
    photo: v.array().nullable(),
  },
  [['problem_id', 'problem_id_tags']],
);
