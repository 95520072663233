/* eslint-disable react-hooks/exhaustive-deps */
import { CheckboxGroupProps as CCheckboxGroupProps, Flex } from '@chakra-ui/react';
import { FieldProps } from 'formik';
import { motion } from 'framer-motion';

import { Checkbox } from './Checkbox';

interface Option {
  value: string | number;
  label?: React.ReactNode;
}

export interface TagCheckboxProps extends CCheckboxGroupProps, FieldProps {
  inline?: boolean;
  options?: Option[];
  additionalOnChange?: (props?: unknown) => void;
  additionalOnClick?: (props: any) => void;
  additionalSelectedItems?: any[];
}

export const TagCheckbox = ({
  options = [],
  additionalOnClick,
  additionalSelectedItems,
}: TagCheckboxProps) => {
  return (
    <motion.div
      transition={{ duration: 0.4 }}
      initial="init"
      animate="end"
      variants={{
        end: { opacity: 1, y: 0 },
        init: { opacity: 0, y: -40 },
      }}
    >
      <Flex display="flex" flexWrap="wrap" align="flex-start" justify="start" w="full" spacing={0}>
        {options.map((option) => {
          return (
            <Checkbox
              onClick={() => {
                additionalOnClick && additionalOnClick(option);
              }}
              checked={additionalSelectedItems?.find((item) => item.label === option.label)}
              key={option.value}
            >
              {option.label ?? option.value}
            </Checkbox>
          );
        })}
      </Flex>
    </motion.div>
  );
};

export default TagCheckbox;
