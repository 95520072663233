export const countdown = (start, additionInMs = 0) => {
  const countDownDate = new Date(start).getTime() + additionInMs;
  const now = new Date().getTime();
  const distance = now - countDownDate;

  if (distance >= 0) return '0:00';

  const minutes = -1 * Math.floor(distance / 60000);
  const seconds = -1 * Math.floor((distance % 60000) / 1000);

  const m = minutes <= 10 && minutes > 1 ? `0${minutes - 1}` : minutes - 1;
  const s = seconds <= 10 ? `0${seconds - 1}` : seconds - 1;

  return `${m}:${s}`;
};
