import { HStack, IconButton, Stack, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useEffect, useState } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';

import { useSellerRequest } from '../../contexts';
import { budgetLabelMap } from '../../utils';

dayjs.extend(customParseFormat);

import { useFCM } from '@/context/AppProvider';
import { readNotification } from '@/modules/consumer';
import { getTimePassed } from '@/utils';

export const NewBudgetCard = ({ schedule }) => {
  const { userNotifications, refetchUserNotifications } = useFCM();
  const { setSelectedSchedule } = useSellerRequest();

  const [isUnread, setIsUnread] = useState(
    userNotifications?.find((notification) => notification.object_pk === schedule.id),
  );

  useEffect(
    () =>
      setIsUnread(
        userNotifications?.find((notification) => notification.object_pk === schedule.id),
      ),
    [userNotifications, schedule],
  );

  return (
    <Stack
      rounded="md"
      bg={isUnread ? 'white' : '#E7EDF6'}
      p="4"
      w="full"
      cursor="pointer"
      flexDirection={{ base: 'column', md: 'row' }}
      spacing={{ base: 2, md: 0 }}
      justifyContent="space-between"
      onClick={async () => {
        setSelectedSchedule(schedule);

        if (isUnread) {
          await readNotification(isUnread);
          refetchUserNotifications();
        }
      }}
    >
      <Stack
        flexDirection={{ base: 'column', xl: 'row' }}
        alignItems={{ base: 'flex-start', xl: 'center' }}
        w="full"
        spacing={0}
      >
        <HStack mb={2} mr={10}>
          <Text fontWeight="bold" color="brand.500" whiteSpace="nowrap">
            {`${getTimePassed(schedule.created_at)} atrás`}
          </Text>
          <Text color="gray.400" whiteSpace="nowrap">
            {dayjs(schedule.created_at).format('DD/MM/YYYY, HH:mm')}
          </Text>
        </HStack>
        <HStack>
          {schedule.budget_request.car ? (
            <>
              <Text
                marginInlineStart="0px !important"
                marginStart="0px !important"
                d={{ base: 'initial', md: 'none' }}
                mr={2}
              >
                Veículo
              </Text>
              <Text
                marginInlineStart="0px !important"
                marginStart="0px !important"
                fontWeight="bold"
              >{`${schedule.budget_request.car.model.manufacturer.name} ${schedule.budget_request.car.model.name} ${schedule.budget_request.car.year}`}</Text>
            </>
          ) : (
            <Text>Veículo Apagado</Text>
          )}
        </HStack>
      </Stack>

      <HStack
        justifyContent="space-between"
        w={{ base: 'full', md: 'fit-content' }}
        alignItems="center"
      >
        <Text
          ml={6}
          whiteSpace="nowrap"
          pos="relative"
          _before={{
            content: '""',
            width: 4,
            height: 4,
            zIndex: 1000,
            pos: 'absolute',
            top: '50%',
            left: -6,
            transform: 'translateY(-50%)',
            border: '3px solid',
            borderColor: 'brand.500',
            rounded: 'full',
          }}
        >
          {(schedule && budgetLabelMap[schedule.status]) || '--'}
        </Text>

        <IconButton
          alignItems="center"
          aria-label="Ver detalhes"
          icon={<MdKeyboardArrowRight size={28} />}
          bg={isUnread ? 'white' : '#E7EDF6'}
        />
      </HStack>
    </Stack>
  );
};
