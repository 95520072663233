import { useQuery } from 'react-query';

import { getConsumerBudgetRequests } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseConsumerBudgetRequestsOptions = {
  config?: QueryConfig<typeof getConsumerBudgetRequests>;
};

export const useConsumerBudgetRequests = ({ config }: UseConsumerBudgetRequestsOptions) => {
  return useQuery(['getConsumerBudgetRequests'], getConsumerBudgetRequests, config);
};
