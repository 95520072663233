/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from '@chakra-ui/button';
import { Heading, HStack, Text, VStack, Link as CLink, Box } from '@chakra-ui/layout';
import { Tag } from '@chakra-ui/tag';
import { useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { ImageListType } from 'react-images-uploading';
import { Link } from 'react-router-dom';

import { ScheduleRequest } from '../../types';

import { BoxWithTitle } from '@/components/common/BoxWithTitle';
import { FieldWrapper, UploadImage, DatePicker, TextInput } from '@/components/Form';
import { useAuth, useScroll } from '@/context/AppProvider';

export const ProblemIdentificationForm = ({ next, previous, selectedProblems }) => {
  const { scrollRef } = useScroll();
  const { user: userIsLogged } = useAuth();

  const [user, setUser] = useState(() => {
    const user = localStorage.getItem('@user');
    return user && JSON.parse(user);
  });

  const [, setImages] = useState<ImageListType>([]);
  const { errors, setFieldValue } = useFormikContext<ScheduleRequest>();
  const datePickerRef = useRef<any>();

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollToTop();
    }
  }, []);

  useEffect(() => {
    if (userIsLogged) {
      const userInfo = localStorage.getItem('@user');
      setUser(() => userInfo && JSON.parse(userInfo));

      userInfo &&
        setFieldValue('customer_name', JSON.parse(userInfo).name || JSON.parse(userInfo).username);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIsLogged]);

  return (
    <VStack
      spacing={5}
      alignItems="flex-start"
      justifyContent="flex-start"
      bg="white"
      rounded="lg"
      shadow="lg"
      maxW="container.sm"
      p={10}
      maxH={{ base: 'initial', lg: '500px' }}
      minH="fit-content"
      overflowY={{ base: 'initial', lg: 'scroll' }}
    >
      <Heading as="p" fontFamily="gill" w="100%" fontSize="xl" textAlign="left">
        Problemas identificados
      </Heading>
      <Box mb={5}>
        {selectedProblems.map((item) => (
          <Tag key={item.value}>{item.label}</Tag>
        ))}
      </Box>
      {!user && (
        <BoxWithTitle
          title="Qual seu nome?"
          wrapperProps={{
            w: 'full',
          }}
        >
          <FieldWrapper
            name="customer_name"
            boxProps={{
              w: 'full',
            }}
            required
            as={(fieldProps) => <TextInput {...fieldProps} />}
          />
        </BoxWithTitle>
      )}
      <FieldWrapper
        name="photo"
        boxProps={{
          w: 'full',
        }}
        required
        as={(fieldProps) => (
          <UploadImage
            title="Envie fotos dos problemas do carro"
            onChange={(images) => {
              setImages(images);
              setFieldValue('photo', images);
            }}
            {...fieldProps}
          />
        )}
      />
      <BoxWithTitle
        title="Sua preferência de horário"
        wrapperProps={{
          w: 'full',
        }}
      >
        <FieldWrapper
          name="preferred_date"
          boxProps={{
            w: 'full',
          }}
          required
          as={({ field, form }) => (
            <DatePicker
              {...field}
              ref={datePickerRef}
              dateFormat="dd 'de' MMMM - HH:mm"
              showTimeSelect
              timeIntervals={15}
              label="Data"
              selectedDate={form.values[field.name]}
              onChange={(date) => form.setFieldValue(field.name, date)}
              onFocus={(e) => {
                e.target.readOnly = true;
                datePickerRef?.current?.scrollIntoView();
              }}
            />
          )}
        />
      </BoxWithTitle>
      <HStack w="full" justifyContent="flex-end">
        <Button onClick={previous} variant="@primary" alignSelf="flex-end">
          Voltar
        </Button>
        <Button
          onClick={next}
          variant="@primary"
          alignSelf="flex-end"
          disabled={Boolean(errors.photo || errors.preferred_date || errors.comment)}
        >
          Avançar
        </Button>
      </HStack>
      {!user && (
        <Text
          d={{ base: 'flex', lg: 'none' }}
          flexDirection="column"
          alignItems="center"
          mx="auto"
          w="full"
        >
          Não perca as informações pesquisadas!{' '}
          <CLink textDecoration="underline">
            <Link to="/entrar">Crie sua conta</Link>
          </CLink>
        </Text>
      )}
    </VStack>
  );
};
