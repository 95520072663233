import { MotionBox } from '../MotionBox';

import logoMin from '@/assets/logo-min.png';

export const Spinner = () => {
  return (
    <MotionBox
      initial={{ rotate: 0 }}
      animate={{ rotate: 360 }}
      transition={{ delay: 0, duration: 1.5, repeat: Infinity }}
      bgImage={logoMin}
      bgSize="contain"
      bgPosition="center"
      bgRepeat="no-repeat"
      w="60px"
      h="60px"
    />
  );
};
