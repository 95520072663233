import { useInfiniteQuery } from 'react-query';

import { QueryConfig } from '@/lib/react-query';

type useInfinitePaginationOptions = {
  tag: string;
  id?: string | number;
  search?: string;
  request: (pageParam, queryObject: any) => Promise<any>;
  config?: QueryConfig<any>;
  multiple?: string;
  name?: string;
};

export const useInfinitePagination = ({
  tag,
  request,
  id,
  search,
  multiple,
  name,
}: useInfinitePaginationOptions) => {
  return useInfiniteQuery(
    [tag, search, id, multiple, name],
    ({ pageParam = 1, queryKey }) => {
      return request(pageParam, queryKey);
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage?.next) {
          return new URL(lastPage.next).searchParams.get('page');
        }

        return false;
      },
    },
  );
};
