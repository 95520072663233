import { createContext, useContext, useState } from 'react';

import { CarDiagnosisI, RepairShopScheduleContextI } from '../types';

const RepairShopScheduleContext = createContext({} as RepairShopScheduleContextI);

export const RepairShopScheduleConsumer = ({ children }) => (
  <RepairShopScheduleContext.Consumer>{children}</RepairShopScheduleContext.Consumer>
);

export const RepairShopScheduleProvider = ({ children }) => {
  const [selectedSchedule, setSelectedSchedule] = useState<CarDiagnosisI | null>(null);

  return (
    <RepairShopScheduleContext.Provider value={{ setSelectedSchedule, selectedSchedule }}>
      {children}
    </RepairShopScheduleContext.Provider>
  );
};

export const useRepairShopSchedule = () => useContext(RepairShopScheduleContext);
