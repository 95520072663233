/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-case-declarations */
import { HStack, Text, VStack, Input, Divider, Button } from '@chakra-ui/react';
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteGroup,
  AutoCompleteCreatable,
} from '@choc-ui/chakra-autocomplete';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { FaRegEdit } from 'react-icons/fa';
import NumberFormat from 'react-number-format';
import { v4 as uuidv4 } from 'uuid';

import { getRepairShopServices } from '../../api';

import { useInfinitePagination } from '@/hooks/useInfinitePagination';

export const ServiceComponent = ({
  selectedSchedule,
  setNewService,
  selectedServices,
  setSelectedServices,
  canEdit,
  service,
  isCustomService,
  customServices,
  setCustomServices,
}) => {
  const [serviceName, setServiceName] = useState('');
  const [servicePrice, setServicePrice] = useState(0);
  const [serviceTime, setServiceTime] = useState(0);
  const [onEdition, setOnEdition] = useState(!service);
  const [customService, setCustomService] = useState(isCustomService);
  const [search, setSearch] = useState<string>();

  const { data: services, fetchNextPage } = useInfinitePagination({
    tag: 'getRepairShopServices',
    id: selectedSchedule.repair_shop.id,
    request: getRepairShopServices,
    search,
    config: { enabled: !!selectedSchedule },
  });

  const currencyFormatter = (value) => {
    if (!Number(value)) return '';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  };

  useEffect(() => {
    if (service) {
      setServiceName(service.service);
      setServicePrice(service.price);
      setServiceTime(service.time);
    }
  }, [service]);

  if (!onEdition) {
    return (
      <VStack w="full" h="full" align="flex-start" justify="flex-start" pt={5}>
        <VStack
          flexDir={{ base: 'column', md: 'row' }}
          w="full"
          justify="space-between"
          align="center"
          mb={3}
          spacing={3}
          h="full"
        >
          <VStack mr={{ base: 0, md: 16, lg: 5 }} w="full" align="flex-start" h="full">
            <Text fontSize="xs">Serviço</Text>
            <Text fontSize="sm" fontWeight="bold">
              {serviceName}
            </Text>
          </VStack>

          <VStack
            mt="0px !important"
            marginInlineStart="0px !important"
            w="full"
            align="flex-start"
            h="full"
          >
            <Text fontSize="xs">Preço</Text>
            <Text fontSize="sm" fontWeight="bold">
              {Number(servicePrice).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Text>
          </VStack>

          <VStack
            marginInlineStart="0px !important"
            pl={{ base: 0, md: 8 }}
            w="full"
            align="flex-start"
            h="full"
            mt="0px !important"
          >
            <Text fontSize="xs">Duração</Text>
            <Text fontSize="sm" fontWeight="bold">
              {`${serviceTime} minutos`}
            </Text>
          </VStack>

          <HStack w="full" justify="space-evenly">
            <HStack
              cursor="pointer"
              onClick={() => {
                setOnEdition(true);
              }}
            >
              <FaRegEdit cursor="pointer" size={20} style={{ color: 'gray' }} />
              <Text>Editar</Text>
            </HStack>
            <HStack
              cursor="pointer"
              onClick={() => {
                if (isCustomService) {
                  setCustomServices(customServices?.filter((s) => s.service !== serviceName));
                } else {
                  setSelectedServices(selectedServices.filter((s) => s.service !== serviceName));
                }
                setNewService(false);
                setOnEdition(false);
              }}
            >
              <AiOutlineDelete size={22} style={{ color: 'gray' }} cursor="pointer" />
              <Text>Excluir</Text>
            </HStack>
          </HStack>
        </VStack>
        <Divider />
      </VStack>
    );
  }

  const debouncedFunction = (e: any, set: Dispatch<SetStateAction<any>>) => {
    setTimeout(() => set(e.target.value), 300);
  };

  return (
    <VStack w="full" align="flex-start" justify="flex-start">
      <HStack
        flexDir={{ base: 'column', md: 'row' }}
        position="relative"
        h="full"
        w="full"
        align="flex-start"
        justify="flex-start"
        pr={0}
      >
        <VStack
          flexDir={{ base: 'column', md: 'row' }}
          marginInlineStart="0px !important"
          w={{ base: 'full', md: 'xs' }}
          mb={{ base: 3, md: 0 }}
        >
          <VStack w="full" align="flex-start" mr={{ base: 0, md: 5 }}>
            <Text fontSize="xs">Serviço</Text>
            <AutoComplete
              listAllValuesOnFocus
              onChange={(e) => {
                const isNewService = services?.pages
                  ?.flatMap((page) => page?.results || [])
                  ?.find((item) => item.service.name === e);

                if (!isNewService) setCustomService(true);

                setServiceName(e);
              }}
              openOnFocus
              creatable
              defaultValue={serviceName}
              emptyState={
                <Text w="full" textAlign="center">
                  Serviço não encontrado
                </Text>
              }
            >
              <AutoCompleteInput
                marginTop="0px !important"
                rounded="md"
                placeholder="Selecionar serviço"
                variant="outline"
                w="full"
                onChange={(e) => debouncedFunction(e, setSearch)}
              />
              <AutoCompleteList
                onScroll={(e) => {
                  const { clientHeight, scrollTop, scrollHeight } = e.target as HTMLElement;
                  const bottom = Math.ceil(clientHeight + scrollTop) >= scrollHeight;
                  if (bottom) fetchNextPage();
                }}
              >
                <AutoCompleteGroup>
                  {Array.from(
                    new Set(
                      services?.pages
                        ?.flatMap((page) => page?.results || [])
                        ?.map((item) => item?.service?.name) || [],
                    ),
                  )
                    ?.filter(
                      (name) => !selectedServices?.map((item) => item?.service)?.includes(name),
                    )
                    ?.map((name, index) => (
                      <AutoCompleteItem
                        key={`option-${index}`}
                        value={name}
                        textTransform="capitalize"
                      >
                        {String(name)}
                      </AutoCompleteItem>
                    ))}
                </AutoCompleteGroup>
                <AutoCompleteCreatable>
                  {({ value }) => <span>Adicionar {value}</span>}
                </AutoCompleteCreatable>
              </AutoCompleteList>
            </AutoComplete>
          </VStack>
        </VStack>

        <VStack w="full" flexDir={{ base: 'column', md: 'row' }} marginInlineStart="0px !important">
          <HStack
            flexDir={{ base: 'column', md: 'row' }}
            w="full"
            h="full"
            align={{ base: 'flex-start', md: 'flex-end' }}
            justify="flex-start"
            pr={{ base: 0, md: 3 }}
          >
            <HStack
              flexDir={{ base: 'column', md: 'row' }}
              mr={{ base: 0, md: 5 }}
              h="full"
              w="full"
              justify="flex-start"
              align={{ base: 'flex-start', md: 'center' }}
            >
              <VStack w="full" h="full" mb={{ base: 3, md: 0 }} align="flex-start" mr={5}>
                <Text mb={2} fontSize="xs">
                  Preço
                </Text>
                <HStack
                  marginInlineStart="0px !important"
                  marginStart="0px !important"
                  w={{ base: 'full', md: 'xm' }}
                  rounded="md"
                  mt="0px !important"
                >
                  <NumberFormat
                    customInput={Input}
                    allowNegative={false}
                    onValueChange={(value) => {
                      setServicePrice(
                        Number(
                          value.formattedValue
                            .replace('R$ ', '')
                            .replace('.', '')
                            .replace(',', '.'),
                        ),
                      );
                    }}
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale
                    format={currencyFormatter}
                    prefix="R$ "
                    defaultValue={onEdition && servicePrice * 100}
                    rounded="md"
                  />
                </HStack>
              </VStack>

              <VStack
                w="full"
                mb={{ base: 3, md: 0 }}
                marginInlineStart="0px !important"
                align="flex-start"
              >
                <Text mb={2} fontSize="xs">
                  Duração
                </Text>
                <HStack
                  marginInlineStart="0px !important"
                  marginStart="0px !important"
                  w={{ base: 'full', md: 'xm' }}
                  rounded="md"
                  mt="0px !important"
                >
                  <NumberFormat
                    customInput={Input}
                    allowNegative={false}
                    onValueChange={(value) => {
                      const aux = Number(value.formattedValue.replace(' minutos', ''));
                      setServiceTime(isNaN(aux) ? 0 : aux);
                    }}
                    suffix=" minutos"
                    rounded="md"
                    defaultValue={serviceTime !== 0 ? serviceTime : ''}
                  />
                </HStack>
              </VStack>
            </HStack>

            <HStack marginInlineStart="0px !important" pt={{ base: 5, md: 0 }} ml={5}>
              <Button
                mr={3}
                onClick={() => {
                  if (customService) {
                    if (onEdition && canEdit) {
                      const aux = customServices?.filter((s) => s.id !== service.id);
                      setCustomServices([
                        ...aux,
                        {
                          id: uuidv4(),
                          createdAt: service.createdAt,
                          service: serviceName,
                          price: servicePrice,
                          time: serviceTime,
                          isCustomService: true,
                        },
                      ]);
                      setOnEdition(false);
                    } else {
                      setCustomServices([
                        ...customServices,
                        {
                          id: uuidv4(),
                          createdAt: new Date(),
                          service: serviceName,
                          price: servicePrice,
                          time: serviceTime,
                          isCustomService: true,
                        },
                      ]);
                      setOnEdition(false);
                      setNewService(false);
                    }
                  } else {
                    if (onEdition && canEdit) {
                      const aux = selectedServices.filter((s) => s.id !== service.id);
                      setSelectedServices([
                        ...aux,
                        {
                          id: uuidv4(),
                          createdAt: service.createdAt,
                          service: serviceName,
                          service_id: services?.pages
                            ?.flatMap((page) => page?.results)
                            ?.find((s) => s.service.name === serviceName)?.id,
                          price: servicePrice,
                          time: serviceTime,
                          isCustomService: false,
                        },
                      ]);
                      setOnEdition(false);
                    } else {
                      setSelectedServices([
                        ...selectedServices,
                        {
                          id: uuidv4(),
                          createdAt: new Date(),
                          service: serviceName,
                          service_id: services?.pages
                            ?.flatMap((page) => page?.results)
                            ?.find((s) => s.service.name === serviceName)?.id,
                          price: servicePrice,
                          time: serviceTime,
                          isCustomService: false,
                        },
                      ]);
                      setOnEdition(false);
                      setNewService(false);
                    }
                  }
                }}
                size="sm"
                variant="@primary"
              >
                Salvar
              </Button>

              <AiOutlineDelete
                size={22}
                color="red.400"
                cursor="pointer"
                onClick={() => {
                  setNewService(false);
                  setOnEdition(false);
                }}
              />
            </HStack>
          </HStack>
        </VStack>
      </HStack>
      <Divider mt="15px !important" />
    </VStack>
  );
};
