import { Button, Text } from '@chakra-ui/react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { motion } from 'framer-motion';
import { useState } from 'react';

import { ResetPasswordCredentials } from '../../api/types';

import schema from './schema';

import { FieldWrapper, Form, TextInput } from '@/components/Form';
import * as LC from '@/components/LC';
import { firebaseApp } from '@/firebase';

export const ResetPasswordForm = ({ loading, setLoading, onSuccess, onError }) => {
  const auth = getAuth(firebaseApp);
  const [emailSent, setEmailSent] = useState(false);

  return (
    <>
      <Form<ResetPasswordCredentials>
        initialValues={{
          email: '',
          password: '',
          passwordConfirmation: '',
        }}
        onSubmit={async (values) => {
          setLoading(true);
          try {
            await sendPasswordResetEmail(auth, values.email).then(() => {
              setEmailSent(true);
            });
            onSuccess();
          } catch (error) {
            onError();
          }
        }}
        validationSchema={schema}
      >
        {() => (
          <motion.div
            transition={{ duration: 0.4 }}
            initial="init"
            animate="end"
            variants={{
              end: { opacity: 1, y: 0 },
              init: { opacity: 0, y: -40 },
            }}
          >
            {emailSent ? (
              <LC.Vertical w="100%">
                <Text fontSize="lg" fontWeight="bold" textAlign="center" mb={3}>
                  Email enviado com sucesso!
                </Text>
                <Text fontSize="md" textAlign="center">
                  Verifique sua caixa de entrada e clique no link enviado para redefinir sua senha.
                </Text>

                <LC.Vertical center spaceBetween mt={10}>
                  <Text fontSize="xm" mb={3}>
                    Não recebeu o email?
                  </Text>
                  <Button
                    onClick={() => setEmailSent(true)}
                    isLoading={loading}
                    type="submit"
                    className="w-full"
                    variant="@primary"
                  >
                    Reenviar email
                  </Button>
                </LC.Vertical>
              </LC.Vertical>
            ) : (
              <LC.Vertical w="100%">
                <Text fontSize="md" align="center" mb={5}>
                  Insira seu email. Enviaremos um link para redefinição de senha.
                </Text>
                <FieldWrapper
                  name="email"
                  required
                  as={(props) => <TextInput type="email" {...props} />}
                />

                <LC.Vertical center spaceBetween mt={5}>
                  <Button isLoading={loading} type="submit" className="w-full" variant="@primary">
                    Enviar email
                  </Button>
                </LC.Vertical>
              </LC.Vertical>
            )}
          </motion.div>
        )}
      </Form>
    </>
  );
};
