/* eslint-disable react-hooks/exhaustive-deps */
import {
  Stack,
  Flex,
  Button,
  VStack,
  HStack,
  Tag,
  Box,
  Image,
  useToast,
  Textarea,
  Skeleton,
  useMediaQuery,
  Divider,
  chakra,
  TagLabel,
  Text,
} from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AiFillStar, AiOutlineClockCircle } from 'react-icons/ai';
import { BsChat, BsCheckCircle } from 'react-icons/bs';
import { GrLocation } from 'react-icons/gr';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { ImageViewer } from 'react-image-viewer-dv';

import { updateSchedule } from '../../../repair-shop/api';
import { useDiagnosis, useDiagnosisPhotos } from '../../../repair-shop/hooks';
import { createEvaluation, createScheduleChatNotification, updateDiagnosis } from '../../api';
import {
  useScheduleChatNotifications,
  useMistakes,
  useScheduleRequestPhotos,
  useScheduleRequestProblems,
} from '../../hooks';
import { ConsumerScheduleContextType } from '../../types';
import { diagnosisLabelMap } from '../../utils';
import { ConsumerChat } from '../ConsumerChat';
import { Rating } from '../Rating';

import { BoxWithTitle } from '@/components/common/BoxWithTitle';
import { SimpleProgressBar } from '@/components/common/SimpleProgressBar';
import { FieldWrapper, Form, TagCheckbox } from '@/components/Form';
import { useFCM } from '@/context/AppProvider';
import { db } from '@/firebase';
import { calculateTime, countdown } from '@/utils';
import { formatArray } from '@/utils/formatArray';
import { getProgress } from '@/utils/getProgress';

export const CarDiagnosis = ({
  selectedSchedule,
  setSelectedSchedule,
}: Partial<ConsumerScheduleContextType>) => {
  const [selectedProblems, setSelectedProblems] = useState<any[] | null>([]);
  const [comment, setComment] = useState<string>();
  const [rating, setRating] = useState(0);
  const [rated, setRated] = useState(false);
  const [isLoadingEvaluation, setIsLoadingEvaluation] = useState(false);
  const { consumerSchedules, refetchUserNotifications } = useFCM();
  const [user] = useState(() => {
    const aux = localStorage.getItem('@user');
    return aux && JSON.parse(aux);
  });

  const [socketList] = useState(consumerSchedules);

  const [isSmallScreen] = useMediaQuery('(max-width: 860px)');

  const toast = useToast();

  const [openChat, setOpenChat] = useState(false);

  const { data: mistakes, isLoading: isLoadingMistakes } = useMistakes({});

  const { data: scheduleRequestPhotos, refetch: refetchScheduleRequestPhotos } =
    useScheduleRequestPhotos({
      id: selectedSchedule.schedule_request.id,
      config: { enabled: !!selectedSchedule },
    });

  const {
    data: scheduleRequestProblems,
    refetch: refetchScheduleRequestProblems,
    isLoading: isLoadingScheduleRequestProblems,
  } = useScheduleRequestProblems({
    id: selectedSchedule.schedule_request.id,
    config: { enabled: !!selectedSchedule },
  });

  const {
    data: diagnosis,
    refetch: refetchDiagnosis,
    isLoading: isLoadingDiagnosis,
  } = useDiagnosis({
    id: selectedSchedule.id,
    config: { enabled: !!selectedSchedule },
  });

  const [custom_repair_shop_parts, setCustom_repair_shop_parts] = useState(() => {
    if (diagnosis?.length > 0) {
      const aux = diagnosis[0]?.custom_repair_shop_parts;
      return JSON.parse(aux);
    }
  });

  const [custom_repair_shop_services, setCustom_repair_shop_services] = useState(() => {
    if (diagnosis?.length > 0) {
      const aux = diagnosis[0]?.custom_repair_shop_services;
      return JSON.parse(aux);
    }
  });

  const {
    data: diagnosisPhotos,
    refetch: refetchDiagnosisPhotos,
    isLoading: isLoadingDiagnosisPhotos,
  } = useDiagnosisPhotos({
    id: diagnosis ? diagnosis[0]?.id : undefined,
    config: { enabled: !!diagnosis },
  });

  const { data: chatNotifications, refetch: refetchChatNotifications } =
    useScheduleChatNotifications({
      id: selectedSchedule.id,
    });

  const [diff, setDiff] = useState<any>();

  useEffect(() => {
    if (diagnosis) {
      setRated(!!diagnosis[0]?.schedule?.schedule_evaluation[0]);
      setRating(diagnosis[0]?.schedule?.schedule_evaluation[0]?.stars);
    }

    if (diagnosis?.length > 0) {
      setCustom_repair_shop_parts(() => {
        const aux = diagnosis[0]?.custom_repair_shop_parts;
        return JSON.parse(aux);
      });

      setCustom_repair_shop_services(() => {
        const aux = diagnosis[0]?.custom_repair_shop_services;
        return JSON.parse(aux);
      });
    }
  }, [diagnosis]);

  useEffect(() => {
    if (diagnosis?.length > 0 && diagnosis.length) {
      const { difference } = calculateTime(
        diagnosis[0]?.created_at,
        diagnosis[0]?.updated_at,
        true,
      );

      setDiff(difference);
    }
  }, [diagnosis]);

  const handleTagBoxChange = (value: any) => {
    if (selectedProblems) {
      setSelectedProblems((prev) => {
        if (prev) {
          if (!selectedProblems?.findIndex((item) => item.label === value.label)) {
            return selectedProblems?.filter((item) => item.label !== value.label);
          }
          return [...prev, value];
        }

        return [value];
      });
    } else {
      setSelectedProblems([value]);
    }
  };

  useEffect(() => {
    refetchDiagnosis();
    refetchDiagnosisPhotos();
    refetchScheduleRequestPhotos();
    refetchScheduleRequestProblems();
  }, [selectedSchedule]);

  useEffect(() => refetchUserNotifications(), []);

  const handleCustomLabels = (quantity) => {
    return quantity > 1 ? `${quantity} peças` : `1 peça`;
  };

  const handleLabels = (part, quantity) => {
    switch (part?.multiple) {
      case 1:
        return quantity > 1 ? `${quantity} peças` : `1 peça`;
      case 2:
        return quantity > 1 ? `${quantity} pares` : `1 par`;
      default:
        return quantity > 1 ? `${quantity} jogos` : `1 jogo`;
    }
  };

  useEffect(() => {
    if (socketList !== consumerSchedules) {
      setSelectedSchedule && setSelectedSchedule(null);
    }
  }, [consumerSchedules]);

  const countdownCallback = useCallback(
    () => countdown(selectedSchedule.end_date),
    [selectedSchedule.end_date],
  );

  const progressCallback = useCallback(
    () =>
      getProgress({
        start_date: selectedSchedule.start_date,
        end_date: selectedSchedule.end_date,
      }),
    [selectedSchedule.start_date, selectedSchedule.end_date],
  );

  const [expirationTime, setExpirationTime] = useState<any>(countdownCallback());
  const [progress, setProgress] = useState<any>(progressCallback());

  const handleExpirationTime = useCallback(async () => {
    const timer = setTimeout(() => {
      setExpirationTime(countdown(selectedSchedule.end_date));
      setProgress(
        getProgress({
          start_date: selectedSchedule.start_date,
          end_date: selectedSchedule.end_date,
        }),
      );
    }, 1000);

    return () => clearTimeout(timer);
  }, [expirationTime, progress, selectedSchedule]);

  useEffect(() => {
    handleExpirationTime();
  }, [handleExpirationTime]);

  const createNotification = async () => {
    await createScheduleChatNotification({
      repair_shop: selectedSchedule.repair_shop.id,
      schedule: selectedSchedule.id,
    });
    refetchChatNotifications();
  };

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
  });

  useEffect(() => {
    db?.collection(
      `user-${selectedSchedule.user}-repairShop-${selectedSchedule.repair_shop.id}-schedule-${selectedSchedule.id}-scheduleRequest-${selectedSchedule.schedule_request.id}-chat`,
    )
      .orderBy('createdAt')
      .onSnapshot((querySnapShot: any) => {
        const data = querySnapShot.docs.map((doc: any) => ({
          ...doc.data(),
          id: doc.id,
        }));

        if (!isFirstRun.current) {
          if (data?.at(-1)?.sender === user.name) {
            createNotification();
            refetchChatNotifications();
          } else {
            refetchChatNotifications();
          }
        }
      });
  }, [db, selectedSchedule.user, selectedSchedule.repair_shop.id]);

  return (
    <Flex w="full" flexDirection="column">
      <HStack w="100%" alignItems="center" justifyContent="space-between" mb="4" pt={10}>
        <Button
          variant="ghost"
          leftIcon={<MdKeyboardArrowLeft />}
          alignSelf="flex-start"
          pl="0"
          onClick={() => {
            if (openChat) {
              setOpenChat(!openChat);
            } else {
              setSelectedSchedule && setSelectedSchedule(null);
            }
          }}
        >
          voltar
        </Button>

        {[
          'started',
          'diagnosed',
          'in_progress',
          'diagnostic_accepted_by_user',
          'in_progress',
        ].includes(selectedSchedule.status) &&
          !openChat && (
            <Button
              variant={
                chatNotifications?.find((notification) => notification.is_read === false)
                  ? '@primary'
                  : '@secondary'
              }
              leftIcon={<BsChat />}
              onClick={() => setOpenChat((prev) => !prev)}
              size={isSmallScreen ? 'sm' : 'md'}
              color="black"
            >
              {chatNotifications?.find((notification) => notification.is_read === false)
                ? 'Nova mensagem!'
                : 'Mensagens'}
            </Button>
          )}
      </HStack>

      {[
        'started',
        'diagnosed',
        'in_progress',
        'diagnostic_accepted_by_user',
        'in_progress',
      ].includes(selectedSchedule.status) && openChat ? (
        <Stack px="2">
          <ConsumerChat
            isBudget={false}
            selectedSchedule={selectedSchedule}
            refetchChatNotifications={refetchChatNotifications}
          />
        </Stack>
      ) : (
        <VStack>
          <HStack
            flexDir={{ base: 'column', md: 'row' }}
            p={4}
            rounded="md"
            bg="white"
            flex="1"
            w="full"
          >
            <VStack alignItems="flex-start" w="full" spacing="2">
              <Text textAlign="left" fontSize="md">
                {selectedSchedule.repair_shop.name}
              </Text>
              <Text textAlign="left" color="gray.600" fontSize="xs" d="flex">
                <GrLocation style={{ marginRight: '5px' }} />
                {`${selectedSchedule.repair_shop.address.street}, ${selectedSchedule.repair_shop.address.number}`}
              </Text>
              <HStack
                w="full"
                alignItems={{ base: 'flex-start', md: 'center' }}
                flexDir={{ base: 'column', md: 'row' }}
              >
                <Box
                  display="flex"
                  flexDir="row"
                  justifyContent="center"
                  w="fit-content"
                  bg="brand.500"
                  rounded="full"
                  py={1}
                  px={3}
                  mb={2}
                >
                  <AiOutlineClockCircle style={{ marginRight: '5px' }} />
                  <Text textAlign="left" color="gray.600" fontSize="xs" d="flex">
                    {selectedSchedule.schedule_request.preferred_date.slice(11)}
                  </Text>
                </Box>
              </HStack>
            </VStack>
            <HStack
              spacing={2}
              justifyContent="space-between"
              w={{ base: 'full', md: 'fit-content' }}
            >
              <Text
                ml={6}
                whiteSpace="nowrap"
                pos="relative"
                mt={{ base: 3, md: 0 }}
                _before={{
                  content: '""',
                  width: 4,
                  height: 4,
                  zIndex: 1000,
                  pos: 'absolute',
                  top: '50%',
                  left: -6,
                  transform: 'translateY(-50%)',
                  border: '3px solid',
                  borderColor: 'brand.500',
                  rounded: 'full',
                }}
              >
                {(selectedSchedule && diagnosisLabelMap[selectedSchedule.status]) || '--'}
              </Text>
            </HStack>
          </HStack>

          <Stack w="full" alignItems="stretch" flexDirectiobn={{ base: 'row', md: 'column' }}>
            {selectedSchedule && selectedSchedule.status === 'finished' && (
              <VStack w="full" p={4} rounded="md" bg="white" flex="1" align="flex-end">
                {isLoadingDiagnosis ? (
                  <Skeleton w="full" height={20} borderRadius={20} mb={8} />
                ) : (
                  <VStack
                    p={4}
                    rounded="md"
                    bg="white"
                    alignItems="flex-start"
                    flex="1"
                    mr={2}
                    spacing="5"
                    w="full"
                  >
                    <Text fontSize="md" fontWeight="bold">
                      {rated ? 'Avaliação' : 'Avaliar'}
                    </Text>

                    <Box>
                      {rated && rating ? (
                        <HStack>
                          {new Array(rating).fill(0).map((_, index) => (
                            <AiFillStar key={index} size={30} color="#EFA808" />
                          ))}
                        </HStack>
                      ) : (
                        <Rating rate={rating} setRate={setRating} />
                      )}
                    </Box>
                    <Box w="full" display="flex" flexWrap="wrap">
                      <Form
                        onSubmit={async () => {
                          setIsLoadingEvaluation(true);

                          const data =
                            rating < 5
                              ? {
                                  repair_shop: selectedSchedule.repair_shop.id,
                                  user: selectedSchedule.user,
                                  stars: rating,
                                  car: selectedSchedule.schedule_request.car.car.id,
                                  comment,
                                  mistakes: selectedProblems?.map((item) => item.value),
                                  schedule: selectedSchedule.id,
                                }
                              : {
                                  repair_shop: selectedSchedule.repair_shop.id,
                                  user: selectedSchedule.user,
                                  stars: rating,
                                  car: selectedSchedule.schedule_request.car.car.id,
                                  schedule: selectedSchedule.id,
                                  comment,
                                };

                          try {
                            await createEvaluation(data);

                            toast({
                              title: 'Avaliação realizada com sucesso.',
                              status: 'success',
                              duration: 5000,
                              isClosable: true,
                            });

                            setIsLoadingEvaluation(false);
                            refetchDiagnosis();
                          } catch (error) {
                            toast({
                              title: 'Erro ao enviar avaliação. Tente novamente.',
                              status: 'error',
                              duration: 5000,
                              isClosable: true,
                            });
                            setIsLoadingEvaluation(false);
                          }
                        }}
                        initialValues={{
                          selectedProblems: [],
                          comment: '',
                        }}
                      >
                        {() => (
                          <>
                            {rated &&
                            diagnosis?.length > 0 &&
                            diagnosis[0]?.schedule?.schedule_evaluation[0]?.stars < 5 ? (
                              <FieldWrapper
                                name="selected_problems"
                                as={() => (
                                  <>
                                    {diagnosis[0]?.schedule?.schedule_evaluation[0]?.mistakes?.map(
                                      (item) => (
                                        <Tag key={item.id}>{item.name}</Tag>
                                      ),
                                    )}
                                  </>
                                )}
                              />
                            ) : (
                              rating < 5 && (
                                <BoxWithTitle title="O que deu errado?" titleSize="sm">
                                  {isLoadingMistakes || !mistakes ? (
                                    <Skeleton height={20} borderRadius={20} mb={8} />
                                  ) : (
                                    <HStack>
                                      <FieldWrapper
                                        name="selected_problems"
                                        as={(fieldProps) => (
                                          <>
                                            <TagCheckbox
                                              options={formatArray(mistakes?.results) || []}
                                              additionalOnClick={handleTagBoxChange}
                                              additionalSelectedItems={selectedProblems || []}
                                              {...fieldProps}
                                            />
                                          </>
                                        )}
                                      />
                                    </HStack>
                                  )}
                                </BoxWithTitle>
                              )
                            )}

                            {rated ? (
                              diagnosis?.length > 0 &&
                              diagnosis[0]?.schedule?.schedule_evaluation[0]?.comment && (
                                <BoxWithTitle title="Obrigado pela avaliação!" titleSize="sm">
                                  <FieldWrapper
                                    name="comment"
                                    as={() => (
                                      <Textarea
                                        cursor="default"
                                        variant="filled"
                                        _focus={{
                                          bg: 'gray.100',
                                        }}
                                        isReadOnly
                                        resize="none"
                                        value={
                                          diagnosis[0]?.schedule?.schedule_evaluation[0].comment
                                        }
                                      />
                                    )}
                                  />
                                </BoxWithTitle>
                              )
                            ) : (
                              <BoxWithTitle title="Conte como foi a sua experiência" titleSize="sm">
                                <FieldWrapper
                                  name="comment"
                                  as={() => (
                                    <FieldWrapper
                                      name="comment"
                                      as={() => (
                                        <Textarea onChange={(e) => setComment(e.target.value)} />
                                      )}
                                    />
                                  )}
                                />
                              </BoxWithTitle>
                            )}

                            {!rated && (
                              <Button
                                isDisabled={!rating || !comment}
                                type="submit"
                                size="sm"
                                fontSize="sm"
                                variant="@primary"
                                isLoading={isLoadingEvaluation}
                              >
                                Enviar avaliação
                              </Button>
                            )}
                          </>
                        )}
                      </Form>
                    </Box>
                  </VStack>
                )}
              </VStack>
            )}

            {['started', 'diagnosed'].includes(selectedSchedule.status) ? (
              <VStack p={4} rounded="md" bg="white" alignItems="flex-start" spacing="5">
                <Text fontSize="md" fontWeight="bold">
                  Problemas
                </Text>
                <HStack
                  display="flex"
                  flexWrap="wrap"
                  align="flex-start"
                  justify="start"
                  w="full"
                  spacing={0}
                >
                  {isLoadingScheduleRequestProblems || !scheduleRequestProblems ? (
                    <Skeleton w="full" height={8} borderRadius={20} />
                  ) : (
                    <>
                      {scheduleRequestProblems?.map((problem) => (
                        <Tag mr="10px !important" mb={3} key={problem.id} size="sm">
                          {problem.problem.name}
                        </Tag>
                      ))}

                      {selectedSchedule?.schedule_request?.customer_problem?.length > 0 &&
                        selectedSchedule?.schedule_request?.customer_problem
                          ?.split(',')
                          ?.map((problem) => (
                            <Tag mr="10px !important" mb={3} key={problem} size="sm">
                              {problem}
                            </Tag>
                          ))}
                    </>
                  )}
                </HStack>
                {!isLoadingScheduleRequestProblems && scheduleRequestPhotos?.length && (
                  <HStack w="full">
                    {scheduleRequestPhotos?.map((photo) => (
                      <ImageViewer key={photo.id}>
                        <Image
                          src={photo.photo}
                          bgPos="center"
                          bgSize="cover"
                          rounded="md"
                          shadow="md"
                          flex={1}
                          h="100px"
                          w="full"
                        />
                      </ImageViewer>
                    ))}
                  </HStack>
                )}
              </VStack>
            ) : (
              diagnosisPhotos?.length > 0 && (
                <VStack w="full" p={4} rounded="md" bg="white" alignItems="flex-start" spacing="5">
                  <Text fontSize="md" fontWeight="bold">
                    Fotos do diagnóstico
                  </Text>
                  <HStack
                    display="flex"
                    flexWrap="wrap"
                    align="flex-start"
                    justify="start"
                    w="full"
                    spacing={0}
                  >
                    {isLoadingDiagnosisPhotos || !diagnosisPhotos ? (
                      <Skeleton w="full" height={8} borderRadius={20} />
                    ) : (
                      diagnosisPhotos?.length > 0 && (
                        <HStack w="full">
                          {diagnosisPhotos?.map((photo) => (
                            <ImageViewer key={photo.id}>
                              <Image
                                src={photo.photo}
                                bgPos="center"
                                bgSize="cover"
                                rounded="md"
                                shadow="md"
                                flex={1}
                                h="100px"
                                w="full"
                              />
                            </ImageViewer>
                          ))}
                        </HStack>
                      )
                    )}
                  </HStack>
                </VStack>
              )
            )}

            <Stack w="full" alignItems="stretch" flexDirectiobn={{ base: 'row', md: 'column' }}>
              {selectedSchedule.schedule_request.comment && (
                <Flex flexDirection={{ base: 'column', md: 'row' }} w="full">
                  <BoxWithTitle
                    title="Observação do cliente"
                    titleSize="sm"
                    wrapperProps={{
                      w: 'full',
                      bg: 'white',
                      p: 4,
                      rounded: 'md',
                    }}
                  >
                    <Text>{selectedSchedule.schedule_request.comment}</Text>
                  </BoxWithTitle>
                </Flex>
              )}

              {selectedSchedule?.status !== 'started' && (
                <VStack p={4} rounded="md" bg="white" alignItems="flex-start" flex="1" spacing="5">
                  <Text fontSize="md" fontWeight="bold">
                    Serviços
                  </Text>

                  {isLoadingDiagnosis || isLoadingDiagnosisPhotos || !diagnosis ? (
                    <Skeleton w="full" height={8} borderRadius={20} />
                  ) : (
                    <>
                      <VStack w="full">
                        {diagnosis?.length > 0 &&
                          diagnosis[0]?.schedule_services_list?.map((service) => (
                            <VStack w="full" align="flex-start" key={service?.id}>
                              <Tag
                                display="flex"
                                alignItems="flex-start"
                                justifyContent="space-between"
                                rounded="none"
                                w="full"
                                marginInlineStart="0px !important"
                                marginStart="0px !important"
                                border="none"
                                px={0}
                                py={0}
                                mb={3}
                              >
                                <TagLabel
                                  ml={0}
                                  marginInlineStart="0px !important"
                                  marginStart="0px !important"
                                  mr={5}
                                >
                                  <Text fontSize="sm" fontWeight="bold" mb={2}>
                                    {service.repairshop_service.service.name}
                                  </Text>
                                </TagLabel>
                                <Text fontSize="sm" fontWeight="bold">
                                  {Number(service.price).toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL',
                                  })}
                                </Text>
                              </Tag>

                              <Divider />
                            </VStack>
                          ))}

                        {diagnosis?.length > 0 &&
                          custom_repair_shop_services &&
                          custom_repair_shop_services?.map((service) => (
                            <VStack w="full" align="flex-start" key={service?.id}>
                              <Tag
                                display="flex"
                                alignItems="flex-start"
                                justifyContent="space-between"
                                rounded="none"
                                w="full"
                                marginInlineStart="0px !important"
                                marginStart="0px !important"
                                border="none"
                                px={0}
                                py={0}
                                mb={3}
                              >
                                <TagLabel
                                  ml={0}
                                  marginInlineStart="0px !important"
                                  marginStart="0px !important"
                                  mr={5}
                                >
                                  <Text fontSize="sm" fontWeight="bold" mb={2}>
                                    {service.service}
                                  </Text>
                                </TagLabel>
                                <Text fontSize="sm" fontWeight="bold">
                                  {Number(service.price).toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL',
                                  })}
                                </Text>
                              </Tag>

                              <Divider />
                            </VStack>
                          ))}
                      </VStack>

                      {diagnosis?.length > 0 && custom_repair_shop_services && (
                        <HStack flexDir={{ base: 'column', md: 'row' }} w="full" mt={10}>
                          <Flex w="full" align="center">
                            <Text
                              display="flex"
                              alignItems="center"
                              w={{ base: 'full', md: 'fit-content' }}
                              justifyContent="space-between"
                              mr={{ base: 0, md: 5 }}
                            >
                              Valor total dos serviços
                              <chakra.span ml={3} fontWeight="bold" fontSize="lg">
                                {Number(
                                  Number(
                                    diagnosis[0]?.schedule_services_list?.length > 0
                                      ? diagnosis[0]?.schedule_services_list?.reduce(
                                          (acc, curr) => acc + Number(curr.price),
                                          0,
                                        )
                                      : 0,
                                  ) +
                                    Number(
                                      custom_repair_shop_services
                                        ? custom_repair_shop_services?.reduce(
                                            (acc, curr) => acc + Number(curr.price),
                                            0,
                                          )
                                        : 0,
                                    ),
                                ).toLocaleString('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL',
                                })}
                              </chakra.span>
                            </Text>
                          </Flex>
                        </HStack>
                      )}
                    </>
                  )}
                </VStack>
              )}

              {selectedSchedule?.status !== 'started' && (
                <VStack p={4} rounded="md" bg="white" alignItems="flex-start" flex="1" spacing="5">
                  <Text fontSize="md" fontWeight="bold">
                    Peças
                  </Text>

                  {isLoadingDiagnosis && !diagnosis ? (
                    <Skeleton w="full" height={8} borderRadius={20} />
                  ) : (
                    <>
                      <VStack w="full" pb={0}>
                        {diagnosis?.length > 0 &&
                          diagnosis[0]?.schedule_parts?.map(
                            ({ attributes, repair_shop_part, price, quantity, manufacturer }) => (
                              <VStack w="full" key={repair_shop_part?.id} pb={5}>
                                <VStack w="full" h="full" align="flex-start" justify="flex-start">
                                  <VStack
                                    flexDir="row"
                                    w="full"
                                    justify="space-between"
                                    align="center"
                                    mb={3}
                                    spacing={3}
                                    h="full"
                                  >
                                    <VStack
                                      mr={{ base: 0, md: 16, lg: 5 }}
                                      w="full"
                                      align="flex-start"
                                      h="full"
                                    >
                                      <Text fontSize="xs">Peça</Text>
                                      <Text fontSize="sm" fontWeight="bold">
                                        {repair_shop_part?.part?.name}
                                      </Text>
                                    </VStack>

                                    <VStack
                                      mt="0px !important"
                                      marginInlineStart="0px !important"
                                      w="full"
                                      align="flex-start"
                                      h="full"
                                    >
                                      <Text fontSize="xs">Preço por peça</Text>
                                      <Text fontSize="sm" fontWeight="bold">
                                        {Number(price).toLocaleString('pt-BR', {
                                          style: 'currency',
                                          currency: 'BRL',
                                        })}
                                      </Text>
                                    </VStack>

                                    <VStack
                                      marginInlineStart="0px !important"
                                      pl={{ base: 0, md: 8 }}
                                      w="full"
                                      align="flex-start"
                                      h="full"
                                      mt="0px !important"
                                    >
                                      <Text fontSize="xs">Quantidade</Text>
                                      <Text fontSize="sm" fontWeight="bold">
                                        {handleLabels(repair_shop_part?.part, quantity)}
                                      </Text>
                                    </VStack>

                                    <VStack
                                      marginInlineStart="0px !important"
                                      pl={{ base: 0, md: 8 }}
                                      w="full"
                                      align="flex-start"
                                      h="full"
                                      mt="0px !important"
                                    >
                                      <Text fontSize="xs">Fabricante</Text>
                                      <Text fontSize="sm" fontWeight="bold">
                                        {manufacturer.name}
                                      </Text>
                                    </VStack>
                                  </VStack>
                                </VStack>

                                {manufacturer?.length > 0 && (
                                  <VStack
                                    mr={{ base: 0, md: 16, lg: 5 }}
                                    w="full"
                                    align="flex-start"
                                    h="full"
                                  >
                                    <Text fontSize="xs">Atributos</Text>
                                    <HStack
                                      display="flex"
                                      flexWrap="wrap"
                                      align="center"
                                      justify="start"
                                      w="full"
                                      pb={5}
                                    >
                                      {attributes?.map(({ name }) => (
                                        <Box
                                          marginInlineStart="0px !important"
                                          marginInlineEnd="3px !important"
                                          key={name}
                                          mt="5px !important"
                                          mb="5px !important"
                                        >
                                          <Tag>
                                            <Text marginTop="0px !important">{name}</Text>
                                          </Tag>
                                        </Box>
                                      ))}
                                    </HStack>
                                  </VStack>
                                )}
                                <Divider />
                              </VStack>
                            ),
                          )}

                        {diagnosis &&
                          custom_repair_shop_parts &&
                          custom_repair_shop_parts?.map((part) => (
                            <VStack key={part.id} w="full" pb={5}>
                              <VStack w="full" h="full" align="flex-start" justify="flex-start">
                                <VStack
                                  flexDir="row"
                                  w="full"
                                  justify="space-between"
                                  align="center"
                                  mb={3}
                                  spacing={3}
                                  h="full"
                                >
                                  <VStack
                                    mr={{ base: 0, md: 16, lg: 5 }}
                                    w="full"
                                    align="flex-start"
                                    h="full"
                                  >
                                    <Text fontSize="xs">Peça</Text>
                                    <Text fontSize="sm" fontWeight="bold">
                                      {part.name}
                                    </Text>
                                  </VStack>

                                  <VStack
                                    mt="0px !important"
                                    marginInlineStart="0px !important"
                                    w="full"
                                    align="flex-start"
                                    h="full"
                                  >
                                    <Text fontSize="xs">Preço por peç</Text>
                                    <Text fontSize="sm" fontWeight="bold">
                                      {Number(part.price).toLocaleString('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                      })}
                                    </Text>
                                  </VStack>

                                  <VStack
                                    marginInlineStart="0px !important"
                                    pl={{ base: 0, md: 8 }}
                                    w="full"
                                    align="flex-start"
                                    h="full"
                                    mt="0px !important"
                                  >
                                    <Text fontSize="xs">Quantidade</Text>
                                    <Text fontSize="sm" fontWeight="bold">
                                      {handleCustomLabels(Number(part.quantity))}
                                    </Text>
                                  </VStack>

                                  <VStack
                                    marginInlineStart="0px !important"
                                    pl={{ base: 0, md: 8 }}
                                    w="full"
                                    align="flex-start"
                                    h="full"
                                    mt="0px !important"
                                  >
                                    <Text fontSize="xs">Fabricante</Text>
                                    <Text fontSize="sm" fontWeight="bold">
                                      {part.manufacturer}
                                    </Text>
                                  </VStack>
                                </VStack>
                              </VStack>
                              <Divider />
                            </VStack>
                          ))}
                      </VStack>

                      {diagnosis && custom_repair_shop_parts && (
                        <HStack h="fit-content" w="full" mt="0px !important" alignItems="center">
                          <Text
                            display="flex"
                            alignItems="center"
                            w={{ base: 'full', md: 'fit-content' }}
                            justifyContent="space-between"
                            mr={{ base: 0, md: 5 }}
                          >
                            Valor total das peças
                            <chakra.span ml={3} fontWeight="bold" fontSize="lg">
                              {Number(
                                Number(
                                  diagnosis[0]?.schedule_parts?.length > 0
                                    ? diagnosis[0]?.schedule_parts?.reduce(
                                        (acc, curr) => acc + curr.price * curr.quantity,
                                        0,
                                      )
                                    : 0,
                                ) +
                                  Number(
                                    custom_repair_shop_parts
                                      ? custom_repair_shop_parts?.reduce(
                                          (acc, curr) => acc + curr.price * Number(curr.quantity),
                                          0,
                                        )
                                      : 0,
                                  ),
                              ).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </chakra.span>
                          </Text>
                        </HStack>
                      )}
                    </>
                  )}
                </VStack>
              )}

              {selectedSchedule && selectedSchedule.status === 'finished' && (
                <>
                  <Flex
                    flexDirection={{ base: 'column', md: 'row' }}
                    bg="white"
                    p={4}
                    rounded="md"
                    w="full"
                  >
                    <HStack
                      flexDir={{ base: 'column', lg: 'row' }}
                      align="flex-start"
                      spacing="7"
                      marginInlineStart={0}
                      w="full"
                    >
                      <HStack
                        flexDir={{ base: 'column', lg: 'row' }}
                        align="flex-start"
                        spacing="7"
                        marginInlineStart={0}
                        w="full"
                      >
                        <HStack mb={{ base: 3, lg: 0 }} mr={{ base: 0, lg: 3 }}>
                          <Text fontSize="md">Serviço finalizado</Text>
                          <BsCheckCircle />
                        </HStack>
                        <VStack
                          w={{ base: 'full', lg: 'fit-content' }}
                          align="flex-start"
                          marginInlineStart="0px !important"
                        >
                          <Text fontSize="md" fontWeight="bold">
                            Duração
                          </Text>
                          <Text fontSize="md">{diff}</Text>
                        </VStack>
                      </HStack>
                    </HStack>
                  </Flex>
                </>
              )}
            </Stack>
          </Stack>

          {diagnosis?.length > 0 &&
            [
              'diagnosed',
              'in_progress',
              'diagnostic_accepted_by_user',
              'in_progress',
              'finished',
            ].includes(selectedSchedule.status) && (
              <>
                {selectedSchedule.status !== 'finished' && (
                  <VStack w="full" p={4} rounded="md" bg="white" flex="1" align="flex-end" mb={10}>
                    <HStack w="full" justify="space-between">
                      <Text fontSize="sm">Duração do serviço</Text>
                      <Text fontSize="sm">{`${diagnosis[0]?.time} minutos`}</Text>
                    </HStack>
                  </VStack>
                )}

                <VStack w="full" p={4} rounded="md" bg="white" flex="1" align="flex-end" mb={10}>
                  <HStack w="full" justify="space-between">
                    <Text fontSize="md" fontWeight="bold">
                      Total
                    </Text>
                    {!diagnosis || diagnosis?.length < 1 ? (
                      <Skeleton w="xs" height={8} borderRadius={20} />
                    ) : (
                      <Text fontSize="md" fontWeight="bold">
                        {Number(diagnosis[0]?.price).toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </Text>
                    )}
                  </HStack>
                </VStack>

                <Flex
                  w="full"
                  flexDir={{ base: 'column', lg: 'row' }}
                  justify="space-between"
                  align={{ base: 'flex-start', lg: 'center' }}
                  pt={5}
                >
                  <HStack flexDir={{ base: 'column', lg: 'row' }}>
                    {['started', 'diagnosed'].includes(selectedSchedule.status) && (
                      <Button
                        mr={{ base: 0, lg: 3 }}
                        mb={{ base: 5, lg: 0 }}
                        variant="@colorless"
                        onClick={async () => {
                          await updateDiagnosis(diagnosis[0].id, 'no');
                          await updateSchedule(selectedSchedule.id, {
                            status: 'diagnostic_canceled_by_user',
                          });
                          setSelectedSchedule && setSelectedSchedule(null);
                        }}
                      >
                        Recusar serviços
                      </Button>
                    )}

                    {['diagnosed'].includes(selectedSchedule.status) && (
                      <Button
                        variant="@primary"
                        mb={{ base: 5, lg: 0 }}
                        marginInlineStart="0px !important"
                        onClick={async () => {
                          if (setSelectedSchedule && selectedSchedule) {
                            await updateDiagnosis(diagnosis[0].id, 'yes');

                            await updateSchedule(selectedSchedule.id, {
                              status: 'diagnostic_accepted_by_user',
                            });
                          }
                          setSelectedSchedule && setSelectedSchedule(null);
                        }}
                      >
                        Aceitar serviços
                      </Button>
                    )}
                  </HStack>

                  {selectedSchedule.status !== 'in_progress' &&
                    selectedSchedule.status !== 'finished' && (
                      <HStack mt={{ base: 5, lg: 0 }}>
                        <Button
                          size="sm"
                          fontSize="sm"
                          fontWeight="normal"
                          textAlign="center"
                          variant="solid"
                          onClick={async () => {
                            if (setSelectedSchedule && selectedSchedule)
                              await updateSchedule(selectedSchedule.id, {
                                status: 'diagnostic_canceled_by_repairshop',
                              });
                            setSelectedSchedule && setSelectedSchedule(null);
                          }}
                        >
                          Cancelar agendamento
                        </Button>
                      </HStack>
                    )}
                </Flex>
              </>
            )}

          {selectedSchedule && selectedSchedule.status === 'in_progress' && (
            <Flex
              w="100%"
              justifyContent="space-between"
              flexDirection={{ base: 'column', md: 'row' }}
              p={4}
            >
              <VStack mt={{ base: 10, md: 0 }} w="full">
                <Flex mx="0px !important" justifyContent="space-between" w="100%" mb={3}>
                  <Text fontWeight="bold">Previsão pra finalizar</Text>
                  <Text>{expirationTime}</Text>
                </Flex>
                <SimpleProgressBar
                  mx="0px !important"
                  w="100%"
                  height="15px"
                  bg="white"
                  value={progress}
                />
              </VStack>
            </Flex>
          )}
        </VStack>
      )}

      <HStack w="full" justify="space-between">
        {['finished', 'diagnostic_canceled_by_repairshop'].includes(selectedSchedule.status) && (
          <Box alignSelf="flex-start">
            <Button
              variant="@colorless"
              onClick={() => setSelectedSchedule && setSelectedSchedule(null)}
            >
              Voltar para agendamentos
            </Button>
          </Box>
        )}
        {['started', 'in_progress'].includes(selectedSchedule.status) && (
          <HStack mt={5} w="full" justify="flex-end">
            <Button
              size="sm"
              fontSize="sm"
              fontWeight="normal"
              textAlign="center"
              variant="solid"
              onClick={async () => {
                if (setSelectedSchedule && selectedSchedule)
                  await updateSchedule(selectedSchedule.id, {
                    status: 'diagnostic_canceled_by_repairshop',
                  });
                setSelectedSchedule && setSelectedSchedule(null);
              }}
            >
              Cancelar agendamento
            </Button>
          </HStack>
        )}
      </HStack>
    </Flex>
  );
};
