import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dotenv from 'dotenv';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';

dayjs.extend(customParseFormat);
dotenv.config();

navigator.serviceWorker.register('/firebase-messaging-sw.js');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
