export default {
  baseStyle: {
    container: {
      transition: 'all .1s ease-in',
      // mb: 3,
      w: 'fit-content',
      fontSize: 'xs',
      borderRadius: 'full !importan',
      fontWeight: 'bold',
      border: '1px solid',
      // borderColor: "",
      bg: 'white',
      borderColor: 'blackAlpha.500',
      // _hover: {
      //   bg: 'brand.200',
      //   borderColor: 'brand.300',
      // },
      mr: 4,
    },
  },
  sizes: {
    sm: {
      container: {
        borderRadius: 'full',
        px: 3,
        py: 2,
      },
    },
    md: {
      container: {
        borderRadius: 'full',
        px: 5,
        py: 2,
      },
    },
    lg: {
      container: {
        borderRadius: 'full',
        px: 8,
        py: 2,
      },
    },
  },
  defaultProps: {
    colorScheme: 'transparent',
  },
};
