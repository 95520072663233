import axiosInstance, { AxiosError } from 'axios';
import { getAuth } from 'firebase/auth';

import history from './history';

import { firebaseApp } from '@/firebase';
import { cookies, storage } from '@/utils';

const config = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
};

const auth = getAuth(firebaseApp);

const unathenticatedInstance = axiosInstance.create(config);
const authenticatedInstance = axiosInstance.create(config);

unathenticatedInstance.interceptors.response.use(
  (response) => response?.data,
  (error: AxiosError) => Promise.reject(error),
);

authenticatedInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async () => {
    try {
      const token = await auth.currentUser?.getIdToken(true);
      if (token) {
        localStorage.setItem('@token', token);
      }
    } catch (error) {
      cookies.clearAccess();
      cookies.clearRefresh();
      storage.clearUser();
      history.push('/entrar');
      return Promise.reject(error);
    }
  },
);

export default {
  unauthorized({ mock = false }: { mock?: boolean }) {
    const fcmToken = localStorage.getItem('@fcm-token');

    if (mock) unathenticatedInstance.defaults.baseURL = process.env.REACT_APP_MOCK_API_URL;
    else unathenticatedInstance.defaults.baseURL = process.env.REACT_APP_API_URL;

    unathenticatedInstance.defaults.headers.common.fcm = fcmToken;

    return unathenticatedInstance;
  },

  authorized({ mock = false }: { mock?: boolean }) {
    const token = localStorage.getItem('@token');
    const fcmToken = localStorage.getItem('@fcm-token');

    authenticatedInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
    authenticatedInstance.defaults.headers.fcm = fcmToken;

    authenticatedInstance.interceptors.request.use(
      function (config) {
        if (mock) config.baseURL = process.env.REACT_APP_MOCK_API_URL;
        else config.baseURL = process.env.REACT_APP_API_URL;

        return config;
      },
      function (error) {
        return Promise.reject(error);
      },
    );

    return authenticatedInstance;
  },
};
//
