import { useQuery } from 'react-query';

import { getScheduleRequestPhotos } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseScheduleRequestPhotosOptions = {
  id?: number;
  config?: QueryConfig<typeof getScheduleRequestPhotos>;
};

export const useScheduleRequestPhotos = ({ id, config }: UseScheduleRequestPhotosOptions) => {
  return useQuery(['getScheduleRequestPhotos', id], getScheduleRequestPhotos, config);
};
