import { useQuery } from 'react-query';

import { getStorePhotos } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseStorePhotosOptions = {
  id: number;
  config?: QueryConfig<typeof getStorePhotos>;
};

export const useStorePhotos = ({ id, config }: UseStorePhotosOptions) => {
  return useQuery(['getStorePhotos', id], getStorePhotos, config);
};
