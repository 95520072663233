import { Flex, Button, VStack, Text, HStack, useToast } from '@chakra-ui/react';
import { useState } from 'react';

import { updateBudgetRequest } from '../../api';

import { useFCM } from '@/context/AppProvider';
import { updateBudget } from '@/modules/store';

export const BudgetRequestCheckout = ({
  selectedBudget,
  setSelectedSchedule,
  setSelectedBudget,
  budgetRequest,
}) => {
  const {
    setBudgetRequest: resetBudgetRequest,
    removeBudgetRequestResponse,
    refetchConsumerBudgetRequests,
  } = useFCM();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const handleBudgetChoose = async () => {
    setIsLoading(true);
    try {
      await updateBudget(selectedBudget.id, {
        status: 'PAID',
      });

      setIsLoading(false);
      toast({
        title: 'Sucesso',
        description: 'Orçamento aceito com sucesso.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      localStorage.removeItem('@budget-request');
      resetBudgetRequest(null);
      removeBudgetRequestResponse();

      setSelectedSchedule(null);
    } catch (error) {
      setIsLoading(false);
      toast({
        title: 'Erro ao aceitar orçamento. Tente novamente.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex w="full" flexDirection="column">
      <VStack>
        <VStack w="full" p={4} rounded="md" bg="white" alignItems="flex-start" flex="1">
          <VStack spacing={0} align="flex-start" w="full">
            <HStack w="full" justify="space-between">
              <Text color="gray.400">Subtotal</Text>
              <Text color="gray.400" fontWeight="bold">
                {Number(
                  selectedBudget?.budget_parts?.reduce(
                    (acc, curr) => acc + Number(curr.price) * curr.quantity,
                    0,
                  ),
                ).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Text>
            </HStack>
            <HStack w="full" justify="space-between">
              <Text color="gray.400">Frete</Text>
              <Text color="gray.400" fontWeight="bold">
                {Number(selectedBudget.shipping_price).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Text>
            </HStack>
            <HStack w="full" justify="space-between">
              <Text fontWeight="bold" fontSize="md">
                Total
              </Text>
              <Text fontWeight="bold" fontSize="md">
                {Number(
                  Number(
                    selectedBudget?.budget_parts?.reduce(
                      (acc, curr) => acc + Number(curr.price * curr.quantity),
                      0,
                    ),
                  ) + Number(selectedBudget.shipping_price),
                ).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Text>
            </HStack>
          </VStack>
        </VStack>

        <HStack w="full" flexDir={{ base: 'column', sm: 'row' }}>
          <HStack
            w="full"
            p={4}
            flex="1"
            flexDir={{ base: 'column', lg: 'row' }}
            align="flex-start"
            justify="flex-start"
          >
            <Button
              onClick={() => setSelectedBudget(null)}
              variant="@colorless"
              mb={{ base: 3, lg: 0 }}
            >
              Escolher outro
            </Button>
            <Button isLoading={isLoading} onClick={handleBudgetChoose} variant="@primary">
              Fazer pedido
            </Button>
          </HStack>

          <Button
            onClick={async () => {
              await updateBudgetRequest(budgetRequest.id, { cancelled_by_user: true });
              refetchConsumerBudgetRequests();
              setSelectedSchedule(null);
            }}
            size="sm"
            variant="solid"
            bg="white"
          >
            Cancelar orçamento
          </Button>
        </HStack>
      </VStack>
    </Flex>
  );
};
