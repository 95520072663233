/* eslint-disable react-hooks/exhaustive-deps */

import { GridItem, SimpleGrid, VStack } from '@chakra-ui/react';
import { useState } from 'react';

import { useScheduleEvaluations } from '../../hooks';

import { EvaluationCard } from './EvaluationCard';

export const ScheduleEvaluationsList = () => {
  const [user] = useState(() => {
    const user = localStorage.getItem('@user');
    return user ? JSON.parse(user) : null;
  });

  const { data: scheduleEvaluations } = useScheduleEvaluations({
    id: user?.id,
    config: { enabled: !!user },
  });

  return (
    <VStack spacing={6}>
      <SimpleGrid
        maxH={{ base: '80vh', md: '800px' }}
        w="full"
        overflowY="auto"
        columns={{ base: 1, lg: 1, xl: 2 }}
      >
        {scheduleEvaluations?.map((evaluation) => (
          <GridItem key={evaluation.id}>
            <EvaluationCard evaluation={evaluation} />
          </GridItem>
        ))}
      </SimpleGrid>
    </VStack>
  );
};
