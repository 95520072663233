import { CheckIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { HStack, IconButton, Stack, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useEffect, useState } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';

dayjs.extend(customParseFormat);

import { useSellerRequest } from '../../contexts';
import { budgetLabelMap } from '../../utils';

import { useFCM } from '@/context/AppProvider';
import { readNotification } from '@/modules/consumer';

export const OrdersCard = ({ schedule }) => {
  const { userNotifications, refetchUserNotifications } = useFCM();

  const { setSelectedSchedule } = useSellerRequest();

  const [isUnread, setIsUnread] = useState(
    userNotifications?.find((notification) => notification.object_pk === schedule.id),
  );

  useEffect(
    () =>
      setIsUnread(
        userNotifications?.find((notification) => notification.object_pk === schedule.id),
      ),
    [userNotifications, schedule],
  );

  return (
    <Stack
      rounded="md"
      bg={isUnread ? 'white' : '#E7EDF6'}
      p="4"
      w="full"
      cursor="pointer"
      flexDirection={{ base: 'column', md: 'row' }}
      spacing={{ base: 2, md: 0 }}
      justifyContent="space-between"
      onClick={async () => {
        setSelectedSchedule(schedule);

        if (isUnread) {
          await readNotification(isUnread);
          refetchUserNotifications();
        }
      }}
    >
      <Stack
        flexDirection={{ base: 'column', md: 'row' }}
        alignItems={{ base: 'flex-start', md: 'center' }}
        spacing={{ base: 2, md: 0 }}
        w="full"
      >
        <HStack mr={3}>
          <Text color="gray.400" whiteSpace="nowrap">
            {`${dayjs(schedule.created_at).format('DD/MM/YYYY')}, ${dayjs(
              schedule.created_at,
            ).format('HH:mm')}`}
          </Text>
        </HStack>
        <HStack>
          {schedule.budget_request.car ? (
            <>
              <Text d={{ base: 'initial', md: 'none' }}>Veículo</Text>
              <Text fontWeight="bold">{`${schedule.budget_request.car.model.manufacturer.name} ${schedule.budget_request.car.model.name} ${schedule.budget_request.car.year}`}</Text>
            </>
          ) : (
            <Text d={{ base: 'initial', md: 'none' }}>Veículo Apagado</Text>
          )}
          \
        </HStack>
      </Stack>

      <HStack justifyContent="space-between" w={{ base: 'full', md: 'fit-content' }}>
        {schedule.status === 'REJECTED' ? (
          <>
            <SmallCloseIcon />
            <Text ml={6} whiteSpace="nowrap" pos="relative">
              {budgetLabelMap[schedule.status]}
            </Text>
          </>
        ) : schedule.status === 'BUDGETED' ? (
          <>
            <Text
              whiteSpace="nowrap"
              pos="relative"
              _before={{
                content: '""',
                width: 4,
                height: 4,
                zIndex: 1000,
                pos: 'absolute',
                top: '50%',
                left: -6,
                transform: 'translateY(-50%)',
                border: '3px solid',
                borderColor: 'brand.500',
                rounded: 'full',
              }}
            >
              {budgetLabelMap[schedule.status]}
            </Text>
          </>
        ) : (
          <HStack align="flex-start">
            <CheckIcon />
            <Text ml={6} whiteSpace="nowrap" pos="relative">
              {budgetLabelMap[schedule.status]}
            </Text>
          </HStack>
        )}

        <IconButton
          aria-label="Ver detalhes"
          icon={<MdKeyboardArrowRight size={28} />}
          bg={isUnread ? 'white' : '#E7EDF6'}
        />
      </HStack>
    </Stack>
  );
};
