import { useQuery } from 'react-query';

import { getDiagnosis } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseDiagnosisOptions = {
  id?: number;
  config?: QueryConfig<typeof getDiagnosis>;
};

export const useDiagnosis = ({ id, config }: UseDiagnosisOptions) => {
  return useQuery(['getDiagnosis', id], getDiagnosis, config);
};
