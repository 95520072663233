import { Box, useDisclosure } from '@chakra-ui/react';

import bgDetailsMap from '@/assets/bg-details-map.png';
import { Scroll } from '@/components/common/Scroll';
import { Footer } from '@/components/Footer';
import { Navbar } from '@/components/Navbar';

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  // const [isSmallScreen] = useMediaQuery('(max-width: 768px)');
  const mobileNav = useDisclosure();

  return (
    <Scroll>
      <Box onClick={mobileNav.onClose} textStyle="light" bg="gray.100">
        <Navbar />
        <Box
          onClick={mobileNav.onClose}
          pos="relative"
          h="full"
          position="relative"
          _before={{
            content: '""',
            w: '100%',
            h: '100%',
            pos: 'absolute',
            right: '0',
            top: '0',
            bgImage: bgDetailsMap,
            bgSize: 'cover',
            bgAttachment: 'absolute',
            opacity: 1,
            zIndex: 'base',
          }}
        >
          <Box w="full" minH="100vh" position="relative" zIndex="docked">
            {children}
          </Box>
        </Box>
        <Footer />
      </Box>
    </Scroll>
  );
};
