import { useToast } from '@chakra-ui/react';
import { useState } from 'react';

import { AuthLayout } from '../components/AuthLayout';
import { LoginForm } from '../components/LoginForm';

export const Login = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  return (
    <AuthLayout
      title="Entrar na plataforma"
      subtitle="MyCar, a plataforma que simplifica sua relação com seu carro."
    >
      <LoginForm
        loading={loading}
        setLoading={setLoading}
        onSuccess={() => {
          toast({
            title: 'Logado com sucesso.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          setLoading(false);
        }}
        onError={(message) => {
          toast({
            title: message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          setLoading(false);
        }}
      />
    </AuthLayout>
  );
};
