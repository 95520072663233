import { RepairShopSidebar } from '../components';
import { EvaluationsList } from '../components/RepairShopEvaluations';
import { RepairShopScheduleConsumer, RepairShopScheduleProvider } from '../contexts';

import { Page } from '@/components/Page';

export const MyEvaluations = () => {
  return (
    <Page title="Suas vendas" pos="relative" minH="100vh">
      <RepairShopScheduleProvider>
        <RepairShopScheduleConsumer>
          {() => (
            <RepairShopSidebar>
              <EvaluationsList />
            </RepairShopSidebar>
          )}
        </RepairShopScheduleConsumer>
      </RepairShopScheduleProvider>
    </Page>
  );
};
