/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/media-has-caption */
import { VStack } from '@chakra-ui/react';
import { useEffect } from 'react';

import { ScheduleCard } from './ScheduleCard';

import { useScroll, useFCM } from '@/context/AppProvider';

export const SchedulesList = ({ activeTab }) => {
  const { scrollRef } = useScroll();
  const { repairShopSchedules, refetchRepairShopSchedules } = useFCM();

  useEffect(() => {
    activeTab === 'schedules' && refetchRepairShopSchedules();
  }, []);

  useEffect(() => {
    scrollRef?.current?.scrollToTop();
  }, []);

  return (
    <VStack maxH={{ base: '80vh', md: '400px' }} overflowY="auto">
      {repairShopSchedules?.map((repairShopSchedule) => (
        <ScheduleCard key={repairShopSchedule.id} schedule={repairShopSchedule} />
      ))}
    </VStack>
  );
};
