import { ConsumerSchedules } from './types';

import axios from '@/lib/axios';

export const getConsumerSchedules = (queryObject): Promise<ConsumerSchedules[]> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`schedule/${id}/user-schedules/`);
};

export const getBudgetParts = (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`budget-request-part/${id}/by-budget-request/`);
};

export const getBudgetRequestParts = (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`/budget-request-part/${id}/by-budget-request/`);
};

export const getConsumerBudgetRequests = (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_] = queryKey;

  return axios.authorized({}).get(`/budget-request/exclude-paid`);
};

export const getConsumerBudgets = (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, status] = queryKey;

  return axios.authorized({}).get(`/budget/by-status-and-user/${status}`);
};

export const getScheduleRequestPhotos = (queryObject): Promise<any[]> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`schedule-request-photo/${id}/by-schedule-request/`);
};

export const getScheduleRequestProblems = (queryObject): Promise<any[]> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`schedule-request-problem/${id}/by-schedule-request/`);
};

export const updateDiagnosis = (id, approved): Promise<any> => {
  return axios.authorized({}).patch(`repair-shop-diagnosis/${id}/`, { approved });
};

export const getMistakes = (): Promise<{
  count: number;
  next: any;
  previous: any;
  results: [any];
}> => {
  return axios.authorized({}).get(`evaluation-mistake/`);
};

export const createEvaluation = (data): Promise<any> => {
  return axios.authorized({}).post(`schedule-evaluation/`, data);
};

export const createBudgetEvaluation = (data): Promise<any> => {
  return axios.authorized({}).post(`/budget-evaluation/`, data);
};

export const updateRepairShopUserEmail = (id: number, email: string) => {
  return axios.authorized({}).patch(`/user/${id}/`, { email });
};

export const getScheduleEvaluations = (queryObject): Promise<any[]> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`/schedule-evaluation/${id}/by-user/`);
};

export const getBudgetEvaluations = (queryObject): Promise<any[]> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`/budget-evaluation/${id}/by-user/`);
};

export const getBudgetEvaluation = (queryObject): Promise<any[]> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`/budget-evaluation/${id}/by-budget/`);
};

export const getBudget = (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`/budget/${id}/`);
};

export const getUserNotifications = (): Promise<any[]> => {
  return axios.authorized({}).get(`/notification/user-unread-notifications/`);
};

export const readNotification = (notification: any): Promise<any> => {
  return axios.authorized({}).patch(`/notification/${notification.id}/`, { is_read: true });
};

export const updateBudgetRequest = (id, data): Promise<any> => {
  return axios.authorized({}).patch(`/budget-request/${id}/`, data);
};

export const createScheduleChatNotification = (data): Promise<any> => {
  return axios.authorized({}).post(`/chat-schedule-notification/`, data);
};

export const createBudgetChatNotification = (data): Promise<any> => {
  return axios.authorized({}).post(`/chat-budget-notification/`, data);
};

export const getUserBudgetNotifications = (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios
    .authorized({})
    .get(`/chat-budget-notification/${id}/notification-by-user-and-budget/`);
};

export const getUserScheduleNotifications = (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;
  return axios
    .authorized({})
    .get(`/chat-schedule-notification/${id}/notification-by-user-and-schedule/`);
};

export const readAllBudgetNotifications = (): Promise<any> => {
  return axios.authorized({}).post('/chat-budget-notification/read-all-notifications/');
};

export const readAllScheduleNotifications = (): Promise<any> => {
  return axios.authorized({}).post('/chat-schedule-notification/read-all-notifications/');
};
