import { LoginCredentials, UserResponse, RegisterCredentials, AuthUser } from './types';

import axios from '@/lib/axios';

export const loginWithEmailAndPassword = (data: LoginCredentials): Promise<UserResponse> => {
  return axios.unauthorized({ mock: true }).post('/auth/login', data);
};

export const registerWithEmailAndPassword = (data: RegisterCredentials): Promise<UserResponse> => {
  return axios.unauthorized({ mock: true }).post('/auth/register', data);
};

export const getUserProfile = (): Promise<AuthUser> => {
  return axios.authorized({ mock: true }).get('/auth/me');
};

export const getRoles = (): Promise<any> => {
  return axios.authorized({}).get('/role/');
};

export const createOnboarding = (userInfo: FormData): Promise<AuthUser> => {
  return axios.authorized({}).post('/user/onboarding/', userInfo, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getUser = (): Promise<any> => {
  return axios.authorized({}).get('/user/detail');
};

export const createCustomer = (data): Promise<any> => {
  return axios.authorized({}).post('/user-customer-info/', data);
};

export const editCustomer = (id, data): Promise<any> => {
  return axios.authorized({}).patch(`/user-customer-info/${id}/`, data);
};

export const getUserAddress = (id): Promise<any> => {
  return axios.authorized({}).get(`/user/${id}/address`);
};

export const editUserAddress = (id): Promise<any> => {
  return axios.authorized({}).get(`/user/${id}/address`);
};

export const createAddress = (data): Promise<any> => {
  return axios.authorized({}).post(`/address/`, data);
};

export const editUser = (data): Promise<any> => {
  return axios.authorized({}).patch('/user/detail/', data);
};

export const editAddress = (id, data): Promise<any> => {
  return axios.authorized({}).patch(`/address/${id}/`, data);
};
