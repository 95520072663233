import { Progress, ProgressProps } from '@chakra-ui/react';

type SimpleProgressBarProps = ProgressProps;

export const SimpleProgressBar = (props: SimpleProgressBarProps) => {
  return (
    <Progress
      rounded="50px"
      height="8px"
      css={{ div: { borderRadius: '50px !important', backgroundColor: '#F8BB32' } }}
      {...props}
    />
  );
};
