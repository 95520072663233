/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Heading, HStack, IconButton, Input, Stack, useToast } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { FiPaperclip } from 'react-icons/fi';
import { RiSendPlane2Fill } from 'react-icons/ri';
import ScrollableFeed from 'react-scrollable-feed';

import { readAllNotifications } from '../../api';
import { MessageI } from '../../api/types';
import { RepairShopScheduleContextI } from '../../types';

import { ChatBox } from './Chatbox';

import { db } from '@/firebase';

import './styles.css';

export const RepairShopChat = ({
  selectedSchedule,
  refetchChatNotifications,
}: Partial<RepairShopScheduleContextI>) => {
  const toast = useToast();
  const repairShop = localStorage.getItem('@repair-shop');
  const parsedRepairShop = repairShop && JSON.parse(repairShop);

  const [messages, setMessages] = useState<MessageI[]>([]);
  const [newMessage, setNewMessage] = useState('');

  const messagesEndRef = useRef<ScrollableFeed | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!repairShop) {
      toast({
        title: 'Faça login para enviar mensagens.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } else {
      if (!newMessage) return;
      else {
        db.collection(
          `user-${selectedSchedule.user}-repairShop-${selectedSchedule.repair_shop.id}-schedule-${selectedSchedule.id}-scheduleRequest-${selectedSchedule.schedule_request.id}-chat`,
        ).add({
          text: newMessage,
          createdAt: Date.now(),
          sendedAt: dayjs().toISOString(),
          sender: parsedRepairShop?.name,
          uid: parsedRepairShop?.id,
          displayName: parsedRepairShop?.name,
        });
        setNewMessage('');
      }
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
    inputRef.current?.setSelectionRange(newMessage.length + 1, newMessage.length + 1);
  }, [newMessage]);

  useEffect(() => {
    db?.collection(
      `user-${selectedSchedule.user}-repairShop-${selectedSchedule.repair_shop.id}-schedule-${selectedSchedule.id}-scheduleRequest-${selectedSchedule.schedule_request.id}-chat`,
    )
      .orderBy('createdAt')
      .onSnapshot((querySnapShot: any) => {
        const data = querySnapShot.docs.map((doc: any) => ({
          ...doc.data(),
          id: doc.id,
        }));
        messagesEndRef.current?.scrollToBottom();
        setMessages(data);
      });
  }, [db, selectedSchedule.user, selectedSchedule.repair_shop.id]);

  useEffect(() => {
    if (db && messagesEndRef.current) {
      messagesEndRef.current?.scrollToBottom();
    }
  }, [db, messagesEndRef]);

  useEffect(() => {
    readAllNotifications();
    refetchChatNotifications();
  }, []);

  return (
    <Stack w="full" h="80vh" bg="white" shadow="md" rounded="md" px="4" py="4">
      <HStack>
        <Heading fontSize="lg" fontWeight="bold">
          {selectedSchedule.schedule_request.customer_name}
        </Heading>
      </HStack>
      <Box
        flex="1"
        d="flex"
        flexDirection="column"
        justifyContent="space-between"
        overflow="hidden"
        p="1"
      >
        <ScrollableFeed forceScroll ref={messagesEndRef} className="h-24">
          <Box>
            {messages?.map((message: any, key: number) => (
              <ChatBox
                message={message}
                isMine={parsedRepairShop?.name === message.sender}
                key={key}
              />
            ))}
          </Box>
        </ScrollableFeed>
      </Box>
      <HStack w="full">
        <form onSubmit={(e) => handleSubmit(e)} style={{ width: '100%' }}>
          <HStack spacing="4" alignItems="center">
            <Box _hover={{ opacity: 0.8 }} cursor="pointer" linearGradient>
              <FiPaperclip size="20" />
            </Box>
            <Input
              w="full"
              rounded="md"
              ref={inputRef}
              placeholder="Digite algo..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
            />
            <IconButton
              type="submit"
              aria-label="send message"
              bg="brand.500"
              _hover={{ bg: 'brand.600' }}
              icon={<RiSendPlane2Fill />}
            />
          </HStack>
        </form>
      </HStack>
    </Stack>
  );
};
