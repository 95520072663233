/* eslint-disable react-hooks/exhaustive-deps */
import { VStack } from '@chakra-ui/react';
import { useEffect } from 'react';

import { BudgetsSentCard } from './BudgetsSentCard';

import { useFCM } from '@/context/AppProvider';

export const BudgetsSent = ({ activeTab }) => {
  const { storeSentBudgets, refetchStoreSentBudgets } = useFCM();

  useEffect(() => {
    activeTab === 1 && refetchStoreSentBudgets();
  }, [activeTab]);

  return (
    <VStack align="flex-start" maxH={{ base: '80vh', md: '400px' }} overflowY="auto">
      {storeSentBudgets?.map((storeSchedule) => (
        <BudgetsSentCard key={storeSchedule.id} schedule={storeSchedule} />
      ))}
    </VStack>
  );
};
