import { useToast } from '@chakra-ui/react';
import { getAuth } from 'firebase/auth';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Wizard, Steps, Step } from 'react-albus';

import {
  createScheduleRequest,
  createScheduleRequestPhoto,
  createScheduleRequestProblem,
} from '../../api';
import { useScheduleRequestStore } from '../../store/schedule-request-store';
import { ScheduleRequest } from '../../types';
import { CarProblemsComponent } from '../CarProblemsComponent';
import { CreateAccountFloatingBox } from '../CreateAccountFloatingBox';
import { ProblemIdentificationForm } from '../ProblemIdentificationForm';
import { SelectCarForm } from '../SelectCarForm';

import schema from './schema';

import { Form } from '@/components/Form';
import { useFCM } from '@/context/AppProvider';
import { firebaseApp } from '@/firebase';
import useLocalStorage from '@/hooks/useLocalStorage';

export const ScheduleRequestForm = () => {
  const [, setScheduleRequestLS] = useLocalStorage<
    (Omit<ScheduleRequest, 'problem_id_tags' | 'problem_id'> & { problem_id: string[] }) | null
  >('@schedule-request', null);

  const {
    actions: { setData },
  } = useScheduleRequestStore();

  const { setScheduleRequest } = useFCM();

  const [selectedProblems, setSelectedProblems] = useState<any[]>([]);
  const [selectedCar, setSelectedCar] = useState<any>();

  const toast = useToast();
  const auth = getAuth(firebaseApp);
  const [user, setUser] = useState<any>();
  const [loading, setLoading] = useState(false);

  const [geolocation, setGeolocation] = useState<any>();

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setGeolocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (err) =>
        toast({
          title: err,
          status: 'error',
          duration: 5000,
          isClosable: true,
        }),
      { enableHighAccuracy: true },
    );
  }, [toast]);

  useEffect(() => {
    if (auth.currentUser) {
      const userInfo = localStorage.getItem('@user');
      setUser(() => userInfo && JSON.parse(userInfo));
    }

    (async () => {
      const token = await auth.currentUser?.getIdToken(true);
      if (token) {
        localStorage.setItem('@token', token);
      }
    })();
  }, [auth.currentUser]);

  return (
    <Form<ScheduleRequest>
      initialValues={{
        customer_name: '',
        preferred_date: '',
        comment: '',
        place: [],
        car: {
          id: 0,
          model: {
            id: 0,
            name: '',
            manufacturer: {
              id: 0,
              name: '',
            },
          },
          year: 0,
        },
        plate: '',
        problem_id: [],
        problem_id_tags: [],
        photo: [],
        model: { id: 0, name: '' },
        year: '',
      }}
      onSubmit={async (values) => {
        setLoading(true);
        const problem_id: string[] = [
          ...values.problem_id.map((problem) => problem.value),
          ...values.problem_id_tags.map((problem) => problem.value),
        ];

        const scheduleRequestData = {
          ..._.omit(values, ['problem_id_tags', 'problem_id']),
          problem_id,
        };

        setScheduleRequestLS(scheduleRequestData);
        setData(scheduleRequestData);

        if (scheduleRequestData) {
          try {
            const payload = auth.currentUser
              ? {
                  customer_name: user?.name || user?.username,
                  preferred_date: `${new Date(scheduleRequestData?.preferred_date)
                    .toLocaleDateString('pt-BR')
                    .split('/')
                    .reverse()
                    .join('-')} ${new Date(scheduleRequestData?.preferred_date).toLocaleTimeString(
                    'pt-BR',
                    { hour: '2-digit', minute: '2-digit' },
                  )}`,
                  comment: scheduleRequestData.comment,
                  latitude: geolocation?.latitude,
                  longitude: geolocation?.longitude,
                  car: selectedCar.id,
                  customer_problem: selectedProblems
                    ?.filter((problem) => isNaN(problem.value))
                    ?.map((problem) => problem.value)
                    .join(','),
                }
              : {
                  customer_name: scheduleRequestData?.customer_name,
                  preferred_date: `${new Date(scheduleRequestData?.preferred_date)
                    .toLocaleDateString('pt-BR')
                    .split('/')
                    .reverse()
                    .join('-')} ${new Date(scheduleRequestData?.preferred_date).toLocaleTimeString(
                    'pt-BR',
                    { hour: '2-digit', minute: '2-digit' },
                  )}`,
                  comment: scheduleRequestData.comment,
                  latitude: geolocation?.latitude,
                  longitude: geolocation?.longitude,
                  model: selectedCar.model.id,
                  year: selectedCar.year,
                  plate: selectedCar.plate,
                  customer_problem: selectedProblems
                    ?.filter((problem) => isNaN(problem.value))
                    ?.map((problem) => problem.value)
                    .join(','),
                };

            const schedule = await createScheduleRequest(payload).then((res) => {
              localStorage.setItem(
                '@schedule-request',
                JSON.stringify({ id: res.id, created_at: new Date() }),
              );

              return res;
            });

            setScheduleRequest({ id: schedule.id, created_at: new Date() });

            scheduleRequestData.photo?.forEach(async (photo) => {
              const photoData = new FormData();
              photoData.append('photo', photo.file);
              photoData.append('schedule_request', String(schedule.id));

              await createScheduleRequestPhoto(photoData);
            });

            selectedProblems
              .filter((problem) => !isNaN(problem.value))
              .forEach(async (problem) => {
                const problemData = new FormData();
                problemData.append('problem', problem.value);
                problemData.append('schedule_request', String(schedule.id));

                await createScheduleRequestProblem(problemData);
              });

            setLoading(false);

            toast({
              title: 'Solicitação enviada com sucesso. Aguarde',
              status: 'success',
              duration: 5000,
              isClosable: true,
            });
          } catch (error) {
            setLoading(false);
            toast({
              title: 'Erro ao enviar solicitação. Tente novamente.',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          }
        }
      }}
      validationSchema={schema}
      // withDebugger
      validateOnMount={true}
    >
      {({ handleSubmit }) => (
        <Wizard>
          {({ next, previous }) => (
            <>
              <Steps>
                <Step id="car-problems">
                  <CarProblemsComponent
                    selectedProblems={selectedProblems}
                    setSelectedProblems={setSelectedProblems}
                    next={next}
                  />
                </Step>
                <Step id="problem-identification">
                  <ProblemIdentificationForm
                    selectedProblems={selectedProblems}
                    next={next}
                    previous={previous}
                  />

                  {!auth.currentUser && <CreateAccountFloatingBox />}
                </Step>
                <Step id="car-selection">
                  <SelectCarForm
                    submit={handleSubmit}
                    previous={previous}
                    selectedCar={selectedCar}
                    setSelectedCar={setSelectedCar}
                    loading={loading}
                  />

                  {!auth.currentUser && <CreateAccountFloatingBox />}
                </Step>
              </Steps>
            </>
          )}
        </Wizard>
      )}
    </Form>
  );
};
