import { useQuery } from 'react-query';

import { getRepairShopSchedules } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseRepairShopSchedulesOptions = {
  id?: number;
  config?: QueryConfig<typeof getRepairShopSchedules>;
};

export const useRepairShopSchedules = ({ id, config }: UseRepairShopSchedulesOptions) => {
  return useQuery(['getRepairShopSchedules', id], getRepairShopSchedules, config);
};
