export default {
  baseStyle: {
    field: {
      fontFamily: 'body',
      fontWeight: 'medium',
    },
  },
  variants: {
    outline: () => ({
      field: {
        bg: 'white',
        color: 'black',
        shadow: 'sm',
        rounded: 'full',
      },
    }),
  },
  sizes: {
    xs: {
      field: {
        borderRadius: 'full',
        fontSize: 'xs',
      },
    },
    sm: {
      field: {
        borderRadius: 'full',
        fontSize: 'xs',
      },
    },
    md: {
      field: {
        borderRadius: 'full',
        fontSize: 'sm',
      },
    },
    lg: {
      field: {
        borderRadius: 'full',
        fontSize: 'md',
      },
    },
  },
};
