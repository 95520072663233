import { RepairShopSolicitation } from './types';

import axios from '@/lib/axios';

export const getStore = async (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`/store/${id}`);
};

export const getAcceptedBudget = async (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_] = queryKey;

  return axios.authorized({}).get(`/budget/all-accepted/`);
};

export const getBudgetRequestParts = async (queryObject): Promise<any[]> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`/budget-request-part/${id}/by-budget-request`);
};

export const getRepairShopRating = async (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`repairshop-rating/${id}/`);
};

export const getRepairShopPhotos = async (queryObject): Promise<any[]> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`repair-shop-photo/${id}/photos/`);
};

export const getRepairShopServices = async (queryObject): Promise<any[]> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`repair-shop-service/${id}/by-repairshop/`);
};

export const getRepairShopSolicitations = (): Promise<RepairShopSolicitation[]> => {
  return axios.authorized({}).get('schedule-request-repairshop/all-repairshop-schedules/');
};

export const getStoreBudgets = (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id, status] = queryKey;

  return axios.authorized({}).get(`/budget/${id}/by-status-and-store/${status}`);
};

export const answerRepairShopSchedule = (id, isApproved) => {
  return axios.authorized({}).patch(`/schedule-request-repairshop/${id}/`, {
    is_approved: isApproved,
  });
};

export const deleteRepairShopScheduleRequest = (id) => {
  return axios.authorized({}).delete(`/schedule-request-repairshop/${id}/`);
};

export const createStoreAddress = (address): Promise<any> => {
  return axios.authorized({}).post(`/address/`, address);
};

export const updateStore = (repairShop: FormData, id: number) => {
  return axios.authorized({}).put(`/store/${id}/`, repairShop);
};

export const updateStoreUserEmail = (id: number, email: string) => {
  return axios.authorized({}).patch(`/user/${id}/`, { email });
};

export const createRepairShopDiagnsosis = (diagnosis): Promise<any> => {
  return axios.authorized({}).post(`/repair-shop-diagnosis/`, diagnosis);
};

export const updateRepairShopDiagnsosis = (id, diagnosis): Promise<any> => {
  return axios.authorized({}).patch(`/repair-shop-diagnosis/${id}/`, diagnosis);
};

export const updateSchedule = (id: number, data: any): Promise<any> => {
  return axios.authorized({}).patch(`/schedule/${id}/`, data);
};

export const getDiagnosis = (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`/repair-shop-diagnosis/${id}/by-schedule/`);
};

export const getDiagnosisPhotos = (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`/repair-shop-diagnosis-photo/${id}/by-diagnosis/`);
};

export const getRepairShopEvaluations = (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`/repair-shop/${id}/rating-by-repair-shop/`);
};

export const getStoreParts = (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`/store-part/${id}/by-store/`);
};

export const getPartManufacturers = (pageParam, queryKey): Promise<any> => {
  const [_, , , multiple, name] = queryKey;

  return axios
    .authorized({})
    .get(
      `/part-manufacturer/by-name-and-multiple/?page=${pageParam}&name=${name}&multiple=${multiple}`,
    );
};

export const getBudgetPartAttribute = (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`/budget-request-part-attribute/${id}/by-budget-request/`);
};

export const createBudgetPart = (data): Promise<any> => {
  return axios.authorized({}).post(`/budget-part/`, data);
};

export const updateBudget = (id: number, data): Promise<any> => {
  return axios.authorized({}).patch(`/budget/${id}/`, data);
};

export const getBudgetParts = (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`/budget-part/${id}/by-budget/`);
};

export const getStoreBudgetEvaluations = (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`/budget-evaluation/${id}/by-store/
  `);
};

export const getStoreRating = async (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`store/${id}/rating-by-store/`);
};

export const getStorePhotos = async (queryObject): Promise<any[]> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`store-photo/${id}/photos/`);
};

export const getStoreSentBudgets = (): Promise<any> => {
  return axios.authorized({}).get(`/budget/exclude-paid/`);
};

export const deleteBudget = (id): Promise<any> => {
  return axios.authorized({}).delete(`/budget/${id}/`);
};

export const createChatNotification = (data): Promise<any> => {
  return axios.authorized({}).post(`/chat-budget-notification/`, data);
};

export const getChatNotifications = (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios
    .authorized({})
    .get(`/chat-budget-notification/${id}/notification-by-user-and-budget/`);
};

export const readAllNotifications = (): Promise<any> => {
  return axios.authorized({}).post('/chat-budget-notification/read-all-notifications/');
};
