import {
  Box,
  chakra,
  Container,
  VStack,
  Stack,
  Text,
  Button,
  useColorModeValue,
  VisuallyHidden,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { ReactNode } from 'react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { useNavigate } from 'react-router';

import { Logo } from '../common/Logo';

import { useAuth } from '@/context/AppProvider';

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: 'blackAlpha.200',
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export const Footer = () => {
  const { user, store, repairShop, logout } = useAuth();
  const navigate = useNavigate();

  return (
    <Box bg="gray.200" zIndex="docked">
      <Container
        as={Stack}
        maxW="container.xl"
        py={4}
        spacing={4}
        justify={'center'}
        align={'center'}
      >
        <Stack
          w="100%"
          direction={{ base: 'column', lg: 'row' }}
          alignItems="center"
          justifyContent="space-between"
          py={5}
          spacing={10}
        >
          {/* <VStack spacing={2} alignItems={{ base: 'center', lg: 'flex-start' }}>
            <Text fontSize="sm">CNPJ: 16.577.631/0002-99</Text>
            <Text fontSize="sm">Av. Conselheiro Aguiar, 1555</Text>
            <Text fontSize="sm">(55) 33 3333-3333</Text>
          </VStack> */}

          <Logo maxW="150px" />

          <VStack spacing={1} alignItems={{ base: 'center', lg: 'flex-end' }}>
            {repairShop ? (
              <>
                <Button
                  onClick={() => {
                    if (window.location.pathname.includes('/oficina/agendamentos'))
                      window.location.reload();
                    window.location.href = '/oficina/agendamentos';
                  }}
                  variant="ghost"
                  px={4}
                >
                  Meus agendamentos
                </Button>
              </>
            ) : store ? (
              <>
                <Button
                  onClick={() => {
                    if (window.location.pathname.includes('/loja/orcamentos&vendas'))
                      window.location.reload();
                    window.location.href = '/loja/orcamentos&vendas';
                  }}
                  variant="ghost"
                  px={4}
                >
                  Meus pedidos
                </Button>
              </>
            ) : (
              <>
                {user && (
                  <Button
                    onClick={() => {
                      if (window.location.pathname.includes('/agendamentos'))
                        window.location.reload();
                      window.location.href = '/agendamentos';
                    }}
                    variant="ghost"
                    px={4}
                  >
                    Meus agendamentos
                  </Button>
                )}
                <Button
                  onClick={() => {
                    if (window.location.pathname.includes('/encontrar-oficinas'))
                      window.location.reload();
                    window.location.href = '/encontrar-oficinas';
                  }}
                  variant="ghost"
                  px={4}
                >
                  Encontrar oficinas
                </Button>
                <Button
                  onClick={() => {
                    if (window.location.pathname.includes('/encontrar-pecas'))
                      window.location.reload();
                    window.location.href = '/encontrar-pecas';
                  }}
                  variant="ghost"
                  px={4}
                >
                  Comprar peças
                </Button>
              </>
            )}
            {!user && (
              <>
                <Button
                  onClick={() => {
                    if (window.location.pathname.includes('/cadastro')) window.location.reload();
                    window.location.href = '/cadastro';
                  }}
                  variant="ghost"
                  px={4}
                >
                  Cadastrar-se
                </Button>
              </>
            )}

            <Button
              onClick={() => {
                if (user) {
                  logout();
                } else {
                  navigate('/entrar');
                }
              }}
              variant="@primary"
              size="sm"
            >
              {user ? 'Sair' : 'Entrar'}
            </Button>
          </VStack>
        </Stack>
      </Container>

      <Box borderTopWidth={1} borderStyle={'solid'} borderColor={'gray.200'} bg="gray.100">
        <Container
          as={Stack}
          maxW="container.xl"
          py={4}
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justify={{ base: 'center', md: 'space-between' }}
          align="center"
          textAlign="center"
        >
          <Text>© {dayjs().year()} MyCar. Direitos Reservados.</Text>
          <Stack direction={'row'} spacing={6}>
            <SocialButton label="Instagram" href="https://instagram.com/mycar">
              <FaInstagram />
            </SocialButton>
            <SocialButton label="Facebook" href="https://facebook.com/mycar">
              <FaFacebook />
            </SocialButton>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
};
