import { useQuery } from 'react-query';

import { getBudgetPartAttribute } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseBudgetPartAttributeOptions = {
  id: number;
  config?: QueryConfig<typeof getBudgetPartAttribute>;
};

export const useBudgetPartAttribute = ({ id, config }: UseBudgetPartAttributeOptions) => {
  return useQuery(['getBudgetPartAttribute', id], getBudgetPartAttribute, config);
};
