import { useQuery } from 'react-query';

import { getStoreBudgets } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseStoreSchedulesOptions = {
  id?: number;
  status?: string;
  config?: QueryConfig<typeof getStoreBudgets>;
};

export const useStoreSchedules = ({ id, status, config }: UseStoreSchedulesOptions) => {
  return useQuery(['getStoreSchedules', id, status], getStoreBudgets, config);
};
