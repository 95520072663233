import { useQuery } from 'react-query';

import { getConsumerSchedules } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseConsumerSchedulesOptions = {
  id?: number;
  config?: QueryConfig<typeof getConsumerSchedules>;
};

export const useConsumerSchedules = ({ id, config }: UseConsumerSchedulesOptions) => {
  return useQuery(['getConsumerSchedules', id], getConsumerSchedules, config);
};
