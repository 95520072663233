import { Box, Center, Flex, Text, VStack } from '@chakra-ui/layout';
import { useMediaQuery } from '@chakra-ui/media-query';
import { Button, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { ScheduleRequestSelectRepairShopCards } from './ScheduleRequestSelectRepairShopCards';
import { ScheduleRequestSelectRepairShopHeaderMobile } from './ScheduleRequestSelectRepairShopHeaderMobile';
import { ScheduleRequestSelectRepairShopMap } from './ScheduleRequestSelectRepairShopMap';

import { MotionBox } from '@/components/common/MotionBox';
import { Spinner } from '@/components/common/Spinner/Spinner';
import { useFCM } from '@/context/AppProvider';
import { RepairShopDetail } from '@/modules/repair-shop';

type Geolocation = {
  latitude: number;
  longitude: number;
};

export const ScheduleRequestSelectRepairShop = ({ scheduleRequestResponse }) => {
  const toast = useToast();
  const { removeScheduleRequestResponse, setScheduleRequest } = useFCM();
  const [isSmallScreen] = useMediaQuery('(max-width: 1000px)');
  const [repairShopCardOpen, setRepairShopCardOpen] = useState(false);

  const [selectedRepairShop, setSelectedRepairShop] = useState();
  const [locations, setLocations] = useState<any[]>([]);

  const [geolocation, setGeolocation] = useState<Geolocation | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 180000);
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setGeolocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (err) =>
        toast({
          title: err,
          status: 'error',
          duration: 5000,
          isClosable: true,
        }),
      { enableHighAccuracy: true },
    );
  }, [toast]);

  useEffect(() => {
    if (scheduleRequestResponse?.length >= 1) setLoading(false);
  }, [scheduleRequestResponse]);

  const cancelRequest = () => {
    localStorage.removeItem('@schedule-request');
    setScheduleRequest(null);
    removeScheduleRequestResponse();

    toast({
      title: 'Solicitação cancelada.',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  useEffect(() => {
    const locations = scheduleRequestResponse?.map((item) => {
      return {
        latitude: item.repair_shop.address.latitude,
        longitude: item.repair_shop.address.longitude,
      };
    });

    setLocations(locations);
  }, [scheduleRequestResponse]);

  return (
    <>
      {loading ? (
        <Center w="100%" minH="70vh">
          <VStack
            p={10}
            display="flex"
            justifyContent="space-between"
            bg="white"
            rounded="xl"
            shadow="md"
            h={260}
          >
            <Text>Estamos procurando as oficinas ideais para você</Text>
            <Spinner />
            <Button onClick={cancelRequest} mt={30} size="sm" variant="@colorless">
              <Text fontWeight="bold">Cancelar Solicitação</Text>
            </Button>
          </VStack>
        </Center>
      ) : (
        geolocation && (
          <Flex
            w="100%"
            minH="90vh"
            pos="relative"
            flexDirection={{ base: 'column-reverse', lg: 'row' }}
            mb={50}
          >
            <Box w={{ base: '100%', lg: '30%' }} mr={{ base: 0, lg: 10 }}>
              {isSmallScreen && repairShopCardOpen && (
                <MotionBox
                  initial={{ y: '100%' }}
                  animate={{ y: 0 }}
                  transition={{ delay: 0, duration: 0.5 }}
                  w="full"
                  h="full"
                  zIndex="popover"
                >
                  <VStack bg="white" w="full" h="full" rounded="md" mt={3}>
                    <ScheduleRequestSelectRepairShopHeaderMobile
                      repairShopCardOpen={repairShopCardOpen}
                      setRepairShopCardOpen={setRepairShopCardOpen}
                    />
                    <RepairShopDetail selectedRepairShop={selectedRepairShop} />
                  </VStack>
                </MotionBox>
              )}

              <ScheduleRequestSelectRepairShopCards
                repairShopCardOpen={repairShopCardOpen}
                setRepairShopCardOpen={setRepairShopCardOpen}
                selectedRepairShop={selectedRepairShop}
                setSelectedRepairShop={setSelectedRepairShop}
              />

              <Button onClick={cancelRequest} mt={5} size="sm" variant="@colorless">
                <Text fontWeight="bold">Cancelar Solicitação</Text>
              </Button>
            </Box>

            <Box bg="white" rounded="lg" shadow="lg" flex={1}>
              {geolocation && (
                <ScheduleRequestSelectRepairShopMap
                  longitude={geolocation.longitude}
                  latitude={geolocation.latitude}
                  markers={locations}
                />
              )}
            </Box>
          </Flex>
        )
      )}
    </>
  );
};
