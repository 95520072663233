import { useQuery } from 'react-query';

import { getChatNotifications } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseChatNotificationsOptions = {
  id?: number;
  config?: QueryConfig<typeof getChatNotifications>;
};

export const useChatNotifications = ({ id, config }: UseChatNotificationsOptions) => {
  return useQuery(['getChatNotifications', id], getChatNotifications, config);
};
