import * as externals from './externals';

const externalsStyles = () =>
  Object.values(externals).reduce(
    (acc, cur) => ({
      ...acc,
      ...(typeof cur === 'function' ? cur() : cur),
    }),
    {},
  );

export const styles = {
  global: () => ({
    '*': {
      '&::-webkit-scrollbar': {
        width: '6px',
        borderRadius: '3px',
        backgroundColor: `blackAlpha.400`,
      },
      '&::-webkit-scrollbar-thumb': {
        width: '6px',
        borderRadius: '3px',
        backgroundColor: `brand.500`,
      },
    },
    html: {
      minH: '100vh',
    },
    body: {
      h: '100%',
      WebkitTapHighlightColor: 'transparent',
      color: 'black',
      '.custom-input': {
        width: '100%',
        minWidth: '0px',
        outline: '2px solid #0000',
        outlineOffset: '2px',
        fontSize: 'var(--chakra-fontSizes-sm)',
        paddingInlineStart: 'var(--chakra-space-4)',
        paddingInlineEnd: 'var(--chakra-space-4)',
        height: 'var(--chakra-sizes-10)',
        borderRadius: 'var(--chakra-radii-md)',
        border: '1px solid',
        borderColor: 'inherit',
        background: 'var(--chakra-colors-white)',
        color: 'var(--chakra-colors-black)',
        boxShadow: 'var(--chakra-shadows-sm)',
      },
    },
    button: {
      cursor: 'pointer',
    },
    p: {
      fontSize: 'sm',
      fontWeight: 'medium',
    },
    section: {
      boxShadow: 'none',
    },
    '#chakra-toast-portal > *': {
      pt: 'safe-top',
      pl: 'safe-left',
      pr: 'safe-right',
      pb: 'safe-bottom',
    },
    ...externalsStyles(),
  }),
};
