/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import { Button } from '@chakra-ui/button';
import { Text, Divider, HStack, VStack, Flex, Link as CLink } from '@chakra-ui/layout';
import { Skeleton } from '@chakra-ui/react';
import { Tag, TagLabel, TagLeftIcon } from '@chakra-ui/tag';
import { getAuth } from 'firebase/auth';
import { motion } from 'framer-motion';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { FaCarAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { deleteUserCar } from '../../api';
import { useCars } from '../../hooks';
import { CreatedCar } from '../../types';

import { CreateCarForm } from './CreateCarForm';

import { BoxWithTitle } from '@/components/common/BoxWithTitle';
import { useScroll, useAuth } from '@/context/AppProvider';
import { firebaseApp } from '@/firebase';
import { queue } from '@/utils';

export const SelectCarForm = ({ submit, previous, selectedCar, setSelectedCar, loading }) => {
  const { scrollRef } = useScroll();
  const { user } = useAuth();
  const [carLS, setCarLS] = useState<{ cars: CreatedCar[] }>(queue.getCars());
  const [addCarFormOpen, setAddCarFormOpen] = useState(false);
  const auth = getAuth(firebaseApp);

  const { data: cars, refetch, isLoading } = useCars({ config: { enabled: !!auth.currentUser } });

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollToTop();
    }
  }, []);

  return (
    <VStack
      spacing={5}
      alignItems="flex-start"
      justifyContent="flex-start"
      bg="white"
      rounded="lg"
      shadow="lg"
      maxW="container.sm"
      p={10}
      maxH={{ base: 'initial', lg: '500px' }}
      overflowY={{ base: 'initial', lg: 'scroll' }}
      minH="fit-content"
    >
      <BoxWithTitle title="Qual será o carro?">
        <Flex
          flex={1}
          justifyContent="flex-start"
          flexDirection="row"
          pb={2}
          flexWrap="wrap"
          pt={2}
        >
          {isLoading && !cars ? (
            <Skeleton rounded="md" h={7} w="200px"></Skeleton>
          ) : cars?.length || carLS?.cars.length ? (
            <>
              {cars?.map((car, index) => {
                return (
                  <Flex w="fit-content" key={index} mr={4} mb={4}>
                    <Tag
                      maxW="xs"
                      bg="white"
                      rounded="md"
                      px={4}
                      pr={_.isEqual(car, selectedCar) ? 1 : 4}
                      border={_.isEqual(car, selectedCar) ? '1px solid' : 'none'}
                      borderColor={_.isEqual(car, selectedCar) ? 'brand.500' : 'none'}
                      shadow="lg"
                      onClick={() => {
                        if (car === selectedCar) setSelectedCar(undefined);
                        else setSelectedCar(car);
                      }}
                      cursor="pointer"
                      _hover={{
                        transform: 'translateY(-5px)',
                      }}
                    >
                      <TagLeftIcon boxSize="12px" as={FaCarAlt} mr={3} />
                      <TagLabel>
                        <Text fontSize="xs" fontWeight="bold" mb={2}>
                          {car.car.model.manufacturer.name} {car.car.model.name} {car.car.year}
                        </Text>
                        <Text fontSize="xs" fontWeight="bold">
                          {car.plate.substr(0, 3)} {car.plate.substr(3, 7)}
                        </Text>
                      </TagLabel>

                      {_.isEqual(car, selectedCar) && (
                        <>
                          <Divider ml={5} mr={5} orientation="vertical" />

                          <TagLeftIcon
                            _hover={{
                              color: 'brand.500',
                            }}
                            onClick={async () => {
                              await deleteUserCar(car.id);
                              setSelectedCar(undefined);
                              refetch();
                            }}
                            boxSize={18}
                            as={AiOutlineDelete}
                            ml={-2}
                          />
                        </>
                      )}
                    </Tag>
                  </Flex>
                );
              })}

              {carLS?.cars.map((car, index) => (
                <Tag
                  maxW="xs"
                  bg="white"
                  key={index}
                  rounded="md"
                  px={4}
                  border={car?.plate === selectedCar?.plate ? '1px solid' : 'none'}
                  borderColor={car?.plate === selectedCar?.plate ? 'brand.500' : 'none'}
                  shadow="lg"
                  onClick={() => {
                    if (car?.plate === selectedCar?.plate) setSelectedCar(undefined);
                    else setSelectedCar(car);
                  }}
                  cursor="pointer"
                  _hover={{
                    transform: 'translateY(-5px)',
                  }}
                  mr={4}
                  mb={4}
                  on
                >
                  <TagLeftIcon boxSize="12px" as={FaCarAlt} mr={3} />
                  <TagLabel>
                    <Text
                      fontSize="xs"
                      fontWeight="bold"
                      mb={2}
                      wordBreak="break-all"
                      whiteSpace="normal"
                      noOfLines={2}
                    >
                      {car.manufacturer.name} {car.model.name} {car.year}
                    </Text>
                    <Text fontSize="xs" fontWeight="bold">
                      {car.plate.substr(0, 3)} {car.plate.substr(3, 7)}
                    </Text>
                  </TagLabel>
                  {car?.plate === selectedCar?.plate && (
                    <>
                      <Divider ml={5} mr={5} orientation="vertical" />

                      <TagLeftIcon
                        _hover={{
                          color: 'brand.500',
                        }}
                        onClick={() => {
                          queue.removeCar(car);
                          setCarLS(queue.getCars());
                        }}
                        boxSize={18}
                        as={AiOutlineDelete}
                        ml={-2}
                      />
                    </>
                  )}
                </Tag>
              ))}
            </>
          ) : (
            <Tag bg="white" rounded="md" px={2} border="1px solid lightgray" shadow="lg">
              <TagLabel>
                <Text fontSize="xs" fontWeight="bold">
                  Nenhum carro criado ainda
                </Text>
              </TagLabel>
            </Tag>
          )}
        </Flex>
      </BoxWithTitle>

      <BoxWithTitle
        title={carLS?.cars.length ? 'Deseja adicionar outro carro?' : 'Deseja criar um carro?'}
        wrapperProps={{
          w: 'full',
        }}
      >
        <motion.div
          transition={{ duration: 0.4 }}
          initial="init"
          animate="end"
          variants={{
            end: { opacity: 1, y: 0 },
            init: { opacity: 0, y: -40 },
          }}
          style={{
            width: '100%',
          }}
        >
          {!addCarFormOpen ? (
            <Button
              onClick={() => setAddCarFormOpen((prev) => !prev)}
              variant="@secondary"
              mt={0}
              alignSelf="flex-start"
              size="sm"
            >
              Adicionar carro
            </Button>
          ) : (
            <CreateCarForm
              setAddCarFormOpen={setAddCarFormOpen}
              setCarLS={setCarLS}
              refetch={refetch}
              setSelectedCar={setSelectedCar}
            />
          )}
        </motion.div>
      </BoxWithTitle>

      <HStack w="full" justifyContent={{ base: 'flex-start', md: 'flex-end' }}>
        <Button onClick={previous} variant="@primary" alignSelf="flex-end">
          Voltar
        </Button>
        <Button
          onClick={submit}
          isLoading={loading}
          variant="@primary"
          alignSelf="flex-end"
          isDisabled={!selectedCar || loading}
        >
          Buscar oficinas
        </Button>
      </HStack>

      {!user && (
        <Text
          d={{ base: 'flex', lg: 'none' }}
          flexDirection="column"
          alignItems="center"
          mx="auto"
          w="full"
        >
          Não perca as informações pesquisadas!{' '}
          <CLink textDecoration="underline">
            <Link to="/entrar">Crie sua conta</Link>
          </CLink>
        </Text>
      )}
    </VStack>
  );
};
