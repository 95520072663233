/* export const APP_NAME = 'MyCar';

export const BRAND_COLOR = '#F8BB32';

export const API_URL = process.env.REACT_APP_API_URL as string;
export const MOCK_API_URL = process.env.REACT_APP_MOCK_API_URL as string;
export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string;
export const MAPBOX_GEOCODING_API = process.env.REACT_APP_GEOCODING_API as string;

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN as string;
export const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT as string;

export const ENVIROMENT = process.env.NODE_ENV;

export const DEFAULT_PUBLIC_PAGE = '/entrar';

export const DEFAULT_PRIVATE_PAGE = '/';

export const ACCESS_TOKEN_ID = '_my-car_access';
export const REFRESH_TOKEN_ID = '_my-car_refresh';
export const USER_INFO_ID = '_my-caruser-info';

export const MSW_DB = '_my-car_msw-db';

export const JWT_ACCESS_SECRET = '123456' as string;
export const JWT_ACCESS_EXPIRES_IN = 10;
export const JWT_REFRESH_SECRET = '123456789' as string;
export const JWT_REFRESH_EXPIRES_IN = 50;
 */

export const APP_NAME = 'MyCar';
export const BRAND_COLOR = '#F8BB32';
export const ENVIROMENT = process.env.NODE_ENV;
export const DEFAULT_PUBLIC_PAGE = '/entrar';
export const DEFAULT_PRIVATE_PAGE = '/';
export const ACCESS_TOKEN_ID = '_my-car_access';
export const REFRESH_TOKEN_ID = '_my-car_refresh';
export const USER_INFO_ID = '_my-caruser-info';
export const MSW_DB = '_my-car_msw-db';
export const JWT_ACCESS_SECRET = '123456' as string;
export const JWT_ACCESS_EXPIRES_IN = 10;
export const JWT_REFRESH_SECRET = '123456789' as string;
export const JWT_REFRESH_EXPIRES_IN = 50;
