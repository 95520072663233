import { Routes, Outlet, Route, Navigate } from 'react-router';

import { MainLayout } from '@/components/Layout';
import { useAuth } from '@/context/AppProvider';
import { Login, Register, ResetPassword } from '@/modules/auth';
import { BudgetRequest } from '@/modules/budget-request';
import { Landing } from '@/modules/misc';
import { ScheduleRequest } from '@/modules/schedule-request';
import { lazyImport } from '@/utils/lazyImport';

const { MainRoutes } = lazyImport(() => import('./MainRoutes'), 'MainRoutes');
const { RepairShopRoutes } = lazyImport(() => import('./RepairShopRoutes'), 'RepairShopRoutes');
const { StoreRoutes } = lazyImport(() => import('./StoreRoutes'), 'StoreRoutes');

export const AppRoutes = () => {
  const { user, repairShop, store } = useAuth();

  const CredentialRoutes = () => {
    if (!user) return <Navigate to="/entrar" />;

    return <Outlet />;
  };

  const RepairShopCredentials = () => {
    if (!user) return <Navigate to="/entrar" />;
    else if (!repairShop) return <Navigate to="/" />;

    return <Outlet />;
  };

  const StoreCredentials = () => {
    if (!user) return <Navigate to="/entrar" />;
    else if (!store) return <Navigate to="/" />;

    return <Outlet />;
  };

  return (
    <Routes>
      <Route element={<App />}>
        <Route path="/" element={<Landing />} />

        <Route path="*" element={<CredentialRoutes />}>
          <Route path="*" element={<MainRoutes />} />
        </Route>

        <Route path="encontrar-oficinas" element={<ScheduleRequest />} />
        <Route path="encontrar-pecas" element={<BudgetRequest />} />

        <Route path="oficina" element={<RepairShopCredentials />}>
          <Route path="*" element={<RepairShopRoutes />} />
        </Route>

        <Route path="loja" element={<StoreCredentials />}>
          <Route path="*" element={<StoreRoutes />} />
        </Route>

        <Route>
          <Route path="entrar" element={<Login />} />
          <Route path="entrar/:redirect" element={<Login />} />
          <Route path="cadastro" element={<Register />} />
          <Route path="cadastro/:redirect" element={<Register />} />
          <Route path="redefinir-senha" element={<ResetPassword />} />
        </Route>
      </Route>
    </Routes>
  );
};

const App = () => {
  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
};
