/* eslint-disable @typescript-eslint/no-var-requires */
import { Image } from '@chakra-ui/image';
import { Box, Text } from '@chakra-ui/layout';
import { useMediaQuery } from '@chakra-ui/media-query';
import mapboxgl from 'mapbox-gl';
import { useRef } from 'react';
import MapGL, { Marker } from 'react-map-gl';
import Geocoder from 'react-map-gl-geocoder';

import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';

(mapboxgl as any).workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

import myCarMap from '@/assets/icone-mycar-map.svg';

export type MarkerP = {
  latitude: number;
  longitude: number;
};

export type Coordinates = {
  rua: string;
  bairro: string;
  cidade: string;
  estado: string;
  cep: string;
};

export type Viewport = {
  latitude: number;
  longitude: number;
  zoom?: number;
};

type MapProps = {
  viewport: Viewport;
  handleViewportChange: (viewport: Viewport) => void;
  defaultMarker?: Viewport | null;
  colorMarker?: string;
  markers?: MarkerP[];
};

export const Map = ({
  viewport,
  handleViewportChange,
  defaultMarker = null,
  markers,
}: MapProps) => {
  const mapRef = useRef<any>();
  const [isSmallScreen] = useMediaQuery('(min-width: 1000px)');

  return (
    <MapGL
      ref={mapRef}
      {...viewport}
      width="100%"
      height="100%"
      onViewportChange={handleViewportChange}
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      mapStyle="mapbox://styles/mapbox/light-v10"
    >
      {defaultMarker && (
        <Marker {...defaultMarker}>
          <Box pos="relative">
            <Text
              pos="absolute"
              top="-60px"
              left="50%"
              transform="translateX(-50%)"
              fontSize="xs"
              w="60px"
              textAlign="center"
            >
              Você está aqui!
            </Text>
            <Box
              bg="brand.500"
              boxShadow="0px 0px 20px #eb7f1bcc"
              w={5}
              h={5}
              pos="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              rounded="full"
            />
            <Box
              border="2px solid #F8BB32"
              w={10}
              h={10}
              pos="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              rounded="full"
            />
          </Box>
        </Marker>
      )}

      {markers?.map((marker, index) => {
        return (
          <Marker
            key={index}
            {...marker}
            onClick={() => {
              const element = document.getElementById(`repair-shop-${index}`);
              if (element) {
                element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                element.style.backgroundColor = '#FEF9EA';
                element.style.transition = 'background-color 0.5s';

                setTimeout(() => {
                  element.style.backgroundColor = 'white';
                  element.style.transition = 'background-color 0.5s';
                }, 800);
              }
            }}
          >
            <Image cursor="pointer" size="40" src={myCarMap} />
          </Marker>
        );
      })}

      {isSmallScreen && (
        <Geocoder
          mapRef={mapRef}
          onViewportChange={handleViewportChange}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
          position="top-left"
          placeholder="Pesquise aqui..."
        />
      )}
    </MapGL>
  );
};
