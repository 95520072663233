import { Flex, VStack, HStack, Avatar, Box, Text, Image } from '@chakra-ui/react';
import { useEffect } from 'react';
import { AiFillStar } from 'react-icons/ai';
import { BsCalendar, BsStarHalf } from 'react-icons/bs';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';

import Clock from '../../../../assets/Clock.svg';
import Location from '../../../../assets/location.svg';
import { useRepairShop, useRepairShopPhotos, useRepairShopRating } from '../../hooks';

import Star from '@/assets/Star.svg';
import { useAuth } from '@/context/AppProvider';

export const RepairShopSidebar = ({ children }) => {
  const { repairShop, setRepairShop } = useAuth();

  const { data: repairShopData } = useRepairShop({
    id: repairShop?.id,
    config: { enabled: !!repairShop?.id },
  });

  const { data: repairShopRating } = useRepairShopRating({
    id: repairShop?.id,
    config: { enabled: !!repairShop?.id },
  });

  const { data: repairShopPhotos } = useRepairShopPhotos({
    id: repairShop?.id,
    config: { enabled: !!repairShop?.id },
  });

  useEffect(() => {
    if (repairShopData) {
      localStorage.setItem('@repair-shop', JSON.stringify(repairShopData));
    }
  }, [repairShopData, setRepairShop]);

  const renderStars = (rating) => {
    const stars: any[] = [];
    for (let i = 0; i < rating; i++) {
      if (Math.trunc(rating - i) > 0) {
        stars.push(<AiFillStar key={i} size={20} color="#FFC107" />);
      } else {
        stars.push(<BsStarHalf key={i} style={{ marginTop: -1 }} size={18} color="#FFC107" />);
      }
    }
    return stars;
  };

  return (
    <HStack alignItems="flex-start">
      <Flex
        flexDirection="column"
        w="350px"
        rounded="md"
        bg="white"
        d={{ base: 'none', lg: 'flex' }}
        marginRight={10}
        color="white"
      >
        <VStack
          position="relative"
          bg="brand.200"
          p={5}
          roundedTopStart="lg"
          roundedTopEnd="lg"
          spacing="5"
          pb="16"
          mb="-16"
          overflow="hidden"
        >
          <Image
            w="calc(100% + 10%)"
            h="calc(100% + 10%)"
            filter="blur(2px) brightness(0.5)"
            top={0}
            zIndex={1}
            position="absolute"
            src={repairShop?.profile_pic}
          />

          <HStack zIndex={2} w="full" alignItems="center">
            <Avatar src={repairShop?.profile_pic} />
          </HStack>

          <VStack zIndex={2} alignItems="flex-start" w="full" spacing="1">
            <Text textAlign="left">{repairShop?.name}</Text>
            <Text textAlign="left" fontSize="xs" d="flex" alignItems="center">
              <Image w={2.5} mr={3} src={Location} />
              {repairShop?.address.street}
            </Text>
            <Text textAlign="left" fontSize="xs" d="flex" alignItems="center">
              <BsCalendar style={{ marginRight: '5px' }} />
              {`Seg - Sex, ${repairShop?.open} as ${repairShop?.closed}`}
            </Text>
          </VStack>

          <HStack w="full" justify="flex-start" zIndex={2}>
            <Text>
              {repairShopRating?.rating
                ? `${repairShopRating.rating.toFixed(1)}`
                : 'Você ainda não possui avaliações'}
            </Text>
            {repairShopRating?.rating && renderStars(repairShopRating.rating)}
            <Text fontSize="xs">
              {repairShopRating?.rating && `${repairShopRating.count} avaliações`}
            </Text>
          </HStack>
        </VStack>

        <Box zIndex={2} w="full" p="5">
          <Text fontWeight="bold" fontSize="sm" mb={2}>
            Fotos da oficina
          </Text>
          <HStack>
            {repairShopPhotos?.length && (
              <>
                <Box
                  bgImg={repairShopPhotos[0].photo}
                  bgPos="center"
                  bgSize="cover"
                  rounded="md"
                  shadow="md"
                  flex={1}
                  h="100px"
                />
                {repairShopPhotos?.length > 1 && repairShopPhotos?.length <= 2 ? (
                  <Box
                    bgImg={repairShopPhotos[1].photo}
                    bgPos="center"
                    bgSize="cover"
                    rounded="md"
                    shadow="md"
                    flex={1}
                    h="100px"
                  />
                ) : (
                  <VStack flex={1}>
                    {repairShopPhotos.slice(1, 3).map((photo) => (
                      <Box
                        key={photo.id}
                        bgImg={photo.photo}
                        bgPos="center"
                        bgSize="cover"
                        rounded="md"
                        shadow="md"
                        h="50px"
                        w="full"
                      />
                    ))}
                  </VStack>
                )}
              </>
            )}
          </HStack>
        </Box>

        <VStack color="black" zIndex={2} w="full">
          <HStack
            as={Link}
            to="/oficina/agendamentos"
            w="full"
            justifyContent="space-between"
            p={5}
            bg="white"
            roundedBottomStart="lg"
            roundedBottomEnd="lg"
          >
            <HStack>
              <Image w={3} src={Clock} />
              <Text>Meus agendamentos</Text>
            </HStack>
            <Box>
              <MdKeyboardArrowRight size={16} />
            </Box>
          </HStack>
          <HStack
            as={Link}
            to="/oficina/avaliacoes"
            w="full"
            justifyContent="space-between"
            p={5}
            bg="white"
            roundedBottomStart="lg"
            roundedBottomEnd="lg"
          >
            <HStack>
              <Image w={3} src={Star} />
              <Text>Minhas avaliações</Text>
            </HStack>
            <Box>
              <MdKeyboardArrowRight size={16} />
            </Box>
          </HStack>
        </VStack>
      </Flex>

      <Box w="full">{children}</Box>
    </HStack>
  );
};
