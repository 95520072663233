/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, Text, Heading, HStack } from '@chakra-ui/layout';
import {
  Link as CLink,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Input,
  Button,
  VStack,
  Divider,
  Image,
} from '@chakra-ui/react';
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteGroup,
  AutoCompleteCreatable,
} from '@choc-ui/chakra-autocomplete';
import { motion } from 'framer-motion';
import _ from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { FaRegEdit } from 'react-icons/fa';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import LogoIcon from '../../../../assets/logo-collapsed.svg';
import { getRepairShopParts, getRepairShopManufacturers } from '../../api';

import { BoxWithTitle } from '@/components/common/BoxWithTitle';
import { FieldWrapper } from '@/components/Form';
import { useAuth } from '@/context/AppProvider';
import { useInfinitePagination } from '@/hooks/useInfinitePagination';

export const CreatePart = ({
  setSelectedParts,
  setNewPart,
  price,
  selectedParts,
  part,
  isEditable,
  setOnEditionPart,
  isCustomPart,
  customParts,
  setCustomParts,
}) => {
  const [step, setStep] = useState(0);
  const { user } = useAuth();
  const [selectedAttributes, setSelectedAttributes] = useState<any>([]);
  const [onEdition, setOnEdition] = useState(!part);
  const [attributes, setAttributes] = useState<any>();

  const [customPart, setcustomPart] = useState(!!isCustomPart);
  const [repairShopPartsSearch, setRepairShopPartsSearch] = useState<string>();
  const [repairShopManufacturersSearch, setRepairShopManufacturersSearch] = useState<string>();

  const [partName, setPartName] = useState<any>(part?.name || '');

  const [selectedPart, setSelectedPart] = useState<any>();

  const {
    data: repairShopParts,
    fetchNextPage: fetchPartsNextPage,
    isLoading,
  } = useInfinitePagination({
    tag: 'getRepairShopParts',
    request: getRepairShopParts,
    search: repairShopPartsSearch,
  });

  const { data: repairShopManufacturers, fetchNextPage: fetchManufacturersNextPage } =
    useInfinitePagination({
      tag: 'getRepairShopManufacturers',
      request: getRepairShopManufacturers,
      search: repairShopManufacturersSearch,
    });

  const createPart = () => {
    if (customPart) {
      setCustomParts((prev) => [
        ...prev,
        {
          id: uuidv4(),
          createdAt: new Date(),
          ...selectedPart,
          isCustomPart: true,
        },
      ]);
      setSelectedPart({});
      setSelectedAttributes([]);
      setStep(0);
      setNewPart(false);
      setOnEdition(false);
    } else {
      setSelectedParts((prev) => [
        ...prev,
        {
          id: uuidv4(),
          createdAt: new Date(),
          ...selectedPart,
          selected_attributes: selectedAttributes,
        },
      ]);
      setSelectedPart({});
      setSelectedAttributes([]);
      setStep(0);
      setNewPart(false);
      setOnEdition(false);
    }
  };

  const editPart = () => {
    if (customPart) {
      const aux = customParts.filter((p) => p.id !== part.id);
      setCustomParts([
        ...aux,
        {
          id: uuidv4(),
          createdAt: part.createdAt,
          ...selectedPart,
          isCustomPart: true,
        },
      ]);
      setOnEdition(false);
      setOnEditionPart && setOnEditionPart(false);
    } else {
      const aux = selectedParts.filter((p) => p.id !== part.id);
      setSelectedParts([
        ...aux,
        {
          id: uuidv4(),
          createdAt: part.createdAt,
          ...selectedPart,
          isCustomPart: false,
        },
      ]);
      setOnEdition(false);
      setOnEditionPart && setOnEditionPart(false);
    }
  };

  useEffect(() => {
    if (
      repairShopParts &&
      repairShopParts?.pages?.flatMap((page) => page?.results)?.length > 0 &&
      selectedPart?.name
    ) {
      const partName = selectedPart.name.split(' - ')[0];

      const aux = repairShopParts?.pages
        ?.flatMap((page) => page?.results)
        ?.find((item) => item.part.name === partName);

      setSelectedPart({
        ...aux,
        name: selectedPart?.name,
        manufacturer_id: aux?.part?.manufacturer?.id,
      });
      setPartName(partName);
      setAttributes(aux?.part.attributes);
      setStep(1);
    }
  }, [repairShopParts, selectedPart?.name]);

  const currencyFormatter = (value) => {
    if (!Number(value)) return '';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  };

  const handleLabels = (part: any) => {
    switch (part?.multiple) {
      case 1:
        return part?.quantity > 1 ? `${part?.quantity} peças` : `1 peça`;
      case 2:
        return part?.quantity > 1 ? `${part?.quantity} pares` : `1 par`;
      case part?.multiple > 2:
        return part?.quantity > 1 ? `${part?.quantity} jogos` : `1 jogo`;
      default:
        return part?.quantity > 1 ? `${part?.quantity} peças` : `1 peça`;
    }
  };

  if (!onEdition) {
    return (
      <VStack w="full" h="full" align="flex-start" justify="flex-start">
        <VStack
          flexDir={{ base: 'column', md: 'row' }}
          w="full"
          justify="space-between"
          align="center"
          mb={3}
          spacing={3}
          h="full"
        >
          <VStack mr={{ base: 0, md: 16, lg: 5 }} w="full" align="flex-start" h="full">
            <Text fontSize="xs">Peça</Text>
            <Text fontSize="sm" fontWeight="bold">
              {isCustomPart ? part.name : `${part.part.name} - ${part.part.manufacturer.name}`}
            </Text>
          </VStack>

          <VStack
            mt="0px !important"
            marginInlineStart="0px !important"
            w="full"
            align="flex-start"
            h="full"
          >
            <Text fontSize="xs">Preço por peça</Text>
            <Text fontSize="sm" fontWeight="bold">
              {Number(part.price).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Text>
          </VStack>

          <VStack
            marginInlineStart="0px !important"
            pl={{ base: 0, md: 8 }}
            w="full"
            align="flex-start"
            h="full"
            mt="0px !important"
          >
            <Text fontSize="xs">Quantidade</Text>
            <Text fontSize="sm" fontWeight="bold">
              {handleLabels(part)}
            </Text>
          </VStack>

          <HStack w="full" justify="space-evenly">
            <HStack
              cursor="pointer"
              onClick={() => {
                setOnEdition(true);
                setSelectedPart(part);
                setOnEditionPart(true);
              }}
            >
              <FaRegEdit cursor="pointer" size={20} style={{ color: 'gray' }} />
              <Text>Editar</Text>
            </HStack>
            <HStack
              cursor="pointer"
              onClick={() => {
                if (customPart) {
                  setCustomParts(customParts.filter((part) => _.isEqual(part, part) === false));
                } else {
                  setSelectedParts(selectedParts.filter((part) => _.isEqual(part, part) === false));
                }
                setNewPart(false);
                setOnEdition(false);
              }}
            >
              <AiOutlineDelete size={22} style={{ color: 'gray' }} cursor="pointer" />
              <Text>Excluir</Text>
            </HStack>
          </HStack>
        </VStack>
        <Divider />
      </VStack>
    );
  }

  const debouncedFunction = (e: any, set: Dispatch<SetStateAction<any>>) => {
    setTimeout(() => set(e.target.value), 300);
  };

  console.log(isCustomPart);

  return (
    <FieldWrapper
      name="parts"
      boxProps={{ w: 'full' }}
      required
      as={() => (
        <FieldWrapper
          name="problem_id"
          boxProps={{
            w: 'full',
            mt: -5,
          }}
          as={() => (
            <>
              <Heading mt={5} mb={5} as="p" fontFamily="gill" fontSize="sm">
                Peça selecionada
              </Heading>

              <AutoComplete
                onChange={(e) => {
                  const partName = e.split(' - ')[0];
                  const manufacturerName = e.split(' - ')[1];

                  const isNewPart = repairShopParts?.pages
                    ?.flatMap((page) => page?.results)
                    ?.find(({ part }) => `${part.name} - ${part.manufacturer.name}` === e);

                  if (!isNewPart) {
                    setcustomPart(true);
                    setSelectedPart({
                      ...selectedPart,
                      name: partName,
                      manufacturer: manufacturerName,
                    });
                    setStep(1);
                  } else {
                    setSelectedPart({
                      ...selectedPart,
                      name: partName,
                      partId: isNewPart.id,
                      manufacturer_id: isNewPart.part.manufacturer.id,
                    });
                    setStep(1);
                  }
                }}
                openOnFocus
                defaultValue={partName}
                creatable
                emptyState={
                  <Text w="full" textAlign="center">
                    Peça não encontrada
                  </Text>
                }
              >
                <AutoCompleteInput
                  rounded="md"
                  placeholder="Selecionar peça"
                  variant="outline"
                  onChange={(e) => debouncedFunction(e, setRepairShopPartsSearch)}
                />

                <AutoCompleteList
                  onScroll={(e) => {
                    const { clientHeight, scrollTop, scrollHeight } = e.target as HTMLElement;
                    const bottom = Math.ceil(clientHeight + scrollTop) >= scrollHeight;
                    if (bottom) fetchPartsNextPage();
                  }}
                >
                  <AutoCompleteGroup>
                    {repairShopParts?.pages
                      ?.flatMap((page) => page?.results)
                      ?.map((part) => `${part?.part?.name} - ${part?.part?.manufacturer?.name}`)
                      ?.map((name, index) => (
                        <AutoCompleteItem
                          key={`option-${index}`}
                          value={name}
                          textTransform="capitalize"
                        >
                          <HStack w="full" justify="space-between">
                            <Text>{String(name)}</Text>
                          </HStack>
                        </AutoCompleteItem>
                      ))}
                  </AutoCompleteGroup>

                  {!isLoading && (
                    <AutoCompleteCreatable>
                      {({ value }) => <span>Adicionar {value}</span>}
                    </AutoCompleteCreatable>
                  )}
                </AutoCompleteList>
              </AutoComplete>

              {step >= 1 && (
                <motion.div
                  transition={{ duration: 0.4 }}
                  initial="init"
                  animate="end"
                  variants={{
                    end: { opacity: 1, y: 0 },
                    init: { opacity: 0, y: -40 },
                  }}
                >
                  <Flex
                    w="full"
                    bg="white"
                    p={5}
                    mt={5}
                    rounded="lg"
                    shadow="lg"
                    flexDirection="column"
                    align={{ base: 'center', md: 'flex-start' }}
                  >
                    <>
                      {attributes?.map((attribute) => {
                        const [, ...values]: any = Object.entries(attribute)[0][1];

                        return (
                          <BoxWithTitle
                            key={Object.entries(attribute)[0][0]}
                            title={Object.entries(attribute)[0][0]}
                            titleSize="sm"
                            wrapperProps={{
                              w: 'full',
                              marginBottom: 3,
                            }}
                          >
                            <FieldWrapper
                              name={String(Object.entries(attribute)[0][0]).toLowerCase()}
                              boxProps={{
                                w: 'full',
                              }}
                              required
                              as={() => (
                                <AutoComplete
                                  onChange={(e) => {
                                    const selectedItem = values?.find((item) => item.name === e);

                                    setSelectedPart({
                                      ...selectedPart,
                                    });
                                    setSelectedAttributes([...selectedAttributes, selectedItem.id]);
                                  }}
                                  openOnFocus
                                  emptyState={
                                    <Text w="full" textAlign="center">
                                      Atributo não encontrado
                                    </Text>
                                  }
                                >
                                  <AutoCompleteInput
                                    rounded="md"
                                    placeholder="Selecionar atributo"
                                    variant="outline"
                                  />
                                  <AutoCompleteList>
                                    {values.map((item, index) => (
                                      <AutoCompleteItem
                                        key={`option-${index}`}
                                        value={item.name}
                                        textTransform="capitalize"
                                      >
                                        <HStack w="full" justify="space-between">
                                          <Text>{item.name}</Text>
                                        </HStack>
                                      </AutoCompleteItem>
                                    ))}
                                  </AutoCompleteList>
                                </AutoComplete>
                              )}
                            />
                          </BoxWithTitle>
                        );
                      })}

                      {customPart && (
                        <BoxWithTitle
                          title="Preferência de fabricante"
                          titleSize="sm"
                          wrapperProps={{
                            w: 'full',
                            marginBottom: 3,
                          }}
                        >
                          <FieldWrapper
                            name="manufacturer"
                            boxProps={{
                              w: 'full',
                            }}
                            required
                            as={() => (
                              <AutoComplete
                                onChange={(e) => {
                                  if (customPart) {
                                    setSelectedPart({
                                      ...selectedPart,
                                      manufacturer: e,
                                      name: `${selectedPart.name.split(' - ')[0]} - ${e}`,
                                    });
                                  } else {
                                    setSelectedPart({
                                      ...selectedPart,
                                      manufacturer: e,
                                      name: `${selectedPart.part.name} - ${e}`,
                                      manufacturer_id: selectedPart.part.manufacturer.id,
                                    });
                                  }
                                }}
                                openOnFocus
                                emptyState={
                                  <Text w="full" textAlign="center">
                                    Fabricante não encontrado
                                  </Text>
                                }
                              >
                                <AutoCompleteInput
                                  rounded="md"
                                  placeholder="Selecionar fabricante"
                                  variant="outline"
                                  onChange={(e) =>
                                    debouncedFunction(e, setRepairShopManufacturersSearch)
                                  }
                                />
                                <AutoCompleteList
                                  onScroll={(e) => {
                                    const { clientHeight, scrollTop, scrollHeight } =
                                      e.target as HTMLElement;
                                    const bottom =
                                      Math.ceil(clientHeight + scrollTop) >= scrollHeight;
                                    if (bottom) fetchManufacturersNextPage();
                                  }}
                                >
                                  {customPart
                                    ? repairShopManufacturers?.pages
                                        ?.flatMap((page) => page?.results)
                                        ?.map((item) => {
                                          return {
                                            name: item.name,
                                            suggested: item.suggested,
                                          };
                                        })
                                        .map((item, index) => {
                                          return (
                                            <AutoCompleteItem
                                              key={`option-${index}`}
                                              value={item.name}
                                              textTransform="capitalize"
                                            >
                                              <HStack w="full" justify="space-between">
                                                <Text>{String(item.name)}</Text>
                                                {item.suggested && (
                                                  <HStack px={3} rounded="full" bg="brand.300">
                                                    <Text fontSize="xs">Indicação MyCar</Text>
                                                    <Image src={LogoIcon} w={3} />
                                                  </HStack>
                                                )}
                                              </HStack>
                                            </AutoCompleteItem>
                                          );
                                        })
                                    : repairShopParts?.pages
                                        ?.flatMap((page) => page?.results)
                                        ?.filter((item) => item.part.name === selectedPart.name)
                                        ?.map((item) => {
                                          return {
                                            name: item.part.manufacturer.name,
                                            suggested: item.part.manufacturer.suggested,
                                          };
                                        })
                                        .map(({ name, suggested }, index) => (
                                          <AutoCompleteItem
                                            key={`option-${index}`}
                                            value={name}
                                            textTransform="capitalize"
                                          >
                                            <HStack w="full" justify="space-between">
                                              <Text>{String(name)}</Text>
                                              {suggested && (
                                                <HStack px={3} rounded="full" bg="brand.300">
                                                  <Text fontSize="xs">Indicação MyCar</Text>
                                                  <Image src={LogoIcon} w={3} />
                                                </HStack>
                                              )}
                                            </HStack>
                                          </AutoCompleteItem>
                                        ))}
                                </AutoCompleteList>
                              </AutoComplete>
                            )}
                          />
                        </BoxWithTitle>
                      )}

                      {
                        <BoxWithTitle
                          title={`Quantidade ${
                            selectedPart.multiple === 1
                              ? '(Unidade)'
                              : selectedPart.multiple === 2
                              ? '(Par)'
                              : selectedPart.multiple > 2
                              ? '(Jogo)'
                              : '(Unidade)'
                          }`}
                          titleSize="sm"
                          wrapperProps={{
                            w: 'full',
                          }}
                        >
                          <FieldWrapper
                            name="quantity"
                            boxProps={{
                              w: 'full',
                            }}
                            required
                            as={() => (
                              <NumberInput
                                onChange={(e) => {
                                  setSelectedPart({
                                    ...selectedPart,
                                    quantity: e,
                                  });
                                }}
                                min={0}
                              >
                                <NumberInputField />
                                <NumberInputStepper>
                                  <NumberIncrementStepper />
                                  <NumberDecrementStepper />
                                </NumberInputStepper>
                              </NumberInput>
                            )}
                          />
                        </BoxWithTitle>
                      }

                      {price && (
                        <BoxWithTitle
                          title="Preço por peça"
                          titleSize="sm"
                          wrapperProps={{
                            w: 'full',
                          }}
                        >
                          <FieldWrapper
                            name="price"
                            boxProps={{
                              w: 'full',
                            }}
                            required
                            as={() => (
                              <HStack
                                marginInlineStart="0px !important"
                                marginStart="0px !important"
                                w="xm"
                                rounded="md"
                                mt="0px !important"
                              >
                                <NumberFormat
                                  customInput={Input}
                                  allowNegative={false}
                                  onValueChange={(value) => {
                                    setSelectedPart({
                                      ...selectedPart,
                                      price: Number(
                                        value.formattedValue
                                          .replace('R$ ', '')
                                          .replace('.', '')
                                          .replace(',', '.'),
                                      ),
                                    });
                                  }}
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  fixedDecimalScale
                                  format={currencyFormatter}
                                  prefix="R$ "
                                  rounded="md"
                                  defaultValue={0}
                                />
                              </HStack>
                            )}
                          />
                        </BoxWithTitle>
                      )}

                      <Flex
                        alignItems="center"
                        pos={{ base: 'absolute', lg: 'initial' }}
                        bottom={{ base: 5, lg: 'initial' }}
                        flexDirection={{ base: 'column', lg: 'row' }}
                        maxW="80%"
                        transform={{ base: 'translateX(-50%)', lg: 'initial' }}
                        left={{ base: '50%', lg: 'initial' }}
                      >
                        {!user && (
                          <Text mb={3}>
                            Não perca as informações pesquisadas!{' '}
                            <CLink textDecoration="underline">
                              <Link to="/entrar">Crie sua conta</Link>
                            </CLink>
                          </Text>
                        )}
                      </Flex>

                      <Button
                        w="fit-content"
                        size="sm"
                        variant="@primary"
                        onClick={() => {
                          if (isEditable) editPart();
                          else createPart();
                        }}
                      >
                        {isEditable ? 'Salvar peça' : 'Adicionar peça'}
                      </Button>
                    </>
                  </Flex>
                </motion.div>
              )}

              {!isEditable && (
                <Text fontSize="xs" mt={2}>
                  Você pode selecionar mais de uma peça!
                </Text>
              )}
            </>
          )}
        />
      )}
    />
  );
};
