import { useToast } from '@chakra-ui/react';
import { useState } from 'react';

import { AuthLayout } from '../components/AuthLayout';
import { RegisterForm } from '../components/RegisterForm';

export const Register = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  return (
    <AuthLayout
      title="Criar uma conta"
      subtitle="MyCar, a plataforma que simplifica sua relação com seu carro."
    >
      <RegisterForm
        loading={loading}
        setLoading={setLoading}
        onSuccess={() => {
          toast({
            title: 'Conta criada com sucesso.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });

          setLoading(false);
        }}
        onError={(message) => {
          setLoading(false);

          switch (message) {
            case 'Firebase: Error (auth/email-already-in-use).':
              return toast({
                title: 'Este email já está cadastrado.',
                status: 'error',
                duration: 5000,
                isClosable: true,
              });

            default:
              return toast({
                title: message,
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
          }
        }}
      />
    </AuthLayout>
  );
};
