import { useQuery } from 'react-query';

import { getUserBudgetNotifications } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseChatNotificationsOptions = {
  id?: number;
  config?: QueryConfig<typeof getUserBudgetNotifications>;
};

export const useBudgetChatNotifications = ({ id, config }: UseChatNotificationsOptions) => {
  return useQuery(['getUserBudgetNotifications', id], getUserBudgetNotifications, config);
};
