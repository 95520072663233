import { Flex, VStack, HStack, Avatar, Box, Text, Image } from '@chakra-ui/react';
import { useEffect } from 'react';
import { AiFillStar } from 'react-icons/ai';
import { BsCalendar, BsGear, BsStarHalf } from 'react-icons/bs';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';

import Location from '../../../../assets/location.svg';
import Lock from '../../../../assets/Lock.svg';
import Question from '../../../../assets/Question.svg';
import Star from '../../../../assets/Star.svg';
import { useStore } from '../../hooks';
import { useStorePhotos } from '../../hooks/useStorePhotos';
import { useStoreRating } from '../../hooks/useStoreRating';

import { useAuth } from '@/context/AppProvider';

export const SellerSidebar = ({ children }) => {
  const { store, setStore } = useAuth();

  const { data: storeData } = useStore({
    id: store?.id,
    config: { enabled: !!store?.id },
  });

  const { data: storeRating } = useStoreRating({
    id: store?.id,
    config: { enabled: !!store?.id },
  });

  const { data: storePhotos } = useStorePhotos({
    id: store?.id,
    config: { enabled: !!store?.id },
  });

  useEffect(() => {
    if (storeData) {
      localStorage.setItem('@store', JSON.stringify(storeData));
    }
  }, [storeData, setStore]);

  const renderStars = (rating) => {
    const stars: any[] = [];
    for (let i = 0; i < rating; i++) {
      if (Math.trunc(rating - i) > 0) {
        stars.push(<AiFillStar key={i} size={20} color="#FFC107" />);
      } else {
        stars.push(<BsStarHalf key={i} style={{ marginTop: -1 }} size={18} color="#FFC107" />);
      }
    }
    return stars;
  };

  return (
    <HStack alignItems="flex-start">
      <Flex
        flexDirection="column"
        w="350px"
        rounded="md"
        bg="white"
        d={{ base: 'none', lg: 'flex' }}
        marginRight={10}
        color="white"
      >
        <VStack
          position="relative"
          bg="brand.200"
          p={5}
          roundedTopStart="lg"
          roundedTopEnd="lg"
          spacing="5"
          pb="16"
          mb="-16"
          overflow="hidden"
        >
          <Image
            w="calc(100% + 10%)"
            h="calc(100% + 10%)"
            filter="blur(2px) brightness(0.5)"
            top={0}
            zIndex={1}
            position="absolute"
            src={store?.profile_pic}
          />

          <HStack w="full" alignItems="center" zIndex={2}>
            <Avatar src={store?.profile_pic} />
          </HStack>

          <VStack zIndex={2} alignItems="flex-start" w="full" spacing="1">
            <Text textAlign="left">{store.name}</Text>
            <Text textAlign="left" fontSize="xs" d="flex" alignItems="center">
              <Image w={2.5} mr={3} src={Location} />
              {`${store.address.street}, ${store.address.number}`}
            </Text>
            <Text textAlign="left" fontSize="xs" d="flex" alignItems="center">
              <BsCalendar style={{ marginRight: '5px' }} />
              {`Seg - Sex, ${store.open} às ${store.closed}`}
            </Text>
          </VStack>

          <HStack w="full" justify="flex-start" zIndex={2}>
            <Text>
              {storeRating?.rating
                ? `${storeRating.rating.toFixed(1)}`
                : 'Você ainda não possui avaliações'}
            </Text>
            {storeRating?.rating && renderStars(storeRating.rating)}
            <Text fontSize="xs">{storeRating?.rating && `${storeRating.count} avaliações`}</Text>
          </HStack>
        </VStack>

        <Box zIndex={2} w="full" p="5">
          <Text fontWeight="bold" fontSize="sm" mb={2}>
            Fotos da loja
          </Text>
          <HStack>
            {storePhotos?.length && (
              <>
                <Box
                  bgImg={storePhotos[0].photo}
                  bgPos="center"
                  bgSize="cover"
                  rounded="md"
                  shadow="md"
                  flex={1}
                  h="100px"
                />
                {storePhotos.length <= 2 ? (
                  <Box
                    bgImg={storePhotos[1]?.photo}
                    bgPos="center"
                    bgSize="cover"
                    rounded="md"
                    shadow="md"
                    flex={1}
                    h="100px"
                  />
                ) : (
                  <VStack flex={1}>
                    {storePhotos.slice(1, 3).map((photo) => (
                      <Box
                        key={photo.id}
                        bgImg={photo.photo}
                        bgPos="center"
                        bgSize="cover"
                        rounded="md"
                        shadow="md"
                        h="50px"
                        w="full"
                      />
                    ))}
                  </VStack>
                )}
              </>
            )}
          </HStack>
        </Box>

        <VStack color="black" zIndex={2} w="full">
          <HStack
            as={Link}
            to="/loja/orcamentos&vendas"
            w="full"
            justifyContent="space-between"
            p={5}
            bg="white"
            roundedBottomStart="lg"
            roundedBottomEnd="lg"
          >
            <HStack>
              <BsGear />
              <Text>Orçamentos e Vendas</Text>
            </HStack>
            <Box>
              <MdKeyboardArrowRight size={16} />
            </Box>
          </HStack>

          <HStack
            as={Link}
            to="/loja/avaliacoes"
            w="full"
            justifyContent="space-between"
            p={5}
            bg="white"
            roundedBottomStart="lg"
            roundedBottomEnd="lg"
          >
            <HStack>
              <Image w={4} src={Star} />
              <Text>Avaliações</Text>
            </HStack>
            <Box>
              <MdKeyboardArrowRight size={16} />
            </Box>
          </HStack>

          <HStack
            w="full"
            justifyContent="space-between"
            p={5}
            bg="white"
            roundedBottomStart="lg"
            roundedBottomEnd="lg"
          >
            <HStack>
              <Image w={3} src={Lock} />
              <Text>Privacidade</Text>
            </HStack>
            <Box>
              <MdKeyboardArrowRight size={16} />
            </Box>
          </HStack>

          <HStack
            w="full"
            justifyContent="space-between"
            p={5}
            bg="white"
            roundedBottomStart="lg"
            roundedBottomEnd="lg"
          >
            <HStack>
              <Image w={2} src={Question} />
              <Text>Política de privacidade</Text>
            </HStack>
            <Box>
              <MdKeyboardArrowRight size={16} />
            </Box>
          </HStack>
        </VStack>
      </Flex>

      <Box w="full">{children}</Box>
    </HStack>
  );
};
