import { Button } from '@chakra-ui/button';
import { HStack, Text, VStack, Link as CLink } from '@chakra-ui/layout';
import { useToast } from '@chakra-ui/react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useEffect, useState } from 'react';
import { AiFillStar } from 'react-icons/ai';
import { BsStarHalf } from 'react-icons/bs';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

dayjs.extend(customParseFormat);

import { createSchedule } from '../../../../schedule-request/api';
import { useScheduleRequestStore } from '../../../store/schedule-request-store';

import { useFCM } from '@/context/AppProvider';
import { useRepairShopRating } from '@/modules/repair-shop';
import { formatDate } from '@/utils';

export interface RepairShopCardProps {
  // repairShop: RepairShop;
  setRepairShopCardOpen: any;
}

export const RepairShopCard = ({
  index,
  repairShop,
  setRepairShopCardOpen,
  setSelectedRepairShop,
}) => {
  // const { data: RepairShopEvaluations } = useRepairShopEvaluations({
  //  id: repairShop.repair_shop.id,
  //  config: { enabled: !!repairShop },
  // });

  const toast = useToast();
  const { setScheduleRequest: resetScheduleRequest } = useFCM();
  const [user] = useState(() => !!localStorage.getItem('@user'));
  const parsedUser = JSON.parse(localStorage.getItem('@user') || '{}');

  const navigate = useNavigate();

  const [scheduleRequest, setScheduleRequest] = useState(() => {
    const scheduleRequest = localStorage.getItem('@schedule-request');
    const parsedScheduleRequest = scheduleRequest && JSON.parse(scheduleRequest);
    return parsedScheduleRequest;
  });

  const { data: repairShopRating } = useRepairShopRating({
    id: repairShop.repair_shop.id,
    config: { enabled: !!repairShop },
  });

  const renderStars = (rating) => {
    const stars: any[] = [];
    for (let i = 0; i < rating; i++) {
      if (Math.trunc(rating - i) > 0) {
        stars.push(<AiFillStar key={i} size={20} color="#FFC107" />);
      } else {
        stars.push(<BsStarHalf key={i} style={{ marginTop: -1 }} size={18} color="#FFC107" />);
      }
    }
    return stars;
  };

  useEffect(() => {
    setScheduleRequest(() => {
      const scheduleRequest = localStorage.getItem('@schedule-request');
      const parsedScheduleRequest = scheduleRequest && JSON.parse(scheduleRequest);
      return parsedScheduleRequest;
    });
  }, []);

  const {
    actions: { reset },
  } = useScheduleRequestStore();

  const handleCreateSchedule = async () => {
    if (!user) {
      window.location.href = '/entrar/sc';
    } else {
      if (parsedUser && scheduleRequest) {
        try {
          await createSchedule({
            status: 'started',
            repair_shop: repairShop.repair_shop.id,
            user: parsedUser.id,
            schedule_request: scheduleRequest.id,
          });

          toast({
            title: 'Agendamento criado com sucesso.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });

          localStorage.removeItem('@repair-shops');
          localStorage.removeItem('@schedule-request');
          resetScheduleRequest(null);

          reset();

          navigate('/agendamentos');
        } catch (error) {
          toast({
            title: 'Erro ao criar agendamento. Tente novamente.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      }
    }
  };

  return (
    <VStack
      id={`repair-shop-${index}`}
      alignItems="flex-start"
      bg="white"
      rounded="lg"
      shadow="md"
      p={3}
      spacing={2}
      w="full"
      mx="auto"
      h="full"
    >
      {repairShop && (
        <>
          <VStack mb={5} w="full" alignItems="flex-start">
            <HStack flexDir={{ base: 'column', md: 'row' }} w="full" justify="space-between" mb={5}>
              <Text fontWeight="bold" fontSize="md">
                {repairShop.repair_shop.name}
              </Text>
              <HStack>{repairShopRating?.rating && renderStars(repairShopRating.rating)}</HStack>
            </HStack>

            <HStack
              mt="0px !important"
              flexDir={{ base: 'column', md: 'row' }}
              w="full"
              justify="space-between"
            >
              <Text fontSize="sm">
                {`${repairShop.repair_shop.address.street}, ${repairShop.repair_shop.address.number}`}
              </Text>
              <VStack align="flex-end">
                <Text fontSize="sm">Aberto das</Text>
                <Text
                  mt="0px !important"
                  fontSize="sm"
                >{`${repairShop.repair_shop.open} às ${repairShop.repair_shop.closed}`}</Text>
              </VStack>
            </HStack>
          </VStack>

          <VStack w="full" alignItems="flex-start">
            <Text fontWeight="bold">Horários disponíveis</Text>
            <Text fontSize="smaller">Está oficina tem o horário selecionado por você!</Text>

            <VStack
              p={2}
              rounded="md"
              bg="brand.400"
              shadow="sm"
              spacing={1}
              justifyContent="center"
            >
              <Text fontSize="sm">
                {formatDate(repairShop.schedule_request.preferred_date.slice(0, 10))}
              </Text>
              <Text fontWeight="bold" fontSize="sm">
                {repairShop.schedule_request.preferred_date.slice(11)}
              </Text>
            </VStack>
          </VStack>

          <VStack w="full">
            <HStack mt={5} w="full" justifyContent="space-between">
              <Button variant="@primary" onClick={handleCreateSchedule} size="sm">
                Agendar
              </Button>
              <Button
                variant="@colorless"
                size="sm"
                onClick={() => {
                  setRepairShopCardOpen(true);
                  setSelectedRepairShop(repairShop);
                }}
              >
                Ver perfil
              </Button>
            </HStack>

            {!user && (
              <Text
                textAlign="center"
                flexDirection="column"
                alignItems="center"
                mx="auto"
                w="full"
                fontSize={13}
                mt={5}
              >
                <CLink fontWeight="bold" textDecoration="underline">
                  <Link to="/entrar/sc">Entre</Link>
                </CLink>{' '}
                com a sua conta para agendar!
              </Text>
            )}
          </VStack>
        </>
      )}
    </VStack>
  );
};
