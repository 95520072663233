/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, Button, VStack, HStack, Text, Skeleton, useToast } from '@chakra-ui/react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useCallback, useEffect, useState } from 'react';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import NumberFormat from 'react-number-format';

import { createBudgetPart, deleteBudget, updateBudget } from '../../api';
import { useBudgetRequestParts } from '../../hooks';
import { budgetLabelMap, budgetActionButtonMap } from '../../utils';
import { PartComponent } from '../PartComponent';

import { useFCM } from '@/context/AppProvider';
import { readNotification } from '@/modules/consumer';
import { getTimePassed } from '@/utils';

dayjs.extend(customParseFormat);

export const BudgetRequest = ({ selectedSchedule, setSelectedSchedule, isUnread }) => {
  const { data: budgetRequestParts, isLoading } = useBudgetRequestParts({
    id: selectedSchedule.budget_request.id,
    config: { enabled: !!selectedSchedule },
  });

  const [transport, setTransport] = useState<any>(selectedSchedule.shipping_price);
  const [days, setDays] = useState(selectedSchedule.shipping_time || 0);
  const [budgetParts, setBudgetParts] = useState<any>([]);
  const [partsValue, setPartsValue] = useState<any>([]);
  const [anyNull, setAnyNull] = useState(false);

  const { refetchUserNotifications, storeBudgetRequests, refetchStoreBudgetRequests } = useFCM();

  const [socketList] = useState(storeBudgetRequests);
  const toast = useToast();

  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const partsTotal = partsValue?.reduce((acc, value) => {
      return acc + value;
    }, 0);

    setTotal(partsTotal + Number(transport));
  }, [partsValue, transport]);

  const handleCreateBudget = async () => {
    setLoading(true);
    try {
      budgetParts.forEach(async (budgetPart) => {
        await createBudgetPart({
          budget_request_part: budgetPart.id,
          budget: selectedSchedule.id,
          quantity: budgetPart.quantity,
          price: budgetPart.selectedValue,
          manufacturer: budgetPart.selectedManufacturer,
        });
      });

      await updateBudget(selectedSchedule.id, {
        status: 'BUDGETED',
        shipping_price: transport,
        shipping_time: days,
      });

      toast({
        title: 'Diagnóstico realizado com sucesso.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setSelectedSchedule(null);

      setLoading(false);
    } catch (error) {
      toast({
        title: 'Erro ao criar diagnóstico. Tente novamente.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const currencyFormatter = (value) => {
    if (!Number(value)) return '';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  };

  useEffect(() => {
    setPartsValue(() => {
      return budgetRequestParts?.map((/* budgetPart */) => {
        // return Number(budgetPart.part.value) * budgetPart.quantity;
        return 0;
      });
    });
  }, [budgetRequestParts]);

  useEffect(() => {
    setAnyNull(() => budgetParts?.some((item: any) => item?.selectedManufacturer === ''));
  }, [budgetParts]);

  const markNotificationAsRead = useCallback(async () => {
    if (isUnread?.length > 0) {
      const readingNotifications = isUnread?.map(
        async (notification) => await readNotification(notification),
      );

      await Promise.all(readingNotifications).then(() => refetchUserNotifications());
    }
  }, [isUnread, refetchUserNotifications]);

  useEffect(() => {
    markNotificationAsRead();
  }, []);

  useEffect(() => {
    if (socketList !== storeBudgetRequests) {
      setSelectedSchedule && setSelectedSchedule(null);
    }
  }, [storeBudgetRequests]);

  return (
    <Flex w="full" flexDirection="column">
      <Button
        variant="ghost"
        leftIcon={<MdKeyboardArrowLeft />}
        alignSelf="flex-start"
        pl="0"
        mb="4"
        onClick={() => {
          if (setSelectedSchedule) setSelectedSchedule(null);
        }}
      >
        voltar
      </Button>

      <VStack>
        <HStack
          flexDirection={{ base: 'column', sm: 'row' }}
          align="flex-start"
          justify="space-between"
          spacing={{ base: 2, md: 0 }}
          w="full"
          rounded="md"
          bg="white"
          p="4"
        >
          <HStack mb={{ base: 3, sm: 0 }} mr={3}>
            <Text fontWeight="bold" color="brand.500" whiteSpace="nowrap">
              {`${getTimePassed(selectedSchedule.created_at)} atrás`}
            </Text>
            <Text color="gray.400" whiteSpace="nowrap">
              {dayjs(selectedSchedule.created_at).format('DD/MM/YYYY, HH:mm')}
            </Text>
          </HStack>

          <HStack marginInlineStart="0px !important" marginStart="0px !important" w="fit-content">
            <Text
              ml={6}
              whiteSpace="nowrap"
              pos="relative"
              _before={{
                content: '""',
                width: 4,
                height: 4,
                zIndex: 1000,
                pos: 'absolute',
                top: '50%',
                left: -6,
                transform: 'translateY(-50%)',
                border: '3px solid',
                borderColor: 'brand.500',
                rounded: 'full',
              }}
            >
              {(selectedSchedule && budgetLabelMap[selectedSchedule.status]) || '--'}
            </Text>
          </HStack>
        </HStack>

        <VStack w="full" p={4} rounded="md" bg="white" alignItems="flex-start" flex="1" spacing="5">
          <HStack w="full" justify={{ base: 'space-between', lg: 'flex-start' }}>
            <Text>Veículo</Text>
            <Text fontWeight="bold">
              {selectedSchedule.budget_request.car
                ? `${selectedSchedule.budget_request.car.model.manufacturer.name} ${selectedSchedule.budget_request.car.model.name}`
                : 'Veículo apagado'}
            </Text>
          </HStack>
          <HStack w="full" justify={{ base: 'space-between', lg: 'flex-start' }}>
            <Text>Cliente</Text>
            <Text fontWeight="bold">{selectedSchedule.budget_request.customer.name}</Text>
          </HStack>

          <HStack w="full" justify={{ base: 'space-between', lg: 'flex-start' }}>
            <Text>Distância</Text>
            <Text fontWeight="bold">8 km</Text>
          </HStack>
          <HStack
            w="full"
            align="flex-start"
            justify={{ base: 'space-between', lg: 'flex-start' }}
            flexDir={{ base: 'column', sm: 'row' }}
          >
            <Text mb={{ base: 3, sm: 0 }} mr={{ base: 0, sm: 3 }}>
              Valor do frete
            </Text>

            <HStack
              marginInlineStart="0px !important"
              marginStart="0px !important"
              w="xm"
              rounded="md"
            >
              <NumberFormat
                className="custom-input"
                allowNegative={false}
                onValueChange={(value) => {
                  setTransport(
                    Number(
                      value.formattedValue.replace('R$ ', '').replace('.', '').replace(',', '.'),
                    ),
                  );
                }}
                decimalSeparator=","
                thousandSeparator="."
                fixedDecimalScale
                format={currencyFormatter}
                prefix="R$ "
                defaultValue={transport}
              />
            </HStack>
          </HStack>

          <HStack
            w="full"
            align="flex-start"
            justify={{ base: 'space-between', lg: 'flex-start' }}
            flexDir={{ base: 'column', sm: 'row' }}
          >
            <Text mb={{ base: 3, sm: 0 }} mr={{ base: 0, sm: 3 }}>
              Tempo de entrega
            </Text>

            <HStack
              marginInlineStart="0px !important"
              marginStart="0px !important"
              w="xm"
              rounded="md"
            >
              <NumberFormat
                className="custom-input"
                allowNegative={false}
                onValueChange={(value) => {
                  setDays(value.formattedValue.replace(' horas', ''));
                }}
                suffix=" horas"
              />
            </HStack>
          </HStack>
        </VStack>

        <VStack w="full" p={4} rounded="md" bg="white" alignItems="flex-start" flex="1" spacing="5">
          <Text fontSize="md" fontWeight="bold">
            Peças
          </Text>

          <VStack w="full">
            {isLoading && !budgetRequestParts ? (
              <Skeleton w="full" height={8} borderRadius={20} />
            ) : (
              [...budgetRequestParts]?.map((part: any, index) => (
                <PartComponent
                  key={part.id}
                  selectedSchedule={selectedSchedule}
                  part={part}
                  budgetParts={budgetParts}
                  setBudgetParts={setBudgetParts}
                  partsValue={partsValue}
                  setPartsValue={setPartsValue}
                  index={index}
                />
              ))
            )}
          </VStack>
        </VStack>

        <VStack w="full" p={4} rounded="md" bg="white" flex="1" align="flex-start" spacing="5">
          <HStack w="full" justify="space-between">
            <Text fontSize="md" fontWeight="bold">
              Total
            </Text>

            {!total ? (
              <Skeleton w="sm" height={8} borderRadius={20} />
            ) : (
              <Text fontSize="md" fontWeight="bold">
                {total.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Text>
            )}
          </HStack>
        </VStack>

        <VStack w="full" p={4} flex="1" align="center" spacing="5">
          <Button
            isDisabled={
              budgetParts.length === 0 ||
              days === '' ||
              transport === '' ||
              transport === 0 ||
              anyNull
            }
            onClick={handleCreateBudget}
            variant="@primary"
            isLoading={loading}
          >
            {selectedSchedule && budgetActionButtonMap[selectedSchedule.status]?.action?.label}
          </Button>

          <Button
            onClick={async () => {
              await deleteBudget(selectedSchedule.id);
              refetchStoreBudgetRequests();
              setSelectedSchedule(null);
            }}
            size="sm"
            variant="solid"
            bg="white"
          >
            Cancelar orçamento
          </Button>
        </VStack>
      </VStack>
    </Flex>
  );
};
