import { useQuery } from 'react-query';

import { getMistakes } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseMistakesOptions = {
  config?: QueryConfig<typeof getMistakes>;
};

export const useMistakes = ({ config }: UseMistakesOptions) => {
  return useQuery(['getMistakes'], getMistakes, config);
};
