import axios from '@/lib/axios';

type Car = {
  plate: string;
  model: { id: number; name: string };
  manufacturer: { id: number; name: string };
  year: string;
  user?: number;
};

export const queue = {
  addCar: (car: Car) => {
    const queue = window.localStorage.getItem('@queue');

    if (queue) {
      const parsedQueue = JSON.parse(queue);
      const aux = { ...parsedQueue, cars: [...parsedQueue.cars, car] };
      window.localStorage.setItem('@queue', JSON.stringify(aux));
    } else {
      window.localStorage.setItem('@queue', JSON.stringify({ cars: [car] }));
    }
  },
  removeCar: (car: Car) => {
    const queue = window.localStorage.getItem('@queue');

    if (queue) {
      const parsedQueue = JSON.parse(queue);
      const aux = { ...parsedQueue, cars: parsedQueue.cars.filter((c) => c.plate !== car.plate) };
      window.localStorage.setItem('@queue', JSON.stringify(aux));
    }
  },
  getCars: () => {
    const queue = window.localStorage.getItem('@queue');
    return queue ? JSON.parse(queue) : { cars: [] };
  },
  createCars: async () => {
    const queue = window.localStorage.getItem('@queue');
    const user = localStorage.getItem('@user');
    const parsedUser = user && JSON.parse(user);

    if (queue) {
      const parsedQueue = JSON.parse(queue);

      const queuePromises = parsedQueue.cars.map(async (car: Car) => {
        const aux = { model: car.model.id, plate: car.plate, year: car.year, user: parsedUser.id };
        await axios.authorized({}).post('/car-user/', aux);
      });

      await Promise.all(queuePromises);

      window.localStorage.removeItem('@queue');
    }
  },
};
