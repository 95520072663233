import { useQuery } from 'react-query';

import { getStoreBudgetEvaluations } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseStoreBudgetEvaluationsOptions = {
  id: number;
  config?: QueryConfig<typeof getStoreBudgetEvaluations>;
};

export const useStoreBudgetEvaluations = ({ id, config }: UseStoreBudgetEvaluationsOptions) => {
  return useQuery(['getStoreBudgetEvaluations', id], getStoreBudgetEvaluations, config);
};
