import { StarIcon } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/react';
import { useState } from 'react';

export const Rating = ({ rate, setRate }) => {
  const [hoverPos, setHoverPos] = useState<number>();

  return (
    <Box mb={3}>
      <StarIcon
        fontSize={24}
        stroke={1 <= rate ? 'brand.500' : 'gray.300'}
        mr={3}
        color={
          hoverPos
            ? hoverPos >= 1
              ? 'brand.500'
              : 'gray.300'
            : rate >= 1
            ? 'brand.500'
            : 'gray.300'
        }
        _hover={{ color: 'brand.500', stroke: 'brand.500', cursor: 'pointer' }}
        onClick={() => setRate(1)}
        onMouseOver={() => setHoverPos(1)}
        onMouseLeave={() => setHoverPos(undefined)}
      />
      <StarIcon
        fontSize={24}
        stroke={2 <= rate ? 'brand.500' : 'gray.300'}
        mr={3}
        color={
          hoverPos
            ? hoverPos >= 2
              ? 'brand.500'
              : 'gray.300'
            : rate >= 2
            ? 'brand.500'
            : 'gray.300'
        }
        _hover={{ color: 'brand.500', stroke: 'brand.500', cursor: 'pointer' }}
        onClick={() => setRate(2)}
        onMouseOver={() => setHoverPos(2)}
        onMouseLeave={() => setHoverPos(undefined)}
      />
      <StarIcon
        fontSize={24}
        stroke={3 <= rate ? 'brand.500' : 'gray.300'}
        mr={3}
        color={
          hoverPos
            ? hoverPos >= 3
              ? 'brand.500'
              : 'gray.300'
            : rate >= 3
            ? 'brand.500'
            : 'gray.300'
        }
        _hover={{ color: 'brand.500', stroke: 'brand.500', cursor: 'pointer' }}
        onClick={() => setRate(3)}
        onMouseOver={() => setHoverPos(3)}
        onMouseLeave={() => setHoverPos(undefined)}
      />
      <StarIcon
        fontSize={24}
        stroke={4 <= rate ? 'brand.500' : 'gray.300'}
        mr={3}
        color={
          hoverPos
            ? hoverPos >= 4
              ? 'brand.500'
              : 'gray.300'
            : rate >= 4
            ? 'brand.500'
            : 'gray.300'
        }
        _hover={{ color: 'brand.500', stroke: 'brand.500', cursor: 'pointer' }}
        onClick={() => setRate(4)}
        onMouseOver={() => setHoverPos(4)}
        onMouseLeave={() => setHoverPos(undefined)}
      />
      <StarIcon
        fontSize={24}
        stroke={5 <= rate ? 'brand.500' : 'gray.300'}
        mr={3}
        color={
          hoverPos
            ? hoverPos >= 5
              ? 'brand.500'
              : 'gray.300'
            : rate >= 5
            ? 'brand.500'
            : 'gray.300'
        }
        _hover={{ color: 'brand.500', stroke: 'brand.500', cursor: 'pointer' }}
        onClick={() => setRate(5)}
        onMouseOver={() => setHoverPos(5)}
        onMouseLeave={() => setHoverPos(undefined)}
      />
    </Box>
  );
};
