import { Button } from '@chakra-ui/button';
import { Heading, HStack, Text, VStack } from '@chakra-ui/layout';
import { IoIosArrowRoundBack } from 'react-icons/io';

import { MotionBox } from '@/components/common/MotionBox';
import { useFCM } from '@/context/AppProvider';

export const ScheduleRequestSelectRepairShopHeader = ({
  repairShopCardOpen,
  setRepairShopCardOpen,
  ...restProps
}) => {
  const { scheduleRequestResponse } = useFCM();
  return (
    <VStack
      spacing={2}
      alignItems="flex-start"
      bg="white"
      justifyContent="flex-start"
      p={5}
      rounded="lg"
      shadow="lg"
      w="full"
      {...restProps}
      // d={{ base: 'none', lg: 'flex' }}
    >
      <HStack alignItems={repairShopCardOpen ? 'center' : 'flex-start'} overflowX="hidden">
        {repairShopCardOpen ? (
          <>
            <MotionBox
              initial={{ x: '-100%' }}
              animate={{ x: 0 }}
              transition={{ delay: 0, duration: 0.5 }}
            >
              <Button
                variant="ghost"
                leftIcon={<IoIosArrowRoundBack />}
                onClick={() => setRepairShopCardOpen(false)}
                size="xs"
                px={3}
              >
                Voltar
              </Button>
            </MotionBox>
            <Heading as="p" fontFamily="gill" w="100%" fontSize="lg" textAlign="left">
              Perfil da oficina
            </Heading>
          </>
        ) : (
          <VStack alignItems="flex-start" transition="all .2s ease-in-out">
            <Heading as="p" fontFamily="gill" w="100%" fontSize="lg" textAlign="left">
              Oficinas próximas a você
            </Heading>

            <Text textAlign="left" mt={2} color="gray.500" fontSize="sm">
              {`${scheduleRequestResponse?.length || 0} oficinas encontradas`}
            </Text>
          </VStack>
        )}
      </HStack>
    </VStack>
  );
};
