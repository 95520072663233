export const calculateTime = (dateStart, dateEnd, full = false) => {
  const start = new Date(dateStart).getTime();
  const end = new Date(dateEnd).getTime();
  const now = new Date().getTime();

  const difference = end - start;
  const timeLeft = start - now;

  const hours = Math.floor(difference / (1000 * 60 * 60));
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

  return full
    ? {
        difference: `${hours} horas e ${minutes} minutos`,
        timeLeft: `${Math.floor(timeLeft / (1000 * 60 * 60))} horas e ${Math.floor(
          (timeLeft % (1000 * 60 * 60)) / (1000 * 60),
        )} minutos`,
        timeLeftPercentage: Math.floor((timeLeft * 100) / start),
      }
    : {
        difference: `${hours}h${minutes}m`,
        timeLeft: `${Math.floor(timeLeft / (1000 * 60 * 60))}h${Math.floor(
          (timeLeft % (1000 * 60 * 60)) / (1000 * 60),
        )}m`,
        timeLeftPercentage: Math.floor((timeLeft * 100) / start),
      };
};
