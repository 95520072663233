/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import ReactHowler from 'react-howler';

import { NewSolicitationCard } from './NewSolicitationCard';

import { useFCM } from '@/context/AppProvider';

export const NewSolicitations = ({ activeTab }) => {
  const { scheduleRequests, refetchScheduleRequests } = useFCM();

  const [play, setPlay] = useState(false);

  const playSound = () => {
    setPlay(true);
    setTimeout(() => setPlay(false), 3000);
  };

  useEffect(() => {
    activeTab === 'schedule-requests' && refetchScheduleRequests();
  }, [activeTab]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetchScheduleRequests();

      if (scheduleRequests?.length > 0) {
        playSound();
      }
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (scheduleRequests?.length > 0) playSound();
  }, [scheduleRequests]);

  return (
    <Flex w="full" alignItems="flex-start" maxW="full" flexWrap="wrap">
      <ReactHowler src="https://mycar-api-sta.s3.amazonaws.com/mycar.mp3" playing={play} />
      {scheduleRequests?.map((solicitation: any) => (
        <NewSolicitationCard key={solicitation.id} solicitation={solicitation} />
      ))}
    </Flex>
  );
};
