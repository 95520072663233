import { VStack } from '@chakra-ui/layout';
import { useMediaQuery } from '@chakra-ui/media-query';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { RepairShopCard } from './RepairShopCard';
import { ScheduleRequestSelectRepairShopHeader } from './ScheduleRequestSelectRepairShopHeader';

import { MotionBox } from '@/components/common/MotionBox';
import { useFCM } from '@/context/AppProvider';
import { RepairShopDetail } from '@/modules/repair-shop';

export const ScheduleRequestSelectRepairShopCards = ({
  repairShopCardOpen,
  setRepairShopCardOpen,
  selectedRepairShop,
  setSelectedRepairShop,
}) => {
  const [isSmallScreen] = useMediaQuery('(max-width: 1000px)');
  const { scheduleRequestResponse } = useFCM();

  return (
    <VStack
      cursor="default"
      spacing={3}
      minH="fit-content"
      h={{ base: '100%', lg: '90%' }}
      flex={0.6}
      mr={{ base: 0, lg: 3 }}
      mt={{ base: 3, lg: 0 }}
    >
      {!isSmallScreen && (
        <ScheduleRequestSelectRepairShopHeader
          repairShopCardOpen={repairShopCardOpen}
          setRepairShopCardOpen={setRepairShopCardOpen}
        />
      )}

      <VStack
        maxH="full"
        h="100%"
        w="full"
        pr={{ base: 0, lg: 0 }}
        pos="relative"
        overflowY={repairShopCardOpen ? 'hidden' : 'auto'}
      >
        {!isSmallScreen && repairShopCardOpen && (
          <MotionBox
            pos="absolute"
            initial={{ y: '100%' }}
            animate={{ y: 0 }}
            transition={{ delay: 0, duration: 0.5 }}
            w="full"
            h="full"
            zIndex="popover"
          >
            <VStack bg="white" w="full" h="full" rounded="md">
              <RepairShopDetail selectedRepairShop={selectedRepairShop} />
            </VStack>
          </MotionBox>
        )}

        {!repairShopCardOpen && (
          <VStack maxH={{ base: 'initial', lg: 'auto' }} overflowY="auto" w="full" pos="relative">
            {scheduleRequestResponse?.map((repairShop, index) => (
              <RepairShopCard
                index={index}
                key={repairShop.id}
                repairShop={repairShop}
                setRepairShopCardOpen={() => setRepairShopCardOpen(true)}
                setSelectedRepairShop={setSelectedRepairShop}
              />
            ))}
          </VStack>
        )}
      </VStack>
    </VStack>
  );
};
