import { Box } from '@chakra-ui/react';
import { useEffect, useState, useCallback } from 'react';

import { Map, Viewport, MarkerP } from '@/components/Map';

type MapContainerProps = {
  markers?: MarkerP[];
  longitude: number;
  latitude: number;
};

export const ScheduleRequestSelectRepairShopMap = ({
  markers,
  longitude,
  latitude,
}: MapContainerProps) => {
  const [viewport, setViewport] = useState<Viewport>({
    latitude,
    longitude,
    zoom: 15,
  });

  const [defaultMarker, setDefaultMarker] = useState<any>(null);

  useEffect(() => {
    setViewport({
      latitude,
      longitude,
      zoom: 15,
    });
    setDefaultMarker({
      latitude,
      longitude,
    });
  }, [latitude, longitude]);

  const handleViewportChange = useCallback((newViewport: Viewport) => setViewport(newViewport), []);

  return (
    <Box w="100%" height={{ base: '50vh', lg: '90vh' }}>
      <Map
        defaultMarker={defaultMarker}
        viewport={viewport}
        handleViewportChange={handleViewportChange}
        markers={markers}
      />
    </Box>
  );
};
