import { useQuery } from 'react-query';

import { getScheduleRequestCar } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseScheduleRequestCar = {
  id?: number;
  config?: QueryConfig<typeof getScheduleRequestCar>;
};

export const useScheduleRequestCar = ({ id, config }: UseScheduleRequestCar) => {
  return useQuery(['getScheduleRequestCar', id], getScheduleRequestCar, config);
};
