/* eslint-disable react-hooks/exhaustive-deps */
import { GridItem, HStack, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { AiOutlineClockCircle } from 'react-icons/ai';

import { ConsumerSchedules } from '../../api/types';

import { ScheduleCard } from './ScheduleCard';

import { useScroll, useFCM } from '@/context/AppProvider';

export interface SchedulesListProps {
  consumerSchedules?: ConsumerSchedules[];
  refetch?: any;
}

export const SchedulesList = ({ consumerSchedules, refetch }: SchedulesListProps) => {
  const { scrollRef } = useScroll();
  const { userNotifications, refetchUserNotifications } = useFCM();
  const [scheduleNotifications, setScheduleNotifications] = useState<any[]>([]);

  useEffect(() => {
    refetch();
    scrollRef?.current?.scrollToTop();
  }, []);

  useEffect(() => {
    setScheduleNotifications(
      userNotifications?.filter((notification) =>
        [
          'CUSTOMER_SCHEDULE',
          'DIAGNOSIS_IN_PROGRESS',
          'DIAGNOSIS_REALIZED',
          'DIAGNOSIS_APPROVED',
          'DIAGNOSIS_FINISHED',
        ].includes(notification.type),
      ),
    );
  }, [userNotifications]);

  useEffect(() => refetchUserNotifications(), []);

  return (
    <VStack spacing={6}>
      <HStack position="relative" alignSelf="flex-start">
        <AiOutlineClockCircle />
        <Text fontSize="xl" mr={3}>
          Meus agendamentos
        </Text>
        {scheduleNotifications?.length > 0 && (
          <Text
            color="black"
            fontSize="xs"
            w={5}
            h={5}
            display="flex"
            alignItems="center"
            textAlign="center"
            justifyContent="center"
            rounded="full"
            bg="brand.500"
          >
            {scheduleNotifications.length}
          </Text>
        )}
      </HStack>

      <SimpleGrid
        maxH={{ base: '80vh', md: '400px' }}
        w="full"
        overflowY="auto"
        columns={{ base: 1, lg: 2, xl: 3 }}
      >
        {consumerSchedules?.map((consumerSchedule: ConsumerSchedules) => (
          <GridItem key={consumerSchedule.id}>
            <ScheduleCard scheduleInfo={consumerSchedule} />
          </GridItem>
        ))}
      </SimpleGrid>
    </VStack>
  );
};
