import { Button, Flex, HStack, Text, useToast, VStack } from '@chakra-ui/react';
import { getAuth, updateEmail } from 'firebase/auth';
import { useState } from 'react';
import { CgProfile } from 'react-icons/cg';
import { ImageType } from 'react-images-uploading';

import { updateRepairShopUserEmail } from '../../api';

import { ProfileFormI } from './types';

import { FieldWrapper, Form, TextInput, UploadAvatarImage } from '@/components/Form';
import { firebaseApp } from '@/firebase';
import { getUser } from '@/modules/auth';
import { updateUser } from '@/modules/repair-shop';
import { getFormularyDiff } from '@/utils/getFormularyDiff';

export const ProfileForm = () => {
  const [profilePicture, setProfilePicture] = useState<ImageType | undefined>();
  const auth = getAuth(firebaseApp);
  const toast = useToast();

  const [user] = useState(() => {
    const user = localStorage.getItem('@user');
    const parsedUser = user && JSON.parse(user);

    return parsedUser || null;
  });

  const initialValues = {
    phone: user.phone,
    name: user.name,
    email: user.email,
    password: user.password,
  };

  return (
    <Flex w="full" flexDirection="column" alignItems="flex-start">
      <HStack alignSelf="flex-start" mb="7">
        <CgProfile size={16} />
        <Text fontSize="xl">Perfil</Text>
      </HStack>

      <Form<ProfileFormI>
        initialValues={initialValues}
        onSubmit={async (values) => {
          try {
            const diffValues = getFormularyDiff(initialValues, values);
            const formData = new FormData();
            for (const key in diffValues) {
              formData.set(key, diffValues[key]);
            }
            if (profilePicture?.file) {
              formData.set('profile_pic', profilePicture?.file);
            }

            await updateUser(user.id, formData);
            if (values.email?.length && auth.currentUser && user) {
              updateEmail(auth.currentUser, values.email);
              await updateRepairShopUserEmail(user.id, values.email);
            }

            const aux = await getUser();
            localStorage.setItem('@user', JSON.stringify(aux.user));

            toast({
              title: 'Perfil atualizado com sucesso.',
              status: 'success',
              duration: 5000,
              isClosable: true,
            });

            window.location.reload();
          } catch (error) {
            toast({
              title: 'Erro ao atualizar o perfil. Tente novamente.',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          }
        }}
      >
        {() => (
          <VStack bg="white" rounded="md" p={5} w="full" alignItems="flex-start" spacing="20px">
            <UploadAvatarImage
              initialSrc="https://chiptronic.com.br/blog/wp-content/uploads/2018/09/Donos-de-oficinas.jpg"
              onChange={(file) => setProfilePicture(file)}
            />

            <FieldWrapper
              name="name"
              boxProps={{
                w: 'full',
              }}
              label="Nome"
              as={(props) => <TextInput placeholder="Digite o nome" {...props} />}
            />

            <FieldWrapper
              name="email"
              boxProps={{
                w: 'full',
              }}
              label="Email"
              as={(props) => <TextInput {...props} placeholder="Digite o email" />}
            />
            <FieldWrapper
              name="password"
              boxProps={{
                w: 'full',
              }}
              label="Senha"
              as={(props) => <TextInput {...props} placeholder="Digite a senha" />}
            />

            <Button mt={5} variant="@primary" type="submit">
              Salvar
            </Button>
          </VStack>
        )}
      </Form>
    </Flex>
  );
};
