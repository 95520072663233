import { useQuery } from 'react-query';

import { getDiagnosisPhotos } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseDiagnosisPhotosOptions = {
  id?: number;
  config?: QueryConfig<typeof getDiagnosisPhotos>;
};

export const useDiagnosisPhotos = ({ id, config }: UseDiagnosisPhotosOptions) => {
  return useQuery(['getDiagnosisPhotos', id], getDiagnosisPhotos, config);
};
