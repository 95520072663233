/* eslint-disable react-hooks/exhaustive-deps */

import { GridItem, HStack, SimpleGrid, Text, VStack } from '@chakra-ui/react';

import { useRepairShopEvaluations } from '../../hooks';

import { EvaluationCard } from './EvaluationCard';

import { useAuth } from '@/context/AppProvider';

export const EvaluationsList = () => {
  const { repairShop } = useAuth();

  const { data: repairShopEvaluations, refetch: refetchEvaluation } = useRepairShopEvaluations({
    id: repairShop?.id,
    config: { enabled: !!repairShop },
  });

  return (
    <VStack spacing={6}>
      <HStack alignSelf="flex-start">
        <Text fontSize="xl">Suas avaliações</Text>
      </HStack>

      <SimpleGrid
        maxH={{ base: '80vh', md: '800px' }}
        w="full"
        overflowY="auto"
        columns={{ base: 1, lg: 1, xl: 2 }}
      >
        {repairShopEvaluations?.map((evaluation) => (
          <GridItem key={evaluation.id}>
            <EvaluationCard evaluation={evaluation} refetchEvaluation={refetchEvaluation} />
          </GridItem>
        ))}
      </SimpleGrid>
    </VStack>
  );
};
