import { theme } from '@chakra-ui/react';

import tailwindColors from './tailwindColors';

export const colors = {
  ...theme.colors,
  ...tailwindColors,
  brand: {
    50: '#fef4df',
    100: '#fce4ad',
    200: '#fbdc95',
    300: '#fbd47c',
    400: '#facb63',
    500: '#F8BB32',
    600: '#efa808',
    700: '#d69607',
    800: '#be8506',
    900: '#8c6205',
  },
  secondary: {
    50: 'rgba(219, 86, 20, 1)',
    100: 'rgba(173, 46, 16, 1)',
    200: 'rgba(150, 42, 14, 1)',
    300: '#7e0b0b',
    400: '#670909',
    500: '#380505',
    600: '#090101',
    700: '#000000',
    800: '#000000',
    900: '#000000',
  },
  brown: {
    100: '#613939',
    300: '#552929',
    500: '#370505',
  },
  gray: tailwindColors.blueGray,
  success: theme.colors.green,
  error: theme.colors.red,
  warning: theme.colors.yellow,
};
