import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { getWeekDay } from './getWeekDay';

dayjs.extend(customParseFormat);

export const formatDate = (date, type = 'simple') => {
  const formattedDate = dayjs(date, 'DD/MM/YYYY');

  return type === 'full'
    ? `${getWeekDay(formattedDate.toDate().getDay())}, ${date}`
    : `${getWeekDay(formattedDate.toDate().getDay())}, ${formattedDate.format('DD/MM')}`;
};
