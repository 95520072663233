import { useQuery } from 'react-query';

import { getStore } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseStoreOptions = {
  id?: number;
  config?: QueryConfig<typeof getStore>;
};

export const useStore = ({ id, config }: UseStoreOptions) => {
  return useQuery(['getStore', id], getStore, config);
};
