import { ConsumerSidebar, ProfileForm } from '../components';

import { Page } from '@/components/Page';

export const Profile = () => {
  return (
    <Page title="Perfil da oficina" pos="relative" minH="100vh">
      <ConsumerSidebar>
        <ProfileForm />
      </ConsumerSidebar>
    </Page>
  );
};
