/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import ReactHowler from 'react-howler';

import { useScheduleRequests } from '../../modules/schedule-request/hooks';

import { messaging } from '@/firebase';
import { useBudgetRequestResponse } from '@/modules/budget-request';
import {
  useConsumerBudgetRequests,
  useConsumerBudgets,
  useConsumerSchedules,
  useUserNotifications,
} from '@/modules/consumer';
import { useRepairShopSchedules } from '@/modules/repair-shop';
import { useScheduleRequestResponse } from '@/modules/schedule-request';
import { useStoreSchedules, useStoreSentBudgets } from '@/modules/store';

interface FCMContextProps {
  token: string | undefined;
  scheduleRequest: any;
  scheduleRequests: any;
  scheduleRequestResponse: any;
  budgetRequest: any;
  storeBudgetRequests: any;
  budgetRequestResponse: any;
  isLoadingBudgetRequestResponse: boolean;
  repairShopSchedules: any;
  storeSentBudgets: any;
  storeOrders: any;
  storeBudgetHistory: any;
  consumerBudgetRequests: any;
  consumerBudgets: any;
  consumerSchedules: any;
  userNotifications: any;
  setScheduleRequest: Dispatch<SetStateAction<any>>;
  setBudgetRequest: Dispatch<SetStateAction<any>>;
  refetchScheduleRequests: () => void;
  refetchScheduleRequestResponse: () => void;
  removeScheduleRequestResponse: () => void;
  refetchStoreBudgetRequests: () => void;
  refetchBudgetRequestResponse: () => void;
  removeBudgetRequestResponse: () => void;
  refetchRepairShopSchedules: () => void;
  refetchStoreSentBudgets: () => void;
  refetchStoreOrders: () => void;
  refetchStoreBudgetHistory: () => void;
  refetchConsumerBudgetRequests: () => void;
  refetchConsumerBudgets: () => void;
  refetchConsumerSchedules: () => void;
  refetchUserNotifications: () => void;
}

const FCMContext = createContext<FCMContextProps>({} as FCMContextProps);

export const FCMProvider: React.FC = ({ children }) => {
  const [token, setToken] = useState<string>();
  const [play, setPlay] = useState(false);

  const playSound = () => {
    setPlay(true);
    setTimeout(() => setPlay(false), 3000);
  };

  const [user, setUser] = useState(() => {
    const user = localStorage.getItem('@user');
    return user && JSON.parse(user);
  });

  const [repairShop, setRepairShop] = useState(() => {
    const repairShop = localStorage.getItem('@repair-shop');
    return repairShop && JSON.parse(repairShop);
  });

  const [store, setStore] = useState(() => {
    const store = localStorage.getItem('@store');
    return store && JSON.parse(store);
  });

  const [scheduleRequest, setScheduleRequest] = useState(() => {
    const request = localStorage.getItem('@schedule-request');
    return request && JSON.parse(request);
  });

  const [budgetRequest, setBudgetRequest] = useState(() => {
    const request = localStorage.getItem('@budget-request');
    return request && JSON.parse(request);
  });

  // =========== Repair Shop ============================================================
  const { data: repairShopSchedules, refetch: refetchRepairShopSchedules } = useRepairShopSchedules(
    {
      id: repairShop?.id,
      config: { enabled: !!repairShop },
    },
  );

  const { data: scheduleRequests, refetch: refetchScheduleRequests } = useScheduleRequests({
    config: { enabled: !!repairShop },
  });

  // =========== Store ============================================================
  const { data: storeBudgetRequests, refetch: refetchStoreBudgetRequests } = useStoreSchedules({
    id: store?.id,
    status: '?status=INIT',
    config: { enabled: !!store },
  });

  const { data: storeSentBudgets, refetch: refetchStoreSentBudgets } = useStoreSentBudgets({
    config: { enabled: !!store },
  });

  const { data: storeOrders, refetch: refetchStoreOrders } = useStoreSchedules({
    id: store?.id,
    status: '?status=PAID&status=SENT',
    config: { enabled: !!store },
  });

  const { data: storeBudgetHistory, refetch: refetchStoreBudgetHistory } = useStoreSchedules({
    id: store?.id,
    status: '?status=FINISHED',
    config: { enabled: !!store },
  });

  // =========== Consumer ============================================================
  const {
    data: budgetRequestResponse,
    refetch: refetchBudgetRequestResponse,
    remove: removeBudgetRequestResponse,
    isLoading: isLoadingBudgetRequestResponse,
  } = useBudgetRequestResponse({
    id: budgetRequest?.id,
    config: { enabled: !!budgetRequest },
  });

  const {
    data: scheduleRequestResponse,
    refetch: refetchScheduleRequestResponse,
    remove: removeScheduleRequestResponse,
  } = useScheduleRequestResponse({
    id: scheduleRequest?.id || '',
    config: { enabled: !!scheduleRequest && !!token },
  });

  const { data: consumerBudgetRequests, refetch: refetchConsumerBudgetRequests } =
    useConsumerBudgetRequests({
      config: { enabled: user?.groups?.some(({ name }) => name === 'customer') },
    });

  const { data: consumerBudgets, refetch: refetchConsumerBudgets } = useConsumerBudgets({
    status: '?status=WAIT&status=PAID&status=SENT&status=FINISHED',
    config: { enabled: user?.groups?.some(({ name }) => name === 'customer') },
  });

  const { data: consumerSchedules, refetch: refetchConsumerSchedules } = useConsumerSchedules({
    id: user?.id,
    config: { enabled: user?.groups?.some(({ name }) => name === 'customer') },
  });

  const { data: userNotifications, refetch: refetchUserNotifications } = useUserNotifications({
    config: { enabled: !!user },
  });

  const updateScheduleRequest = () => {
    const request = localStorage.getItem('@budget-request');

    if (request) {
      const parsedRequest = JSON.parse(request);
      const now = new Date().getTime();
      const start = new Date(parsedRequest.created_at).getTime();
      const scheduleRequestExpiration = 20 * 60 * 1000; // 20 minutes

      if (now - start > scheduleRequestExpiration) {
        removeScheduleRequestResponse();
        setScheduleRequest(null);
        localStorage.removeItem('@schedule-request');
      } else setBudgetRequest(parsedRequest);
    } else setBudgetRequest(null);
  };

  /* const updateBudgetRequest = () => {
    const request = localStorage.getItem('@schedule-request');
    setScheduleRequest(request && JSON.parse(request));
  }; */

  const updateRepairShop = () => {
    const repairShop = localStorage.getItem('@repair-shop');
    setRepairShop(repairShop && JSON.parse(repairShop));
  };

  const updateStore = () => {
    const store = localStorage.getItem('@store');
    setStore(store && JSON.parse(store));
  };

  const updateUser = () => {
    const user = localStorage.getItem('@user');
    setUser(user && JSON.parse(user));
  };

  const subscribeUserToTopics = async (userToken) => {
    const topics = ['schedule_request', 'schedule_request_response'];

    const topicsPromise = topics.map(async (topic) => {
      return await axios.post(
        `https://iid.googleapis.com/iid/v1/${userToken}/rel/topics/${topic}`,
        {},
        {
          headers: {
            Authorization: `key=${process.env.REACT_APP_FIREBASE_SERVER_KEY}`,
          },
        },
      );
    });

    return await Promise.all(topicsPromise);
  };

  useEffect(() => {
    updateScheduleRequest();
  }, []);

  useEffect(() => {
    (async () => {
      const token = await messaging.getToken();
      setToken(token);
      localStorage.setItem('@fcm-token', token);

      await subscribeUserToTopics(token);

      navigator.serviceWorker.addEventListener('message', (message) => {
        // const parsedData = JSON.parse(data);

        if (message?.data?.firebaseMessaging?.payload?.data) {
          const { type } = message.data.firebaseMessaging.payload.data;

          switch (type) {
            case 'schedule_request': // consumer fez solicitacao
              updateUser();

              //if (repairShop && parsedData.includes(repairShop.id)) {
              refetchScheduleRequests();
              //}

              break;

            case 'schedule_request_mechanic': // mechanic recebe solicitacoes
              console.log('schedule_request_mechanic');
              updateRepairShop();

              //if (repairShop && parsedData.includes(repairShop.id)) {
              refetchScheduleRequests();
              //}

              break;

            case 'schedule_request_response': // consumer com resposta das oficinas
              updateScheduleRequest();
              updateUser();

              //if (scheduleRequest && parsedData === scheduleRequest.id) {
              refetchScheduleRequestResponse();
              //}

              break;

            case 'schedule_response': // consumer atualizacao dos agendamentos
              updateUser();
              //if (repairShop && parsedData.includes(repairShop.id)) {
              // refetchRepairShopSchedules();
              // refetchScheduleRequests();
              //}

              refetchConsumerSchedules();

              break;

            case 'schedule_mechanic': // mechanic atualizacao dos agendamentos
              updateRepairShop();

              //if (repairShop && parsedData.includes(repairShop.id)) {
              refetchRepairShopSchedules();
              //}

              break;

            case 'repair_shop_changed_schedule_status': // mechanic muda status do schedule
              updateRepairShop();

              //if (repairShop && parsedData.includes(repairShop.id)) {
              refetchRepairShopSchedules();
              //}

              break;

            case 'user_changed_schedule_status': // user muda status do schedule
              updateUser();

              //if (user && user.groups.some(({ name }) => name === 'customer')) {
              refetchConsumerSchedules();
              //}
              break;

            case 'budget_request_customer': // customer cria budget request
              break;

            case 'budget_requests': // seller recebe budget requests
              updateStore();
              playSound();

              //if (store && parsedData.includes(store.id)) {
              refetchStoreBudgetRequests();
              //}

              break;

            case 'budget_budgeted': // consumer recebe novo orçamento pra a solicitação
              refetchBudgetRequestResponse();

              break;

            case 'budget_request_response': // seller recebe orçamentos enviados
              //if (budgetRequest && parsedData === budgetRequest.id) {
              refetchStoreSentBudgets();
              //}

              break;

            case 'user_changed_budget_status': // consumer atualizaçao no budget
              updateUser();

              //if (user && user.groups.some(({ name }) => name === 'customer')) {
              refetchConsumerBudgetRequests();
              refetchConsumerBudgets();
              refetchConsumerSchedules();
              //}

              break;

            case 'store_changed_budget_status': // store recebe atualizaçao no budget
              updateStore();
              playSound();

              //if (store && parsedData.includes(store.id)) {
              refetchStoreBudgetRequests();
              refetchStoreSentBudgets();
              refetchStoreOrders();
              refetchStoreBudgetHistory();
              //}

              break;

            default:
              break;
          }
        } else {
          const { type } = message.data.data;

          switch (type) {
            case 'schedule_request': // consumer fez solicitacao
              updateUser();

              //if (repairShop && parsedData.includes(repairShop.id)) {
              refetchScheduleRequests();
              //}

              break;

            case 'schedule_request_mechanic': // mechanic recebe solicitacoes
              console.log('schedule_request_mechanic');
              updateRepairShop();

              //if (repairShop && parsedData.includes(repairShop.id)) {
              refetchScheduleRequests();
              //}

              break;

            case 'schedule_request_response': // consumer com resposta das oficinas
              updateScheduleRequest();
              updateUser();

              //if (scheduleRequest && parsedData === scheduleRequest.id) {
              refetchScheduleRequestResponse();
              //}

              break;

            case 'schedule_response': // consumer atualizacao dos agendamentos
              updateUser();
              //if (repairShop && parsedData.includes(repairShop.id)) {
              // refetchRepairShopSchedules();
              // refetchScheduleRequests();
              //}

              refetchConsumerSchedules();

              break;

            case 'schedule_mechanic': // mechanic atualizacao dos agendamentos
              updateRepairShop();

              //if (repairShop && parsedData.includes(repairShop.id)) {
              refetchRepairShopSchedules();
              //}

              break;

            case 'repair_shop_changed_schedule_status': // mechanic muda status do schedule
              updateRepairShop();

              //if (repairShop && parsedData.includes(repairShop.id)) {
              refetchRepairShopSchedules();
              //}

              break;

            case 'user_changed_schedule_status': // user muda status do schedule
              updateUser();

              //if (user && user.groups.some(({ name }) => name === 'customer')) {
              refetchConsumerSchedules();
              //}
              break;

            case 'budget_request_customer': // customer cria budget request
              break;

            case 'budget_requests': // seller recebe budget requests
              updateStore();
              playSound();

              //if (store && parsedData.includes(store.id)) {
              refetchStoreBudgetRequests();
              //}

              break;

            case 'budget_budgeted': // consumer recebe novo orçamento pra a solicitação
              refetchBudgetRequestResponse();

              break;

            case 'budget_request_response': // seller recebe orçamentos enviados
              //if (budgetRequest && parsedData === budgetRequest.id) {
              refetchStoreSentBudgets();
              //}

              break;

            case 'user_changed_budget_status': // consumer atualizaçao no budget
              updateUser();

              //if (user && user.groups.some(({ name }) => name === 'customer')) {
              refetchConsumerBudgetRequests();
              refetchConsumerBudgets();
              refetchConsumerSchedules();
              //}

              break;

            case 'store_changed_budget_status': // store atualizaçao no budget
              updateStore();
              playSound();

              //if (store && parsedData.includes(store.id)) {
              refetchStoreBudgetRequests();
              refetchStoreSentBudgets();
              refetchStoreOrders();
              refetchStoreBudgetHistory();
              //}

              break;

            default:
              break;
          }
        }

        refetchUserNotifications();
      });
    })();
  }, []);

  return (
    <FCMContext.Provider
      value={{
        token,
        scheduleRequest,
        setScheduleRequest,
        scheduleRequestResponse,
        refetchScheduleRequestResponse,
        scheduleRequests,
        refetchScheduleRequests,
        removeScheduleRequestResponse,
        budgetRequest,
        setBudgetRequest,
        storeBudgetRequests,
        refetchStoreBudgetRequests,
        budgetRequestResponse,
        refetchBudgetRequestResponse,
        removeBudgetRequestResponse,
        isLoadingBudgetRequestResponse,
        repairShopSchedules,
        refetchRepairShopSchedules,
        storeSentBudgets,
        refetchStoreSentBudgets,
        storeOrders,
        refetchStoreOrders,
        storeBudgetHistory,
        refetchStoreBudgetHistory,
        consumerBudgetRequests,
        refetchConsumerBudgetRequests,
        consumerBudgets,
        refetchConsumerBudgets,
        consumerSchedules,
        refetchConsumerSchedules,
        userNotifications,
        refetchUserNotifications,
      }}
    >
      <>
        <ReactHowler src="https://mycar-api-sta.s3.amazonaws.com/mycar.mp3" playing={play} />
        {children}
      </>
    </FCMContext.Provider>
  );
};

export const useFCM = (): FCMContextProps => {
  const context = useContext(FCMContext);

  if (!context) {
    throw new Error('useFCM must be used within a FCMProvider');
  }

  return context;
};
