import { VStack, HStack, Avatar, Box, Text, Image } from '@chakra-ui/react';
import { useState } from 'react';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';

import Lock from '../../../../assets/Lock.svg';
import Question from '../../../../assets/Question.svg';
import Star from '../../../../assets/Star.svg';
import Profile from '../../../../assets/User.svg';

export const ConsumerSidebar = ({ children }) => {
  const [user] = useState(() => {
    const user = localStorage.getItem('@user');
    const parsedUser = user && JSON.parse(user);

    return parsedUser;
  });

  return (
    <HStack alignItems="flex-start">
      <VStack
        flexDirection="column"
        w="350px"
        rounded="md"
        shadow="md"
        d={{ base: 'none', lg: 'flex' }}
        marginRight={10}
        color="black"
      >
        <HStack w="full" bg="white" p={5} roundedTopStart="lg" roundedTopEnd="lg">
          <HStack alignItems="center">
            <Avatar />
          </HStack>

          <VStack color="black" alignItems="flex-start" w="full" spacing="1">
            <Text textAlign="left" fontSize="md">
              {user?.name}
            </Text>
          </VStack>
        </HStack>

        <VStack color="black" mt={0} w="full">
          <HStack
            as={Link}
            to="/perfil"
            w="full"
            justifyContent="space-between"
            p={5}
            bg="white"
            roundedBottomStart="lg"
            roundedBottomEnd="lg"
          >
            <HStack>
              <Image w={3} src={Profile} />
              <Text>Perfil</Text>
            </HStack>
            <Box>
              <MdKeyboardArrowRight size={16} />
            </Box>
          </HStack>

          <HStack
            p={5}
            as={Link}
            to="/pedidos"
            w="full"
            justifyContent="space-between"
            bg="white"
            roundedBottomStart="lg"
            roundedBottomEnd="lg"
          >
            <HStack>
              <AiOutlineClockCircle size={16} />
              <Text>Meus pedidos</Text>
            </HStack>
            <Box>
              <MdKeyboardArrowRight size={16} />
            </Box>
          </HStack>

          <HStack
            p={5}
            as={Link}
            to="/agendamentos"
            w="full"
            justifyContent="space-between"
            bg="white"
            roundedBottomStart="lg"
            roundedBottomEnd="lg"
          >
            <HStack>
              <AiOutlineClockCircle size={16} />
              <Text>Meus agendamentos</Text>
            </HStack>
            <Box>
              <MdKeyboardArrowRight size={16} />
            </Box>
          </HStack>

          <HStack
            p={5}
            as={Link}
            to="/avaliacoes"
            w="full"
            justifyContent="space-between"
            bg="white"
            roundedBottomStart="lg"
            roundedBottomEnd="lg"
          >
            <HStack>
              <Image src={Star} w={3} />
              <Text>Minhas avaliações</Text>
            </HStack>
            <Box>
              <MdKeyboardArrowRight size={16} />
            </Box>
          </HStack>

          <HStack
            w="full"
            justifyContent="space-between"
            p={5}
            bg="white"
            roundedBottomStart="lg"
            roundedBottomEnd="lg"
          >
            <HStack>
              <Image w={3} src={Lock} />
              <Text>Privacidade</Text>
            </HStack>
            <Box>
              <MdKeyboardArrowRight size={16} />
            </Box>
          </HStack>

          <HStack
            w="full"
            justifyContent="space-between"
            p={5}
            bg="white"
            roundedBottomStart="lg"
            roundedBottomEnd="lg"
          >
            <HStack>
              <Image w={2} src={Question} />
              <Text>Política de privacidade</Text>
            </HStack>
            <Box>
              <MdKeyboardArrowRight size={16} />
            </Box>
          </HStack>
        </VStack>
      </VStack>

      <Box w="full">{children}</Box>
    </HStack>
  );
};
