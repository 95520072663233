import { Button, Text } from '@chakra-ui/react';
import { useParams } from 'react-router';

import { LoginCredentials } from '../../api/types';

import schema from './schema';

import { Link } from '@/components/common/Link';
import { FieldWrapper, Form, TextInput } from '@/components/Form';
import * as LC from '@/components/LC';
import { useAuth } from '@/context/AppProvider';

type LoginFormProps = {
  onSuccess: () => void;
  onError: (message: string) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  redirect?: boolean;
};

export const LoginForm = ({ loading, setLoading, onSuccess, onError }: LoginFormProps) => {
  const { login } = useAuth();
  const { redirect } = useParams();

  return (
    <>
      {/* <Button variant="social" mb={6} mx="auto" leftIcon={<FcGoogle size={20} />}>
        Entre com o Google
      </Button> */}
      <Form<LoginCredentials>
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={async (values) => {
          setLoading(true);
          login(values, onSuccess, onError, redirect);
        }}
        validationSchema={schema}
      >
        {() => (
          <LC.Vertical w="100%">
            <FieldWrapper
              name="email"
              required
              label="Email"
              as={(props) => <TextInput type="email" {...props} />}
            />
            <FieldWrapper
              name="password"
              required
              label="Senha"
              as={(props) => <TextInput type="password" {...props} />}
            />

            <LC.Vertical center spaceBetween mt={5}>
              <Button isLoading={loading} type="submit" className="w-full" variant="@primary">
                Entrar
              </Button>
              <Button size="sm" as={Link} to="../redefinir-senha" mt={4} variant="ghost">
                Esqueci minha senha
              </Button>
              <Button
                size="sm"
                as={Link}
                to={
                  redirect === 'sc'
                    ? '/cadastro/sc'
                    : redirect === 'bd'
                    ? '/cadastro/bd'
                    : '/cadastro'
                }
                mt={4}
                variant="ghost"
              >
                Não tem uma conta?
                <Text ml={1}>Criar meu cadastro</Text>
              </Button>
            </LC.Vertical>
          </LC.Vertical>
        )}
      </Form>
    </>
  );
};
