import { useQuery } from 'react-query';

import { getRepairShopServices, getRepairShopServicesWithoutPagination } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseRepairShopServicesOptions = {
  id?: number;
  config?: QueryConfig<typeof getRepairShopServices>;
};

export const useRepairShopServices = ({ id, config }: UseRepairShopServicesOptions) => {
  return useQuery(['getRepairShopServices', id], getRepairShopServicesWithoutPagination, config);
};
