import { Box, BoxProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { MotionBox } from '@/components/common/MotionBox';
import { Head } from '@/components/Head';

type PageProps = {
  children: ReactNode;
  title: string;
} & BoxProps;

export const Page = ({ children, title, ...restProps }: PageProps) => {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.5,
      },
    },
  };

  return (
    <MotionBox
      variants={container}
      initial="hidden"
      animate="show"
      maxW={{ base: 'container.md', lg: '900px', xl: '1200px', '2xl': 'container.xl' }}
      mx="auto"
    >
      <Head title={`${title}`} />
      <Box w="100%" pt={[16, 20, 24]} pb={6} px={{ base: 2 }} {...restProps}>
        <Box h="100%">{children}</Box>
      </Box>
    </MotionBox>
  );
};
