import { Button } from '@chakra-ui/button';
import { VStack } from '@chakra-ui/layout';
import { useToast, Skeleton, Text } from '@chakra-ui/react';
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from '@choc-ui/chakra-autocomplete';
import { Formik } from 'formik';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { createCar, getCarModels, getSpecificModelCars } from '../../../api/index';

import schema from './schema';

import { BoxWithTitle } from '@/components/common/BoxWithTitle';
import { FieldWrapper, TextInputMask } from '@/components/Form';
import { useScroll } from '@/context/AppProvider';
import { useInfinitePagination } from '@/hooks/useInfinitePagination';
import { useCarManufacturers } from '@/modules/budget-request';
import { queue } from '@/utils';

export const CreateCarForm = ({ setAddCarFormOpen, setCarLS, refetch, setSelectedCar }) => {
  const toast = useToast();
  const { scrollRef } = useScroll();
  const [manufacturer, setManufacturer] = useState<string>();
  const [model, setModel] = useState<string>();
  const user = localStorage.getItem('@user');
  const parsedUser = user && JSON.parse(user);

  const [carYears, setCarYears] = useState<any[]>();
  const [searchManufactures, setSearchManufactures] = useState('');
  const [searchCarModels, setSearchCarModels] = useState('');
  const [searchYear, setSearchYear] = useState('');
  const [manufacturerId, setManufacturerId] = useState<number>();
  const [carModelId, setCarModelId] = useState<number>();

  const {
    data: carManufacturers,
    fetchNextPage,
    hasNextPage,
  } = useCarManufacturers({
    search: searchManufactures,
  });

  const {
    data: carModels,
    fetchNextPage: nextPageModels,
    hasNextPage: hasNextPageModels,
  } = useInfinitePagination({
    tag: 'cars-models',
    request: getCarModels,
    id: manufacturerId,
    search: searchCarModels ?? '',
    config: { enabled: !!manufacturer },
  });

  const {
    data: allModelCars,
    isLoading: isLoadingModels,
    fetchNextPage: nextPageYears,
    hasNextPage: hasNextPageYears,
  } = useInfinitePagination({
    tag: 'all-models',
    request: getSpecificModelCars,
    id: carModelId,
    search: searchYear ?? '',
    config: { enabled: !!model },
  });

  useEffect(() => {
    setCarYears(allModelCars?.pages.flatMap((page) => page?.results).map((car) => car.year));
  }, [allModelCars]);

  const getModel = (modelName: string) => {
    return carModels?.pages
      .flatMap((page) => page?.results)
      .find((model: { id: number; name: string }) => {
        return modelName === model.name;
      });
  };

  const debouncedFunction = (e: any, set: Dispatch<SetStateAction<any>>) => {
    setTimeout(() => set(e.target.value), 300);
  };

  return (
    <Formik
      initialValues={{
        plate: '',
        model: '',
        year: '',
        manufacturer: '',
      }}
      validationSchema={schema}
      onSubmit={async (values, actions) => {
        if (parsedUser) {
          try {
            const car = await createCar({
              model: getModel(values.model)?.id,
              year: values.year,
              user: parsedUser.id,
              plate: values.plate,
            });

            setSelectedCar(car);

            setAddCarFormOpen((prev) => !prev);
            actions.resetForm();
            refetch();

            toast({
              title: 'Carro criado com sucesso.',
              status: 'success',
              duration: 5000,
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: 'Erro ao criar carro. Tente novamente.',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          }
        } else {
          const car = {
            model: getModel(values.model) || { id: 0, name: '' },
            manufacturer: { id: Number(values.manufacturer), name: manufacturer || '' },
            year: values.year,
            plate: values.plate,
          };

          queue.addCar(car);

          setCarLS(queue.getCars());

          setSelectedCar(car);

          setAddCarFormOpen((prev) => !prev);
          actions.resetForm();
        }

        scrollRef?.current?.scrollToTop();
      }}
    >
      {({ errors, setFieldValue, submitForm, getFieldProps }) => (
        <VStack spacing={5} justifyContent="flex-start" w="full">
          <BoxWithTitle
            title="Qual a placa do carro?"
            titleSize="sm"
            wrapperProps={{
              w: 'full',
            }}
          >
            <FieldWrapper
              name="plate"
              boxProps={{
                w: 'full',
              }}
              required
              as={(fieldProps) => (
                <TextInputMask
                  rounded="md"
                  mask="aaa9*99"
                  placeholder="Digite a placa do carro"
                  onChange={(e) => setFieldValue('plate', e.target.value.toUpperCase())}
                  {...fieldProps}
                />
              )}
            />
          </BoxWithTitle>

          <BoxWithTitle
            title="Qual é a fabricante do seu carro?"
            titleSize="sm"
            wrapperProps={{
              w: 'full',
            }}
          >
            <FieldWrapper
              name="manufacturer"
              boxProps={{
                w: 'full',
              }}
              required
              as={() => (
                <>
                  <AutoComplete
                    listAllValuesOnFocus
                    onChange={(e) => {
                      setManufacturer(e);
                      setFieldValue('manufacturer', e);
                      setFieldValue('model', null);
                      setFieldValue('year', null);
                      carManufacturers?.pages
                        ?.flatMap((page) => page?.results)
                        ?.forEach((manufacturer) => {
                          if (manufacturer && manufacturer.name === e) {
                            setManufacturerId(manufacturer.id);
                          }
                        });
                    }}
                    openOnFocus
                    emptyState={
                      <Text w="full" textAlign="center">
                        Fabricante não encontrado
                      </Text>
                    }
                  >
                    <AutoCompleteInput
                      marginTop="0px !important"
                      rounded="md"
                      placeholder="Selecionar fabricante"
                      variant="outline"
                      w="full"
                      onChange={(e) => debouncedFunction(e, setSearchManufactures)}
                    />
                    <AutoCompleteList
                      onScroll={(e) => {
                        const { clientHeight, scrollTop, scrollHeight } = e.target as HTMLElement;
                        const bottom = Math.ceil(clientHeight + scrollTop) >= scrollHeight;
                        if (bottom && hasNextPage) fetchNextPage();
                      }}
                    >
                      {carManufacturers?.pages
                        ?.flatMap((page) => page?.results)
                        ?.map(({ name }) => name)
                        .map((item) => {
                          return (
                            <AutoCompleteItem key={item} value={item}>
                              {item}
                            </AutoCompleteItem>
                          );
                        })}
                    </AutoCompleteList>
                  </AutoComplete>
                </>
              )}
            />
          </BoxWithTitle>

          {manufacturer && (
            <BoxWithTitle
              title="Qual é o modelo do seu carro?"
              titleSize="sm"
              wrapperProps={{
                w: 'full',
              }}
            >
              <FieldWrapper
                name="model"
                boxProps={{
                  w: 'full',
                }}
                required
                as={() => (
                  <>
                    {isLoadingModels ? (
                      <Skeleton h={8} w="full" />
                    ) : (
                      <AutoComplete
                        listAllValuesOnFocus
                        onChange={(e) => {
                          setModel(e);
                          setFieldValue('model', e);

                          carModels?.pages
                            .flatMap((page) => page?.results)
                            .forEach((carModel) => {
                              if (carModel.name === e) {
                                setCarModelId(carModel.name);
                              }
                            });
                        }}
                        defaultValue={getFieldProps('model').value}
                        openOnFocus
                        emptyState={
                          <Text w="full" textAlign="center">
                            Modelo não encontrado
                          </Text>
                        }
                      >
                        <AutoCompleteInput
                          marginTop="0px !important"
                          rounded="md"
                          placeholder="Selecionar modelo"
                          variant="outline"
                          w="full"
                          onChange={(e) => debouncedFunction(e, setSearchCarModels)}
                        />
                        <AutoCompleteList
                          onScroll={(e) => {
                            const { clientHeight, scrollTop, scrollHeight } =
                              e.target as HTMLElement;
                            const bottom = Math.ceil(clientHeight + scrollTop) >= scrollHeight;
                            if (bottom && hasNextPageModels) nextPageModels();
                          }}
                        >
                          {carModels?.pages
                            ?.flatMap((page) => page?.results)
                            ?.map((model) => model?.name)
                            ?.map((item) => {
                              return (
                                <AutoCompleteItem key={item} value={item}>
                                  {item}
                                </AutoCompleteItem>
                              );
                            })}
                        </AutoCompleteList>
                      </AutoComplete>
                    )}
                  </>
                )}
              />
            </BoxWithTitle>
          )}

          {model && (
            <BoxWithTitle
              title="Qual é o ano do carro?"
              titleSize="sm"
              wrapperProps={{
                w: 'full',
              }}
            >
              <FieldWrapper
                name="year"
                boxProps={{
                  w: 'full',
                }}
                required
                as={() => (
                  <>
                    {(carYears && carYears?.length <= 0) || !carYears ? (
                      <Skeleton h={8} w="full" />
                    ) : (
                      <AutoComplete
                        listAllValuesOnFocus
                        onChange={(e) => {
                          setFieldValue('year', e);
                        }}
                        defaultValue={getFieldProps('year').value}
                        openOnFocus
                        emptyState={
                          <Text w="full" textAlign="center">
                            Ano não encontrado
                          </Text>
                        }
                      >
                        <AutoCompleteInput
                          marginTop="0px !important"
                          rounded="md"
                          placeholder="Selecionar ano"
                          variant="outline"
                          w="full"
                          onChange={(e) => debouncedFunction(e, setSearchYear)}
                        />
                        <AutoCompleteList
                          onScroll={(e) => {
                            const { clientHeight, scrollTop, scrollHeight } =
                              e.target as HTMLElement;
                            const bottom = Math.ceil(clientHeight + scrollTop) >= scrollHeight;
                            if (bottom && hasNextPageYears) nextPageYears();
                          }}
                        >
                          {carYears?.map((year, index) => (
                            <AutoCompleteItem
                              key={`option-${index}`}
                              value={String(year)}
                              textTransform="capitalize"
                            >
                              {String(year)}
                            </AutoCompleteItem>
                          ))}
                        </AutoCompleteList>
                      </AutoComplete>
                    )}
                  </>
                )}
              />
            </BoxWithTitle>
          )}

          <Button
            onClick={submitForm}
            variant="@primary"
            disabled={!!Object.keys(errors).length}
            mt={0}
            alignSelf="flex-start"
            size="sm"
          >
            Adicionar carro
          </Button>
        </VStack>
      )}
    </Formik>
  );
};
