import { useInfiniteQuery } from 'react-query';

import { getCarManufacturers } from '../api';

export const useCarManufacturers = ({ search }) => {
  return useInfiniteQuery(
    ['getCarManufacturers', search],
    ({ pageParam = 1, queryKey }) => {
      return getCarManufacturers(pageParam, queryKey);
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.next) {
          return new URL(lastPage.next).searchParams.get('page');
        }

        return allPages[allPages.length];
      },
    },
  );
};
