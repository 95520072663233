import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

import { firebaseApp } from '@/firebase';
import { createOnboarding, getUser, getRoles } from '@/modules/auth';
import { queue } from '@/utils';

interface AuthProviderProps {
  user: boolean;
  setUser: Dispatch<SetStateAction<boolean>>;
  repairShop: any | null;
  setRepairShop: Dispatch<SetStateAction<any>>;
  store: any | null;
  setStore: Dispatch<SetStateAction<any>>;
  register: (...args) => any;
  login: (...args) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthProviderProps>({} as AuthProviderProps);

export const AuthProvider = ({ children }) => {
  const auth = getAuth(firebaseApp);

  const [user, setUser] = useState(() => {
    const user = localStorage.getItem('@user');
    return !!user;
  });

  const [repairShop, setRepairShop] = useState(() => {
    const repairShop = localStorage.getItem('@repair-shop');

    return repairShop && JSON.parse(repairShop);
  });

  const [store, setStore] = useState<any | null>(() => {
    const store = localStorage.getItem('@store');
    return store && JSON.parse(store);
  });

  const register = async (values, onSuccess, onError, redirect) => {
    try {
      const { user }: any = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password,
      );

      if (auth.currentUser) {
        sendEmailVerification(auth.currentUser);

        const userCredentials = await signInWithEmailAndPassword(
          auth,
          values.email,
          values.password,
        );

        const token = await userCredentials.user.getIdToken();

        const roles = await getRoles();

        if (token) {
          const onboardingUser = new FormData();

          localStorage.setItem('@token', token);

          onboardingUser.set('uid', user.auth.currentUser.uid);
          onboardingUser.set('name', values.name);
          onboardingUser.set('username', values.name);
          onboardingUser.set('email', values.email);
          onboardingUser.set('password', values.password);
          onboardingUser.set(
            'role',
            `${roles?.results?.find((role) => role.name === 'customer').id}`,
          );

          await createOnboarding(onboardingUser);
        }
      }
      onSuccess();

      switch (redirect) {
        case 'sc':
          return (window.location.href = '/entrar/sc');

        case 'bd':
          return (window.location.href = '/entrar/bd');

        default:
          return (window.location.href = '/entrar');
      }
    } catch (err: any) {
      switch (err.message) {
        case 'Firebase: Error (auth/email-already-in-use).':
          return onError('Este email já está cadastrado.');

        default:
          return onError(err.message);
      }
    }
  };

  const login = async (values, onSuccess, onError, redirect) => {
    try {
      const userCredentials = await signInWithEmailAndPassword(auth, values.email, values.password);

      const token = await userCredentials.user.getIdToken();

      if (token) {
        localStorage.setItem('@token', token);
        const userData = await getUser();
        const userRoles = userData.user.groups.map(({ name }) => name);

        if (userRoles.some((role) => ['mechanic', 'mechanic-admin'].includes(role))) {
          localStorage.setItem('@user', JSON.stringify(userData.user));
          localStorage.setItem('@repair-shop', JSON.stringify(userData.repair_shop));

          setRepairShop(userData.repair_shop);
          setUser(true);
          onSuccess();
          window.location.href = '/oficina/agendamentos';
        } else if (userRoles.some((role) => ['seller', 'seller-admin'].includes(role))) {
          localStorage.setItem('@user', JSON.stringify(userData.user));
          localStorage.setItem('@store', JSON.stringify(userData.store));

          setStore(userData.store);
          setUser(true);
          onSuccess();
          return (window.location.href = '/loja/orcamentos&vendas');
        } else if (userRoles.includes('customer')) {
          localStorage.setItem('@customer', JSON.stringify(userData.id));
          localStorage.setItem('@user', JSON.stringify(userData.user));
          if (userData.address) localStorage.setItem('@address', JSON.stringify(userData.address));

          setUser(true);
          onSuccess();
          await queue.createCars();

          switch (redirect) {
            case 'sc':
              return (window.location.href = '/encontrar-oficinas');
            case 'bd':
              return (window.location.href = '/encontrar-pecas');
            default:
              return (window.location.href = '/agendamentos');
          }
        }
      }
    } catch (err: any) {
      localStorage.clear();
      setRepairShop(null);
      setStore(null);
      await auth.signOut();

      switch (err.message) {
        case 'Firebase: Error (auth/user-not-found).':
          return onError('Email incorreto ou não cadastrado.');
        case 'Firebase: Error (auth/wrong-password).':
          return onError('Senha incorreta.');
        default:
          return onError(err.message);
      }
    }
  };

  const logout = async () => {
    localStorage.clear();
    setRepairShop(null);
    setStore(null);
    await auth.signOut();
    window.location.href = '/';
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        setRepairShop,
        repairShop,
        setStore,
        store,
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthProviderProps => {
  const context = useContext(AuthContext);

  return context;
};
