import { Flex, Text, Center, Button, Image } from '@chakra-ui/react';
import { useState } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { GoFileMedia } from 'react-icons/go';
import ImageUploading, { ImageListType } from 'react-images-uploading';

type UploadImageProps = {
  onChange: (imageList: ImageListType) => void;
  title?: string;
};

export const UploadImage = ({ onChange: onChangeParent, title }: UploadImageProps) => {
  const [images, setImages] = useState<ImageListType>([]);

  const onChange = (imageList: ImageListType) => {
    onChangeParent(imageList);
    setImages(imageList);
  };

  return (
    <ImageUploading
      multiple
      value={images}
      onChange={onChange}
      maxNumber={10}
      dataURLKey="data_url"
    >
      {({
        imageList,
        onImageUpload,
        // onImageRemoveAll,
        // onImageUpdate,
        // onImageRemove,
        // isDragging,
        dragProps,
      }) => (
        // write your building UI
        <div className="upload__image-wrapper">
          <Center
            position="relative"
            rounded="lg"
            bg="gray.100"
            d="flex"
            spacing={5}
            w="full"
            flexDir="column"
            p={5}
            pb={3}
            border="1px dashed"
            borderColor="gray.400"
          >
            <Text mb={5} fontWeight="bold">
              {title || 'Envie suas fotos'}
            </Text>
            <Button
              aria-label="Adicionar imagem"
              icon={<AiOutlineCloudUpload size="1.5rem" />}
              bg="white"
              border="1px solid"
              borderColor="gray.400"
              rounded="lg"
              zIndex="base"
              onClick={onImageUpload}
              _hover={{ bg: 'brand.500' }}
              leftIcon={<GoFileMedia />}
              {...dragProps}
            >
              Enviar
            </Button>
            <Flex mt={10} flexWrap="wrap" w="full" mx="auto">
              {imageList.map((image) => {
                return (
                  <Image
                    key={image.file?.name}
                    src={image.data_url}
                    border="1px solid"
                    borderColor="gray.400"
                    objectFit="contain"
                    objectPosition="center"
                    w="auto"
                    h="100px"
                    mr={3}
                    mb={5}
                  />
                );
              })}
            </Flex>
            {/* {imageList.length > 0 ? (
              <Avatar
                // style={isDragging ? { color: 'red' } : null}
                src={imageList?.[0].data_url}
                w="100%"
                h="100%"
              />
            ) : initialSrc ? (
              <Avatar
                // style={isDragging ? { color: 'red' } : null}
                src={initialSrc}
                w="100%"
                h="100%"
              />
            ) : (
              <Box bg="gray.600" w="100%" h="100%" borderRadius="50%" />
            )} */}
          </Center>
        </div>
      )}
    </ImageUploading>
  );
};
