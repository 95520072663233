import { useToast } from '@chakra-ui/react';
import { createContext, useState, useContext } from 'react';

import { createSchedule, createScheduleRequest } from '../../modules/schedule-request/api';
import {
  CreatedSchedule,
  CreatedScheduleRequest,
  Schedule,
} from '../../modules/schedule-request/api/types';

interface ScheduleProviderProps {
  schedule: CreatedSchedule | null;
  scheduleRequest: CreatedScheduleRequest | null;
  setSchedule: (schedule: CreatedSchedule | null) => void;
  setScheduleRequest: (scheduleRequest: CreatedScheduleRequest | null) => void;
  newSchedule: (schedule: Schedule) => void;
  newScheduleRequest: (scheduleRequest: any) => void;
}

const ScheduleContext = createContext<ScheduleProviderProps>({} as ScheduleProviderProps);

export const ScheduleProvider = ({ children }) => {
  const toast = useToast();
  const [schedule, setSchedule] = useState<CreatedSchedule | null>(null);
  const [scheduleRequest, setScheduleRequest] = useState<CreatedScheduleRequest | null>(null);

  const newSchedule = async (schedule: Schedule) => {
    try {
      const aux = await createSchedule(schedule);
      setSchedule(aux);

      toast({
        title: 'Agendamento criado com sucesso.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Houve um erro. Tente novamente.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setSchedule({} as CreatedSchedule);
    }
  };

  const newScheduleRequest = async (scheduleRequest: any) => {
    try {
      const aux = await createScheduleRequest(scheduleRequest);
      localStorage.setItem('@schedule-request', JSON.stringify(aux));
      setScheduleRequest(aux);

      toast({
        title: 'Solicitação enviada com sucesso. Aguarde.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Houve um erro. Tente novamente.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <ScheduleContext.Provider
      value={{
        schedule,
        scheduleRequest,
        setSchedule,
        setScheduleRequest,
        newSchedule,
        newScheduleRequest,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
};

export const useSchedule = (): ScheduleProviderProps => {
  const context = useContext(ScheduleContext);

  return context;
};
