import { Button } from '@chakra-ui/react';
import { useParams } from 'react-router';

import { RegisterCredentials } from '../../api/types';

import schema from './schema';

import { Link } from '@/components/common/Link';
import { FieldWrapper, Form, TextInput } from '@/components/Form';
import * as LC from '@/components/LC';
import { useAuth } from '@/context/AppProvider';

type RegisterFormProps = {
  onSuccess: () => void;
  onError: (message: string) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

export const RegisterForm = ({ loading, setLoading, onSuccess, onError }: RegisterFormProps) => {
  const { register } = useAuth();
  const { redirect } = useParams();

  return (
    <>
      {/* <Button variant="social" mb={6} mx="auto" leftIcon={<FcGoogle size={20} />}>
        Entre com o Google
      </Button> */}
      <Form<RegisterCredentials>
        initialValues={{
          name: '',
          username: '',
          email: '',
          password: '',
          confirmPassword: '',
        }}
        onSubmit={async (values) => {
          setLoading(true);
          register(values, onSuccess, onError, !!redirect);
        }}
        validationSchema={schema}
      >
        {() => (
          <>
            <FieldWrapper
              name="name"
              required
              label="Nome completo"
              as={(props) => <TextInput {...props} />}
            />
            <FieldWrapper
              name="email"
              required
              label="Email"
              as={(props) => <TextInput {...props} />}
            />
            <FieldWrapper
              name="password"
              required
              label="Senha"
              as={(props) => <TextInput type="password" {...props} />}
            />
            <FieldWrapper
              name="confirmPassword"
              required
              label="Confirmar senha"
              as={(props) => <TextInput type="password" {...props} />}
            />

            <LC.Vertical center spaceBetween mt={8}>
              <Button isLoading={loading} type="submit" className="w-full" variant="@primary">
                Prosseguir
              </Button>
              <Button
                as={Link}
                to={
                  redirect === 'sc'
                    ? '../entrar/sc'
                    : redirect === 'bd'
                    ? '../entrar/bd'
                    : '../entrar'
                }
                variant="ghost"
                mt={4}
                size="sm"
              >
                Já tem uma conta? Entrar aqui
              </Button>
            </LC.Vertical>
          </>
        )}
      </Form>
    </>
  );
};
