import { Container, Heading, Text, Divider } from '@chakra-ui/react';

import * as LC from '@/components/LC';
import { Page } from '@/components/Page';

type AuthLayoutProps = {
  children: React.ReactNode;
  title: string;
  subtitle?: string;
};

export const AuthLayout = ({ children, title, subtitle }: AuthLayoutProps) => {
  return (
    <Page title={title}>
      <Container maxW="container.md">
        <LC.Vertical
          center
          mx="auto"
          w={['100%', '100%', '60%']}
          py={8}
          borderRadius="xl"
          boxShadow="md"
          bg="white"
        >
          {title && (
            <Heading as="h1" fontSize="xl" mb={2} textAlign="center">
              {title}
            </Heading>
          )}
          {subtitle && (
            <Text fontSize="sm" maxW="60%" mb={2} fontWeight="normal" textAlign="center" mx="auto">
              {subtitle}
            </Text>
          )}

          <Divider bg="gray.300" mt={6} />

          <LC.Vertical py={8} px={[8, 12]} w="100%">
            {children}
          </LC.Vertical>
        </LC.Vertical>
      </Container>
    </Page>
  );
};
