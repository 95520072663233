/* eslint-disable react/display-name */
import { Button } from '@chakra-ui/button';
import { HStack, Stack, Box, Flex, Heading, SimpleGrid } from '@chakra-ui/layout';
import { useMediaQuery } from '@chakra-ui/react';
import { chakra } from '@chakra-ui/system';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { useRef, useState } from 'react';
import { AiFillCar } from 'react-icons/ai';
import { VscTools } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import background1 from '@/assets/Background1.svg';
import background2 from '@/assets/Background2.svg';
import background3 from '@/assets/Background3.svg';
import landingBanner2 from '@/assets/Buscar_oficinas_MyCar.svg';
import landingBanner1 from '@/assets/landing-banner-1.png';
import landingBanner3 from '@/assets/parts.png';

const Feature = ({ title, icon, description, path }) => (
  <Flex
    rounded="lg"
    alignItems="flex-start"
    justifyContent="space-between"
    direction="column"
    bg="gray.100"
    p={4}
    boxShadow="md"
    zIndex={3}
  >
    <HStack>
      {icon}
      <Heading as="h3" fontSize="2xl">
        {title}
      </Heading>
    </HStack>

    <chakra.p
      mt={{ base: 3, sm: 5, md: 5 }}
      mx={{ sm: 'auto', lg: 0 }}
      mb={6}
      fontSize={{ base: 'sm', md: 'md' }}
      color="gray.500"
      lineHeight="base"
    >
      {description}
    </chakra.p>

    <Button as={Link} to={path} variant="@primary">
      Buscar
    </Button>
  </Flex>
);

export const Landing = () => {
  const [isSmallScreen] = useMediaQuery('(max-width: 479px)');
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const refSlider = useRef<Slider>(null);

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => {
      return (
        <Box
          as="span"
          fontSize="sm"
          fontWeight="semibold"
          color="gray.500"
          rounded="full"
          bottom={{ base: '40px', md: '100px' }}
          bg="transparent"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {dots.map((dot, index) => (
            <Box
              key={dot}
              h={activeSlide === index ? 2 : 1}
              w={activeSlide === index ? 2 : 6}
              bg={activeSlide === index ? 'brand.500' : 'gray.300'}
              rounded="lg"
              display="flex"
              justifyContent="center"
              alignItems="center"
              margin={2}
              onClick={() => refSlider?.current?.slickGoTo(index)}
            >
              {dot}
            </Box>
          ))}
        </Box>
      );
    },
    customPaging: () => <></>,
    afterChange: (current) => setActiveSlide(current),
  };

  return (
    <div style={{ overflowX: 'hidden' }}>
      <Slider ref={refSlider} {...settings}>
        <Box
          w="100vw"
          h={{ base: '60vh', md: '80vh' }}
          bgImage={{ base: background1, md: landingBanner1 }}
          bgColor={{ base: 'trueGray.200', md: 'trueGray.200' }}
          bgSize={{ sm: 400, md: 'cover' }}
          bgPosition={{ sm: 300, md: 'center' }}
          bgRepeat="no-repeat"
          zIndex={-1}
        >
          <Box
            maxW="container.lg"
            mx="auto"
            py={10}
            minH={{ base: '60vh', md: '80vh' }}
            d="flex"
            alignItems="center"
            justifyContent="center"
            direction="column"
          >
            <Box w="full" px={10} border="solid 1px transparent" textAlign="left">
              <Box mx="auto" maxW={{ base: '7xl' }}>
                <Box w={{ base: 'full', md: 11 / 12, xl: 8 / 12 }} mr="auto">
                  <Heading
                    fontSize={{ base: '4xl', sm: '5xl', md: '7xl' }}
                    letterSpacing="tight"
                    lineHeight="short"
                    color="gray.900"
                    pt={5}
                  >
                    <chakra.span display={{ base: 'block', xl: 'inline' }}>Simples </chakra.span>
                    <chakra.span display={{ base: 'block', xl: 'inline' }} color="brand.600">
                      Prático{' '}
                    </chakra.span>
                    <chakra.span display={{ base: 'block', xl: 'inline' }}>Rápido</chakra.span>
                  </Heading>
                  <chakra.p
                    mt={{ base: 3, sm: 5, md: 0 }}
                    mx={{ sm: 'auto', lg: 0 }}
                    mb={6}
                    fontSize={{ base: 'md', md: '2xl' }}
                    lineHeight="base"
                    fontWeight={{ base: 'normal', md: 'normal' }}
                  >
                    Transformando a sua {isSmallScreen && <br />}
                    relação com seu carro.
                  </chakra.p>
                  <Stack
                    direction={{ base: 'column', sm: 'row' }}
                    spacing={{ base: 4, md: 2 }}
                    justifyContent={{ base: 'center', md: 'flex-start' }}
                    align={{ base: 'center', md: 'flex-start' }}
                  >
                    {!localStorage.getItem('@user') && (
                      <>
                        <Button
                          as={Link}
                          to="/entrar"
                          variant="@primary"
                          fontSize={{ base: 'sm', md: 'lg' }}
                        >
                          Entrar em sua conta
                        </Button>
                        <Button
                          as={Link}
                          to="/cadastro"
                          variant="@primaryLight"
                          fontSize={{ base: 'sm', md: 'lg' }}
                        >
                          Não tem uma conta?
                        </Button>
                      </>
                    )}
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          w="100vw"
          h={{ base: '60vh', md: '80vh' }}
          bgColor={{ base: '#4a2426', md: '#4a2426' }}
          bgImage={{ base: background2, md: landingBanner2 }}
          bgSize="cover"
          bgPosition={'right'}
          bgRepeat="no-repeat"
        >
          <Box
            maxW="container.lg"
            mx="auto"
            py={10}
            zIndex={1}
            h={{ base: '60vh', md: '80vh' }}
            d="flex"
            alignItems="center"
            justifyContent="center"
            direction="column"
          >
            <Box w="full" px={10} border="solid 1px transparent" textAlign="left">
              <Box
                mx="auto"
                maxW={{ base: '7xl' }}
                display="flex"
                flexDirection="column"
                alignItems={{ base: 'flex-start', lg: 'flex-start' }}
              >
                <Box w={{ base: 'full', md: '70%', xl: 8 / 12 }} mr={0}>
                  <Heading
                    fontSize={{ base: '3xl', sm: '4xl', md: '7xl' }}
                    letterSpacing="tight"
                    lineHeight="1"
                    fontWeight="extrabold"
                    color={'gray.900'}
                  >
                    <chakra.span color="brand.600" display={{ base: 'block', xl: 'inline' }}>
                      Te conectamos
                    </chakra.span>{' '}
                    <chakra.span color="brand.600" display={{ base: 'block', xl: 'inline' }}>
                      com as melhores oficinas!
                    </chakra.span>
                  </Heading>
                  <chakra.p
                    letterSpacing="tight"
                    fontSize={{ base: 'md', md: '2xl' }}
                    lineHeight="base"
                    fontWeight={{ base: 'normal', md: 'normal' }}
                    color={{ base: 'white', md: 'gray.300' }}
                    mb={6}
                  >
                    Encontre as oficinas mais confiáveis próximas a você.
                  </chakra.p>
                  <Stack
                    direction={{ base: 'column', sm: 'row' }}
                    spacing={{ base: 4, md: 2 }}
                    justifyContent={{ base: 'center', md: 'flex-start' }}
                    align={{ base: 'center', md: 'flex-start' }}
                  >
                    <Button
                      as={Link}
                      to="/encontrar-oficias"
                      variant="@primary"
                      fontSize={{ base: 'sm', md: 'lg' }}
                    >
                      Buscar Oficinas
                    </Button>
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          w="100vw"
          h={{ base: '60vh', md: '80vh' }}
          bgColor={{ base: '#4a2426', md: '#4a2426' }}
          bgImage={{ base: background3, md: landingBanner3 }}
          bgSize={{ base: 390, md: 'contain' }}
          bgPosition={'right'}
          bgRepeat="no-repeat"
        >
          <Box
            maxW="container.md"
            mx="auto"
            py={10}
            zIndex={1}
            minH={{ base: '60vh', md: '80vh' }}
            d="flex"
            alignItems="center"
            justifyContent="center"
            direction="column"
          >
            <Box w="full" px={10} border="solid 1px transparent" textAlign="left">
              <Box
                mx="auto"
                maxW={{ base: '7xl' }}
                display="flex"
                flexDirection="column"
                alignItems={{ base: 'flex-start', lg: 'flex-start' }}
              >
                <Box w={{ base: 'full', md: '70%', xl: 8 / 12 }} mr={0}>
                  <Heading
                    fontSize={{ base: '3xl', sm: '4xl', md: '7xl' }}
                    letterSpacing="tight"
                    lineHeight="1"
                    fontWeight="extrabold"
                    color={'gray.900'}
                  >
                    <chakra.span color="brand.600" display={{ base: 'block', xl: 'inline' }}>
                      Compre peças de
                    </chakra.span>{' '}
                    <chakra.span color="brand.600" display={{ base: 'block', xl: 'inline' }}>
                      forma fácil e confiável!
                    </chakra.span>
                  </Heading>
                  <chakra.p
                    letterSpacing="tight"
                    fontSize={{ base: 'md', md: '2xl' }}
                    lineHeight="base"
                    fontWeight={{ base: 'normal', md: 'normal' }}
                    color={{ base: 'white', md: 'gray.300' }}
                    mb={6}
                  >
                    Faça a solicitação de peças e encontre os melhores fornecedores!
                  </chakra.p>
                  <Stack
                    direction={{ base: 'column', sm: 'row' }}
                    spacing={{ base: 4, md: 2 }}
                    justifyContent={{ base: 'center', md: 'flex-start' }}
                    align={{ base: 'center', md: 'flex-start' }}
                  >
                    <Button
                      as={Link}
                      to="/encontrar-pecas"
                      variant="@primary"
                      fontSize={{ base: 'sm', md: 'lg' }}
                    >
                      Buscar Peças
                    </Button>
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Slider>

      <SimpleGrid
        mt={-10}
        columns={[1, 1, 2]}
        spacing={5}
        zIndex={3}
        maxW="container.lg"
        mx="auto"
        mb={10}
        px={5}
      >
        <Feature
          path="/encontrar-oficinas"
          title="Encontrar oficinas"
          icon={<VscTools size={32} />}
          description="Encontre parceiros MyCar para solucionar problemas do seu veículo."
        />
        <Feature
          path="/encontrar-pecas"
          title="Comprar peças"
          icon={<AiFillCar size={32} />}
          description="Encontre os melhores fornecedores para peças de carros."
        />
      </SimpleGrid>

      {/*  <Box maxW="container.lg" mx="auto" px={5} pb={10}>
        <Carousel
          CarouselItem={Image as JSXElementConstructor<unknown>}
          columns={4}
          data={[
            {
              src: bateriasMouraLogo,
              alt: 'Baterias Moura',
            },
            {
              src: boschLogo,
              alt: 'Bosch',
            },
            {
              src: heliarLogo,
              alt: 'Heliar',
            },
            {
              src: nakataLogo,
              alt: 'Nakata Automotiva',
            },
          ]}
          keyProp="id"
          keysToPass={['src', 'alt']}
          title="Nossos parceiros"
          carouselItemProps={{
            maxW: '200px',
            d: 'flex',
            alignItems: 'center',
            objectFit: 'contain',
            objectPosition: 'center',
          }}
          centeredSlides={false}
          centerInsufficientSlides={true}
        />
      </Box> */}
    </div>
  );
};
