import { Button } from '@chakra-ui/button';
import { Heading, HStack, VStack } from '@chakra-ui/layout';
import { IoIosArrowRoundBack } from 'react-icons/io';

import { MotionBox } from '@/components/common/MotionBox';

export const ScheduleRequestSelectRepairShopHeaderMobile = ({
  repairShopCardOpen,
  setRepairShopCardOpen,
  ...restProps
}) => {
  return (
    <VStack
      spacing={2}
      alignItems="flex-start"
      bg="white"
      justifyContent="center"
      p={5}
      rounded="lg"
      shadow="lg"
      w="full"
      {...restProps}
      // d={{ base: 'none', lg: 'flex' }}
    >
      <HStack alignItems="center" overflowX="hidden">
        {repairShopCardOpen && (
          <MotionBox
            initial={{ x: '-100%' }}
            animate={{ x: 0 }}
            transition={{ delay: 0, duration: 0.5 }}
          >
            <Button
              variant="ghost"
              leftIcon={<IoIosArrowRoundBack />}
              onClick={() => setRepairShopCardOpen(false)}
              size="xs"
              px={3}
            >
              Voltar
            </Button>
          </MotionBox>
        )}

        <VStack alignItems="flex-start" transition="all .2s ease-in-out">
          <Heading as="p" fontFamily="gill" w="100%" fontSize="lg" textAlign="left">
            Perfil da oficina
          </Heading>
        </VStack>
      </HStack>
    </VStack>
  );
};
