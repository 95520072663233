import { useQuery } from 'react-query';

import { getRepairShop } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseRepairShopOptions = {
  id?: number;
  config?: QueryConfig<typeof getRepairShop>;
};

export const useRepairShop = ({ id, config }: UseRepairShopOptions) => {
  return useQuery(['getRepairShop', id], getRepairShop, config);
};
