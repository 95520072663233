/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, Text, Heading, HStack } from '@chakra-ui/layout';
import {
  Link as CLink,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Button,
  Input,
} from '@chakra-ui/react';
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from '@choc-ui/chakra-autocomplete';
import { motion } from 'framer-motion';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';

import { BoxWithTitle } from '@/components/common/BoxWithTitle';
import { FieldWrapper } from '@/components/Form';
import { useAuth, useScroll } from '@/context/AppProvider';

export const EditPart = ({
  allParts,
  selectedPart,
  setSelectedPart,
  attributes,
  setAttributes,
  setSelectedParts,
  setOnEdition,
  price,
  setSearch,
  fetchNextPage,
}) => {
  const { scrollRef } = useScroll();
  const [step, setStep] = useState(1);
  const [changePartName, setChangePartName] = useState(false);
  const { user } = useAuth();
  const [selected_attributes, setSelectedAttributes] = useState<any>([]);

  const editPart = () => {
    setSelectedParts((prev) => [...prev, { ...selectedPart, selected_attributes }]);
    setSelectedPart({});
    setSelectedAttributes([]);
    setOnEdition(false);
    setStep(0);
    scrollRef?.current?.scrollToTop();
  };

  useEffect(() => {
    if (allParts?.pages?.length > 0 && selectedPart?.name) {
      const aux = allParts?.pages
        ?.flatMap((page) => page.results)
        ?.find(
          (item) =>
            item.name === selectedPart.name && item.manufacturer.name === selectedPart.manufacturer,
        );
      setSelectedPart({
        ...aux,
        quantity: 0,
        manufacturer: selectedPart?.manufacturer,
        manufacturer_id: aux?.manufacturer.id,
      });
      setAttributes(aux?.attributes);
    }
  }, [allParts, changePartName]);

  const currencyFormatter = (value) => {
    if (!Number(value)) return '';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  };

  const debouncedFunction = (e: any, set: Dispatch<SetStateAction<any>>) => {
    setTimeout(() => set(e.target.value), 300);
  };

  return (
    <FieldWrapper
      name="parts"
      boxProps={{ w: 'full' }}
      required
      as={() => (
        <FieldWrapper
          name="problem_id"
          boxProps={{
            w: 'full',
            mt: -5,
          }}
          as={() => (
            <>
              <Heading mt={5} mb={5} as="p" fontFamily="gill" fontSize="sm">
                Peça selecionada
              </Heading>

              <AutoComplete
                listAllValuesOnFocus
                onChange={(e) => {
                  setSelectedPart({
                    ...selectedPart,
                    name: e.split(' - ')[0],
                    manufacturer: e.split(' - ')[1],
                  });
                  setChangePartName(true);
                }}
                openOnFocus
                emptyState={
                  <Text w="full" textAlign="center">
                    Peça não encontrada
                  </Text>
                }
              >
                <AutoCompleteInput
                  value={selectedPart.name}
                  variant="outlined"
                  placeholder="Selecionar peça"
                  rounded="md"
                  onChange={(e) => debouncedFunction(e, setSearch)}
                />
                <AutoCompleteList
                  onScroll={(e) => {
                    const { clientHeight, scrollTop, scrollHeight } = e.target as HTMLElement;
                    const bottom = Math.ceil(clientHeight + scrollTop) >= scrollHeight;
                    if (bottom) fetchNextPage();
                  }}
                >
                  {allParts?.pages
                    ?.flatMap((page) => page?.results)
                    ?.map((part) => `${part.name} - ${part.manufacturer.name}`)
                    ?.map((name) => (
                      <AutoCompleteItem
                        key={String(name)}
                        value={String(name)}
                        textTransform="capitalize"
                      >
                        {String(name)}
                      </AutoCompleteItem>
                    ))}
                </AutoCompleteList>
              </AutoComplete>

              {step >= 1 && (
                <motion.div
                  transition={{ duration: 0.4 }}
                  initial="init"
                  animate="end"
                  variants={{
                    end: { opacity: 1, y: 0 },
                    init: { opacity: 0, y: -40 },
                  }}
                >
                  <Flex
                    bg="white"
                    p={5}
                    mt={5}
                    rounded="lg"
                    shadow="lg"
                    flexDirection="column"
                    align={{ base: 'center', md: 'flex-start' }}
                  >
                    <>
                      {attributes?.map((attribute) => {
                        const [, ...values]: any = Object.entries(attribute)[0][1];

                        return (
                          <BoxWithTitle
                            key={Object.entries(attribute)[0][0]}
                            title={Object.entries(attribute)[0][0]}
                            titleSize="sm"
                            wrapperProps={{
                              w: 'full',
                              marginBottom: 3,
                            }}
                          >
                            <FieldWrapper
                              name={String(Object.entries(attribute)[0][0]).toLowerCase()}
                              boxProps={{
                                w: 'full',
                              }}
                              required
                              as={() => (
                                <AutoComplete
                                  onChange={(e) => {
                                    const selectedItem = values?.find((item) => item.name === e);

                                    setSelectedPart({
                                      ...selectedPart,
                                    });
                                    setSelectedAttributes([
                                      ...selected_attributes,
                                      selectedItem.id,
                                    ]);
                                  }}
                                  openOnFocus
                                  emptyState={
                                    <Text w="full" textAlign="center">
                                      Atributo não encontrado
                                    </Text>
                                  }
                                >
                                  <AutoCompleteInput
                                    rounded="md"
                                    placeholder="Selecionar atributo"
                                    variant="outline"
                                  />
                                  <AutoCompleteList>
                                    {values.map((item, index) => (
                                      <AutoCompleteItem
                                        key={`option-${index}`}
                                        value={item.name}
                                        textTransform="capitalize"
                                      >
                                        <HStack w="full" justify="space-between">
                                          <Text>{item.name}</Text>
                                        </HStack>
                                      </AutoCompleteItem>
                                    ))}
                                  </AutoCompleteList>
                                </AutoComplete>
                              )}
                            />
                          </BoxWithTitle>
                        );
                      })}

                      {attributes && (
                        <BoxWithTitle
                          title={`Quantidade ${
                            selectedPart.multiple === 1
                              ? '(Unidade)'
                              : selectedPart.multiple === 2
                              ? '(Par)'
                              : '(Jogo)'
                          }`}
                          titleSize="sm"
                          wrapperProps={{
                            w: 'full',
                          }}
                        >
                          <FieldWrapper
                            name="plate"
                            boxProps={{
                              w: 'full',
                            }}
                            required
                            as={() => (
                              <NumberInput
                                onChange={(e) => {
                                  setSelectedPart({
                                    ...selectedPart,
                                    quantity: Number(e),
                                  });
                                }}
                                min={0}
                                value={selectedPart.quantity}
                              >
                                <NumberInputField />
                                <NumberInputStepper>
                                  <NumberIncrementStepper />
                                  <NumberDecrementStepper />
                                </NumberInputStepper>
                              </NumberInput>
                            )}
                          />
                        </BoxWithTitle>
                      )}

                      {price && (
                        <BoxWithTitle
                          title="Preço"
                          titleSize="sm"
                          wrapperProps={{
                            w: 'full',
                          }}
                        >
                          <FieldWrapper
                            name="price"
                            boxProps={{
                              w: 'full',
                            }}
                            required
                            as={() => (
                              <HStack
                                marginInlineStart="0px !important"
                                marginStart="0px !important"
                                w="xm"
                                rounded="md"
                                mt="0px !important"
                              >
                                <NumberFormat
                                  customInput={Input}
                                  allowNegative={false}
                                  onValueChange={(value) => {
                                    setSelectedPart({
                                      ...selectedPart,
                                      price: Number(
                                        value.formattedValue
                                          .replace('R$ ', '')
                                          .replace('.', '')
                                          .replace(',', '.'),
                                      ),
                                    });
                                  }}
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  fixedDecimalScale
                                  format={currencyFormatter}
                                  prefix="R$ "
                                  rounded="md"
                                  defaultValue={selectedPart.price}
                                />
                              </HStack>
                            )}
                          />
                        </BoxWithTitle>
                      )}

                      {!user && (
                        <Text mt={5} mb={5}>
                          Não perca as informações pesquisadas!{' '}
                          <CLink textDecoration="underline">
                            <Link to="/entrar">Crie sua conta</Link>
                          </CLink>
                        </Text>
                      )}

                      <Button w="fit-content" size="sm" variant="@primary" onClick={editPart}>
                        Salvar peça
                      </Button>
                    </>
                  </Flex>
                </motion.div>
              )}

              <Text fontSize="xs" mt={5}>
                Você pode selecionar mais de uma peça!
              </Text>
            </>
          )}
        />
      )}
    />
  );
};
