import { useQuery } from 'react-query';

import { getBudgetEvaluations } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseBudgetEvaluationsOptions = {
  id?: number;
  config?: QueryConfig<typeof getBudgetEvaluations>;
};

export const useBudgetEvaluations = ({ id, config }: UseBudgetEvaluationsOptions) => {
  return useQuery(['getBudgetEvaluations', id], getBudgetEvaluations, config);
};
