export default {
  baseStyle: {
    field: {
      rounded: 'full',
      fontFamily: 'body',
      fontWeight: 'medium',
      border: '0px',
      '> option': {
        bg: 'white',
        fontFamily: 'body',
        borderBottom: '1px solid lightgray',
        fontSize: 'sm',
        fontWeight: 'medium',
      },
    },
  },
  sizes: {
    xs: {
      field: {
        borderRadius: 'full',
        fontSize: 'xs',
      },
    },
    sm: {
      field: {
        borderRadius: 'full',
        fontSize: 'sm',
      },
    },
    md: {
      field: {
        borderRadius: 'full',
        fontSize: 'sm',
      },
    },
    lg: {
      field: {
        borderRadius: 'full',
        fontSize: 'md',
      },
    },
  },
  defaultProps: {
    borderColor: 'gray.300',
    border: '1px solid',
    bg: 'white',
    color: 'black',
    shadow: 'sm',
    rounded: 'full',
  },
};
