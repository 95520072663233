import { Box, Divider, Flex, HStack, Stack, Text, VStack } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { BsCalendar } from 'react-icons/bs';

import { readNotification } from '../../api';
import { useConsumerSchedule } from '../../contexts';
import { useBudgetRequestParts } from '../../hooks';
import { budgetLabelMap } from '../../utils';

import { SimpleProgressBar } from '@/components/common/SimpleProgressBar';
import { useFCM } from '@/context/AppProvider';
import { getWeekDay } from '@/utils';
import { getMonth } from '@/utils/getMonth';
import { getProgress } from '@/utils/getProgress';

export const BudgetCard = ({ scheduleInfo }) => {
  const { userNotifications, refetchUserNotifications } = useFCM();
  const { setSelectedSchedule } = useConsumerSchedule();
  const { start_date, end_date } = scheduleInfo;

  const { data: budgetParts } = useBudgetRequestParts({
    id: scheduleInfo.budget_request.id,
    config: { enabled: !!scheduleInfo },
  });

  const [isUnread, setIsUnread] = useState(
    userNotifications?.find((notification) => notification.object_pk === scheduleInfo.id),
  );

  const label = () => {
    if (budgetParts?.length) {
      switch (budgetParts[0].part.multiple) {
        case 1:
          return 'Unidade';
        case 2:
          return 'Par';
        default:
          return 'Jogo';
      }
    }
  };

  useEffect(
    () =>
      setIsUnread(
        userNotifications?.find((notification) => notification.object_pk === scheduleInfo.id),
      ),
    [userNotifications, scheduleInfo],
  );

  return (
    <Stack
      rounded="md"
      bg={isUnread ? 'white' : '#E7EDF6'}
      p="4"
      cursor="pointer"
      flexDirection="column"
      spacing={2}
      mr={{ base: 0, md: 4 }}
      mb={4}
      justifyContent="space-between"
      onClick={async () => {
        setSelectedSchedule(scheduleInfo);

        if (isUnread) {
          await readNotification(isUnread);
          refetchUserNotifications();
        }
      }}
    >
      <HStack alignItems="flex-start" justify="space-between" w="full" spacing="1">
        <Text textAlign="left" fontSize="sm">
          {`${scheduleInfo.budget_request.car.model.manufacturer.name} ${scheduleInfo.budget_request.car.model.name} ${scheduleInfo.budget_request.car.year}`}
        </Text>

        <Flex>
          <Text textAlign="left" color="gray.600" fontSize="xs" d="flex" alignItems="center">
            <BsCalendar style={{ marginRight: '5px' }} />
            {`${getWeekDay(new Date(scheduleInfo.created_at).getDay())}, ${new Date(
              scheduleInfo.created_at,
            ).getDay()} de ${getMonth(new Date(scheduleInfo.created_at).getMonth())}`}
          </Text>
        </Flex>
      </HStack>
      <Divider mb="5px !important" />
      <VStack align="flex-start" spacing={1}>
        <HStack>
          <Text fontWeight="bold" fontSize="xs">
            {budgetParts?.length &&
              `${budgetParts[0].quantity}x ${budgetParts[0].part.name} (${label()})`}
          </Text>
        </HStack>
        <Text color="gray.400" fontSize="xs">
          {budgetParts?.length && `${budgetParts[0].manufacturer.name}`}
        </Text>
      </VStack>
      <Text fontSize="xs">...</Text>
      <Text fontSize="xs">Ver todas as peças</Text>
      <Divider mb="5px !important" />
      <Flex alignItems="center">
        <Text
          ml={6}
          whiteSpace="nowrap"
          pos="relative"
          fontSize="xs"
          fontWeight="semibold"
          color="#21272A"
          _before={{
            content: '""',
            width: 4,
            height: 4,
            zIndex: 1000,
            pos: 'absolute',
            top: '50%',
            left: -6,
            transform: 'translateY(-50%)',
            border: '3px solid',
            borderColor: 'brand.500',
            rounded: 'full',
          }}
        >
          {budgetLabelMap[scheduleInfo.status]}
        </Text>

        {scheduleInfo.status === 'in_progress' && getProgress({ start_date, end_date }) !== -1 && (
          <Box w="100%" px="10px">
            <SimpleProgressBar value={getProgress({ start_date, end_date })} />
          </Box>
        )}
      </Flex>
    </Stack>
  );
};
