/* eslint-disable react-hooks/exhaustive-deps */
import {
  Flex,
  Button,
  VStack,
  Stack,
  HStack,
  Text,
  Tag,
  Box,
  Image,
  useMediaQuery,
  TagLabel,
  Divider,
  chakra,
  Skeleton,
} from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { BsChat, BsCheckCircle } from 'react-icons/bs';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { ImageViewer } from 'react-image-viewer-dv';

import {
  useScheduleRequestPhotos,
  useScheduleRequestProblems,
} from '../../../schedule-request/hooks';
import { createChatNotification, updateSchedule } from '../../api';
import { useChatNotifications, useDiagnosis, useDiagnosisPhotos } from '../../hooks';
import { diagnosisActionButtonMap, diagnosisLabelMap } from '../../utils';
import { RepairShopChat } from '../RepairShopChat';

import { CarDiagnosisStartedForm } from './CarDiagnosisStartedForm';

import { BoxWithTitle } from '@/components/common/BoxWithTitle';
import { SimpleProgressBar } from '@/components/common/SimpleProgressBar';
import { useFCM } from '@/context/AppProvider';
import { db } from '@/firebase';
import { readNotification, updateDiagnosis } from '@/modules/consumer';
import { countdown } from '@/utils';
import { calculateTime } from '@/utils/calculateTime';
import { getProgress } from '@/utils/getProgress';
import parseLongString from '@/utils/parseLongString';

export const CarDiagnosis = ({ isUnread, selectedSchedule, setSelectedSchedule }) => {
  const [openChat, setOpenChat] = useState(false);
  const { repairShopSchedules } = useFCM();
  const [socketList] = useState(repairShopSchedules);

  const countdownCallback = useCallback(
    () => countdown(selectedSchedule.end_date),
    [selectedSchedule.end_date],
  );

  const progressCallback = useCallback(
    () =>
      getProgress({
        start_date: selectedSchedule.start_date,
        end_date: selectedSchedule.end_date,
      }),
    [selectedSchedule.start_date, selectedSchedule.end_date],
  );

  const [expirationTime, setExpirationTime] = useState<any>(countdownCallback());
  const [progress, setProgress] = useState<any>(progressCallback());

  const handleExpirationTime = useCallback(async () => {
    const timer = setTimeout(() => {
      setExpirationTime(countdown(selectedSchedule.end_date));
      setProgress(
        getProgress({
          start_date: selectedSchedule.start_date,
          end_date: selectedSchedule.end_date,
        }),
      );
    }, 1000);

    return () => clearTimeout(timer);
  }, [expirationTime, progress, selectedSchedule]);

  useEffect(() => {
    handleExpirationTime();
  }, [handleExpirationTime]);

  const {
    data: scheduleRequestPhotos,
    refetch: refetchScheduleRequestPhotos,
    isLoading: isLoadingPhotos,
  } = useScheduleRequestPhotos({
    id: selectedSchedule.schedule_request.id,
    config: { enabled: !!selectedSchedule },
  });

  const {
    data: scheduleRequestProblems,
    refetch: refetchScheduleRequestProblems,
    isLoading: isLoadingProblems,
  } = useScheduleRequestProblems({
    id: selectedSchedule.schedule_request.id,
    config: { enabled: !!selectedSchedule },
  });

  const {
    data: diagnosis,
    refetch: refetchDiagnosis,
    isLoading: isLoadingDiagnosis,
  } = useDiagnosis({
    id: selectedSchedule?.id,
    config: { enabled: !!selectedSchedule },
  });

  const {
    data: diagnosisPhotos,
    refetch: refetchDiagnosisPhotos,
    isLoading: isLoadingDiagnosisPhotos,
  } = useDiagnosisPhotos({
    id: diagnosis ? diagnosis[0]?.id : null,
    config: { enabled: !!diagnosis },
  });

  const { data: chatNotifications, refetch: refetchChatNotifications } = useChatNotifications({
    id: selectedSchedule.id,
    config: { enabled: !!selectedSchedule },
  });

  const [selectedProblems, setSelectedProblems] = useState<any[]>([]);
  const [changed, setChanged] = useState(false);

  const [diff, setDiff] = useState<any>();
  const [, setTimeLeft] = useState<any>();
  const [timeLeftPercentage, setTimeLeftPercentage] = useState<any>();
  const [isSmallScreen] = useMediaQuery('(max-width: 860px)');

  const { refetchUserNotifications } = useFCM();

  useEffect(() => {
    if (diagnosis && diagnosis.length > 0) {
      const { difference, timeLeft, timeLeftPercentage } = calculateTime(
        diagnosis[0].created_at,
        diagnosis[0].updated_at,
        true,
      );

      setDiff(difference);
      setTimeLeft(timeLeft);
      setTimeLeftPercentage(timeLeftPercentage);
    }
  }, [diagnosis]);

  useEffect(() => {
    if (scheduleRequestProblems && changed === false) {
      setSelectedProblems(() => {
        return scheduleRequestProblems?.map((problem) => {
          return { value: problem.problem.id, label: problem.problem.name };
        });
      });
      setChanged(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleRequestProblems]);

  useEffect(() => {
    refetchDiagnosis();
    refetchDiagnosisPhotos();
    refetchScheduleRequestPhotos();
    refetchScheduleRequestProblems();
  }, [selectedSchedule]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (diagnosis && diagnosis.length > 0) {
        const { timeLeftPercentage } = calculateTime(
          diagnosis[0].created_at,
          diagnosis[0].updated_at,
        );

        setTimeLeftPercentage(timeLeftPercentage);
      }
    }, 1000);
    if (timeLeftPercentage === 0) clearTimeout(timer);
    return () => clearTimeout(timer);
  });

  const markNotificationAsRead = useCallback(async () => {
    if (isUnread) {
      await readNotification(isUnread);
      refetchUserNotifications();
    }
  }, [isUnread, refetchUserNotifications]);

  useEffect(() => {
    markNotificationAsRead();
  }, []);

  const handleCustomLabels = (quantity) => {
    return quantity > 1 ? `${quantity} peças` : `1 peça`;
  };

  const handleLabels = (part, quantity) => {
    switch (part?.multiple) {
      case 1:
        return quantity > 1 ? `${quantity} peças` : `1 peça`;
      case 2:
        return quantity > 1 ? `${quantity} pares` : `1 par`;
      default:
        return quantity > 1 ? `${quantity} jogos` : `1 jogo`;
    }
  };

  const [custom_repair_shop_parts, setCustom_repair_shop_parts] = useState(() => {
    if (diagnosis?.length > 0) {
      const aux = diagnosis[0]?.custom_repair_shop_parts;
      return JSON.parse(aux);
    }
  });

  const [custom_repair_shop_services, setCustom_repair_shop_services] = useState(() => {
    if (diagnosis?.length > 0) {
      const aux = diagnosis[0]?.custom_repair_shop_services;
      return JSON.parse(aux);
    }
  });

  useEffect(() => {
    if (repairShopSchedules !== socketList) {
      setSelectedSchedule && setSelectedSchedule(null);
    }
  }, [repairShopSchedules]);

  useEffect(() => {
    if (diagnosis?.length > 0) {
      setCustom_repair_shop_parts(() => {
        const aux = diagnosis[0]?.custom_repair_shop_parts;
        return JSON.parse(aux);
      });

      setCustom_repair_shop_services(() => {
        const aux = diagnosis[0]?.custom_repair_shop_services;
        return JSON.parse(aux);
      });
    }
  }, [diagnosis]);

  const createNotification = async () => {
    await createChatNotification({
      user: selectedSchedule.user,
      schedule: selectedSchedule.id,
    });
    refetchChatNotifications();
  };

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
  });

  useEffect(() => {
    db?.collection(
      `user-${selectedSchedule.user}-repairShop-${selectedSchedule.repair_shop.id}-schedule-${selectedSchedule.id}-scheduleRequest-${selectedSchedule.schedule_request.id}-chat`,
    )
      .orderBy('createdAt')
      .onSnapshot((querySnapShot: any) => {
        const data = querySnapShot.docs.map((doc: any) => ({
          ...doc.data(),
          id: doc.id,
        }));

        if (!isFirstRun.current) {
          if (data?.at(-1)?.sender === selectedSchedule.repair_shop.name) {
            createNotification();
            refetchChatNotifications();
          } else {
            refetchChatNotifications();
          }
        }
      });
  }, [db, selectedSchedule.user, selectedSchedule.repair_shop.id, isFirstRun]);

  return (
    <Flex w="full" flexDirection="column">
      <HStack w="100%" alignItems="center" justifyContent="space-between" mb={4} mt={10}>
        <Button
          variant="ghost"
          leftIcon={<MdKeyboardArrowLeft />}
          alignSelf="flex-start"
          pl="0"
          onClick={() => {
            if (openChat) {
              setOpenChat(!openChat);
            } else {
              setSelectedSchedule && setSelectedSchedule(null);
            }
          }}
        >
          voltar
        </Button>

        {[
          'started',
          'diagnosed',
          'in_progress',
          'diagnostic_accepted_by_user',
          'in_progress',
        ].includes(selectedSchedule.status) &&
          !openChat && (
            <Button
              variant={
                chatNotifications?.find((notification) => notification.is_read === false)
                  ? '@primary'
                  : '@secondary'
              }
              leftIcon={<BsChat />}
              onClick={() => setOpenChat((prev) => !prev)}
              color="black"
              size={isSmallScreen ? 'sm' : 'md'}
            >
              {chatNotifications?.find((notification) => notification.is_read === false)
                ? 'Nova mensagem!'
                : 'Mensagens'}
            </Button>
          )}
      </HStack>
      {[
        'started',
        'diagnosed',
        'in_progress',
        'diagnostic_accepted_by_user',
        'in_progress',
      ].includes(selectedSchedule.status) && openChat ? (
        <Stack px="2">
          <RepairShopChat
            selectedSchedule={selectedSchedule}
            refetchChatNotifications={refetchChatNotifications}
          />
        </Stack>
      ) : ['started', 'diagnostic_canceled_by_user'].includes(selectedSchedule?.status) ? (
        <CarDiagnosisStartedForm
          diagnosisId={diagnosis ? diagnosis[0]?.id : undefined}
          selectedSchedule={selectedSchedule}
          setSelectedSchedule={setSelectedSchedule}
          selectedProblems={selectedProblems}
          setSelectedProblems={setSelectedProblems}
          scheduleRequestPhotos={scheduleRequestPhotos}
        />
      ) : (
        <VStack>
          <Stack
            flexDirection={{ base: 'column', md: 'row' }}
            alignItems={{ base: 'flex-start', md: 'center' }}
            spacing={{ base: 2, md: 0 }}
            w="full"
            rounded="md"
            bg="white"
            p="4"
          >
            {selectedSchedule?.schedule_request.car ? (
              <>
                <HStack flex={0.4}>
                  <Text fontWeight="bold" whiteSpace="nowrap">
                    {selectedSchedule?.schedule_request.car.plate}
                  </Text>
                  <Text color="gray.400" whiteSpace="nowrap">
                    {selectedSchedule.schedule_request.preferred_date.slice(11)}
                  </Text>
                </HStack>
                <HStack flex={1}>
                  <Text d={{ base: 'initial', md: 'none' }}>Veículo</Text>
                  <Text fontWeight="bold">
                    {parseLongString(
                      `${selectedSchedule?.schedule_request.car.car.model.manufacturer.name} ${selectedSchedule?.schedule_request.car.car.model.name} ${selectedSchedule?.schedule_request.car.car.year}`,
                      32,
                    )}
                  </Text>
                </HStack>
              </>
            ) : (
              <HStack flex={1}>
                <Text fontWeight="bold" whiteSpace="nowrap">
                  Veículo apagado
                </Text>
              </HStack>
            )}
            <HStack justifyContent="space-between" w={{ base: 'full', md: 'fit-content' }}>
              <Text
                ml={6}
                whiteSpace="nowrap"
                pos="relative"
                _before={{
                  content: '""',
                  width: 4,
                  height: 4,
                  zIndex: 1000,
                  pos: 'absolute',
                  top: '50%',
                  left: -6,
                  transform: 'translateY(-50%)',
                  border: '3px solid',
                  borderColor: 'brand.500',
                  rounded: 'full',
                }}
              >
                {(selectedSchedule && diagnosisLabelMap[selectedSchedule.status]) || '--'}
              </Text>
            </HStack>
          </Stack>

          {['started', 'diagnosed'].includes(selectedSchedule.status) && (
            <Flex flexDirection={{ base: 'column', md: 'row' }} w="full">
              <BoxWithTitle
                title="Problemas"
                titleSize="sm"
                wrapperProps={{
                  w: 'full',
                  bg: 'white',
                  p: 4,
                  rounded: 'md',
                }}
              >
                {isLoadingProblems ||
                isLoadingPhotos ||
                !scheduleRequestProblems ||
                !scheduleRequestPhotos ? (
                  <Skeleton w="full" height={8} borderRadius={20} />
                ) : (
                  <>
                    <HStack
                      display="flex"
                      flexWrap="wrap"
                      align="flex-start"
                      justify="start"
                      w="full"
                      spacing={0}
                    >
                      {scheduleRequestProblems?.map((problem) => (
                        <Tag mr="10px !important" mb={3} key={problem.id} size="sm">
                          {problem.problem.name}
                        </Tag>
                      ))}

                      {selectedSchedule?.schedule_request?.customer_problem?.length &&
                        selectedSchedule?.schedule_request?.customer_problem
                          ?.split(',')
                          ?.map((problem) => (
                            <Tag mr="10px !important" mb={3} key={problem} size="sm">
                              {problem}
                            </Tag>
                          ))}
                    </HStack>
                    <HStack w="full">
                      {scheduleRequestPhotos?.map((photo) => (
                        <ImageViewer key={photo.id}>
                          <Image
                            src={photo.photo}
                            bgPos="center"
                            bgSize="cover"
                            rounded="md"
                            shadow="md"
                            flex={1}
                            h="200px"
                            w="full"
                          />
                        </ImageViewer>
                      ))}
                    </HStack>
                  </>
                )}
              </BoxWithTitle>
            </Flex>
          )}

          {!['started'].includes(selectedSchedule.status) && diagnosisPhotos?.length > 0 && (
            <VStack w="full" p={4} rounded="md" bg="white" alignItems="flex-start" spacing="5">
              <Text fontSize="sm" fontWeight="bold">
                Fotos do diagnóstico
              </Text>
              <HStack
                display="flex"
                flexWrap="wrap"
                align="flex-start"
                justify="start"
                w="full"
                spacing={0}
              >
                {isLoadingDiagnosisPhotos || !diagnosisPhotos ? (
                  <Skeleton w="full" height={8} borderRadius={20} />
                ) : (
                  diagnosisPhotos?.length > 0 && (
                    <HStack w="full">
                      {diagnosisPhotos?.map((photo) => (
                        <ImageViewer key={photo.id}>
                          <Image
                            src={photo.photo}
                            bgPos="center"
                            bgSize="cover"
                            rounded="md"
                            shadow="md"
                            flex={1}
                            h="100px"
                            w="full"
                          />
                        </ImageViewer>
                      ))}
                    </HStack>
                  )
                )}
              </HStack>
            </VStack>
          )}

          {selectedSchedule.schedule_request.comment && (
            <Flex flexDirection={{ base: 'column', md: 'row' }} w="full">
              <BoxWithTitle
                title="Observação do cliente"
                titleSize="sm"
                wrapperProps={{
                  w: 'full',
                  bg: 'white',
                  p: 4,
                  rounded: 'md',
                }}
              >
                <Text>{selectedSchedule.schedule_request.comment}</Text>
              </BoxWithTitle>
            </Flex>
          )}

          <Flex flexDirection={{ base: 'column', md: 'row' }} w="full">
            <BoxWithTitle
              title="Serviços"
              titleSize="sm"
              wrapperProps={{
                w: 'full',
                bg: 'white',
                p: 4,
                rounded: 'md',
              }}
            >
              {isLoadingDiagnosis || !diagnosis ? (
                <Skeleton w="full" height={8} borderRadius={20} />
              ) : (
                diagnosis &&
                selectedSchedule.status !== 'started' && (
                  <>
                    <VStack justify="flex-start" align="flex-start" w="full" spacing={0}>
                      {diagnosis[0]?.schedule_services_list.map((service) => (
                        <VStack pb={5} w="full" align="flex-start" key={service?.id}>
                          <Tag
                            display="flex"
                            alignItems="flex-start"
                            justifyContent="space-between"
                            rounded="none"
                            w="full"
                            marginInlineStart="0px !important"
                            marginStart="0px !important"
                            border="none"
                            px={0}
                            py={0}
                            mb={3}
                          >
                            <TagLabel
                              ml={0}
                              marginInlineStart="0px !important"
                              marginStart="0px !important"
                              mr={5}
                            >
                              <Text fontSize="sm" fontWeight="bold" mb={2}>
                                {service.repairshop_service.service.name}
                              </Text>
                            </TagLabel>
                            <Text fontSize="sm" fontWeight="bold">
                              {Number(service.price).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </Text>
                          </Tag>

                          <Divider />
                        </VStack>
                      ))}

                      {diagnosis?.length > 0 &&
                        custom_repair_shop_services &&
                        custom_repair_shop_services?.map((service) => (
                          <VStack pb={5} w="full" align="flex-start" key={service?.id}>
                            <Tag
                              display="flex"
                              alignItems="flex-start"
                              justifyContent="space-between"
                              rounded="none"
                              w="full"
                              marginInlineStart="0px !important"
                              marginStart="0px !important"
                              border="none"
                              px={0}
                              py={0}
                              mb={3}
                            >
                              <TagLabel
                                ml={0}
                                marginInlineStart="0px !important"
                                marginStart="0px !important"
                                mr={5}
                              >
                                <Text fontSize="sm" fontWeight="bold" mb={2}>
                                  {service.service}
                                </Text>
                              </TagLabel>
                              <Text fontSize="sm" fontWeight="bold">
                                {Number(service.price).toLocaleString('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL',
                                })}
                              </Text>
                            </Tag>

                            <Divider />
                          </VStack>
                        ))}

                      <HStack flexDir={{ base: 'column', md: 'row' }} w="full" pt={0}>
                        {diagnosis?.length > 0 && (
                          <Flex w="full" align="center">
                            <Text
                              display="flex"
                              alignItems="center"
                              w={{ base: 'full', md: 'fit-content' }}
                              justifyContent="space-between"
                              mr={{ base: 0, md: 5 }}
                            >
                              Valor total dos serviços
                              <chakra.span ml={3} fontWeight="bold" fontSize="lg">
                                {Number(
                                  Number(
                                    diagnosis[0]?.schedule_services_list?.reduce(
                                      (acc, curr) => acc + Number(curr.price),
                                      0,
                                    ) || 0,
                                  ) +
                                    Number(
                                      custom_repair_shop_services
                                        ? custom_repair_shop_services?.reduce(
                                            (acc, curr) => acc + Number(curr.price),
                                            0,
                                          )
                                        : 0,
                                    ),
                                ).toLocaleString('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL',
                                })}
                              </chakra.span>
                            </Text>
                          </Flex>
                        )}
                      </HStack>
                    </VStack>
                  </>
                )
              )}
            </BoxWithTitle>
          </Flex>

          <Flex flexDirection={{ base: 'column', md: 'row' }} w="full">
            <BoxWithTitle
              title="Peças"
              titleSize="sm"
              wrapperProps={{
                w: 'full',
                bg: 'white',
                p: 4,
                rounded: 'md',
              }}
            >
              {isLoadingDiagnosis && !diagnosis ? (
                <Skeleton w="full" height={8} borderRadius={20} />
              ) : (
                <HStack
                  display="flex"
                  flexWrap="wrap"
                  align="center"
                  justify="start"
                  w="full"
                  spacing={0}
                >
                  <VStack justify="flex-start" align="flex-start" w="full" spacing={0}>
                    {diagnosis[0]?.schedule_parts?.map(
                      ({ attributes, repair_shop_part, price, quantity, manufacturer }) => (
                        <VStack mb={5} w="full" align="flex-start" key={repair_shop_part?.id}>
                          <VStack w="full" h="full" align="flex-start" justify="flex-start">
                            <VStack
                              flexDir="row"
                              w="full"
                              justify="space-between"
                              align="center"
                              mb={3}
                              spacing={3}
                              h="full"
                            >
                              <VStack
                                mr={{ base: 0, md: 16, lg: 5 }}
                                w="full"
                                align="flex-start"
                                h="full"
                              >
                                <Text fontSize="xs">Peça</Text>
                                <Text fontSize="sm" fontWeight="bold">
                                  {repair_shop_part?.part?.name}
                                </Text>
                              </VStack>

                              <VStack
                                mt="0px !important"
                                marginInlineStart="0px !important"
                                w="full"
                                align="flex-start"
                                h="full"
                              >
                                <Text fontSize="xs">Preço por peça</Text>
                                <Text fontSize="sm" fontWeight="bold">
                                  {Number(price).toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL',
                                  })}
                                </Text>
                              </VStack>

                              <VStack
                                marginInlineStart="0px !important"
                                pl={{ base: 0, md: 8 }}
                                w="full"
                                align="flex-start"
                                h="full"
                                mt="0px !important"
                              >
                                <Text fontSize="xs">Quantidade</Text>
                                <Text fontSize="sm" fontWeight="bold">
                                  {handleLabels(repair_shop_part?.part, quantity)}
                                </Text>
                              </VStack>

                              <VStack
                                marginInlineStart="0px !important"
                                pl={{ base: 0, md: 8 }}
                                w="full"
                                align="flex-start"
                                h="full"
                                mt="0px !important"
                              >
                                <Text fontSize="xs">Fabricante</Text>
                                <Text fontSize="sm" fontWeight="bold">
                                  {manufacturer.name}
                                </Text>
                              </VStack>
                            </VStack>
                          </VStack>

                          {attributes?.length > 0 && (
                            <VStack
                              mr={{ base: 0, md: 16, lg: 5 }}
                              w="full"
                              align="flex-start"
                              h="full"
                            >
                              <Text fontSize="xs">Atributos</Text>
                              <HStack
                                display="flex"
                                flexWrap="wrap"
                                align="center"
                                justify="start"
                                w="full"
                                pb={5}
                              >
                                {attributes?.map(({ name }) => (
                                  <Box
                                    marginInlineStart="0px !important"
                                    marginInlineEnd="3px !important"
                                    key={name}
                                    mt="5px !important"
                                    mb="5px !important"
                                  >
                                    <Tag>
                                      <Text marginTop="0px !important">{name}</Text>
                                    </Tag>
                                  </Box>
                                ))}
                              </HStack>
                            </VStack>
                          )}

                          <Divider />
                        </VStack>
                      ),
                    )}

                    {diagnosis?.length > 0 && (
                      <>
                        {custom_repair_shop_parts &&
                          custom_repair_shop_parts?.map((item) => (
                            <VStack mb={5} pb={5} w="full" align="flex-start" key={item?.id}>
                              <VStack w="full" h="full" align="flex-start" justify="flex-start">
                                <VStack
                                  flexDir="row"
                                  w="full"
                                  justify="space-between"
                                  align="center"
                                  mb={3}
                                  spacing={3}
                                  h="full"
                                >
                                  <VStack
                                    mr={{ base: 0, md: 16, lg: 5 }}
                                    w="full"
                                    align="flex-start"
                                    h="full"
                                  >
                                    <Text fontSize="xs">Peça</Text>
                                    <Text fontSize="sm" fontWeight="bold">
                                      {item.name}
                                    </Text>
                                  </VStack>

                                  <VStack
                                    mt="0px !important"
                                    marginInlineStart="0px !important"
                                    w="full"
                                    align="flex-start"
                                    h="full"
                                  >
                                    <Text fontSize="xs">Preço</Text>
                                    <Text fontSize="sm" fontWeight="bold">
                                      {Number(item.price).toLocaleString('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                      })}
                                    </Text>
                                  </VStack>

                                  <VStack
                                    marginInlineStart="0px !important"
                                    pl={{ base: 0, md: 8 }}
                                    w="full"
                                    align="flex-start"
                                    h="full"
                                    mt="0px !important"
                                  >
                                    <Text fontSize="xs">Quantidade</Text>
                                    <Text fontSize="sm" fontWeight="bold">
                                      {handleCustomLabels(item.quantity)}
                                    </Text>
                                  </VStack>

                                  <VStack
                                    marginInlineStart="0px !important"
                                    pl={{ base: 0, md: 8 }}
                                    w="full"
                                    align="flex-start"
                                    h="full"
                                    mt="0px !important"
                                  >
                                    <Text fontSize="xs">Fabricante</Text>
                                    <Text fontSize="sm" fontWeight="bold">
                                      {item.manufacturer}
                                    </Text>
                                  </VStack>
                                </VStack>
                              </VStack>

                              <Divider />
                            </VStack>
                          ))}

                        <HStack flexDir={{ base: 'column', md: 'row' }} w="full" pt={5}>
                          <Flex w="full" align="center">
                            <Text
                              display="flex"
                              alignItems="center"
                              w={{ base: 'full', md: 'fit-content' }}
                              justifyContent="space-between"
                              mr={{ base: 0, md: 5 }}
                            >
                              Valor total das peças
                              <chakra.span ml={3} fontWeight="bold" fontSize="lg">
                                {Number(
                                  Number(
                                    diagnosis[0]?.schedule_parts?.reduce(
                                      (acc, curr) => acc + curr.price * curr.quantity,
                                      0,
                                    ),
                                  ) +
                                    Number(
                                      custom_repair_shop_parts
                                        ? custom_repair_shop_parts?.reduce(
                                            (acc, curr) => acc + curr.price * Number(curr.quantity),
                                            0,
                                          )
                                        : 0,
                                    ),
                                ).toLocaleString('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL',
                                })}
                              </chakra.span>
                            </Text>
                          </Flex>
                        </HStack>
                      </>
                    )}
                  </VStack>
                </HStack>
              )}
            </BoxWithTitle>
          </Flex>

          <VStack w="full" p={4} rounded="md" bg="white" flex="1" align="flex-end" mb={10}>
            <HStack w="full" justify="space-between">
              <Text fontSize="md" fontWeight="bold">
                Total
              </Text>
              {isLoadingDiagnosis || !diagnosis ? (
                <Skeleton w="sm" height={8} borderRadius={20} />
              ) : selectedSchedule?.status === 'diagnostic_canceled_by_repairshop' ? (
                <Text fontSize="md" fontWeight="bold">
                  {Number(0).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Text>
              ) : (
                <Text fontSize="md" fontWeight="bold">
                  {Number(diagnosis[0]?.price).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Text>
              )}
            </HStack>
          </VStack>

          {selectedSchedule?.status === 'in_progress' && (
            <Stack w="100%" alignItems="stretch" flexDirection={{ base: 'column', md: 'row' }}>
              <VStack p={4} rounded="md" bg="white" alignItems="flex-start" flex="1" w="100%">
                <Box alignSelf="flex-start" flexDirection="column" alignItems="flex-start" w="100%">
                  <HStack mb={3} w="100%">
                    <Text fontSize="md" fontWeight="bold">
                      Serviço em andamento
                    </Text>
                  </HStack>
                  <Box m={0} alignItems="flex-start" flexDirection="column" w="100%">
                    <Flex
                      w={{ base: '100%', lg: '50%' }}
                      mb={3}
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Text fontSize="sm">Total pago</Text>
                      {diagnosis && (
                        <Text fontSize="sm">
                          {Number(diagnosis[0].price).toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </Text>
                      )}
                    </Flex>
                    <Flex
                      w={{ base: '100%', lg: '50%' }}
                      mb={3}
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Text fontSize="sm">Previsão para finalizar</Text>
                      <Text fontSize="sm">{expirationTime}</Text>
                    </Flex>
                  </Box>
                </Box>

                <SimpleProgressBar
                  mx="0px !important"
                  w={{ base: '100%', lg: '60%' }}
                  height="15px"
                  bg="white"
                  value={progress}
                />
              </VStack>
            </Stack>
          )}

          <VStack w="full" justify="space-between" align="center">
            {['finished', 'diagnostic_canceled_by_repairshop'].includes(selectedSchedule.status) ? (
              <>
                <Flex
                  flexDirection={{ base: 'column', md: 'row' }}
                  bg="white"
                  p={4}
                  rounded="md"
                  w="full"
                >
                  <HStack
                    flexDir={{ base: 'column', lg: 'row' }}
                    pt="5"
                    align="flex-start"
                    spacing="7"
                    marginInlineStart={0}
                    w="full"
                  >
                    <HStack mb={{ base: 3, lg: 0 }} mr={{ base: 0, lg: 3 }}>
                      <Text fontSize="md">Serviço finalizado</Text>
                      <BsCheckCircle />
                    </HStack>
                    <VStack
                      w={{ base: 'full', lg: 'fit-content' }}
                      align="flex-start"
                      marginInlineStart="0px !important"
                    >
                      <Text fontSize="md" fontWeight="bold">
                        Duração
                      </Text>
                      <Text fontSize="md">{diff}</Text>
                    </VStack>
                  </HStack>
                </Flex>

                <Box alignSelf="flex-start" pt="4">
                  <Button
                    variant="@colorless"
                    onClick={() => setSelectedSchedule && setSelectedSchedule(null)}
                  >
                    Voltar para agendamentos
                  </Button>
                </Box>
              </>
            ) : (
              <HStack
                display="flex"
                alignSelf="flex-start"
                w="full"
                justify="space-between"
                flexDir={{ base: 'column', md: 'row' }}
                align={{ base: 'flex-start', md: 'center' }}
              >
                <Flex
                  flexDir={{ base: 'column', lg: 'row' }}
                  align={{ base: 'flex-start', lg: 'center' }}
                >
                  <Button
                    mr={{ base: 0, lg: 3 }}
                    mb={{ base: 5, lg: 0 }}
                    variant="@colorless"
                    onClick={async () => {
                      await updateDiagnosis(diagnosis[0].id, 'no');
                      await updateSchedule(selectedSchedule.id, {
                        status: 'diagnostic_canceled_by_user',
                      });
                      setSelectedSchedule && setSelectedSchedule(null);
                    }}
                  >
                    {selectedSchedule &&
                      diagnosisActionButtonMap[selectedSchedule.status].cancelAction.label}
                  </Button>

                  <Button
                    disabled={selectedSchedule.status === 'diagnosed'}
                    variant="@primary"
                    mb={{ base: 3, md: 0 }}
                    onClick={async () => {
                      if (setSelectedSchedule && selectedSchedule)
                        if (selectedSchedule?.status === 'diagnostic_accepted_by_user') {
                          await updateSchedule(selectedSchedule.id, { status: 'in_progress' });
                        } else {
                          await updateSchedule(selectedSchedule.id, { status: 'finished' });
                        }
                      setSelectedSchedule && setSelectedSchedule(null);
                    }}
                  >
                    {selectedSchedule &&
                      diagnosisActionButtonMap[selectedSchedule.status].action.label}
                  </Button>
                </Flex>

                {!['finished', 'diagnostic_canceled_by_repairshop'].includes(
                  selectedSchedule.status,
                ) && (
                  <HStack>
                    <Button
                      mb={{ base: 3, md: 0 }}
                      size="sm"
                      fontSize="sm"
                      fontWeight="normal"
                      textAlign="center"
                      variant="solid"
                      onClick={async () => {
                        if (setSelectedSchedule && selectedSchedule)
                          await updateSchedule(selectedSchedule.id, {
                            status: 'diagnostic_canceled_by_repairshop',
                          });
                        setSelectedSchedule && setSelectedSchedule(null);
                      }}
                    >
                      Cancelar agendamento
                    </Button>
                  </HStack>
                )}
              </HStack>
            )}
          </VStack>
        </VStack>
      )}
    </Flex>
  );
};
