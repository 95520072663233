/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/exhaustive-deps */
import { StarIcon } from '@chakra-ui/icons';
import {
  Flex,
  Button,
  VStack,
  Stack,
  HStack,
  Text,
  Divider,
  Box,
  Tag,
  Skeleton,
  Textarea,
  useToast,
  useMediaQuery,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import { useCallback, useEffect, useRef, useState } from 'react';
import { AiFillStar } from 'react-icons/ai';
import { BsChat } from 'react-icons/bs';
import { MdKeyboardArrowLeft } from 'react-icons/md';

import { createBudgetChatNotification, createBudgetEvaluation, readNotification } from '../../api';
import { useBudgetEvaluation, useBudgetChatNotifications, useMistakes } from '../../hooks';
import { budgetLabelMap } from '../../utils';
import { ConsumerChat } from '../ConsumerChat';
import { Rating } from '../Rating';

import { BoxWithTitle } from '@/components/common/BoxWithTitle';
import { BudgetStatusProgress } from '@/components/common/BudgetStatusProgress';
import { FieldWrapper, Form, TagCheckbox } from '@/components/Form';
import { useFCM } from '@/context/AppProvider';
import { db } from '@/firebase';
import { formatArray } from '@/utils/formatArray';

export const TrackShipping = ({ selectedSchedule, setSelectedSchedule, isUnread }) => {
  const [selectedProblems, setSelectedProblems] = useState<any[] | null>([]);
  const [comment, setComment] = useState<string>();
  const [rating, setRating] = useState<number>(0);
  const [rated, setRated] = useState(false);
  const [isLoadingEvaluation, setIsLoadingEvaluation] = useState(false);
  const toast = useToast();
  const [isSmallScreen] = useMediaQuery('(max-width: 860px)');
  const [openChat, setOpenChat] = useState(false);
  const { refetchUserNotifications, consumerBudgets } = useFCM();

  const [user] = useState(() => {
    const aux = localStorage.getItem('@user');
    return aux && JSON.parse(aux);
  });

  const [socketList] = useState(consumerBudgets);

  const { data: mistakes, isLoading: isLoadingMistakes } = useMistakes({});

  const {
    data: evaluation,
    refetch,
    isLoading,
  } = useBudgetEvaluation({
    id: selectedSchedule.id,
    config: { enabled: !!selectedSchedule },
  });

  const { data: chatNotifications, refetch: refetchChatNotifications } = useBudgetChatNotifications(
    {
      id: selectedSchedule.id,
    },
  );

  const handleTagBoxChange = (value: any) => {
    if (selectedProblems) {
      setSelectedProblems((prev) => {
        if (prev) {
          if (!selectedProblems?.findIndex((item) => item.label === value.label)) {
            return selectedProblems?.filter((item) => item.label !== value.label);
          }
          return [...prev, value];
        }

        return [value];
      });
    } else {
      setSelectedProblems([value]);
    }
  };

  useEffect(() => {
    if (evaluation) {
      setRated(!!evaluation[0]);
      setRating(evaluation[0]?.stars);
    }
  }, [evaluation]);

  const markNotificationAsRead = useCallback(async () => {
    if (isUnread?.length > 0) {
      const readingNotifications = isUnread?.map(
        async (notification) => await readNotification(notification),
      );

      await Promise.all(readingNotifications).then(() => refetchUserNotifications());
    }
  }, [isUnread, refetchUserNotifications]);

  useEffect(() => {
    markNotificationAsRead();
  }, []);

  useEffect(() => {
    if (socketList !== consumerBudgets) {
      setSelectedSchedule && setSelectedSchedule(null);
    }
  }, [consumerBudgets]);

  const createNotification = async () => {
    await createBudgetChatNotification({
      store: selectedSchedule.store.id,
      budget: selectedSchedule.id,
    });
    refetchChatNotifications();
  };

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
  });

  useEffect(() => {
    db?.collection(
      `user-${selectedSchedule.budget_request.customer.id}-store-${selectedSchedule.store.id}-budget-${selectedSchedule.id}-budgetRequest-${selectedSchedule.budget_request.id}-chat`,
    )
      .orderBy('createdAt')
      .onSnapshot((querySnapShot: any) => {
        const data = querySnapShot.docs.map((doc: any) => ({
          ...doc.data(),
          id: doc.id,
        }));

        if (!isFirstRun.current) {
          if (data?.at(-1)?.sender === user.name) {
            createNotification();
            refetchChatNotifications();
          } else {
            refetchChatNotifications();
          }
        }
      });
  }, [db, selectedSchedule.user, selectedSchedule.store.id]);

  return (
    <Flex w="full" flexDirection="column">
      <HStack w="100%" alignItems="center" justifyContent="space-between" mb="4" mt={10}>
        <Button
          variant="ghost"
          leftIcon={<MdKeyboardArrowLeft />}
          alignSelf="flex-start"
          pl="0"
          mb="4"
          onClick={() => {
            if (openChat) {
              setOpenChat(!openChat);
            } else {
              setSelectedSchedule && setSelectedSchedule(null);
            }
          }}
        >
          voltar
        </Button>

        {['PAID', 'SENT', 'FINISHED'].includes(selectedSchedule.status) && (
          <Button
            size={isSmallScreen ? 'sm' : 'md'}
            variant={
              chatNotifications?.find((notification) => notification.is_read === false)
                ? '@primary'
                : '@secondary'
            }
            leftIcon={<BsChat />}
            onClick={() => setOpenChat((prev) => !prev)}
          >
            {chatNotifications?.find((notification) => notification.is_read === false)
              ? 'Nova mensagem!'
              : 'Mensagens'}
          </Button>
        )}
      </HStack>

      {['PAID', 'SENT', 'FINISHED'].includes(selectedSchedule.status) && openChat ? (
        <Stack px="2">
          <ConsumerChat
            isBudget
            selectedSchedule={selectedSchedule}
            refetchChatNotifications={refetchChatNotifications}
          />
        </Stack>
      ) : (
        <VStack>
          <Stack
            flexDirection={{ base: 'column', md: 'row' }}
            alignItems={{ base: 'flex-start', md: 'center' }}
            justify="space-between"
            spacing={{ base: 2, md: 0 }}
            w="full"
            rounded="md"
            bg="white"
            p="4"
          >
            <HStack>
              <HStack mr={3}>
                <Text color="gray.400" whiteSpace="nowrap">
                  {`${dayjs(selectedSchedule?.created_at).format('DD/MM/YYYY')}, ${dayjs(
                    selectedSchedule?.created_at,
                  ).format('HH:mm')}`}
                </Text>
              </HStack>
            </HStack>

            <HStack justifyContent="space-between" w={{ base: 'full', md: 'fit-content' }}>
              <Text
                ml={6}
                whiteSpace="nowrap"
                pos="relative"
                _before={{
                  content: '""',
                  width: 4,
                  height: 4,
                  zIndex: 1000,
                  pos: 'absolute',
                  top: '50%',
                  left: -6,
                  transform: 'translateY(-50%)',
                  border: '3px solid',
                  borderColor: 'brand.500',
                  rounded: 'full',
                }}
              >
                {budgetLabelMap[selectedSchedule.status]}
              </Text>
            </HStack>
          </Stack>

          <VStack
            w="full"
            p={4}
            rounded="md"
            bg="white"
            alignItems="flex-start"
            flex="1"
            spacing="5"
          >
            <VStack w="full" align="flex-start">
              <Text fontWeight="bold">{selectedSchedule?.store?.name}</Text>
              <HStack>
                {selectedSchedule.store_evaluation ? (
                  Array.from({ length: selectedSchedule.store_evaluation }).map((_, index) => (
                    <StarIcon key={index} color="brand.500" />
                  ))
                ) : (
                  <Text>Esta loja ainda não possui avaliações.</Text>
                )}
              </HStack>
            </VStack>
            {selectedSchedule.status === 'FINISHED' && (
              <Box border="2px solid #F8BB32" rounded="full" p={2}>
                <Text fontWeight="bold">{`Entregue dia ${dayjs(selectedSchedule.updated_at).format(
                  'DD/MM/YYYY',
                )}`}</Text>
              </Box>
            )}
          </VStack>

          <VStack w="full" p={4} rounded="md" bg="white" flex="1" align="flex-start" spacing="5">
            <VStack w="full" align="flex-start" justify="space-between">
              <Text fontSize="md" fontWeight="bold">
                Endereço
              </Text>

              <Text marginStart="0px !important" marginInlineStart="0px !important">
                {selectedSchedule?.budget_request?.shipping}
              </Text>
            </VStack>
          </VStack>

          <VStack
            w="full"
            p={4}
            rounded="md"
            bg="white"
            alignItems="flex-start"
            flex="1"
            spacing="5"
          >
            <Text fontSize="md" fontWeight="bold">
              Peças
            </Text>
            <VStack w="full">
              <VStack w="full">
                {selectedSchedule.budget_parts?.map((item) => (
                  <>
                    <VStack w="full" key={item.id} align="space-between" flexDirection="row" mb={5}>
                      <VStack align="flex-start" w="full">
                        <HStack w="full" align="space-between">
                          <VStack w="full" align="flex-start">
                            <Text fontWeight="bold">{`${item.quantity}x ${item.budget_request_part.part.name}`}</Text>
                            <Text color="gray.400">{item.manufacturer.name}</Text>
                          </VStack>

                          <VStack w="full" align="start">
                            <Text w="full" align="end" fontWeight="bold">
                              {Number(Number(item.price) * item.quantity).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </Text>
                          </VStack>
                        </HStack>

                        <HStack
                          display="flex"
                          flexWrap="wrap"
                          align="center"
                          justify="start"
                          w="full"
                        >
                          {item?.budget_request_part?.attributes?.map((attribute) => (
                            <Box
                              marginInlineStart="0px !important"
                              marginInlineEnd="3px !important"
                              key={attribute.id}
                              mt="5px !important"
                              mb="5px !important"
                            >
                              <Tag>
                                <Text marginTop="0px !important">{attribute.attribute.name}</Text>
                              </Tag>
                            </Box>
                          ))}
                        </HStack>
                      </VStack>
                    </VStack>

                    <Divider />
                  </>
                ))}
              </VStack>
            </VStack>
          </VStack>

          <VStack w="full" p={4} rounded="md" bg="white" flex="1" align="flex-end" mb={10}>
            <HStack w="full" align="flex-start" justify="space-between">
              <Text color="gray.500" fontSize="sm" fontWeight="bold">
                Frete
              </Text>

              <Text color="gray.500" fontSize="sm" fontWeight="bold">
                {Number(selectedSchedule.shipping_price).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Text>
            </HStack>
            <HStack w="full" justify="space-between">
              <Text color="gray.500" fontSize="sm" fontWeight="bold">
                Subtotal
              </Text>
              <Text color="gray.500" fontSize="sm" fontWeight="bold">
                {Number(
                  selectedSchedule?.budget_parts?.reduce(
                    (acc, part) => acc + Number(part.price) * part.quantity,
                    0,
                  ),
                ).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Text>
            </HStack>
            <HStack w="full" justify="space-between">
              <Text fontSize="md" fontWeight="bold">
                Total
              </Text>
              <Text fontSize="md" fontWeight="bold">
                {Number(
                  Number(
                    selectedSchedule?.budget_parts?.reduce(
                      (acc, part) => acc + Number(part.price) * part.quantity,
                      0,
                    ),
                  ) + Number(selectedSchedule.shipping_price),
                ).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Text>
            </HStack>
          </VStack>

          {selectedSchedule.status === 'FINISHED' && (
            <VStack w="full" p={4} rounded="md" bg="white" flex="1" align="flex-end" mb={10}>
              {isLoading || !evaluation ? (
                <Skeleton w="full" height={20} borderRadius={20} mb={8} />
              ) : (
                <VStack
                  p={4}
                  rounded="md"
                  bg="white"
                  alignItems="flex-start"
                  flex="1"
                  mr={2}
                  spacing="5"
                  w="full"
                >
                  <Text fontSize="md" fontWeight="bold">
                    {rated ? 'Avaliação' : 'Avaliar'}
                  </Text>

                  <Box>
                    {rated && rating ? (
                      <HStack>
                        {new Array(rating).fill(0).map((_, index) => (
                          <AiFillStar key={index} size={30} color="#EFA808" />
                        ))}
                      </HStack>
                    ) : (
                      <Rating rate={rating} setRate={setRating} />
                    )}
                  </Box>

                  <Box w="full" display="flex" flexWrap="wrap">
                    <Form
                      onSubmit={async () => {
                        setIsLoadingEvaluation(true);

                        const data = {
                          user: selectedSchedule.budget_request.customer.id,
                          car: selectedSchedule.budget_request.car.id,
                          stars: rating,
                          comment,
                          budget: selectedSchedule.id,
                          mistakes: selectedProblems?.map((item) => item.value),
                        };

                        try {
                          await createBudgetEvaluation(data);

                          toast({
                            title: 'Avaliação realizada com sucesso.',
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                          });

                          setIsLoadingEvaluation(false);
                          refetch();
                        } catch (error) {
                          toast({
                            title: 'Erro ao enviar avaliação. Tente novamente.',
                            status: 'error',
                            duration: 5000,
                            isClosable: true,
                          });
                          setIsLoadingEvaluation(false);
                        }
                      }}
                      initialValues={{
                        selectedProblems: [],
                        comment: '',
                      }}
                    >
                      {() => (
                        <>
                          {rated && evaluation && evaluation[0].stars < 5 ? (
                            <FieldWrapper
                              name="selected_problems"
                              as={() => (
                                <>
                                  {evaluation[0]?.mistakes?.map((item) => (
                                    <Tag key={item.id}>{item.name}</Tag>
                                  ))}
                                </>
                              )}
                            />
                          ) : (
                            rating &&
                            rating < 5 && (
                              <BoxWithTitle title="O que deu errado?" titleSize="sm">
                                {isLoadingMistakes || !mistakes ? (
                                  <Skeleton height={20} borderRadius={20} mb={8} />
                                ) : (
                                  <HStack>
                                    <FieldWrapper
                                      name="selected_problems"
                                      as={(fieldProps) => (
                                        <TagCheckbox
                                          options={formatArray(mistakes?.results) || []}
                                          additionalOnClick={handleTagBoxChange}
                                          additionalSelectedItems={selectedProblems || []}
                                          {...fieldProps}
                                        />
                                      )}
                                    />
                                  </HStack>
                                )}
                              </BoxWithTitle>
                            )
                          )}

                          {rated ? (
                            evaluation &&
                            evaluation[0]?.comment && (
                              <BoxWithTitle title="Obrigado pela avaliação" titleSize="sm">
                                <FieldWrapper
                                  name="comment"
                                  as={() => (
                                    <FieldWrapper
                                      name="comment"
                                      as={() => (
                                        <Textarea
                                          cursor="default"
                                          variant="filled"
                                          _focus={{
                                            bg: 'gray.100',
                                          }}
                                          isReadOnly
                                          resize="none"
                                          value={evaluation[0]?.comment}
                                        />
                                      )}
                                    />
                                  )}
                                />
                              </BoxWithTitle>
                            )
                          ) : (
                            <BoxWithTitle title="Conte como foi a sua experiência" titleSize="sm">
                              <FieldWrapper
                                name="comment"
                                as={() => (
                                  <FieldWrapper
                                    name="comment"
                                    as={() => (
                                      <Textarea onChange={(e) => setComment(e.target.value)} />
                                    )}
                                  />
                                )}
                              />
                            </BoxWithTitle>
                          )}

                          {!rated && (
                            <Button
                              isDisabled={!rating || !comment}
                              type="submit"
                              size="sm"
                              fontSize="sm"
                              variant="@primary"
                              isLoading={isLoadingEvaluation}
                            >
                              Enviar avaliação
                            </Button>
                          )}
                        </>
                      )}
                    </Form>
                  </Box>
                </VStack>
              )}
            </VStack>
          )}

          <VStack
            pt={5}
            sx={{
              '&::-webkit-scrollbar': {
                width: 0,
                backgroundColor: `transparent`,
              },
              '&::-webkit-scrollbar-thumb': {
                width: 0,
                backgroundColor: `transparent`,
              },
            }}
            overflow="overlay auto"
            w="full"
            align="flex-start"
          >
            <BudgetStatusProgress status={selectedSchedule.status} />
          </VStack>
        </VStack>
      )}
    </Flex>
  );
};
