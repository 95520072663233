import { Avatar } from '@chakra-ui/avatar';
import { Button } from '@chakra-ui/button';
import { Box, Flex, GridItem, HStack, SimpleGrid, Text, VStack } from '@chakra-ui/layout';
import { useToast } from '@chakra-ui/react';
import { useState } from 'react';
import { AiFillStar } from 'react-icons/ai';
import { BsStarHalf } from 'react-icons/bs';
import { useNavigate } from 'react-router';

import { useScheduleRequestStore } from '../../../schedule-request/store/schedule-request-store';
import { /* useRepairShopPhotos, */ useRepairShopRating, useRepairShopServices } from '../../hooks';

import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@/components/Tabs';
import { useAuth } from '@/context/AppProvider';
import { createSchedule } from '@/modules/schedule-request';

export const RepairShopDetail = ({ selectedRepairShop }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { user: userIsLogged } = useAuth();

  const [user] = useState(() => {
    const user = localStorage.getItem('@user');
    return user && JSON.parse(user);
  });

  const {
    actions: { reset },
  } = useScheduleRequestStore();

  const [scheduleRequest] = useState(() => {
    const scheduleRequest = localStorage.getItem('@schedule-request');
    return scheduleRequest && JSON.parse(scheduleRequest);
  });

  const { data: repairShopRating } = useRepairShopRating({
    id: selectedRepairShop.repair_shop.id,
    config: { enabled: !!selectedRepairShop },
  });

  /* const { data: photos } = useRepairShopPhotos({
    id: selectedRepairShop.repair_shop.id,
    config: { enabled: !!selectedRepairShop },
  }); */

  const { data: services } = useRepairShopServices({
    id: selectedRepairShop.repair_shop.id,
    config: { enabled: !!selectedRepairShop },
  });

  const renderStars = (rating) => {
    const stars: any[] = [];

    for (let i = 0; i < rating; i++) {
      if (Math.trunc(rating - i) > 0) {
        stars.push(<AiFillStar key={i} size={20} color="#FFC107" />);
      } else {
        stars.push(<BsStarHalf key={i} style={{ marginTop: -1 }} size={18} color="#FFC107" />);
      }
    }
    return stars;
  };

  const handleCreateSchedule = async () => {
    if (!userIsLogged) return (window.location.href = '/entrar/sc');

    if (user && scheduleRequest) {
      try {
        await createSchedule({
          status: 'started',
          start_date: new Date().toLocaleDateString('pt-BR', {
            timeZone: 'UTC',
          }),
          repair_shop: selectedRepairShop.id,
          user: user.id,
          schedule_request: scheduleRequest.id,
        });

        toast({
          title: 'Agendamento criado com sucesso.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        localStorage.removeItem('@repair-shops');
        localStorage.removeItem('@schedule-request');
        reset();

        navigate('/agendamentos');
      } catch (error) {
        toast({
          title: 'Erro ao criar agendamento. Tente novamente.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <VStack w="full" bg="gray.100" h={{ base: '90%', lg: 'full' }} top={0}>
      <VStack w="full" bg="white" rounded="md" shadow="sm" p={3} pb={4} alignItems="center">
        <VStack maxW="300px" spacing={5} w="full" mx="auto">
          <HStack w="full" spacing={5}>
            <Avatar
              src={selectedRepairShop.repair_shop.profile_pic}
              name={selectedRepairShop.repair_shop.name}
              size="lg"
            />

            <VStack w="full" alignItems="flex-start" spacing={1}>
              <Text fontSize="md">{selectedRepairShop.repair_shop.name}</Text>
              <HStack>
                {repairShopRating?.rating && renderStars(repairShopRating.rating)}
                <Text fontSize="xs">
                  {repairShopRating?.count
                    ? `(${repairShopRating?.count})`
                    : 'Esta oficina não possui avaliações.'}
                </Text>
              </HStack>

              <Text fontSize="sm" mr="20%">
                {selectedRepairShop.repair_shop.address.street},{' '}
                {selectedRepairShop.repair_shop.address.number}
              </Text>
              <Text fontSize="sm">{`${selectedRepairShop.repair_shop.open} às ${selectedRepairShop.repair_shop.closed}`}</Text>
            </VStack>
          </HStack>

          <HStack w="full" justifyContent="center" spacing={5}>
            <Button
              onClick={handleCreateSchedule}
              variant="@primary"
              size="sm"
              disabled={!userIsLogged}
            >
              Agendar
            </Button>
          </HStack>
        </VStack>
      </VStack>

      <VStack w="full" h="full" pos="relative">
        <Tabs w="full" pos="relative" h="100%">
          <TabList
            mx="auto"
            pos="absolute"
            left="50%"
            transform="translateX(-50%)"
            bg="gray.100"
            w="full"
            d="flex"
            justifyContent="center"
            align="center"
            top="0"
            pb={2}
            fontSize="md"
          >
            <Tab>
              <Text>Sobre a oficina</Text>
            </Tab>
            <Tab>
              <Text>Avaliações</Text>
            </Tab>
          </TabList>
          <TabPanels mt={38} overflowY="auto" h="full">
            <TabPanel>
              {/* <Box w="full">
                <Text fontWeight="bold" fontSize="md" mb={2}>
                  Fotos da oficina
                </Text>
                <HStack>
                  {photos && (
                    <>
                      <Box
                        bgImg={photos?.length > 0 && photos[0]?.photo}
                        bgPos="center"
                        bgSize="cover"
                        rounded="md"
                        shadow="md"
                        flex={1}
                        h="100px"
                      />
                      {photos?.length > 1 && photos?.length <= 2 ? (
                        <Box
                          bgImg={photos[1]?.photo}
                          bgPos="center"
                          bgSize="cover"
                          rounded="md"
                          shadow="md"
                          flex={1}
                          h="100px"
                        />
                      ) : (
                        <VStack flex={1}>
                          {photos?.length > 0 &&
                            photos
                              ?.slice(1, 3)
                              .map((photo) => (
                                <Box
                                  key={photo.id}
                                  bgImg={photo.photo}
                                  bgPos="center"
                                  bgSize="cover"
                                  rounded="md"
                                  shadow="md"
                                  h="50px"
                                  w="full"
                                />
                              ))}
                        </VStack>
                      )}
                    </>
                  )}
                </HStack>
              </Box> */}
              <Box w="full" mt={5}>
                <Text fontWeight="bold" fontSize="md" mb={3}>
                  Serviços da oficina
                </Text>
                <SimpleGrid columns={2} spacing={5}>
                  {services?.results?.map((service) => (
                    <GridItem key={service.id}>
                      <Box h="full" rounded="md" shadow="md" px={3} py={2} bg="white">
                        <Text fontSize="sm" mb={3}>
                          {service.service.name}
                        </Text>
                      </Box>
                    </GridItem>
                  ))}
                </SimpleGrid>
              </Box>
            </TabPanel>
            <TabPanel>
              <Flex w="full" flexDirection="column" alignItems="center">
                <Text fontWeight="bold" fontSize="sm" mb={2}>
                  Comentários de clientes
                </Text>
                {/* <VStack alignItems="flex-start">
                  <HStack>
                    <AiFillStar size={16} color="#efa808" />
                    <AiFillStar size={16} color="#efa808" />
                    <AiFillStar size={16} color="#efa808" />
                    <AiFillStar size={16} color="#efa808" />
                    <AiFillStar size={16} color="#efa808" />
                    <Text fontSize="xs">(200)</Text>
                  </HStack>
                  <HStack>
                    <AiFillStar size={16} color="#efa808" />
                    <AiFillStar size={16} color="#efa808" />
                    <AiFillStar size={16} color="#efa808" />
                    <AiFillStar size={16} color="#efa808" />
                    <AiOutlineStar size={16} color="#efa808" />
                    <Text fontSize="xs">(1000)</Text>
                  </HStack>
                  <HStack>
                    <AiFillStar size={16} color="#efa808" />
                    <AiFillStar size={16} color="#efa808" />
                    <AiFillStar size={16} color="#efa808" />
                    <AiOutlineStar size={16} color="#efa808" />
                    <AiOutlineStar size={16} color="#efa808" />
                    <Text fontSize="xs">(500)</Text>
                  </HStack>
                  <HStack>
                    <AiFillStar size={16} color="#efa808" />
                    <AiFillStar size={16} color="#efa808" />
                    <AiOutlineStar size={16} color="#efa808" />
                    <AiOutlineStar size={16} color="#efa808" />
                    <AiOutlineStar size={16} color="#efa808" />
                    <Text fontSize="xs">(50)</Text>
                  </HStack>
                  <HStack>
                    <AiFillStar size={16} color="#efa808" />
                    <AiOutlineStar size={16} color="#efa808" />
                    <AiOutlineStar size={16} color="#efa808" />
                    <AiOutlineStar size={16} color="#efa808" />
                    <AiOutlineStar size={16} color="#efa808" />
                    <Text fontSize="xs">(10)</Text>
                  </HStack>
                </VStack>

                <HStack mt={7} alignItems="flex-start">
                  <Avatar
                    src="https://d2eip9sf3oo6c2.cloudfront.net/instructors/avatars/000/000/032/medium/oapgW_Fp_400x400.jpg"
                    name="Dan Abramov"
                    size="md"
                  />
                  <VStack alignItems="flex-start" spacing={1}>
                    <Text>Carlos Ribeiro</Text>

                    <HStack>
                      <FaCarAlt color="#A3A3A3" />
                      <Text fontSize="x-small" color="gray.700">
                        Jeep Renegade
                      </Text>
                    </HStack>

                    <HStack>
                      <AiFillStar size={12} color="#efa808" />
                      <AiFillStar size={12} color="#efa808" />
                      <AiFillStar size={12} color="#efa808" />
                      <AiFillStar size={12} color="#efa808" />
                      <AiFillStar size={12} color="#efa808" />
                    </HStack>

                    <Text fontSize="xs">
                      Experiência excelente com a mecânica do Zé. Durante todo o processo me
                      mantiveram a par de tudo, atualizaram aqui na plataforma e me senti super
                      presente! Além da qualidade do que foi entregado. Nota 10!
                    </Text>
                  </VStack>
                </HStack> */}
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </VStack>
  );
};
