import { Tab as CTab } from '@chakra-ui/react';

export const Tab = ({ children, ...restProps }) => {
  return (
    <CTab
      fontSize="x-small"
      fontWeight="bold"
      pos="relative"
      px={0}
      mr={6}
      mb={2}
      mt={1}
      ml={1}
      textAlign="left"
      _hover={{
        opacity: 0.6,
      }}
      _selected={{
        opacity: 1,
        _after: {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: 'full',
          height: '0.1rem',
          bg: 'brand.500',
          transition: 'all 0.2s cubic-bezier(0.33, 1, 0.68, 1)',
        },
      }}
      _disabled={{
        opacity: 0.3,
        cursor: 'not-allowed',
      }}
      {...restProps}
    >
      {children}
    </CTab>
  );
};
