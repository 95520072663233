/* eslint-disable react-hooks/exhaustive-deps */
import { Box, HStack, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { AiOutlineClockCircle } from 'react-icons/ai';

import { BudgetRequest, BudgetsRequestList, ConsumerSidebar, TrackShipping } from '../components';
import { BudgetsList } from '../components/BudgetsList';
import { ConsumerScheduleProvider, ConsumerScheduleConsumer } from '../contexts';
import { ConsumerScheduleContextType } from '../types';

import { Page } from '@/components/Page';
import { TabList, Tabs, Tab, TabPanels, TabPanel } from '@/components/Tabs';
import { useFCM } from '@/context/AppProvider';

export const MyBudgets = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  // const [budgetRequest, setBudgetRequest] = useState();

  const [budgetRequestNotifications, setBudgetRequestNotifications] = useState<any[]>([]);
  const [budgetNotifications, setBudgetNotifications] = useState<any[]>([]);
  // const [isLoaded, setIsLoaded] = useState(false);

  const {
    consumerBudgetRequests,
    refetchConsumerBudgetRequests,
    consumerBudgets,
    refetchConsumerBudgets,
    userNotifications,
  } = useFCM();

  useEffect(() => {
    setBudgetNotifications(
      userNotifications?.filter((notification) =>
        ['CUSTOMER_PURCHASED_PARTS', 'CUSTOMER_BUDGET_SENT', 'CUSTOMER_BUDGET_FINISHED'].includes(
          notification.type,
        ),
      ),
    );
    setBudgetRequestNotifications(
      userNotifications?.filter((notification) =>
        [
          'CUSTOMER_BUDGET_REQUEST',
          'CUSTOMER_BUDGET_ORDER',
          'BUDGET_ORDER',
          'DIAGNOSIS_BUDGETED',
          'CUSTOMER_BUDGET_BUDGETED',
        ].includes(notification.type),
      ),
    );
  }, [userNotifications]);

  /*   useEffect(() => {
    if (
      consumerBudgetRequests !== undefined &&
      consumerBudgets !== undefined &&
      userNotifications !== undefined
    ) {
      setIsLoaded(true);
    }
  }, []); */

  return (
    <Page title="Meus Pedidos" pos="relative" minH="100vh">
      <ConsumerScheduleProvider>
        <ConsumerScheduleConsumer>
          {({ selectedSchedule, setSelectedSchedule }: ConsumerScheduleContextType) => {
            return (
              <ConsumerSidebar>
                {selectedSchedule ? (
                  activeTab === 0 ? (
                    <BudgetRequest
                      // isLoaded={isLoaded}
                      selectedSchedule={selectedSchedule}
                      setSelectedSchedule={setSelectedSchedule}
                      isUnread={budgetRequestNotifications?.filter(
                        (notification) => notification.object_pk === selectedSchedule.id,
                      )}
                    />
                  ) : (
                    <TrackShipping
                      selectedSchedule={selectedSchedule}
                      setSelectedSchedule={setSelectedSchedule}
                      isUnread={budgetNotifications?.filter(
                        (notification) => notification.object_pk === selectedSchedule.id,
                      )}
                    />
                  )
                ) : (
                  <>
                    <HStack alignSelf="flex-start" mb={5}>
                      <AiOutlineClockCircle />
                      <Text fontSize="xl">Meus pedidos</Text>
                    </HStack>
                    <Tabs index={activeTab}>
                      <TabList bg="gray.100" w="full" d="flex">
                        <Tab onClick={async () => setActiveTab(0)} fontSize="sm">
                          Pedidos de orçamento
                          {budgetRequestNotifications?.length > 0 &&
                            consumerBudgetRequests?.length > 0 && (
                              <Text
                                display="absolute"
                                color="black"
                                fontSize="xs"
                                w={4}
                                h={4}
                                align="center"
                                rounded="full"
                                bg="brand.500"
                                ml={3}
                              >
                                {budgetRequestNotifications.length}
                              </Text>
                            )}
                        </Tab>
                        <Tab onClick={async () => setActiveTab(1)} fontSize="sm">
                          Orçamentos escolhidos
                          {budgetNotifications?.length > 0 && (
                            <Text
                              display="absolute"
                              color="black"
                              fontSize="xs"
                              w={4}
                              h={4}
                              align="center"
                              rounded="full"
                              bg="brand.500"
                              ml={3}
                            >
                              {budgetNotifications.length}
                            </Text>
                          )}
                        </Tab>
                      </TabList>
                      <TabPanels>
                        <TabPanel>
                          <Box w="full">
                            <BudgetsRequestList
                              activeTab={activeTab}
                              refetch={refetchConsumerBudgetRequests}
                              consumerBudgets={consumerBudgetRequests}
                            />
                          </Box>
                        </TabPanel>
                        <TabPanel>
                          <Box w="full">
                            <BudgetsList
                              activeTab={activeTab}
                              refetch={refetchConsumerBudgets}
                              consumerBudgets={consumerBudgets}
                            />
                          </Box>
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </>
                )}
              </ConsumerSidebar>
            );
          }}
        </ConsumerScheduleConsumer>
      </ConsumerScheduleProvider>
    </Page>
  );
};
