import { useQuery } from 'react-query';

import { getBudget } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseBudgetOptions = {
  id: number;
  config?: QueryConfig<typeof getBudget>;
};

export const useBudget = ({ id, config }: UseBudgetOptions) => {
  return useQuery(['getBudget', id], getBudget, config);
};
