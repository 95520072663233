import { useQuery } from 'react-query';

import { getUserScheduleNotifications } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseChatNotificationsOptions = {
  id?: number;
  config?: QueryConfig<typeof getUserScheduleNotifications>;
};

export const useScheduleChatNotifications = ({ id, config }: UseChatNotificationsOptions) => {
  return useQuery(['getUserScheduleNotifications', id], getUserScheduleNotifications, config);
};
