import { useQuery } from 'react-query';

import { getBudgetRequestParts } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseBudgetRequestPartsOptions = {
  id: number;
  config?: QueryConfig<typeof getBudgetRequestParts>;
};

export const useBudgetRequestParts = ({ id, config }: UseBudgetRequestPartsOptions) => {
  return useQuery(['getBudgetRequestParts', id], getBudgetRequestParts, config);
};
