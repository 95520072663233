import { Box, Divider, Flex, Stack, Text, VStack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useCallback, useEffect, useState } from 'react';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { BsCalendar } from 'react-icons/bs';
import { GrLocation } from 'react-icons/gr';

dayjs.extend(customParseFormat);

import { RepairShop } from '../../../repair-shop/api/types';
import { readNotification } from '../../api';
import { useConsumerSchedule } from '../../contexts';

import { SimpleProgressBar } from '@/components/common/SimpleProgressBar';
import { useFCM } from '@/context/AppProvider';
import { diagnosisLabelMap } from '@/modules/repair-shop';
import { getWeekDay } from '@/utils';
import { getMonth } from '@/utils/getMonth';
import { getProgress } from '@/utils/getProgress';

export interface ScheduleCardProps {
  repairShop: RepairShop;
  schedule: any;
}

export const ScheduleCard = ({ scheduleInfo }) => {
  const { userNotifications, refetchUserNotifications } = useFCM();
  const { setSelectedSchedule } = useConsumerSchedule();
  const { start_date, end_date, schedule_request } = scheduleInfo;
  const formattedDate = dayjs(schedule_request.preferred_date.slice(0, 10), 'DD/MM/YYYY');

  const [isUnread, setIsUnread] = useState(
    userNotifications?.find((notification) => notification.object_pk === scheduleInfo.id),
  );

  useEffect(
    () =>
      setIsUnread(
        userNotifications?.find((notification) => notification.object_pk === scheduleInfo.id),
      ),
    [userNotifications, scheduleInfo],
  );

  const progressCallback = useCallback(
    () =>
      getProgress({
        start_date: start_date,
        end_date: end_date,
      }),
    [start_date, end_date],
  );

  const [progress, setProgress] = useState<any>(progressCallback());

  const handleExpirationTime = useCallback(async () => {
    const timer = setTimeout(() => {
      setProgress(
        getProgress({
          start_date: start_date,
          end_date: end_date,
        }),
      );
    }, 1000);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [end_date, start_date, progress]);

  useEffect(() => {
    handleExpirationTime();
  }, [handleExpirationTime]);

  return (
    <Stack
      rounded="md"
      bg={isUnread ? 'white' : '#E7EDF6'}
      p="4"
      cursor="pointer"
      flexDirection="column"
      spacing={2}
      mr={{ base: 0, md: 4 }}
      mb={4}
      justifyContent="space-between"
      onClick={async () => {
        setSelectedSchedule(scheduleInfo);

        if (isUnread) {
          await readNotification(isUnread);
          refetchUserNotifications();
        }
      }}
    >
      <VStack alignItems="flex-start" w="full" spacing="1">
        <Text textAlign="left" fontSize="md">
          {scheduleInfo.repair_shop.name}
        </Text>
        <Text textAlign="left" color="gray.600" fontSize="xs" d="flex" alignItems="center">
          <GrLocation style={{ marginRight: '5px' }} />
          {`${scheduleInfo.repair_shop.address.street}, ${scheduleInfo.repair_shop.address.number}`}
        </Text>
        <Flex my="10px !important">
          <Text
            textAlign="left"
            color="gray.600"
            fontSize="xs"
            d="flex"
            alignItems="center"
            bg="brand.500"
            rounded="full"
            py={0}
            px={2}
            mr="10px"
          >
            <AiOutlineClockCircle style={{ marginRight: '5px' }} />
            {schedule_request.preferred_date.slice(11)}
          </Text>
          <Text textAlign="left" color="gray.600" fontSize="xs" d="flex" alignItems="center">
            <BsCalendar style={{ marginRight: '5px' }} />
            {`${getWeekDay(formattedDate.toDate().getDay())}, ${formattedDate
              .toDate()
              .getDate()} de ${getMonth(formattedDate.toDate().getMonth())}`}
          </Text>
        </Flex>
      </VStack>
      <Divider mb="5px !important" />
      <Flex alignItems="center">
        <Text
          ml={6}
          whiteSpace="nowrap"
          pos="relative"
          fontSize="xs"
          fontWeight="semibold"
          color="#21272A"
          _before={{
            content: '""',
            width: 4,
            height: 4,
            zIndex: 1000,
            pos: 'absolute',
            top: '50%',
            left: -6,
            transform: 'translateY(-50%)',
            border: '3px solid',
            borderColor: 'brand.500',
            rounded: 'full',
          }}
        >
          {scheduleInfo.status === 'in_progress'
            ? 'Em progresso'
            : diagnosisLabelMap[scheduleInfo.status]}
        </Text>

        {scheduleInfo.status === 'in_progress' && progress !== -1 && (
          <Box w="100%" px="10px">
            <SimpleProgressBar value={progress} />
          </Box>
        )}
      </Flex>
    </Stack>
  );
};
