import { BudgetRequestForm } from '../components/BudgetRequestForm';

import { Page } from '@/components/Page';

export const BudgetRequest = () => {
  return (
    <Page title="Encontrar peças" pos="relative" minH={{ base: 'initial', lg: '100vh' }}>
      <BudgetRequestForm />
    </Page>
  );
};
