import { ScheduleRequestForm } from '../components/ScheduleRequestForm';
import { ScheduleRequestSelectRepairShop } from '../components/ScheduleRequestSelectRepairShop';

import { Page } from '@/components/Page';
import { useFCM } from '@/context/AppProvider';

export const ScheduleRequest = () => {
  const { scheduleRequest, scheduleRequestResponse } = useFCM();

  return (
    <Page title="Encontrar oficinas" pos="relative" minH={{ base: 'initial', lg: '100vh' }}>
      {scheduleRequest ? (
        <ScheduleRequestSelectRepairShop scheduleRequestResponse={scheduleRequestResponse} />
      ) : (
        <ScheduleRequestForm />
      )}
    </Page>
  );
};
