import { createContext, useContext, useState } from 'react';

import { SellerScheduleContextType } from '../types';

const SellerScheduleContext = createContext({} as SellerScheduleContextType);

export const SellerRequest = ({ children }) => (
  <SellerScheduleContext.Consumer>{children}</SellerScheduleContext.Consumer>
);

export const SellerProvider = ({ children }) => {
  const [selectedSchedule, setSelectedSchedule] = useState<any | null>(null);

  return (
    <SellerScheduleContext.Provider value={{ setSelectedSchedule, selectedSchedule }}>
      {children}
    </SellerScheduleContext.Provider>
  );
};

export const useSellerRequest = () => useContext(SellerScheduleContext);
