/* eslint-disable react-hooks/exhaustive-deps */
import { VStack } from '@chakra-ui/react';
import { useEffect } from 'react';

import { OrdersCard } from './OrdersCard';

import { useFCM } from '@/context/AppProvider';

export const Orders = ({ activeTab }) => {
  const { storeOrders, refetchStoreOrders } = useFCM();

  useEffect(() => {
    activeTab === 2 && refetchStoreOrders();
  }, [activeTab]);

  return (
    <VStack align="flex-start" maxH={{ base: '80vh', md: '400px' }} overflowY="auto">
      {storeOrders?.map((storeSchedule) => (
        <OrdersCard key={storeSchedule.id} schedule={storeSchedule} />
      ))}
    </VStack>
  );
};
