import { CarManufacturer, CarModel, Car, CreatedSchedule } from './types';

import axios from '@/lib/axios';

export const getParts = (pageParam, queryKey): Promise<any> => {
  const [_, search] = queryKey;

  return axios.authorized({}).get(`/part/?page=${pageParam}&name=${search || ''}`);
};

export const getManufacturers = (): Promise<any> => {
  return axios.authorized({}).get('/part-manufacturer/');
};

export const getCars = (): Promise<Car[]> => {
  return axios.authorized({}).get('/car-user/all-user-cars/');
};

export const getCarManufacturers = (pageParam, queryKey): Promise<CarManufacturer> => {
  const [_, search] = queryKey;

  if (search?.length > 0)
    return axios.authorized({}).get(`/car-manufacturer?page=${pageParam}&name=${search || ''}`);

  return axios.authorized({}).get(`/car-manufacturer?page=${pageParam}`);
};

export const getCarModels = (pageParam, queryKey): Promise<CarModel> => {
  const [_, search, id] = queryKey;

  return axios
    .authorized({})
    .get(`/car-model/?manufacturer=${id}&page=${pageParam}&name=${search || ''}`);
};

export const getSpecificModelCars = (pageParam, queryKey): Promise<any> => {
  const [_, search, id] = queryKey;

  return axios.authorized({}).get(`/car?model=${id}&page=${pageParam}&name=${search || ''}`);
};

export const createBudgetRequest = (budgetRequest: any): Promise<any> => {
  return axios.authorized({}).post('/budget-request/', budgetRequest);
};

export const createBudgetRequestPart = (budgetRequestPart: any): Promise<any> => {
  return axios.authorized({}).post('/budget-request-part/', budgetRequestPart);
};

export const getScheduleRequest = (id: number): Promise<any> => {
  return axios.authorized({}).get(`/schedule-request-repairshop/${id}/by-schedule-request/`);
};

export const createScheduleRequestPhotos = (data) => {
  return axios.authorized({}).post('/schedule-request-photo', data);
};

export const createScheduleRequestProblem = (data) => {
  return axios.authorized({}).post('/schedule-request-problem', data);
};

export const createCar = (data): Promise<any> => {
  return axios.authorized({}).post('/car-user/', data);
};

export const createSchedule = (schedule: any): Promise<CreatedSchedule> => {
  return axios.authorized({}).post('/schedule/', schedule);
};

export const getRepairShopAddress = (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;
  return axios.authorized({}).get(`/address/${id}`);
};

export const getBudgetRequestResponse = (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;
  return axios.authorized({}).get(`/budget/${id}/budgeted/`);
};

export const deleteScheduleRequest = (id) => {
  return axios.authorized({}).delete(`/schedule-request-repairshop/${id}/`);
};

export const getScheduleRequestPhotos = (queryObject): Promise<any[]> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`/schedule-request-photo/${id}/by-schedule-request/`);
};

export const getScheduleRequestProblems = (queryObject): Promise<any[]> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`/schedule-request-problem/${id}/by-schedule-request/`);
};

export const getScheduleRequestCar = (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`/car-user/${id}/`);
};

export const getBudgetRequests = (): Promise<any> => {
  return axios.authorized({}).get(`/schedule-request-repairshop/all-repairshop-schedule-request/`);
};

export const createScheduleRequestPhoto = (data) => {
  return axios.unauthorized({}).post('/schedule-request-photo/', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const createScheduleRequestProblemData = (data) => {
  return axios.unauthorized({}).post('/schedule-request-problem/', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const deleteUserCar = (id) => {
  return axios.authorized({}).delete(`/car-user/${id}/`);
};

export const createBudgetRequestPartAttribute = (data) => {
  return axios.authorized({}).post('/budget-request-part-attribute/', data);
};

export const getBudgetPartAttribute = (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`/budget-request-part-attribute/${id}/by-budget-request/`);
};

export const getBudgetRequestById = (id): Promise<any> => {
  return axios.authorized({}).get(`/budget-request/${id}/`);
};
