import { Problem, Car, CreatedSchedule, CreatedScheduleRequest, CarModelResponse } from './types';

import axios from '@/lib/axios';

export const getProblems = (pageParam, queryKey): any => {
  const [_, search] = queryKey;

  return axios.authorized({}).get(`/problem/?page=${pageParam}&name=${search || ''}`);
};

export const getSuggestedProblems = (): Promise<Problem[]> => {
  return axios.authorized({}).get('/problem/suggested');
};

export const getCars = (): Promise<Car[]> => {
  return axios.authorized({}).get('/car-user/all-user-cars/');
};

export const getSpecificModelCars = (pageParam, queryKey): Promise<any> => {
  const [_, search, id] = queryKey;

  return axios.authorized({}).get(`/car?model=${id}&page=${pageParam}&name=${search || ''}`);
};

export const getCarManufacturers = (): Promise<any> => {
  return axios.authorized({}).get('/car-manufacturer');
};

export const getCarModels = (pageParam, queryKey): Promise<CarModelResponse> => {
  const [_, search, id] = queryKey;

  return axios
    .authorized({})
    .get(`/car-model/?manufacturer=${id}&page=${pageParam}&name=${search || ''}`);
};

export const createScheduleRequest = (scheduleRequest: any): Promise<CreatedScheduleRequest> => {
  return axios.authorized({}).post('/schedule-request/', scheduleRequest);
};

export const getScheduleRequest = (id: number): Promise<any> => {
  return axios.authorized({}).get(`/schedule-request-repairshop/${id}/by-schedule-request/`);
};

export const createScheduleRequestPhotos = (data) => {
  return axios.authorized({}).post('/schedule-request-photo', data);
};

export const createScheduleRequestProblem = (data) => {
  return axios.authorized({}).post('/schedule-request-problem/', data);
};

export const createCar = (data) => {
  return axios.authorized({}).post('/car-user/', data);
};

export const createSchedule = (schedule: any): Promise<CreatedSchedule> => {
  return axios.authorized({}).post('/schedule/', schedule);
};

export const getRepairShopAddress = (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;
  return axios.authorized({}).get(`/address/${id}`);
};

export const getScheduleRequestResponse = (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;
  return axios.authorized({}).get(`/schedule-request-repairshop/${id}/by-schedule-request/`);
};

export const deleteScheduleRequest = (id) => {
  return axios.authorized({}).delete(`/schedule-request-repairshop/${id}/`);
};

export const getScheduleRequestPhotos = (queryObject): Promise<any[]> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`/schedule-request-photo/${id}/by-schedule-request/`);
};

export const getScheduleRequestProblems = (queryObject): Promise<any[]> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`/schedule-request-problem/${id}/by-schedule-request/`);
};

export const getScheduleRequestCar = (queryObject): Promise<any> => {
  const { queryKey } = queryObject;
  const [_, id] = queryKey;

  return axios.authorized({}).get(`/car-user/${id}/`);
};

export const getScheduleRequests = (): Promise<any> => {
  return axios.authorized({}).get(`/schedule-request-repairshop/all-repairshop-schedule-request/`);
};

export const createScheduleRequestPhoto = (data) => {
  return axios.unauthorized({}).post('/schedule-request-photo/', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const createScheduleRequestProblemData = (data) => {
  return axios.unauthorized({}).post('/schedule-request-problem/', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const deleteUserCar = (id) => {
  return axios.authorized({}).delete(`/car-user/${id}/`);
};
