import { Box, BoxProps, Heading, StackProps, Text, VStack } from '@chakra-ui/layout';

type BoxWithTitleProps = {
  titleSize?: string;
  title: string;
  subtitle?: string;
  titleProps?: string;
  children: unknown;
  wrapperProps?: StackProps;
} & BoxProps;

export const BoxWithTitle = ({
  titleSize = 'md',
  title,
  subtitle,
  titleProps,
  wrapperProps,
  children,
  ...props
}: BoxWithTitleProps) => {
  return (
    <VStack spacing={5} {...wrapperProps}>
      <Box w="100%">
        <Heading as="p" fontFamily="gill" fontSize={titleSize} textAlign="left" {...titleProps}>
          {title}
        </Heading>
        {subtitle && (
          <Text textAlign="left" mt={2}>
            {subtitle}
          </Text>
        )}
      </Box>

      <Box w="full" {...props}>
        {children}
      </Box>
    </VStack>
  );
};
