/* eslint-disable react-hooks/exhaustive-deps */
import { GridItem, SimpleGrid, VStack } from '@chakra-ui/react';
import { useEffect } from 'react';

import { BudgetRequestCard } from './BudgetRequestCard';

export interface BudgetsRequestListProps {
  consumerBudgets?: any;
  refetch?: any;
  activeTab: any;
}

export const BudgetsRequestList = ({
  activeTab,
  consumerBudgets,
  refetch,
}: BudgetsRequestListProps) => {
  useEffect(() => {
    if (activeTab === 0) refetch();
  }, [activeTab]);

  return (
    <VStack spacing={6}>
      <SimpleGrid
        maxH={{ base: '80vh' }}
        w="full"
        overflowY="auto"
        columns={{ base: 1, lg: 2, xl: 3 }}
      >
        {consumerBudgets?.map((consumerBudget, index) => (
          <GridItem key={index}>
            <BudgetRequestCard scheduleInfo={consumerBudget} />
          </GridItem>
        ))}
      </SimpleGrid>
    </VStack>
  );
};
