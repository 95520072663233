import { useQuery } from 'react-query';

import { getStoreSentBudgets } from '../api';

import { QueryConfig } from '@/lib/react-query';

type UseStoreSentBudgetsOptions = {
  config?: QueryConfig<typeof getStoreSentBudgets>;
};

export const useStoreSentBudgets = ({ config }: UseStoreSentBudgetsOptions) => {
  return useQuery(['getStoreSentBudgets'], getStoreSentBudgets, config);
};
