import { Flex, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';

export const ChatBox = ({ message, isMine }) => {
  return (
    <Flex my={2} p={2}>
      <Flex flexDirection="column" width="100%">
        <Flex
          bg={isMine ? 'brand.500' : '#EBEBEB'}
          pr={2}
          py={2}
          pl={4}
          borderRadius={12}
          boxShadow="0 2px 2px #0f0f0f0f"
          ml={isMine ? 'auto' : undefined}
          mr={isMine ? undefined : 'auto'}
        >
          <Text fontSize={15} wordBreak="break-word" color="black">
            {message?.text}
          </Text>
          <Flex ml="auto" mt="auto" pl={4} alignItems="center" justifyContent="flex-end">
            <Text fontSize={12} opacity="72%" color={isMine ? 'black' : '#4B4B4B'}>
              {dayjs(message?.sendedAt).format('HH:mm A')}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
