import { Button } from '@chakra-ui/button';
import { Text } from '@chakra-ui/layout';
import { Link } from 'react-router-dom';

import { MotionBox } from '@/components/common/MotionBox';
import { useAuth } from '@/lib/authentication';

export const CreateAccountFloatingBox = () => {
  const { user } = useAuth();

  if (!user)
    return (
      <MotionBox
        initial={{ y: 0 }}
        animate={{ y: 10 }}
        transition={{ delay: 0, duration: 1.5, yoyo: Infinity }}
        shadow="lg"
        borderBottomLeftRadius="50px"
        borderTopLeftRadius="50px"
        borderTopRightRadius="50px"
        bg="white"
        pos="absolute"
        bottom="24"
        right="10"
        p={10}
        d={{ base: 'none', lg: 'flex' }}
        flexDirection="column"
        alignItems="center"
      >
        <Text mb={5}>Não perca as informações pesquisadas!</Text>
        <Button as={Link} variant="@primary" to="/entrar">
          Crie sua conta
        </Button>
      </MotionBox>
    );

  return <></>;
};
