import { Center, Heading, Text, Button, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import errors from './errors.json';

import { Page } from '@/components/Page';

export const Error404 = () => {
  const navigate = useNavigate();

  return (
    <Page title={errors['404'].title}>
      <Center flex="1" p="8">
        <VStack direction={{ base: 'column', md: 'row' }} alignItems="center" spacing={5}>
          <Heading mb={5}>{errors['404'].title}</Heading>
          <Text color="black">{errors['404'].description}</Text>
          <Button variant="@primary" onClick={() => navigate(-1)} mx="auto">
            {errors['404'].actions.goBack}
          </Button>
        </VStack>
      </Center>
    </Page>
  );
};
