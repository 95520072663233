import { Box } from '@chakra-ui/react';

export const Checkbox = ({ checked, children, ...restProps }) => {
  return (
    <Box
      {...restProps}
      transition="all .1s ease-in"
      cursor="pointer"
      px={5}
      py={2}
      maxW="20%"
      mb={3}
      w="fit-content"
      fontSize="xs"
      rounded="full"
      fontWeight="bold"
      border="1px solid"
      bg={checked ? 'brand.500' : 'transparent'}
      borderColor={checked ? 'brand.500' : 'blackAlpha.500'}
      _hover={{
        bg: 'brand.200',
        borderColor: 'brand.300',
      }}
      minW="fit-content"
      mx={2}
    >
      {children}
    </Box>
  );
};
