import { Button, IconButton } from '@chakra-ui/button';
import { CloseButton } from '@chakra-ui/close-button';
import { useDisclosure } from '@chakra-ui/hooks';
import { Box, Container, Flex, HStack, VStack } from '@chakra-ui/layout';
import { chakra } from '@chakra-ui/system';
import VisuallyHidden from '@chakra-ui/visually-hidden';
import { useEffect, useRef } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { Link, useLocation } from 'react-router-dom';

import { Logo } from '../common/Logo';

import { useAuth } from '@/context/AppProvider';

export const Navbar = () => {
  const mobileNav = useDisclosure();
  const { pathname } = useLocation();
  const pageRef = useRef<HTMLDivElement>(null);
  const { user, repairShop, logout, store } = useAuth();

  useEffect(() => {
    if (pathname && pageRef) {
      pageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [pathname]);

  return (
    <chakra.header
      w="full"
      py={4}
      ref={pageRef}
      position="absolute"
      top="0"
      left="0"
      zIndex="sticky"
      bg="#F1F5F9"
    >
      <Container maxW={{ base: 'container.md', lg: '900px', xl: '1200px', '2xl': 'container.xl' }}>
        <Flex alignItems="center" justifyContent="space-between" mx="auto">
          <Button onClick={mobileNav.onClose} as={Link} to="/" variant="ghost" px={0}>
            <Logo maxW="100px" />
            <VisuallyHidden>MyCar</VisuallyHidden>
          </Button>

          <HStack display="flex" alignItems="center" spacing={1}>
            {user ? (
              repairShop ? (
                <HStack spacing={1} mr={1} display={{ base: 'none', lg: 'inline-flex' }}>
                  <Button onClick={mobileNav.onClose} as={Link} to="/" variant="ghost" px={4}>
                    Início
                  </Button>
                  <Button
                    onClick={() => {
                      mobileNav.onClose();
                      if (window.location.pathname.includes('/oficina/agendamentos'))
                        window.location.reload();
                      window.location.href = '/oficina/agendamentos';
                    }}
                    variant="ghost"
                    px={4}
                  >
                    Meus Agendamentos
                  </Button>
                </HStack>
              ) : store ? (
                <HStack spacing={1} mr={1} display={{ base: 'none', lg: 'inline-flex' }}>
                  <Button
                    onClick={() => {
                      mobileNav.onClose();
                      if (window.location.pathname.includes('/loja/orcamentos&vendas'))
                        window.location.reload();
                      window.location.href = '/loja/orcamentos&vendas';
                    }}
                    variant="ghost"
                    px={4}
                  >
                    Meus pedidos
                  </Button>

                  {!user && (
                    <Button
                      onClick={mobileNav.onClose}
                      as={Link}
                      to="/cadastro"
                      variant="ghost"
                      px={4}
                    >
                      Cadastrar-se
                    </Button>
                  )}
                </HStack>
              ) : (
                <HStack spacing={1} mr={1} display={{ base: 'none', lg: 'inline-flex' }}>
                  <Button
                    onClick={() => {
                      mobileNav.onClose();
                      if (window.location.pathname.includes('/agendamentos'))
                        window.location.reload();
                      window.location.href = '/agendamentos';
                    }}
                    variant="ghost"
                    px={4}
                  >
                    Meus agendamentos
                  </Button>
                  <Button
                    onClick={() => {
                      mobileNav.onClose();
                      if (window.location.pathname.includes('/pedidos')) window.location.reload();
                      window.location.href = '/pedidos';
                    }}
                    variant="ghost"
                    px={4}
                  >
                    Meus pedidos
                  </Button>
                  <Button
                    onClick={mobileNav.onClose}
                    as={Link}
                    to="/encontrar-oficinas"
                    variant="ghost"
                    px={4}
                  >
                    Encontrar oficinas
                  </Button>
                  <Button
                    onClick={mobileNav.onClose}
                    as={Link}
                    to="/encontrar-pecas"
                    variant="ghost"
                    px={4}
                  >
                    Comprar peças
                  </Button>

                  {!user && (
                    <Button
                      onClick={mobileNav.onClose}
                      as={Link}
                      to="/cadastro"
                      variant="ghost"
                      px={4}
                    >
                      Cadastrar-se
                    </Button>
                  )}
                </HStack>
              )
            ) : (
              <HStack spacing={1} mr={1} display={{ base: 'none', lg: 'inline-flex' }}>
                <Button
                  onClick={mobileNav.onClose}
                  as={Link}
                  to="/encontrar-oficinas"
                  variant="ghost"
                  px={4}
                >
                  Encontrar oficinas
                </Button>
                <Button
                  onClick={mobileNav.onClose}
                  as={Link}
                  to="/encontrar-pecas"
                  variant="ghost"
                  px={4}
                >
                  Comprar peças
                </Button>

                {!user && (
                  <Button
                    as={Link}
                    onClick={mobileNav.onClose}
                    to={pathname.includes('oficina') ? '/oficina/cadastro' : '/cadastro'}
                    variant="ghost"
                    px={4}
                  >
                    Cadastrar-se
                  </Button>
                )}
              </HStack>
            )}

            <Button
              as={Link}
              to={!user ? '/entrar' : '/'}
              variant="@primary"
              size="sm"
              onClick={() => {
                mobileNav.onClose();
                if (user) logout();
              }}
              display={{ base: 'none', lg: 'inline-flex' }}
            >
              {!user ? 'Entrar' : 'Sair'}
            </Button>

            <Box onMouseLeave={mobileNav.onClose} display={{ base: 'inline-flex', lg: 'none' }}>
              <IconButton
                display={{ base: 'flex', lg: 'none' }}
                aria-label="Open menu"
                fontSize="20px"
                color={'gray.800'}
                variant="ghost"
                icon={<AiOutlineMenu />}
                onClick={mobileNav.isOpen ? mobileNav.onClose : mobileNav.onOpen}
              />

              <VStack
                pos="absolute"
                top={0}
                left="50%"
                transform="translateX(-50%)"
                bg="brand.500"
                display={mobileNav.isOpen ? 'flex' : 'none'}
                flexDirection="column"
                p={2}
                pb={8}
                my={2}
                spacing={3}
                rounded="lg"
                shadow="sm"
                zIndex={1000}
                w="90%"
              >
                <HStack w="100%" justifyContent="space-between">
                  <Button onClick={mobileNav.onClose} as={Link} to="/" variant="ghost" px="3">
                    <VisuallyHidden>MyCar</VisuallyHidden>
                  </Button>
                  <CloseButton aria-label="Close menu" onClick={mobileNav.onClose} />
                </HStack>

                {user ? (
                  repairShop ? (
                    <>
                      <Button
                        onClick={mobileNav.onClose}
                        as={Link}
                        to="/"
                        variant="ghost"
                        px={4}
                        size="sm"
                      >
                        Início
                      </Button>
                      <Button
                        onClick={mobileNav.onClose}
                        as={Link}
                        to="/oficina/agendamentos"
                        variant="ghost"
                        px={4}
                        size="sm"
                      >
                        Meus Agendamentos
                      </Button>
                      <Button
                        onClick={() => {
                          mobileNav.onClose();
                          if (window.location.pathname.includes('/oficina/avaliacoes'))
                            window.location.reload();
                          window.location.href = '/oficina/avaliacoes';
                        }}
                        variant="ghost"
                        px={4}
                        size="sm"
                      >
                        Minhas Avaliações
                      </Button>
                    </>
                  ) : store ? (
                    <>
                      <Button
                        onClick={mobileNav.onClose}
                        as={Link}
                        to="/"
                        variant="ghost"
                        px={4}
                        size="sm"
                      >
                        Início
                      </Button>
                      <Button
                        onClick={mobileNav.onClose}
                        as={Link}
                        to="/loja/orcamentos&vendas"
                        variant="ghost"
                        px={4}
                        size="sm"
                      >
                        Meus Pedidos
                      </Button>
                      <Button
                        onClick={() => {
                          mobileNav.onClose();
                          if (window.location.pathname.includes('/loja/avaliacoes'))
                            window.location.reload();
                          window.location.href = '/loja/avaliacoes';
                        }}
                        variant="ghost"
                        px={4}
                        size="sm"
                      >
                        Minhas Avaliações
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={mobileNav.onClose}
                        as={Link}
                        to="/"
                        variant="ghost"
                        px={4}
                        size="sm"
                      >
                        Início
                      </Button>
                      <Button
                        onClick={mobileNav.onClose}
                        as={Link}
                        to="/perfil"
                        variant="ghost"
                        px={4}
                        size="sm"
                      >
                        Perfil
                      </Button>
                      <Button
                        onClick={mobileNav.onClose}
                        as={Link}
                        to="/agendamentos"
                        variant="ghost"
                        px={4}
                        size="sm"
                      >
                        Meus agendamentos
                      </Button>
                      <Button
                        onClick={mobileNav.onClose}
                        as={Link}
                        to="/pedidos"
                        variant="ghost"
                        px={4}
                        size="sm"
                      >
                        Meus pedidos
                      </Button>
                      <Button
                        onClick={mobileNav.onClose}
                        as={Link}
                        to="/encontrar-oficinas"
                        variant="ghost"
                        px={4}
                        size="sm"
                      >
                        Encontrar oficinas
                      </Button>
                      <Button
                        onClick={mobileNav.onClose}
                        as={Link}
                        to="/encontrar-pecas"
                        variant="ghost"
                        px={4}
                        size="sm"
                      >
                        Comprar peças
                      </Button>
                      <Button
                        onClick={() => {
                          mobileNav.onClose();
                          if (window.location.pathname.includes('/avaliacoes'))
                            window.location.reload();
                          window.location.href = '/avaliacoes';
                        }}
                        variant="ghost"
                        px={4}
                        size="sm"
                      >
                        Minhas Avaliações
                      </Button>

                      {!localStorage.getItem('@user') && (
                        <Button
                          onClick={mobileNav.onClose}
                          as={Link}
                          to="/cadastro"
                          variant="ghost"
                          px={4}
                          size="sm"
                        >
                          Cadastrar-se
                        </Button>
                      )}
                    </>
                  )
                ) : (
                  <>
                    <Button
                      onClick={mobileNav.onClose}
                      as={Link}
                      to="/encontrar-oficinas"
                      variant="ghost"
                      px={4}
                      size="sm"
                    >
                      Encontrar oficinas
                    </Button>
                    <Button
                      onClick={mobileNav.onClose}
                      as={Link}
                      to="/encontrar-pecas"
                      variant="ghost"
                      px={4}
                      size="sm"
                    >
                      Comprar peças
                    </Button>

                    {!user && (
                      <Button
                        as={Link}
                        onClick={mobileNav.onClose}
                        to={pathname.includes('oficina') ? '/oficina/cadastro' : '/cadastro'}
                        variant="ghost"
                        px={4}
                        size="sm"
                      >
                        Cadastrar-se
                      </Button>
                    )}
                  </>
                )}

                <Button
                  as={Link}
                  to={!user ? '/entrar' : '/'}
                  variant="@primary"
                  size="sm"
                  onClick={() => {
                    mobileNav.onClose();
                    if (user) logout();
                  }}
                >
                  {!user ? 'Entrar' : 'Sair'}
                </Button>
              </VStack>
            </Box>
          </HStack>
        </Flex>
      </Container>
    </chakra.header>
  );
};
