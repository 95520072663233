import { Flex } from '@chakra-ui/layout';
import { TabList as CTabList } from '@chakra-ui/react';
import ScrollContainer from 'react-indiana-drag-scroll';

export const TabList = ({ children, ...restProps }) => {
  return (
    <Flex
      pos="relative"
      mx={4}
      // _after={{
      //   opacity: [1, 0],
      //   content: '""',
      //   position: 'absolute',
      //   right: 0,
      //   top: '50%',
      //   transform: 'translateY(-50%)',
      //   height: '100%',
      //   bgGradient: `linear(to-l, brand.500, #BC00FF00)`,
      //   w: 16,
      //   zIndex: 1,
      //   borderRadius: 'md',
      // }}
    >
      <CTabList
        as={ScrollContainer}
        overflowX="scroll"
        whiteSpace="nowrap"
        zIndex={2}
        {...restProps}
      >
        {children}
      </CTabList>
    </Flex>
  );
};
