import { useToast } from '@chakra-ui/react';
import { getAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { Wizard, Steps, Step } from 'react-albus';

import {
  createBudgetRequest,
  createBudgetRequestPart,
  createBudgetRequestPartAttribute,
  createCar,
  getBudgetRequestById,
} from '../../api';
import { ChoosePartComponent } from '../ChoosePartComponent';
import { SelectCarForm } from '../SelectCarForm';

import schema from './schema';

import { Form } from '@/components/Form';
import { useFCM } from '@/context/AppProvider';
import { firebaseApp } from '@/firebase';
import { createAddress, editAddress, editCustomer, getUserAddress } from '@/modules/auth';

export const BudgetRequestForm = () => {
  const toast = useToast();
  const auth = getAuth(firebaseApp);

  const [customer] = useState(() => {
    const customer = localStorage.getItem('@customer');
    return customer && JSON.parse(customer);
  });

  const [parsedUser, setParsedUser] = useState(() => {
    const user = localStorage.getItem('@user');
    return user && JSON.parse(user);
  });

  const [hasBudget, setHasBudget] = useState(() => {
    const budget = localStorage.getItem('@budget-request');
    return budget && JSON.parse(budget);
  });

  const [userHasAddress, setUserHasAddress] = useState(() => {
    const address = localStorage.getItem('@address');
    return address && JSON.parse(address);
  });

  const [selectedCar, setSelectedCar] = useState<any>(hasBudget?.budget_request?.car || undefined);
  const [selectedParts, setSelectedParts] = useState<any[]>(hasBudget?.parts || []);

  const [selectedAddress, setSelectedAddress] = useState<any>(
    hasBudget?.address || userHasAddress || undefined,
  );

  const { setBudgetRequest } = useFCM();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const token = await auth.currentUser?.getIdToken(true);
      if (token) {
        localStorage.setItem('@token', token);
      }
    })();
  }, [auth.currentUser]);

  useEffect(() => {
    const budget = localStorage.getItem('@budget-request');
    if (budget) setHasBudget(JSON.parse(budget));

    const user = localStorage.getItem('@user');
    if (user) setParsedUser(JSON.parse(user));

    const address = localStorage.getItem('@address');
    if (address) setUserHasAddress(JSON.parse(address));

    setSelectedAddress(hasBudget?.address || userHasAddress || undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form<any>
      initialValues={schema}
      validationSchema={schema}
      onSubmit={async (values) => {
        try {
          setIsLoading(true);

          if (parsedUser) {
            if (hasBudget) {
              const car = await createCar({
                model: hasBudget?.car.model.id,
                year: hasBudget?.car.year,
                user: parsedUser.id,
                plate: hasBudget?.car.plate,
              });

              const budgetRequest = await createBudgetRequest({
                latitude: selectedAddress.latitude,
                longitude: selectedAddress.longitude,
                customer: parsedUser?.id,
                shipping: selectedAddress?.full_address,
                car: car?.car?.id,
              });

              const userAddress = await getUserAddress(customer);

              if (userAddress) {
                const editedAddress = await editAddress(userAddress.id, {
                  latitude: hasBudget.address.latitude,
                  longitude: hasBudget.address.longitude,
                  full_address: hasBudget.address.full_address,
                  complement: hasBudget.address.complement,
                });

                await editCustomer(customer, {
                  user: parsedUser?.id,
                  address: editedAddress.id,
                });

                localStorage.setItem('@address', JSON.stringify(editedAddress));
              } else {
                const createdAddress = await createAddress({
                  latitude: hasBudget.address.latitude,
                  longitude: hasBudget.address.longitude,
                  full_address: hasBudget.address.full_address,
                  complement: hasBudget.address.complement,
                });

                await editCustomer(customer, {
                  user: parsedUser?.id,
                  address: createdAddress.id,
                });

                localStorage.setItem('@address', JSON.stringify(createdAddress));
              }

              setBudgetRequest(budgetRequest.id);

              const promise = hasBudget.parts.map(async (part) => {
                const budgetRequestPart = await createBudgetRequestPart({
                  quantity: part.quantity,
                  part: part.id,
                  budget_request: budgetRequest.id,
                  manufacturer: part.manufacturer_id,
                });

                return part?.selected_attributes?.map(
                  async (attribute) =>
                    await createBudgetRequestPartAttribute({
                      budget_request_part: budgetRequestPart.id,
                      attribute: attribute,
                    }),
                );
              });

              await Promise.all(promise).then(async () => {
                await getBudgetRequestById(budgetRequest.id);

                toast({
                  title: 'Solicitação de orçamento enviada com sucesso.',
                  status: 'success',
                  duration: 5000,
                  isClosable: true,
                });

                localStorage.removeItem('@budget-request');

                window.location.href = '/pedidos';
                setIsLoading(false);
              });
            } else {
              const { car, parts } = values;

              const payload = {
                latitude: selectedAddress.latitude,
                longitude: selectedAddress.longitude,
                customer: parsedUser?.id,
                car: car.car.id,
                shipping: selectedAddress?.full_address,
              };

              const budgetRequest = await createBudgetRequest(payload);

              const userAddress = await getUserAddress(customer);

              if (userAddress) {
                const editedAddress = await editAddress(userAddress.id, {
                  latitude: selectedAddress?.latitude,
                  longitude: selectedAddress?.longitude,
                  full_address: selectedAddress?.full_address,
                  complement: selectedAddress?.complement,
                });

                await editCustomer(customer, {
                  user: parsedUser?.id,
                  address: editedAddress.id,
                });

                localStorage.setItem('@address', JSON.stringify(editedAddress));
              } else {
                const createdAddress = await createAddress({
                  latitude: selectedAddress?.latitude,
                  longitude: selectedAddress?.longitude,
                  full_address: selectedAddress?.full_address,
                  complement: selectedAddress?.complement,
                });

                await editCustomer(customer, {
                  user: parsedUser?.id,
                  address: createdAddress.id,
                });

                localStorage.setItem('@address', JSON.stringify(createdAddress));
              }

              setBudgetRequest(budgetRequest.id);

              const promise = parts.map(async (part) => {
                const budgetRequestPart = await createBudgetRequestPart({
                  quantity: part.quantity,
                  part: part.id,
                  budget_request: budgetRequest.id,
                  manufacturer: part.manufacturer_id,
                });

                return part?.selected_attributes?.map(
                  async (attribute) =>
                    await createBudgetRequestPartAttribute({
                      budget_request_part: budgetRequestPart.id,
                      attribute: attribute,
                    }),
                );
              });

              await Promise.all(promise).then(async () => {
                await getBudgetRequestById(budgetRequest.id);

                toast({
                  title: 'Solicitação de orçamento enviada com sucesso.',
                  status: 'success',
                  duration: 5000,
                  isClosable: true,
                });

                localStorage.removeItem('@budget-request');

                window.location.href = '/pedidos';
                setIsLoading(false);
              });
            }
          } else {
            const { car, parts } = values;

            const address = {
              latitude: selectedAddress?.latitude,
              longitude: selectedAddress?.longitude,
              full_address: selectedAddress?.full_address,
              complement: selectedAddress?.complement,
            };

            localStorage.setItem(
              '@budget-request',
              JSON.stringify({
                parts,
                address,
                car,
              }),
            );
            setIsLoading(false);
            window.location.href = '/entrar/bd';
          }
        } catch (error) {
          setIsLoading(false);
          console.log(error);
          toast({
            title: 'Erro ao enviar solicitação. Tente novamente.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      }}
      validateOnMount={false}
    >
      {({ handleSubmit }) => (
        <Wizard>
          {({ next, previous }) => (
            <>
              <Steps step={hasBudget && { id: 'select-parts' }}>
                <Step id="select-car">
                  <SelectCarForm
                    next={next}
                    selectedCar={selectedCar}
                    setSelectedCar={setSelectedCar}
                  />
                </Step>
                <Step id="select-parts">
                  <ChoosePartComponent
                    submit={handleSubmit}
                    previous={previous}
                    selectedParts={selectedParts}
                    setSelectedParts={setSelectedParts}
                    selectedAddress={selectedAddress}
                    setSelectedAddress={setSelectedAddress}
                    isLoading={isLoading}
                  />
                </Step>
              </Steps>
            </>
          )}
        </Wizard>
      )}
    </Form>
  );
};
